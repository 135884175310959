import request from '../util/request';

export const getManagedUsers = async () => {
  return request('/user/manager/list').then((res) => {
    if (!res || !res.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const getManagedUserDetails = async (userId: string) => {
  return request(`/user/manager/${userId}`).then((res) => {
    if (!res || !res.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
