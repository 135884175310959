import { GiftRose as RoseGift } from 'assets/svgs';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
type RoseSVGProps = React.SVGAttributes<SVGElement> & {
  type?: 'yellow' | 'white';
  price?: string | number;
};
const GiftRose: React.FC<RoseSVGProps> = forwardRef((props, ref) => {
  return (
    <div ref={ref as any} className={props.className}>
      <RoseGift {...props} />
    </div>
  );
});

export default styled(GiftRose)`
  .rose-block {
    width: 98px;
    background: rgba(0, 0, 0, 0.98);
    border-radius: 15px;
    color: #f5da92;
    font-size: 34px;
    line-height: 38px;
    font-weight: 600;
    margin: 0 0 5px;
    padding: 10px;
    text-align: center;
    svg {
      width: 40px;
      height: auto;
      vertical-align: top;
    }
    &.bg-default {
      background: #ff114f;
      color: #fff;
    }
  }
`;
