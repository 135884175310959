import { ImageThumbnail, InfoIcon, LockIcon, PlusFilled } from 'assets/svgs';
import attrAccept from 'attr-accept';
import Button from 'components/NButton';
import Scrollbar from 'components/Scrollbar';
import UploadandEditor from 'components/UploadandEditor';
import AttachmentContainer from 'pages/chat/components/AttachmentContainer';
import SortableList from 'pages/Sales/components/InputDragable/SortableList';
import { ReactElement, useEffect, useState } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import styled from 'styled-components';
import { MediaType } from 'types/ChatSubscription';
import { getAudioFileDuration, getVideoCover } from 'util/index';
import { v4 as uuid } from 'uuid';

interface Props {
  showButton?: boolean;
  onChange?: (files: MediaType[]) => void;
  limit?: number;
  accept?: string[] | string;
  value?: MediaType[];
  className?: string;
  message?: string;
  subMessage?: string;
}
function GallaryViewUploadWidget({
  value,
  showButton = true,
  onChange,
  limit = 10000,
  accept = ['image/*', 'video/*'],
  className,
  message,
  subMessage,

  ...rest
}: Props): ReactElement {
  const [files, setFiles] = useState<MediaType[]>([]);
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (value) setFiles(value);
  }, [value]);

  const handleSuccess = async (fls: any) => {
    const newFiles: MediaType[] = [];
    for (let index = 0; index < fls.length; index++) {
      const file = fls[index];
      let url = undefined;
      let thumb = undefined;
      let duratonInSeconds = 0;
      let duration = undefined;
      if (attrAccept(file, 'video/*')) {
        await getVideoCover(file)
          .then((payload: any) => {
            duration = payload.timeDuration;
            duratonInSeconds = payload.duration;
            thumb = URL.createObjectURL(payload.blob);
          })
          .catch((e) => {
            console.log(e, 'error');
          });
      } else if (attrAccept(file, 'image/*')) {
        url = URL.createObjectURL(file);
      } else if (attrAccept(file, 'audio/*')) {
        await getAudioFileDuration(file)
          .then((payload: any) => {
            duration = payload.timeDuration;
            duratonInSeconds = payload.duration;
          })
          .catch((e) => {
            console.log(e, 'error');
          });
      }
      newFiles.push({
        name: file.name,
        type: file.type,
        id: uuid(),
        size: file.size,
        orignalFile: file,
        path: url,
        thumbnail: thumb,
        videoDuration: duration,
        duration: duratonInSeconds,
      });
    }

    const f = files.concat(newFiles);
    setFiles(f);
    onChange?.(f);
  };
  const onSortEnd = async ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex === newIndex) return;
    const sortedArray = arrayMove<any>(files, oldIndex, newIndex).map(
      (item, index) => ({ ...item, sortOrder: index }),
    );
    setFiles(sortedArray);
  };
  const removeFile = (id: string) => {
    const f = files.filter((file) => file.id !== id);
    setFiles(f);
    onChange?.(f);
  };

  return (
    <div className={className}>
      <div
        className={`items-area ${files.length > 0 ? 'imag_media d-flex' : ''}`}
      >
        <Scrollbar className="custom-scroll-bar-holder">
          {files.length > 0 ? (
            <SortableList
              useDragHandle
              axis="xy"
              onSortEnd={onSortEnd}
              distance={5}
              lockAxis="x"
              items={files}
              renderItem={(m: any, index: number) => {
                return (
                  <AttachmentContainer
                    media={m}
                    dragHandle={true}
                    showOptions={{
                      closeIcon: true,
                      edit: false,
                      play:
                        !m.islocK && attrAccept({ type: m.type }, 'vidoe/*'),
                      video: attrAccept({ type: m.type }, 'vidoe/*'),
                      timeStampText: false,
                    }}
                    filekey={'id'}
                    onClose={removeFile}
                    icon={m.islocK ? <LockIcon /> : undefined}
                  />
                );
              }}
            />
          ) : (
            <div className="no-messages">
              <span className="img">
                <InfoIcon />
              </span>
              <strong className="title">
                {message || 'Create your Free Message'}
              </strong>
              <p>
                {subMessage ||
                  'Please enter your message and any related media.'}
              </p>
            </div>
          )}
          {showButton && (
            <UploadandEditor
              customRequest={handleSuccess}
              disabled={files.length >= limit}
              cropper={false}
              accept={accept}
              {...rest}
            >
              {files.length > 0 ? (
                <div className="upload_placeholder">
                  <PlusFilled />
                </div>
              ) : (
                <Button
                  icon={<ImageThumbnail />}
                  block
                  type="primary"
                  shape="circle"
                  className="bg-blue"
                >
                  ATTACH MEDIA
                </Button>
              )}
            </UploadandEditor>
          )}
        </Scrollbar>
      </div>
    </div>
  );
}

export default styled(GallaryViewUploadWidget)`
  padding: 1px 0 0;

  .items-area {
    height: 320px;

    @media (max-width: 767px) {
      height: 210px;
    }

    &.imag_media {
      height: 165px;

      @media (max-width: 767px) {
        height: 105px;
      }

      .rc-scollbar {
        display: flex;
        width: auto;
      }
    }

    .no-messages {
      @media (max-width: 767px) {
        padding: 20px 0 10px;
      }
    }

    .custom-scroll-bar-holder {
      > div {
        z-index: 10;

        &:first-child {
          overflow-x: auto !important;
        }
      }
    }

    .sortable {
      display: flex;
      margin-top: 0;
    }

    .drag-dots {
      width: 4px;
      height: 4px;
      display: block;
      margin: 8px auto 0;
      color: #d6dade;
      transform: rotate(90deg);
      transition: all 0.4s ease;
      position: relative;
      right: -8px;

      @media (max-width: 767px) {
        right: -13px;
      }

      &:hover {
        color: #255b87;
      }
    }
  }

  .upload_placeholder {
    width: 106px;
    min-width: 106px;
    height: 136px;
    background: #e1e4eb;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 767px) {
      height: 77px;
      width: 77px;
      min-width: 77px;
    }

    svg {
      width: 46px;
      height: 46px;
      color: #255b87;

      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
      }
    }
  }

  .imag_media {
    margin: 20px -5px 10px;
    overflow: auto;
  }

  .img-container {
    width: 136px;
    min-width: 136px;
    height: 136px;
    padding: 0;
    margin: 0 10px 10px 5px;

    @media (max-width: 767px) {
      height: 77px;
      width: 77px;
      min-width: 77px;
    }

    img {
      position: static;
    }
  }
`;
