import { Calendar, PopCourse } from 'assets/svgs';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import styled from 'styled-components';
import IPop from 'types/Pop.type';
import IUser from 'types/user';

dayjs.extend(advancedFormat);
interface ICourseHeader {
  className?: string;
  pop?: IPop;
  publicUser?: IUser;
}

const CourseHeader: React.FunctionComponent<ICourseHeader> = ({
  className,
  pop,
  publicUser,
}) => {
  return (
    <div className={`${className}  pop-card`}>
      <div className="heading_Wrapper d-flex align-items-center justify-content-between">
        <div className="heading_Wrapper d-flex align-items-center">
          {pop?.isThumbnailActive && pop?.popThumbnail ? (
            <img src={pop.popThumbnail} className="thumbnail" alt="" />
          ) : (
            <span className="icon">{<PopCourse />}</span>
          )}
          <h2>{pop?.title}</h2>
        </div>

        <span className="course_price">${pop?.price}</span>
      </div>
      {pop?.description && <p>{pop.description}</p>}
      <div className="course-detail">
        <div className="instructor_info">
          <div className="instructor">Created by:</div>
          <div className="creator-image">
            {/* "/assets/images/img-user02.jpg" */}
            <img
              src={`${
                publicUser?.profileImage ||
                '/assets/images/default-profile-img.svg'
              }`}
              alt="..."
            />
          </div>
          <div className="instructor_name">
            <span className="instructor_firstname">
              {publicUser?.firstName}
            </span>{' '}
            {publicUser?.lastName}
          </div>
        </div>
        <ul className="instructor_course_info">
          <li>
            <div className="img">
              <Calendar />
            </div>
            {/* <div className="text">last Updated: 10/20/21</div> */}
            <div className="text">
              Last Updated: {dayjs(pop?.updatedAt).format('MM/DD/YY')}
            </div>
          </li>
          {/* <li>
            <div className="img">
              <WorldIcon />
            </div>
            <div className="text">English</div>
          </li> */}
          {/* <li>
            <div className="img img-large">
              <img src="/assets/images/CCIcon.png" alt="..." />
            </div>
            <div className="text">English</div>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default styled(CourseHeader)`
  font-size: 18px;
  line-height: 1.5;
  color: #7a7a7a;
  font-weight: 500;
  padding: 0 0 25px;

  @media (max-width: 579px) {
    font-size: 14px;
  }

  h2 {
    color: #404040;
    font-size: 30px;
    line-height: 1.3;
    font-weight: 500;
    margin: 0 0 5px;

    @media (max-width: 579px) {
      font-size: 24px;
    }
  }

  p {
    margin: 0 0 6px;
  }

  .course-detail {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 400;
  }

  .instructor_info {
    display: flex;
    align-items: center;
    color: #6a6a6a;
    font-size: 14px;
    padding: 0 20px 10px 0;
    line-height: 18px;
  }

  .instructor_name {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    .instructor_firstname {
      text-transform: capitalize;
    }
  }

  .creator-image {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 8px 0 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .instructor_course_info {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 13px;
    line-height: 16px;
    color: #330c3a;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      padding: 0 25px 10px 0;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        padding: 0 15px 10px 0;
      }
    }

    .text {
      padding: 0 0 0 11px;
    }
  }
  .icon {
    margin-right: 12px;
    color: #3b719e;
    width: 50px;
    height: 50px;

    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }
  .thumbnail {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: cover;
    margin: 0 10px 0 0;
  }
`;
