import Loading from 'components/loader';
import { ENVIRONMENT } from 'config';
import SelfiePopPrivate from 'pages/[username]/[popslug]/components/SelfiePopPrivate';
import React, { Fragment, ReactElement } from 'react';
import { Route, RouteProps, useParams } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

const Page404 = React.lazy(() => import('pages/404'));
const GuestLayout = React.lazy(() => import('layout/guest'));
const PublicVideoLayout = React.lazy(() => import('layout/PublicVideoLayout'));
const SinglePanelLayout = React.lazy(() => import('layout/SinglePanelLayout'));

const PublicVideo = React.lazy(() => import('pages/account/public-video'));
const Course = React.lazy(() => import('pages/course'));
const ActivateAccount = React.lazy(() => import('pages/activate-account'));
const ProfileUnavailable = React.lazy(
  () => import('pages/profile-unavailable'),
);
const EmailVerify = React.lazy(() => import('pages/verify-email'));
const RecoverPassword = React.lazy(() => import('pages/recover-password'));
const ResetPassword = React.lazy(() => import('pages/reset-password'));
const ResetPasswordRecoveryLink = React.lazy(
  () => import('pages/reset-password-recovery-link'),
);

const ComingSoonForm = React.lazy(() => import('pages/coming-soon/form'));
const ComingSoon = React.lazy(() => import('pages/coming-soon'));
const Markups = React.lazy(() => import('pages/markups'));
const Home = React.lazy(() => import('pages/index'));
const SignUp = React.lazy(() => import('pages/signup'));
const Terms = React.lazy(() => import('pages/terms'));
const Policy = React.lazy(() => import('pages/policy'));

const Login = React.lazy(() => import('pages/login'));
const Logout = React.lazy(() => import('pages/logout'));

export const EmptyLayout: React.FC = ({ children, ...rest }) => {
  return (
    <Fragment>{React.cloneElement(children as ReactElement, rest)}</Fragment>
  );
};

export interface IRoutes extends Partial<RouteProps> {
  name?: string;
  Component: any;
  RouteComponent?: React.ElementType;
  Layout?: React.ElementType;
  layoutProps?: { [key: string]: any };
}

const RedirectToSign = () => {
  const { username } = useParams<{ username: string }>();
  return <Redirect to={`/signup/${username}`} />;
};

const routes: IRoutes[] = [
  {
    name: 'Coming Soon',
    path: '/coming-soon',
    Component: ComingSoon,
    Layout: EmptyLayout,
    exact: true,
  },
  {
    name: 'Coming Soon Form',
    path: '/coming-soon/registration',
    Component: ComingSoonForm,
    Layout: EmptyLayout,
    exact: true,
  },
  {
    name: 'Home',
    path: '/',
    Component: Home,
    exact: true,
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    Component: () => <Redirect to="/my-profile" />,
    exact: true,
  },
  {
    name: 'login',
    path: '/login',
    Component: Login,
    Layout: GuestLayout,
    exact: true,
  },
  {
    name: 'Logout',
    path: '/logout',
    Component: Logout,
    Layout: GuestLayout,
    exact: true,
  },
  {
    name: 'Verify Email',
    path: '/verify-email',
    Component: EmailVerify,
    exact: true,
  },
  {
    name: 'Reset Password',
    path: '/reset-password',
    Component: ResetPassword,
    exact: true,
  },
  {
    name: 'Activate Account',
    path: '/activate-account',
    Component: ActivateAccount,
    exact: true,
  },
  {
    name: 'Reover Password',
    path: '/reset-password-recovery-link',
    Component: ResetPasswordRecoveryLink,
    exact: true,
  },
  {
    name: 'Terms',
    path: '/terms',
    Component: Terms,
    exact: true,
  },
  {
    name: 'Private Pop',
    path: '/privatePop',
    Component: SelfiePopPrivate,
    exact: true,
  },
  {
    name: 'Course',
    path: '/course',
    Component: Course,
    exact: true,
  },
  {
    name: 'Policy',
    path: '/policy',
    Component: Policy,
    exact: true,
  },
  {
    name: 'Recover Password',
    path: '/recover-password',
    Component: RecoverPassword,
    exact: true,
  },
  {
    name: 'signup',
    path: '/signup',
    Component: SignUp,
    Layout: GuestLayout,
    exact: true,
  },
  {
    name: 'signup',
    path: '/signup/:refId',
    Component: SignUp,
    Layout: GuestLayout,
    exact: true,
  },
  {
    name: '404',
    path: '/404',
    Component: Page404,
    exact: true,
  },
  {
    name: 'profile-unavailable',
    path: '/profile-unavailable',
    Component: ProfileUnavailable,
    exact: true,
  },
];

export const RoutesArray: string[] = routes
  .map((r) => r.path as string)
  .concat(['/markups', '/shoutout/view/:orderId', '/innercircle/:username']);

export default function Routes(): ReactElement {
  return (
    <React.Suspense fallback={<Loading loading={true} />}>
      <Switch>
        {routes.map((route, index: number) => {
          const {
            Component,
            Layout,
            RouteComponent,
            name,
            path,
            exact,
            layoutProps,
          } = route;
          const CLayout = Layout || EmptyLayout;
          const CRoute = RouteComponent || Route;
          return (
            <CRoute key={index} name={name} path={path} exact={exact}>
              <CLayout key={path} {...layoutProps}>
                <Component />
              </CLayout>
            </CRoute>
          );
        })}

        {ENVIRONMENT === 'development' && (
          <Route path={'/markups'} component={Markups} exact />
        )}
        <Route path="/shoutout/view/:orderId" exact>
          <PublicVideoLayout>
            <SinglePanelLayout>
              <PublicVideo />
            </SinglePanelLayout>
          </PublicVideoLayout>
        </Route>
        <Route path="/innercircle/:username" exact>
          <RedirectToSign />
        </Route>
      </Switch>
    </React.Suspense>
  );
}
