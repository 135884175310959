import { checkOrderExists, orderCreate } from 'api/Order';
import { getPopByName } from 'api/Pop';
import { updateGuestUser } from 'api/User';
import { createOrderWithGuestUser } from 'api/Utils';
import { EmailSvg, NameSvg } from 'assets/svgs';
import { AvatarStatus } from 'components/ChatWidgets';
import FocusInput from 'components/focus-input';
import Model, { AppAlert } from 'components/Model';
import SignInPopUP from 'components/SignInPopUp';
import { ServiceType } from 'enums';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import useDispatch from 'hooks/useDispatch';
import useOpenClose from 'hooks/useOpenClose';
import useRequestLoader from 'hooks/useRequestLoader';
import { stringify } from 'querystring';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  RESET_PURCHASE_STATES,
  SET_GUEST_USER,
  SET_ORDER,
} from 'reducer/purchaseStates';
import styled from 'styled-components';
import { arrayFilter, getImageURL, isValidUrl } from 'util/index';
import * as yup from 'yup';
import ListItem from './components/ListItem';
const StyledListItem = styled.div`
  .form_section {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
    padding: 23px 17px;
    color: #565656;
    margin: 0 0 30px;
    ${({ theme }) => {
      if (theme?.button?.style?.includes('soft-square')) {
        return `
          border-radius: 4px;
        `;
      }
      if (theme?.button?.style?.includes('circule')) {
        return `
           border-radius: 35px;
        `;
      }
    }}

    .user_head {
      margin: 0 0 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    .form-heading {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 20px;

      h2 {
        margin: 0;
        font-size: 28px;
        line-height: 32px;
        font-weight: 700;

        @media (max-width: 767px) {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }

    .user-image {
      width: 50px;
      height: 50px;
      min-width: 50px;
      margin: 0 15px 0 0;

      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
        min-width: 30px;
        margin: 0 10px 0 0;
      }
    }

    .materialized-input {
      &.text-input {
        margin: 0 0 16px !important;
        &.input-active {
          label {
            font-size: 13px;
            top: -8px;
          }
        }

        label {
          color: #9b9b9b;
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
          top: 17px;
          background: none;

          &:after {
            position: absolute;
            left: 0;
            right: 0;
            top: 10px;
            content: '';
            height: 2px;
            background: #fff;
          }

          span {
            position: relative;
            z-index: 2;
          }
        }

        .pre-fix {
          top: 16px;
          left: 14px;
          width: 22px;

          path {
            fill: #9b9b9b;
          }
        }

        .error-msg {
          @media (min-width: 768px) {
            position: absolute;
            right: 10px;
            top: 16px !important;
            width: auto !important;
          }
        }
      }

      .form-control {
        height: 52px;
        border-color: #484848;
        background: #fff;
      }
    }

    .profile-btn {
      margin: 0 !important;
    }
  }
`;
const ChatSubscriptionForm = ({
  className,
  IsUser,
  onSubmitCb,
  publicUser = {},
}: any) => {
  const { loggedIn, user } = useAuth();
  const [isOpen, openSignPopUp, closeSignPopUp] = useOpenClose(false);
  const [stopPOPup, onStopPOPupOpen, onStopPOPupClose] = useOpenClose();
  const [selectedV, setSelectedV] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [ownProfile, setOwnProfile] = useState<boolean>(false);
  const [pop, setPop] = useState<any>({});
  const { stopLoading } = useRequestLoader();
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Enter valid email address')
      .required('Enter valid email address'),
    name: yup
      .string()
      .required('Enter your full name')
      .matches(/^(\D+\s+\D+)(\s*\D*)*$/, 'Enter your full name'),
  });

  const { username, popslug } = useParams<{
    username: string;
    popslug: string;
  }>();

  const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
    useFormik({
      validationSchema,
      initialValues: {
        name: '',
        email: '',
        phone: '',
      },
      onSubmit: async (formData) => {
        handleCreateOrder(formData);
        onSubmitCb?.(formData);
      },
    });
  useEffect(() => {
    //     if (!!username && !!popslug) {
    //   getPopData();
    // }
    // Remove later when api is ready to give pop
    if (publicUser?._id) {
      if (user._id === publicUser._id) {
        setOwnProfile(true);
      } else {
        const userChatSubPop: any = publicUser.links.find(
          (u: any) => u?.popLinksId?.popType === ServiceType.CHAT_SUBSCRIPTION,
        );
        if (userChatSubPop) {
          getPopData(userChatSubPop.popLinksId.popName);
        }
      }
    }
  }, [publicUser?._id]);
  const getPopData = async (slug: string = '') => {
    const popData = await getPopByName(username, popslug || slug).catch(
      (e: Error) => console.log(e),
    );
    if (!popData) {
      return history.replace('/404');
    }
    const vs: any = [
      // {
      //   _id,
      //   title,
      //   price,
      //   description,
      //   product: true,
      //   isActive: true,
      // },
      ...(popData?.priceVariations || []),
    ]
      .filter(({ isActive }) => isActive)
      .map((v: any) => {
        return { label: `${v.title || ''} - $${v.price}`, value: v };
      });
    // setVariations(vs);
    const indexof = vs?.findIndex((el: any) => el?.value?.isDefault === true);
    if (indexof !== -1) {
      setSelectedV(vs[indexof]);
    } else {
      setSelectedV(vs[0]);
    }
    if (!popData.owner?.isActiveProfile) {
      if (user?._id !== popData.owner?._id) {
        history.replace('/');
      }
    }

    // check if the user is need this own profile
    if (loggedIn && user?._id === popData.owner?._id) {
      setOwnProfile(true);
    }

    if (popData?.owner) {
      popData.owner.themeName = popData.owner.themeColor?.colorSetName
        .toLowerCase()
        .replace(' ', '-');
      popData.owner.themeNumber = 1;
      if (popData.owner.themeName === 'purple-haze')
        popData.owner.themeNumber = 2;
      else if (popData.owner.themeName === 'sedona')
        popData.owner.themeNumber = 3;
    }

    setPop(popData);
  };
  const onSuccessLoginCB = async (user: any) => {
    closeSignPopUp();
    const selected = selectedV.value;
    const ifNotExist = await checkIfexist();
    if (!ifNotExist) {
      return;
    }
    const sQuestions = arrayFilter(pop?.questions || [], {
      isActive: true,
    }).concat(arrayFilter(selected?.questions || [], { isActive: true }));

    let requestData: any = {
      questions: sQuestions,
      popId: pop._id,
      vpopId: selected._id,
      buyer: loggedIn && user?._id ? user?._id : null,
    };
    const response = loggedIn
      ? await orderCreate(requestData)
      : await createOrderWithGuestUser(requestData);
    const { order } = response;
    const prams = stringify({ order: order._id, type: pop.popType });
    history.push(
      `/${publicUser?.username}/purchase/add-a-card-and-checkout?${prams}`,
    );
  };
  const handleCreateOrder = async (values?: {
    name: string;
    email: string;
    phone: string;
  }) => {
    if (ownProfile) {
      onStopPOPupOpen();
      return true;
    }

    const selected = selectedV.value;

    const sQuestions = arrayFilter(pop?.questions || [], {
      isActive: true,
    }).concat(arrayFilter(selected?.questions || [], { isActive: true }));
    setIsLoading(true);
    stopLoading(true);
    dispatch({ type: RESET_PURCHASE_STATES });

    let requestData: any = {
      questions: sQuestions,
      popId: pop._id,
      vpopId: selected?._id,
      buyer: loggedIn && user?._id ? user?._id : null,
    };
    try {
      !loggedIn && (await validationSchema.validate(values));
      const response = loggedIn
        ? await orderCreate(requestData)
        : await createOrderWithGuestUser(requestData, values?.email);

      const { order, guestUser } = response;
      if (!loggedIn) dispatch({ type: SET_GUEST_USER, payload: guestUser });

      dispatch({
        type: SET_ORDER,
        payload: order,
      });
      if (!loggedIn) {
        await validationSchema.validate(values);
        const name = values?.name?.replace(/\s{2,}/g, ' ').trim();
        const firstName = name?.split(' ').slice(0, -1).join(' ');
        const lastName = name?.split(' ').slice(-1).join(' ');
        await updateGuestUser({
          firstName,
          lastName,
          email: values?.email,
          phone: values?.phone,
        }).then((data) => {
          dispatch({
            type: SET_GUEST_USER,
            payload: { ...guestUser, ...data },
          });
        });
      }
      const prams = stringify({ order: order._id, type: pop.popType });
      return history.push(
        `/${publicUser?.username}/purchase/add-a-card-and-checkout?${prams}`,
      );
    } catch (e: any) {
      setIsLoading(false);
      if (e?.message === 'Email already exists') {
        openSignPopUp();
        return;
      }
      AppAlert({
        title: 'Please Enter Correct information',
        text: e.message,
      });
    }
    stopLoading(false);
    setIsLoading(false);
  };
  const { url: profileImageUrl, fallbackUrl: profileFallback } = getImageURL(
    publicUser?.profileImage,
    {
      defaultUrl: publicUser?.profileImage
        ? publicUser?.profileImage
        : '/assets/images/default-profile-img.svg',

      imgix: { all: 'w=163&h=163' },
    },
  );
  const checkIfexist = async () => {
    return await checkOrderExists(pop?._id).then((res: any) => {
      if (res?.isExist) {
        setIsLoading(false);

        AppAlert({
          title: 'Order Already Exists',
          text: 'Redirecting to chat',
          onConfirm: () => {
            history.push(
              `/my-subscriptions?userId=${publicUser?._id}&type=chat`,
            );
          },
        });
        return false;
      }
      return true;
    });
  };
  const submitHandler = async (e: any) => {
    if (ownProfile) {
      onStopPOPupOpen();
      return;
    }
    if (!ownProfile) {
      if (loggedIn) {
        e?.preventDefault();
        setIsLoading(true);
        const ifNotExist = await checkIfexist();
        if (ifNotExist) {
          handleCreateOrder();
        } else {
          return;
        }
      } else {
        handleSubmit();
      }
    } else {
      onStopPOPupClose();
    }
  };

  return (
    <>
      <div className={`${className} form-subscription-area text-center`}>
        <StyledListItem>
          <div className="form_section">
            <div className="user_head">
              <div className="form-heading">
                <AvatarStatus
                  src={
                    publicUser.profileImage
                      ? profileImageUrl
                      : '/assets/images/default-profile-img.svg'
                  }
                  fallbackUrl={
                    publicUser.profileImage ||
                    '/assets/images/default-profile-img.svg'
                  }
                />
                <h2>Subscribe for Free</h2>
              </div>
              <span>
                Signup here to get access my private chat and exclusive member
                only content!
              </span>
            </div>
            {!IsUser && (
              <div className="fields-wrap">
                <FocusInput
                  value={values.name}
                  hasIcon={true}
                  label="Public Username"
                  name="name"
                  prefixElement={<NameSvg />}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.name}
                  materialDesign
                  touched={touched.name}
                />
                <div className="form_fields">
                  <FocusInput
                    value={values.email}
                    hasIcon={true}
                    label="Your Email"
                    name="email"
                    prefixElement={<EmailSvg />}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email}
                    materialDesign
                    touched={touched.email}
                  />
                </div>
                <ListItem
                  fallbackUrl={pop?.imageURL}
                  key={pop._id}
                  title={'Get Free Access'}
                  linkType={!isValidUrl(pop?.imageURL) ? pop?.platfrom : ''}
                  // icon={iconUrl}
                  onClick={submitHandler}
                  showIcon={false}
                  isLoading={isLoading}
                />
              </div>
            )}
            {IsUser && (
              <div className="fields-wrap">
                <ListItem
                  fallbackUrl={pop?.imageURL}
                  key={pop._id}
                  title={'Get Free Access'}
                  linkType={!isValidUrl(pop?.imageURL) ? pop?.platfrom : ''}
                  onClick={submitHandler}
                  showIcon={false}
                  isLoading={isLoading}
                />
              </div>
            )}
          </div>
        </StyledListItem>
      </div>
      <Model
        open={stopPOPup}
        icon="error"
        title="Can not perform this action"
        text="Sorry, but you can not purchase a service from yourself."
        onClose={onStopPOPupClose}
        onConfirm={onStopPOPupClose}
      />
      <SignInPopUP
        isOpen={isOpen}
        onClose={closeSignPopUp}
        onSuccessCallback={onSuccessLoginCB}
        email={values.email}
      />
    </>
  );
};
export default styled(ChatSubscriptionForm)``;
