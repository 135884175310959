import Image from 'components/Image';
import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  src?: string;
  isActive?: boolean;
  fallbackUrl?: string;
}

const AvatarStatus: React.FC<Props> = (props) => {
  const { className, src, isActive, fallbackUrl } = props;

  return (
    <div className={`${className} user-image`}>
      <Image
        src={src || '/assets/images/default-profile-img.svg'}
        fallbackUrl={fallbackUrl || '/assets/images/default-profile-img.svg'}
      />
      {isActive && (
        <span className="status" style={{ backgroundColor: '#06D6A0' }}></span>
      )}
    </div>
  );
};

export default styled(AvatarStatus)`
  &.user-image {
    position: relative;
    width: 37px;
    height: 37px;

    .image-comp {
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .status {
      position: absolute;
      right: -1px;
      bottom: 1px;
      border: 2px solid #ffffff;
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }
  }
`;
