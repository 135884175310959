import { Card, ProfileCard } from 'components/PrivatePageComponents';
import CardAddComments from 'components/PrivatePageComponents/CardAddComments';
import CardListComments from 'components/PrivatePageComponents/CardListComments';
import CardPublicFooter from 'components/PrivatePageComponents/CardPublicFooter';
import PostBannerImages from 'components/PrivatePageComponents/PostBannerImages';
import PostBuySection from 'components/PrivatePageComponents/PostBuySection';
import PostCaption from 'components/PrivatePageComponents/PostCaption';
import PrivatePost from 'components/PrivatePageComponents/PrivatePost';
import PublicCardImage from 'components/PrivatePageComponents/PublicCardImage';
import useAuth from 'hooks/useAuth';
import useDispatch from 'hooks/useDispatch';
import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

function SelfiePopPrivatePop({
  className,
}: {
  className?: string;
}): ReactElement {
  const { user, loggedIn } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className={`${className} container sm-container mt-20`}>
      {/* <ProfileCard className="mb-20" />
      <Card /> */}

      <ProfileCard className="mb-20" />
      <Card cardClass="mb-20" key={1}>
        <Card.Header />
        <Card.Body caption={<PostCaption />}>
          <div className="card-private-area">
            <PrivatePost />
            <PostBuySection />
          </div>
        </Card.Body>
      </Card>

      <Card cardClass="mb-20" key={2}>
        <Card.Header />
        <Card.Body caption={<PostCaption />}>
          <PublicCardImage />
        </Card.Body>
        <Card.Footer>
          <CardPublicFooter />
        </Card.Footer>
      </Card>
      <Card cardClass="mb-20" key={3}>
        <Card.Header />
        <Card.Body caption={<PostCaption />}></Card.Body>
        <Card.Footer>
          <CardPublicFooter />
        </Card.Footer>
      </Card>
      <Card cardClass="mb-20" key={4}>
        <Card.Header />
        <Card.Body caption={<PostCaption />}>
          <PostBannerImages />
        </Card.Body>
        <Card.Footer>
          <CardPublicFooter />
        </Card.Footer>
        <CardAddComments />
        <CardListComments />
      </Card>
    </div>
  );
}

export default styled(SelfiePopPrivatePop)``;
