import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Modal from './modal';

export function RouterPrompt(props: any) {
  const { when, onOK, onCancel, title, showPrompt, setShowPrompt, boolFunc , subTitle } =
    props;

  const history = useHistory();
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (when && !boolFunc()) {
      history.block((prompt) => {
        prompt.search
          ? setCurrentPath(`${prompt.pathname}${prompt.search}`)
          : setCurrentPath(`${prompt.pathname}`);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }
    return () => {
      history.block(() => {});
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, when, boolFunc]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK, showPrompt]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Modal
      isOpen={showPrompt}
      title={title}
      footerChange={true}
      onOk={handleOK}
      onClose={() => handleCancel()}
    >
      {
        subTitle || 'There are unsaved changes. Are you sure want to leave this page ?'
      }
      
    </Modal>
  ) : null;
}
