import React, { forwardRef, useEffect, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

// Only loads the YouTube player
type IVdideoProps =   ReactPlayerProps & {
className?: string
}
const VideoPlay = forwardRef(
  ({ url, mute, loop, playing, className= 'videoPlayer', ...rest }: IVdideoProps, ref) => {
    const [isPlaying, setIsPlaying] = useState<boolean>();
    useEffect(() => {
      setIsPlaying(playing);
      return () => {};
    }, [playing]);

    return (
      <div className={`rc-vidoe-player ${className}`}>
        <ReactPlayer
          ref={ref as any}
          url={url}
          muted={mute}
          controls={true}
          className={'react-player'}
          width="100%"
          height="100%"
          playing={isPlaying}
          loop={loop}
          {...rest}
        />
      </div>
    );
  },
);

export default VideoPlay;
