import {
  DollarChat,
  LikeHeart,
  MessageBoxIcon,
  SolidHeart,
  StatisticsIcon,
} from 'assets/svgs';
import ToolTip from 'components/tooltip';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect, useState } from 'react';
import { updatelikes } from 'store/reducer/salesState';
import { onToggleModal } from 'store/reducer/statisticsModelState';
import styled from 'styled-components';
import {
  IPost,
  IPostCommentType,
  IPostLikesType,
  IPostUser,
} from 'types/Sales';
import { v4 as uuid } from 'uuid';
interface ICardPublicFooter {
  className?: string;
  id?: string;
  user?: Record<string, any>;
  onChangeLike?: (
    id: string,
    liked: boolean,
    postLikes: IPostLikesType,
  ) => Promise<any>;
  postliked?: boolean;
  postLikes?: IPostLikesType;
  setCommentOpen?: Function;
  tipHandler?: Function;
  comments?: IPostCommentType;
  isSeller?: Boolean;
  post?: Partial<IPost>;
}

const CardPublicFooter: React.FunctionComponent<ICardPublicFooter> = ({
  className,
  setCommentOpen,
  postliked,
  postLikes = { items: [], totalCount: 0 },
  onChangeLike,
  tipHandler,
  user = {},
  id = '',
  isSeller = false,
  comments,
  post,
}) => {
  const [liked, setLiked] = useState<boolean>(false);
  const [disableclick, setDisableclick] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setLiked(!!postliked);
  }, [user, id, postliked]);

  const getFirstFour = (postLikes: any) => {
    let images: any = [];
    if (!postLikes?.totalCount) {
      return images;
    }
    if (liked) {
      images.push({
        img: user.profileImage || '/assets/images/default-profile-img.svg',
        _id: user._id,
      });
    }
    for (let i = 0; i <= postLikes?.totalCount; i++) {
      if (postLikes.items[i]) {
        const lUser = postLikes.items[i].userId;
        if (user?._id !== (lUser as IPostUser)._id) {
          images.push({
            img:
              (lUser as IPostUser)?.profileImage ||
              '/assets/images/default-profile-img.svg',
            _id: (lUser as IPostUser)._id,
          });
        }
        if (images.length >= 4) {
          break;
        }
      }
    }
    return images;
  };

  const totalCount = postLikes.totalCount;

  let secondUser = postLikes.items.find(
    (p) => (p.userId as IPostUser)._id !== user?._id,
  )?.userId;
  let thirdUser = postLikes.items.find(
    (p) =>
      (p.userId as IPostUser)._id !== (secondUser as IPostUser)?._id &&
      (p.userId as IPostUser)._id !== user?._id,
  )?.userId;
  return (
    <div className={className}>
      <div className="card-public-footer-container">
        <div className="card-public-footer-container-item">
          <div className="footer-left">
            <div
              className="img-heart"
              onClick={() => {
                if (disableclick) {
                  return;
                }
                setDisableclick(true);
                const tempLikes = { ...postLikes };
                const newPostlikes = {
                  items: [...postLikes.items],
                  totalCount: postLikes.totalCount,
                };
                setLiked(!liked);
                if (liked) {
                  const index = newPostlikes.items.findIndex(
                    (p) => (p.userId as IPostUser)?._id === user?._id,
                  );
                  if (index > -1) {
                    newPostlikes.items.splice(index, 1);
                    newPostlikes.totalCount = newPostlikes.totalCount - 1;
                  }
                } else {
                  newPostlikes.totalCount = newPostlikes.totalCount + 1;
                  newPostlikes.items.unshift({
                    createdAt: dayjs().utc().format(),
                    postId: id,
                    updatedAt: dayjs().utc().format(),
                    userId: {
                      firstName: user?.username,
                      lastName: user?.lastName,
                      profileImage: user?.profileImage,
                      username: user?.username,
                      _id: user?._id,
                    },
                    _id: uuid(),
                  });
                }
                onChangeLike?.(id, !liked, newPostlikes)
                  .then(() => {
                    setDisableclick(false);
                  })
                  .catch((e) => {
                    dispatch(
                      updatelikes({
                        postLikes: tempLikes,
                        postId: id,
                      }),
                    );
                    setDisableclick(false);
                    setLiked(!liked);
                  });
              }}
            >
              <span className="img">
                {liked ? (
                  <ToolTip overlay={'Liked'}>
                    <SolidHeart fill="red" />
                  </ToolTip>
                ) : (
                  <ToolTip overlay={'Like'}>
                    <LikeHeart />
                  </ToolTip>
                )}
              </span>

              {!!totalCount && (
                <span className="counter">{totalCount || 0}</span>
              )}
            </div>{' '}
            <div className="image_row">
              {getFirstFour(postLikes).map((img: any) => {
                return <img key={img._id} src={img.img} alt="" />;
              })}
              {/* <img src={'/assets/images/img-user02.jpg'} alt="" />
              <img src={'/assets/images/img-user02.jpg'} alt="" />
              <img src={'/assets/images/img-user02.jpg'} alt="" /> */}
            </div>
            {totalCount > 0 && (
              <div className="card-public-footer-like-info">
                <strong>
                  {liked && totalCount > 1 ? 'You ,' : liked && 'You '}{' '}
                  {secondUser
                    ? ` ${(secondUser as IPostUser).firstName} ${
                        (secondUser as IPostUser).lastName
                      } `
                    : ''}
                  {!liked &&
                    totalCount >= 2 &&
                    thirdUser &&
                    `${totalCount > 2 ? ',' : ' and'} ${
                      (thirdUser as IPostUser).firstName
                    } ${(thirdUser as IPostUser).lastName}`}
                </strong>{' '}
                <div>
                  {totalCount >= 3 &&
                    ` and  ${totalCount - 2} other${
                      totalCount - 2 > 1 ? 's' : ''
                    } `}
                  liked this
                </div>
              </div>
            )}
          </div>
          <ul className="list-items">
            {!isSeller && (
              <li onClick={() => tipHandler?.()}>
                <DollarChat />
                <span className="text">Tip</span>
              </li>
            )}
            <li onClick={() => setCommentOpen?.(id)}>
              <ToolTip overlay={'Comments'}>
                <MessageBoxIcon />
              </ToolTip>
              <span className="text">{comments?.totalCount || 0}</span>
            </li>
            {isSeller && (
              <li
                onClick={() =>
                  dispatch(onToggleModal({ isOpen: true, post: post }))
                }
              >
                <ToolTip overlay={'Post Statistics'}>
                  <StatisticsIcon />
                </ToolTip>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default styled(CardPublicFooter)`
  border-top: 1px solid #ecf0f8;
  margin: 24px 0 0;
  padding: 20px 0 0;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  color: #a6a7b0;

  @media (max-width: 579px) {
    margin: 15px 0 0;
    padding: 15px 0 0;
  }

  .card-public-footer-container-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .image_row {
    display: flex;

    img {
      width: 26px;
      height: 26px;
      border: 1px solid #ffffff;
      border-radius: 100%;
      object-fit: cover;

      &:nth-child(2) {
        + img,
        ~ img {
          @media (max-width: 579px) {
            display: none;
          }
        }
      }

      + img {
        margin-left: -9px;
      }
    }
  }

  .footer-left {
    display: flex;
    align-items: center;
  }

  .img-heart {
    min-width: 80px;
    padding: 0 5px 0 0;

    @media (max-width: 579px) {
      min-width: 55px;
    }

    .img {
      cursor: pointer;

      &:hover {
        path {
          fill: #255b87;
        }
      }
    }

    .counter {
      padding: 0 0 0 12px;

      @media (max-width: 579px) {
        padding: 0 0 0 6px;
      }
    }
  }

  .card-public-footer-like-info {
    max-width: 110px;
    margin: 0 0 0 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    @media (max-width: 579px) {
      margin: 0 0 0 10px;
    }

    strong {
      color: #515365;
      font-weight: 500;
    }
  }

  .list-items {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;

    li {
      margin: 0 0 0 15px;
      cursor: pointer;

      @media (max-width: 579px) {
        margin: 0 0 0 8px;
      }

      &:hover {
        color: #255b87;

        path {
          fill: #255b87;
        }
      }
    }

    .text {
      padding: 0 0 0 9px;

      @media (max-width: 579px) {
        padding: 0 0 0 5px;
      }
    }

    svg {
      width: 20px;
      height: auto;

      @media (max-width: 579px) {
        width: 16px;
      }
    }

    path {
      fill: #a6a7b0;
    }
  }
`;
