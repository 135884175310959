import { Spinner } from 'assets/svgs';
import { WhatsAppLoader as WLoader } from 'components/loader';
import Select from 'components/Select';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IPost, IPostComment, IPostCommentType, IPostUser } from 'types/Sales';
import { getSortbyParam } from 'util/index';
import Comment from './Comment';

interface ICardListComments {
  className?: string;
  item?: IPost;
  user?: Record<string, any>;
  isCommentsFetching?: boolean;
  toggleCommentLike?: (id: string, postId?: string) => Promise<any>;
  getPostComments?: (
    id: string,
    params: Record<string, any>,
    append?: boolean,
  ) => Promise<any>;
  handleAddComemnt?: (
    id: string,
    values: Record<string, any>,
  ) => Promise<any> | void;
  postRef?: MutableRefObject<any>;
  postComment?: string;
  managedUser?: Record<string, any>;
  managedAccountId?: string;
  setOpenedComemnt?: any;
}
const PAGE_LIMIT = 5;
const options = [
  { value: 'Newest', label: 'Newest' },
  { value: 'Oldest', label: 'Oldest' },
];
const WhatsAppLoader = styled(WLoader)`
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const CardListComments = forwardRef(
  (
    {
      className,
      item,
      user,
      getPostComments,
      toggleCommentLike,
      handleAddComemnt,
      postRef,
      postComment,
      setOpenedComemnt,
      managedAccountId,
      managedUser,
    }: ICardListComments,
    ref,
  ) => {
    const [comments, setComments] = useState<IPostCommentType>({
      items: [],
      totalCount: 0,
    });
    const [fethingComemnts, setFethingComemnts] = useState(false);
    const [fethingSort, setFethingSort] = useState(false);
    const [sortby, setSortby] = useState({ value: 'Newest', label: 'Newest' });
    const [sorting, setSorting] = useState(true);
    const [commentToReply, setCommentToReply] = useState<any>();
    const { tempComments = {} } = useAppSelector((state) => state.mysales);

    useEffect(() => {
      setComments(item?.comments || { items: [], totalCount: 0 });
    }, [item]);

    useEffect(() => {
      if (postComment) {
        commentToReply && setCommentToReply(null);
      }
    }, [postComment]);

    useEffect(() => {
      if (postComment) {
        commentToReply && setOpenedComemnt(null);
      }
    }, [commentToReply]);

    const toggleLike = useCallback((id: string, postId?: string) => {
      toggleCommentLike?.(id, postId).catch(console.log);
    }, []);
    const handlepagination = (
      id: string,
      order = 'desc',
      skip?: number,
      append: boolean = false,
    ) => {
      setFethingComemnts(true);

      const paramsList: any = {
        skip: skip || 0,

        // orderId,
        limit: PAGE_LIMIT,
        sort: getSortbyParam('createdAt'),
        sellerId: managedAccountId,

        // filter: filterby,
        order: order,
        // isActive: true,
      };
      getPostComments?.(id, paramsList, append)
        .catch((e) => {
          setFethingComemnts(false);
        })
        .then((data) => {
          setFethingComemnts(false);
        });
    };
    const handlepaginationSort = (
      id: string,
      order = 'desc',
      skip?: number,
      append: boolean = false,
    ) => {
      setFethingSort(true);
      const paramsList: any = {
        skip: skip || 0,
        limit: PAGE_LIMIT,
        sort: getSortbyParam('createdAt'),
        sellerId: managedAccountId,
        order: order,
      };
      getPostComments?.(id, paramsList, append)
        .catch((e) => {
          setFethingSort(false);
        })
        .then((data) => {
          setFethingSort(false);
        });
    };

    return (
      <div className={className}>
        <div className="comment_sort">
          Sort by:{' '}
          <Select
            options={options}
            className="sort-val"
            size="x-small"
            isSearchable={false}
            onChange={(value: any) => {
              if (item?._id) {
                handlepaginationSort(item?._id, sorting ? 'asc' : 'desc', 0);
                setSorting(!sorting);
                setSortby(value);
              }
            }}
            value={sortby}
          />
        </div>
        {fethingSort ? (
          <WhatsAppLoader>
            <Spinner width="28px" height="28px" color="#255b87" />
          </WhatsAppLoader>
        ) : (
          <>
            <div className="comment_list">
              {comments?.items?.map((postComment: IPostComment) => {
                const isSeller = user?._id === item?.userId;
                const isTipBuyer =
                  (postComment.userId as IPostUser)?._id === user?._id;
                return (
                  <Comment
                    managedAccountId={managedAccountId}
                    item={item}
                    isSeller={isSeller}
                    isTipBuyer={isTipBuyer}
                    key={postComment._id}
                    managedUser={managedUser}
                    comment={postComment}
                    postId={item?._id}
                    toggleLike={toggleLike}
                    user={(postComment?.userId as any) || ({} as any)}
                    setCommentToReply={setCommentToReply}
                    selectedComment={commentToReply}
                    parentCommentId={postComment._id}
                  />
                );
              })}
            </div>
            {comments && comments.totalCount > comments.items.length && (
              <div className="more-comments">
                {fethingComemnts ? (
                  <WhatsAppLoader>
                    <Spinner width="28px" height="28px" color="#255b87" />
                  </WhatsAppLoader>
                ) : (
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      item?._id &&
                        handlepagination(
                          item?._id,
                          sorting ? 'desc' : 'asc',
                          // comments.items.length,
                          comments.items?.length,
                          !!comments.items?.length,
                        );
                    }}
                  >
                    <i className="icon icon-plus"></i>
                    VIEW MORE COMMENTS
                  </Link>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  },
);

export default styled(CardListComments)`
  padding: 15px 0 1px;
  font-weight: 400;

  .comment_sort {
    font-size: 12px;
    line-height: 14px;
    color: #a6a7b0;
    margin: 0 0 20px;

    .sort-val {
      font-weight: 500;
      padding: 0 0 0 5px;
      color: #515465;
      display: inline-block;
      vertical-align: middle;
      min-width: 80px;
    }

    .react-select__value-container {
      padding: 0;
    }

    /* .react-select__input {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    } */

    .react-select__control {
      cursor: pointer;
      border: none;
      min-height: inherit;
      font-size: 12px;

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__indicators {
        width: auto;
        height: auto;
      }

      .react-select__indicator {
        padding: 0;
      }
    }
  }

  .more-comments {
    margin: 0 -20px;
    padding: 20px 20px 0;
    text-align: center;
    border-top: 1px solid #ecf0f8;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    color: #c8c9cf;

    @media (max-width: 479px) {
      margin: 0 -15px;
      padding: 15px 15px 0;
    }

    a {
      color: #c8c9cf;

      &:hover {
        color: #0f8bba;
      }
    }

    .icon {
      margin: 0 10px 0 0;
    }
  }
`;
