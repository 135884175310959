import { ArrowBack } from 'assets/svgs';
import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  backUrl?: string;
  title?: string | ReactElement | ReactNode;
  onClick?: (e: any) => void;
}

export const EditBack = ({ className, backUrl, title, onClick }: Props) => {
  return (
    <div className={`${className} edit-back`}>
      <Link to={backUrl || ''} className="link-back" onClick={onClick}>
        <ArrowBack />
      </Link>
      <strong className="title">{title}</strong>
      {/* <span className="link-image">
        <Eye />
      </span> */}
    </div>
  );
};

export default styled(EditBack)`
  background: #f5f5f6;
  font-size: 16px;
  line-height: 1.5;
  padding: 14px 60px 13px;
  position: relative;
  color: #3b719e;
  text-align: center;
  border-bottom: 1px solid #d9dce0;
  min-height: 52px;

  a {
    color: #3b719e;
  }

  .title {
    font-weight: 500;
  }

  .link-back {
    line-height: 1;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
    width: 20px;
    height: 20px;

    &:before {
      background: transparent;
      width: 40px;
      height: 40px;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    }

    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .link-image {
    position: absolute;
    right: 28px;
    top: 50%;
    max-width: 28px;
    transform: translate(0, -50%);

    svg {
      min-width: 20px;
    }
  }
`;
