//// Example
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getGiftLists as gLists } from 'api/ChatSubscriptions';
import { AxiosRequestConfig } from 'axios';
import { GiftType } from 'types/ChatSubscription';
import type { RootState } from '../store';
// Define a type for the slice state
interface IGiftsState {
  gifts: { items: GiftType[]; hasMore: boolean };

  isFetchingGifts?: boolean;
}

// Define the initial state using that type
const initialState: IGiftsState = {
  gifts: { items: [], hasMore: false },
  isFetchingGifts: false,
};

export const getGiftLists = createAsyncThunk<
  IGiftsState['gifts'],
  {
    callback?: (...args: any) => void;
    options?: AxiosRequestConfig;
  }
>('gifts/getGifts', async ({ callback, options }) => {
  const response = await gLists(options);
  callback?.(response);
  return response;
});

export const giftsSlice = createSlice({
  name: 'gifts',
  initialState,
  reducers: {
    setGifts: (state, action) => {
      state.gifts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGiftLists.fulfilled, (state, action) => {
      // Add user to the state array
      state.gifts = {
        items: [...state.gifts.items, ...action.payload.items],
        hasMore: action.payload.hasMore,
      };
      state.isFetchingGifts = false;
    });
    builder.addCase(getGiftLists.pending, (state, action) => {
      // Add user to the state array
      state.isFetchingGifts = true;
    });
    builder.addCase(getGiftLists.rejected, (state, action) => {
      // Add user to the state array
      state.isFetchingGifts = false;
    });
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = giftsSlice;

export const { setGifts } = actions;

// Other code such as selectors can use the imported `RootState` type
export const selectGifts = (state: RootState) => state.gifts;

export default reducer;
