import Loading from 'components/loader';
import React, { cloneElement } from 'react';

interface Props {}

const SinglePanelLayout: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <div className="container sm-container page-container mt-30 mb-30">
      <React.Suspense fallback={<Loading loading />}>
        {React.Children.toArray(children).map((child: any) => {
          return cloneElement(child, { Layout1: 'yes', ...rest });
        })}
      </React.Suspense>
    </div>
  );
};

export default SinglePanelLayout;
