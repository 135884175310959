import { loadStripe } from '@stripe/stripe-js';
import { AxiosRequestConfig } from 'axios';
import { STRIPE_PUBLIC_KEY } from 'config';
import { stringify } from 'querystring';
import request, {
  cancellableRequest,
  cancellableRequest1,
  request1,
} from 'util/request';
const apikey = STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(apikey === undefined ? '' : apikey);
export const togglePostLike = async (id?: string, params?: any) => {
  return await request(`/post/like/${id}?${stringify(params)}`, {
    method: 'GET',
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const GetPosts = async () => {
  return await cancellableRequest('post/allposts', `/post`, {
    method: 'GET',
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const GetMembershipsTiers = async (
  params?: Record<string, any>,
  customError?: Record<string, any>,
  options?: AxiosRequestConfig,
) => {
  return await cancellableRequest1({
    requestId: '/post/membership',
    url: `/post/membership?${stringify(params)}`,
    options: {
      ...options,
      method: 'GET',
    },
    customError,
  }).then((res: any) => {
    if ((!res || !res?.success) && res?.reason !== 'cancelled') {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const GetMyPosts = async (
  params?: Record<string, any>,
  customError?: { statusCodes: [number] },
  options?: AxiosRequestConfig,
) => {
  return await cancellableRequest1({
    requestId: 'post/my-post',
    url: `/post/my-posts?${stringify(params)}`,
    options: {
      ...options,
      method: 'GET',
    },
    customError,
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const GetMyScheduledPost = async (
  params?: Record<string, any>,
  customError?: { statusCodes: [number] },
) => {
  return await cancellableRequest1({
    requestId: 'post/list-by-range',
    url: `/post/list-by-range?${stringify(params)}`,
    options: {
      method: 'GET',
    },
    customError,
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const getPurchaseHistory = async (
  values: any,
  customError?: { statusCodes: [number] },
) => {
  return await cancellableRequest1({
    requestId: 'transaction/purchase-history',
    url: `/wallet-history/${values?.seller}/${values?.buyer}`,
    options: {
      method: 'GET',
    },
    customError,
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const GetUserPost = async (
  id?: string,
  params?: Record<string, any>,
  customError?: { statusCodes: [number] },
) => {
  return await cancellableRequest1({
    requestId: 'post/user-post',
    url: `/post/user/${id}?${stringify(params)}`,
    options: {
      method: 'GET',
    },
    customError,
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const createComment = async (id: string, values: any, params?: any) => {
  return await request(`/post/comment/${id}?${stringify(params)}`, {
    method: 'PUT',
    data: values,
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const deletePost = async (id: string, params?: Record<string, any>) => {
  return await request(`/post/${id}?${stringify(params)}`, {
    method: 'DELETE',
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const editPost = async (
  id: string,
  data: Record<string, any>,
  params?: Record<string, any>,
) => {
  return await request(`/post/${id}?${stringify(params)}`, {
    method: 'PUT',
    data,
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const createPost = async (values: any) => {
  return await request('/post', {
    method: 'POST',
    data: values,
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const getPostLikes = async (id: string, params: any) => {
  return await request(`/post/likes/${id}`, {
    method: 'GET',
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const toggleCommentLike = async (id: string, params?: any) => {
  return await request(`/post/comment/like/${id}?${stringify(params)}`, {
    method: 'GET',
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const getCommentReplies = async (
  id: string,
  params?: Record<string, any>,
) => {
  return await cancellableRequest(
    'post/getpostcommentReplies',
    `/post/replies/comments/${id}?${stringify(params)}`,
    {
      method: 'GET',
    },
  ).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const addCommentReply = async (id: string, values: any, params: any) => {
  return await request(`/post/reply/comment/${id}?${stringify(params)}`, {
    method: 'PUT',
    data: values,
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const getPostComments = async (
  id: string,
  params?: Record<string, any>,
) => {
  return await cancellableRequest(
    'post/getpostcomments',
    `/post/comments/${id}?${stringify(params)}`,
    {
      method: 'GET',
    },
  ).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const createPayToViewPaymentIntent = async (subId: string) => {
  return await request(`/post/payment-intent/pay-to-view/${subId}`, {
    method: 'GET',
  }).then((res: any) => {
    if (res?.data?.client_secret) {
      return res?.data;
    }
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const createTipPaymentIntent = async (subId: string, values: any) => {
  return await request(`/post/payment-intent/tip/${subId}`, {
    method: 'POST',
    data: values,
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const payForPost = async (subId: string) => {
  const secret = await createPayToViewPaymentIntent(subId);
  if (!secret.client_secret) {
    throw new Error(
      'Sorry, Due to some technical issue this action can not be performed',
    );
  }

  const stripe = await stripePromise;

  const res = await stripe
    ?.confirmCardPayment(secret.client_secret)
    .then((res) => {
      if (res.error) {
        throw new Error(res.error.message);
      }
      return res;
    });
  return request(`/post/payment-confirm/pay-to-view/${subId}`, {
    method: 'GET',
  }).then((res) => {
    if (!res || !res.success) {
      throw new Error(res.data?.message || '');
    }

    return res;
  });
};

export const tipForPost = async (subId: string, amount: number) => {
  const secret = await createTipPaymentIntent(subId, { amount });
  if (!secret.client_secret) {
    throw new Error(
      'Sorry, Due to some technical issue this action can not be performed',
    );
  }

  const stripe = await stripePromise;

  const res = await stripe
    ?.confirmCardPayment(secret.client_secret)
    .then((res) => {
      if (res.error) {
        throw new Error(res.error.message);
      }
      return res;
    });
  return request(
    `/post/payment-confirm/tip/${subId}/${res?.paymentIntent?.id}`,
    {
      method: 'GET',
    },
  ).then((res) => {
    if (!res || !res.success) {
      throw new Error(res.data?.message || '');
    }

    return res;
  });
};
export const createMemberShipPaymentIntent = async (
  postId: string,
  membershipId: string,
) => {
  return await request(
    `/post/payment-intent/membership/${postId}/${membershipId}`,
    {
      method: 'GET',
    },
  ).then((res: any) => {
    if (res?.data?.client_secret) {
      return res?.data;
    }
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const payForMembership = async (
  postId: string,
  membershipId: string,
) => {
  try {
    const secret = await createMemberShipPaymentIntent(postId, membershipId);
    if (!secret.client_secret) {
      throw new Error(
        'Sorry, Due to some technical issue this action can not be performed',
      );
    }
    const stripe = await stripePromise;

    const res = await stripe
      ?.confirmCardPayment(secret.client_secret)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error.message);
        }
        return res;
      });
    return request(
      `/post/payment-confirm/membership/${postId}/${res?.paymentIntent?.id}`,
      {
        method: 'GET',
      },
    ).then((res) => {
      if (!res || !res.success) {
        throw new Error(res.data?.message || '');
      }
      return res;
    });
  } catch (error: any) {
    throw new Error(error);
  }
};
export const createMemberShipUpgradePaymentIntent = async (
  subId: string,
  membershipId: string,
) => {
  return await request(
    `/post/membership/payment-intent/${subId}/${membershipId}`,
    {
      method: 'GET',
    },
  ).then((res: any) => {
    if (res?.data?.client_secret) {
      return res?.data;
    }
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const payForMembershipUpgrade = async (
  subId: string,
  membershipId: string,
) => {
  try {
    const secret = await createMemberShipUpgradePaymentIntent(
      subId,
      membershipId,
    );
    if (secret.success && secret?.message === 'Payment Confirmed') {
      return secret;
    }
    if (!secret.client_secret) {
      throw new Error(
        'Sorry, Due to some technical issue this action can not be performed',
      );
    }
    const stripe = await stripePromise;
    const res = await stripe
      ?.confirmCardPayment(secret.client_secret)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error.message);
        }
        return res;
      });
    return request(
      `/post/membership/payment-confirm/${subId}/${res?.paymentIntent?.id}`,
      {
        method: 'GET',
      },
    ).then((res) => {
      if (!res || !res.success) {
        throw new Error(res.data?.message || '');
      }
      return res;
    });
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getPostTips = async (
  id: string,
  params: Record<string, any>,
  customError?: Record<string, any>,
) => {
  return await cancellableRequest1({
    requestId: 'post/tips',
    url: `/post/tips/${id}?${stringify(params)}`,
    options: {
      method: 'GET',
    },
    customError,
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const getMemberShipsByUserId = async (
  id: string,
  params: Record<string, any>,
  customError?: Record<string, any>,
) => {
  return await cancellableRequest1({
    requestId: 'post/userMemberships',
    url: `/post/membership/${id}
?${stringify(params)}`,
    options: {
      method: 'GET',
    },
    customError,
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const getSubscriptionIdByUserId = async (
  id: string,
  params: Record<string, any>,
  customError?: Record<string, any>,
) => {
  return await cancellableRequest1({
    requestId: 'sales/userSubscription',
    url: `/chat/subscription/${id}
?${stringify(params)}`,
    options: {
      method: 'GET',
    },
    customError,
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const getSubscriptionById = async (
  id: string,
  params?: Record<string, any>,
  customError?: Record<string, any>,
) => {
  return await cancellableRequest1({
    requestId: 'sales/subsDetails',
    url: `/chat/${id}
?${stringify(params)}`,
    options: {
      method: 'GET',
    },
    customError,
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const getPostAnalytics = async (
  postId?: string,
  options?: AxiosRequestConfig,
) => {
  return await request1({
    url: `/analytics/post/${postId}`,
    options: {
      method: 'GET',
      ...options,
    },
  }).then((res: any) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const updateImagesPhysically = async (data: any) => {
  return await request(`/image/image-update`, {
    method: 'PUT',
    data: { ...data, from: 'post' },
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
