import request, { cancellableRequest } from '../util/request';

export const create = async (values: any) => {
  return await request('/pop', {
    method: 'POST',
    data: values,
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const createPop = async (values: any) => {
  return await cancellableRequest('createPop', `/pop`, {
    method: 'POST',
    data: values,
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const updatePopData = async (values: any, popId: string) => {
  return await cancellableRequest('updatepop', `/pop/${popId}`, {
    method: 'PUT',
    data: values,
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res.data;
  });
};

export const addPriceVariation = async (values: any, popID: string) => {
  return await request(`/pop/push/${popID}`, {
    method: 'PUT',
    data: { name: 'priceVariations', value: values },
  });
};

export const updatePriceVariation = async (
  values: any,
  { popID, vID }: any,
) => {
  return await request(`/pop/update-nested-attribute/${popID}`, {
    method: 'PUT',
    data: { name: 'priceVariations', id: vID, value: values },
  });
};

export const updatePop = async (values: any, popId: string) => {
  return await request(`/pop/${popId}`, { method: 'PUT', data: values }).then(
    (res) => {
      if (!res || !res?.success) {
        throw new Error(res?.data?.message || '');
      }
      return res.data;
    },
  );
};

export const addAdditionArt = async (values: any, popID: string) => {
  return await request(`/pop/push/${popID}`, {
    method: 'PUT',
    data: { name: 'additionalArt', value: values },
  });
};

export const getPopByName = async (userName: string, popSlug: string) => {
  return request(`/pop/get-by-pop-name/${userName}/${popSlug}`).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const sharePop = async (data: any) => {
  return request('/pop-share', {
    data,
    method: 'POST',
  }).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res.data;
  });
};

export const getPopByUser = async (userId: string, cookies: any = null) => {
  return request(`/pop/getByUser/${userId}`, {}, cookies).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};

export const getPopLiveAvailability = async (popId: string) => {
  return request(`/pop/get-pop-live-availability/${popId}`).then((res) => {
    if (!res || !res?.success) {
      throw new Error(res?.data?.message || '');
    }
    return res;
  });
};
export const sortPopArribute = async ( {popId= '', values=[], attributeName=''}: {popId: string, values:any, attributeName: string}) => {
  const res = await request(`/pop/sort/${popId}`, {
    method: 'PUT',
    data: { list: values, name: attributeName },
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};