import { loadStripe } from '@stripe/stripe-js';
import { AxiosRequestConfig } from 'axios';
import { AppAlert } from 'components/Model';
import { STRIPE_PUBLIC_KEY } from 'config';
import { stringify } from 'querystring';
import { CustomErrorType } from 'types';
import request, { cancellableRequest, request1 } from 'util/request';

const apikey = STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(apikey === undefined ? '' : apikey);

export const sendChatMessage = async (
  subscriptionId: string,
  data: Record<string, any>,
) => {
  const res = await request(`/chat/message/${subscriptionId}`, {
    method: 'POST',
    data,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const getMessages = async (
  subscriptionId: string,
  options?: AxiosRequestConfig,
  handleError: boolean = true,
) => {
  const res = await request(
    `/chat/list/${subscriptionId}`,
    {
      ...options,
      method: 'GET',
    },
    null,
    true,
    handleError,
  );
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const chatUnsubscribe = async (
  subscriptionId: string,
  data: Record<string, any>, // {autoRenew: boolean}
) => {
  const res = await request(`/chat/renew/subscription/${subscriptionId}`, {
    method: 'POST',
    data,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};

export const addPriceVariation = async (
  popId: string,
  data: Record<string, any>, // {autoRenew: boolean}
) => {
  const res = await request(`/pop/push/${popId}`, {
    method: 'PUT',
    data,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const delPriceVariation = async (
  popId: string,
  data: Record<string, any>, // {autoRenew: boolean}
) => {
  const res = await request(`/pop/pull/${popId}`, {
    method: 'PUT',
    data,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const updateVariation = async (
  popId: string,
  data: Record<string, any>, // {autoRenew: boolean}
  query?: Record<string, any>,
) => {
  const res = await request(`/pop/update/${popId}?${stringify(query)}`, {
    method: 'PUT',
    data,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const getSubscriptionByNotes = async (
  subscriptionId?: string,
  params?: any,
  options?: AxiosRequestConfig,
) => {
  const res = await request(
    `/chat/notes/${subscriptionId}?${stringify(params)}`,
    {
      method: 'get',
    },
  );
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const addNote = async (
  subscriptionId: string,
  data: Record<string, any>,
) => {
  const res = await request(`/chat/note/${subscriptionId}`, {
    method: 'PUT',
    data,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const deleteNote = async (
  subscriptionId: string,
  noteId: string,
  params?: Record<string, any>,
) => {
  const res = await request(
    `/chat/note/${subscriptionId}/${noteId}?${stringify(params)}`,
    {
      method: 'DELETE',
    },
  );
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const addTag = async (
  subscriptionId: string,
  data: Record<string, any>,
) => {
  const res = await request(`/chat/add/tag/${subscriptionId}`, {
    method: 'PUT',
    data,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const deleteTag = async (
  subscriptionId: string,
  data: Record<string, any>,
) => {
  const res = await request(`/chat/remove/tag/${subscriptionId}`, {
    method: 'DELETE',
    data,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const addEmoji = async (
  subscriptionId: string,
  messageId: string,
  data: { type: string },
) => {
  const res = await request(`/chat/emoji/${subscriptionId}/${messageId}`, {
    method: 'PUT',
    data,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};

export const getSubscriptionsList = async (params = {}) => {
  const res = await request(`/chat/subscription/list?${stringify(params)}`, {
    method: 'GET',
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};

export const getAllMedia = async (id: string, options: AxiosRequestConfig) => {
  const res = await request(`/chat/media/library/${id}`, {
    ...options,
    method: 'GET',
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const addNickNameAndNotifications = async (
  subscriptionId: string,
  data: Record<string, any>,
  query?: Record<string, any>,
) => {
  const res = await request(
    `/chat/update/${subscriptionId}?${stringify(query)}`,
    {
      method: 'PUT',
      data,
    },
  );
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};

export const sendMedia = async (
  subscriptionId: string,
  data: {
    library: {
      type?: string;
      thumbnail?: string;
      path?: string;
      isPaidType?: boolean;
      videoDuration?: string;
      name?: string;
    }[];
    message?: string;
    price?: number;
  },
) => {
  const res = await request(`/chat/media/${subscriptionId}`, {
    method: 'POST',
    data,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};

const createPaidMessagePaymentIntent = async (
  subId: string,
  messageId: string,
) => {
  return await request(
    `/chat/payment/message/intent/${subId}/${messageId}`,
  ).then((res) => {
    if (!res || !res.success) {
      throw new Error(res.data?.message || '');
    }

    return res;
  });
};

export const payForMessage = async (subId: string, messageId: string) => {
  const secret = await createPaidMessagePaymentIntent(subId, messageId);

  if (!secret.client_secret) {
    throw new Error(
      'Sorry, Due to some technical issue this action can not be performed',
    );
  }

  const stripe = await stripePromise;

  const res = await stripe
    ?.confirmCardPayment(secret.client_secret)
    .then((res) => {
      if (res.error) {
        throw new Error(res.error.message);
      }
      return res;
    });

  return request(`/chat/buy/message/${subId}/${messageId}`).then((res) => {
    if (!res || !res.success) {
      throw new Error(res.data?.message || '');
    }

    return res;
  });
};
export const getSubScriptionsSubList = async (query?: Record<string, any>) => {
  const res = await request(`/chat/fav/subscription/list?${stringify(query)}`, {
    method: 'GET',
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const createSubsList = async (
  subscriptionId: string,
  data: Record<string, any>,
) => {
  const res = await request(`/chat/subscription/list/${subscriptionId}`, {
    method: 'POST',
    data,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const addUserInSubsList = async (
  subscriptionId: string,
  listId: string,
  params = {},
) => {
  const res = await request(
    `/chat/subscription/list/${subscriptionId}/${listId}?${stringify(params)}`,
    {
      method: 'PUT',
    },
  );
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const deleteUserInSubsList = async (
  subscriptionId: string,
  listId: string,
  params = {},
) => {
  const res = await request(
    `/chat/subscription/list/${subscriptionId}/${listId}?${stringify(params)}`,
    {
      method: 'DELETE',
    },
  );
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};

export const sendChatGift = async (
  subId: string,
  data: Record<string, any>,
) => {
  try {
    const clientSceret = await createGiftBillingIntend(subId, data);
    if (!clientSceret.client_secret) {
      throw new Error(
        clientSceret?.data?.message ||
          'Sorry, Due to some technical issue this action can not be performed',
      );
    }

    const stripe = await stripePromise;

    await stripe?.confirmCardPayment(clientSceret.client_secret).then((e) => {
      if (e.error) {
        throw new Error(e.error.message);
      }
      return e;
    });
    const response = await request(
      `/chat/gift/${subId}/${clientSceret.giftId}`,
      {
        method: 'POST',
      },
    );

    if (response?.error) {
      throw new Error(response.message);
    }
    return response;
  } catch (error: any) {
    AppAlert({
      title: 'Oops! We ran into some problems',
      text: error.message,
    });
    throw error;
  }
};

export const createGiftBillingIntend = async (
  subId: string,
  data: Record<string, any>,
) => {
  return await request(`/chat/gift/intent/${subId}`, {
    method: 'POST',
    data,
  });
};
export const sendGiftRequest = async (giftRequest: any) => {
  const res = await request(`/chat/gift/request`, {
    method: 'POST',
    data: giftRequest,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};

export const getGiftLists = async (options?: AxiosRequestConfig) => {
  const res = await cancellableRequest('gifts_lists', `/chat/gift/list`, {
    method: 'GET',
    ...options,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const chartUserForChecoutGift = async (giftId: string) => {
  try {
    const clientSceret = await createCheckoutBillingIntent(giftId);
    if (!clientSceret.client_secret) {
      throw new Error(
        clientSceret?.data?.message ||
          'Sorry, Due to some technical issue this action can not be performed',
      );
    }

    const stripe = await stripePromise;

    await stripe?.confirmCardPayment(clientSceret.client_secret).then((e) => {
      if (e.error) {
        throw new Error(e.error.message);
      }
      return e;
    });
    const response = await sendCheckoutGiftId(giftId);

    if (response?.error) {
      throw new Error(response.message);
    }
    return response;
  } catch (error: any) {
    AppAlert({
      title: 'Oops! We ran into some problems',
      text: error.message,
    });
    throw error;
  }
};
export const sendCheckoutGiftId = async (giftId: string) => {
  return await request(`/chat/gift/order/${giftId}`, {
    method: 'POST',
  });
};

export const createCheckoutBillingIntent = async (subId: string) => {
  return await request(`/chat/gift/intent/${subId}`, {
    method: 'GET',
  });
};
export const confirmGiftSub = async (id: string, handleError = true) => {
  const res = await request(
    `/chat/gift/subscription/confirmation/${id}
`,
    {
      method: 'GET',
    },
    null,
    true,
    handleError,
  );
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const getGiftById = async (id: string, handleError = true) => {
  const res = await request(
    `/chat/gift/${id}
`,
    {
      method: 'GET',
    },
    null,
    true,
    false,
  );
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
// ******************************* Coupons *****************************

export const createCoupon = async (
  data: Record<string, any>,
  customError?: CustomErrorType,
) => {
  const res = await request1({
    url: `/coupon/`,
    options: {
      method: 'POST',
      data,
    },
    customError,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const updateCoupon = async (_id: string, data: Record<string, any>) => {
  const res = await request(`/coupon/${_id}`, {
    method: 'PUT',
    data,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const deleteCoupon = async (_id: string, data: Record<string, any>) => {
  const res = await request(`/coupon/${_id}`, {
    method: 'DELETE',
    data,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
export const sortCoupon = async (values: any) => {
  const res = await request(`/coupon/sort`, {
    method: 'POST',
    data: { list: values },
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};

export const getCouponLists = async (
  popId: string,
  customError?: Record<string, any>,
) => {
  const res = await request1({
    url: `/coupon/list/${popId}`,
    options: {
      method: 'GET',
    },
    customError: customError,
  });
  if (!res || !res.success) {
    throw new Error(res.data?.message || '');
  }
  return res;
};
