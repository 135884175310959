import { Question, Shoutout } from 'assets/svgs';
import { ServiceType } from 'enums';
import { useAppSelector } from 'hooks/useAppSelector';
import useSelector from 'hooks/useSelector';
import Course from 'pages/course';
import MyWall from 'pages/Sales/MyWall';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Questions } from 'types';
import { parseQuery } from 'util/index';
import Model from '../../../components/Model';
import useOpenClose from '../../../hooks/useOpenClose';
import Advertise from './components/advertise';
import ChatSubscription from './components/ChatSubscription';
import Generic from './components/generic';
const shoutoutQuestion: Questions[] = [
  {
    title: 'Who is this Custom Video for?',
    text: 'Is this Custom Video for you or someone else?',
    responseType: 'radioOptions',
    responseOptions: [
      { key: 'someone_else', value: true, text: 'Someone Else' },
      { key: 'me', value: false, text: 'Myself' },
    ],
    responseValue: 'someone_else',
    isRequired: true,
    questionHint: 'Is this Custom Video for you?',
    isActive: true,
  },
  {
    title: 'Who is this Custom Video for?',
    text: '',
    responseType: 'text',
    responseValue: '',
    isRequired: true,
    questionHint: 'The person this Custom Video is for.',
    isActive: true,
  },
  {
    title: 'What do you want me to say to them ?',
    text: 'Shoutout Instructions',
    responseType: 'bigText',
    responseValue: '',
    isRequired: true,
    isActive: true,
    questionHint:
      'Try to be as specific as possible with your request such as your relationship to the recipient, numbers and details.',
  },
];
export const setChatLayoutHeight = () => {
  setTimeout(() => {
    const el = document.querySelector('.chat_widget_height');
    el?.setAttribute(
      'style',
      `
      height: calc(100vh - ${el.getBoundingClientRect().y}px)
    `,
    );
  }, 0);
};
const PreviewPublicServices: React.FC<{ user?: any; className?: string }> = ({
  user: publicUser,
  className,
}) => {
  const location = useLocation();
  const { subType, slider, viewType } = parseQuery(location.search);
  const { emailNotificationClosedByUser } = useSelector(
    (state) => state.appStates,
  );

  const [stopPOPup, onStopPOPupOpen, onStopPOPupClose] = useOpenClose();

  const [pop, setPop] = useState<any>({});
  const [ownProfile, setOwnProfile] = useState<boolean>(false);
  const { previewPop } = useAppSelector((state) => state.course);
  useEffect(() => {
    setChatLayoutHeight();
  }, [emailNotificationClosedByUser]);
  // UsePageView(publicUser, pop);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 700);
    setOwnProfile(true);
  }, []);
  const GetPop = useMemo(() => {
    // if (viewType) {
    //   return <ChatWidget className="chat_widget_height" />;
    // }
    const popType = subType;
    switch (popType) {
      case ServiceType.PAYMA:
        return (
          <Generic
            // pop={pop}
            isPreview={true}
            pop={{ ...(previewPop?.popLinksId || {}) }}
            ownProfile={ownProfile}
            onStopPOPupOpen={onStopPOPupOpen}
            buttonTitle="Submit Question"
            // infoCard={{
            //   title: 'How does a PayMA Work?',
            //   items: [
            //     {
            //       icon: '/assets/images/svg/send.svg',
            //       label: 'Send your question',
            //     },
            //     {
            //       icon: '/assets/images/svg/message.svg',
            //       label: 'Get your reply',
            //     },
            //     {
            //       icon: '/assets/images/svg/thumbsup.svg',
            //       label: 'Pass through',
            //     },
            //   ],
            // }}
            icon={<Question />}
          />
        );
      case ServiceType.POPLIVE:
        return (
          <Generic
            // pop={pop}
            isPreview={true}
            pop={{ ...(previewPop?.popLinksId || {}) }}
            ownProfile={ownProfile}
            onStopPOPupOpen={onStopPOPupOpen}
            buttonTitle="Book it"
            infoCard={{
              title: 'How does it Work?',
              items: [
                {
                  icon: '/assets/images/svg/calendar-icon.svg',
                  label: 'Select a date',
                },
                {
                  icon: '/assets/images/svg/clock-icon.svg',
                  label: 'Choose the time',
                },
                {
                  icon: '/assets/images/svg/book-video-icon.svg',
                  label: 'Book Your Video Call',
                },
              ],
            }}
          />
        );
      case ServiceType.SHOUTOUT:
        return (
          <Generic
            // pop={{ ...pop, questions: shoutoutQuestion }}
            // pop={pop}
            pop={
              {
                ...(previewPop?.popLinksId || {}),
                questions: shoutoutQuestion,
              } || {}
            }
            isPreview={true}
            ownProfile={ownProfile}
            onStopPOPupOpen={onStopPOPupOpen}
            buttonTitle="Order Custom Video"
            icon={<Shoutout />}
            infoCard={{
              title: 'How does Custom Video Work?',
              items: [
                {
                  icon: '/assets/images/svg/forward.svg',
                  label: 'Send your Request',
                },
                {
                  icon: '/assets/images/svg/video-clip.svg',
                  label: 'Get your video',
                },
                {
                  icon: '/assets/images/svg/thumbsup.svg',
                  label: 'Share and enjoy',
                },
              ],
            }}
          />
        );
      case ServiceType.DIGITAL_DOWNLOADS:
        return (
          <Generic
            // pop={pop}
            isPreview={true}
            pop={{ ...(previewPop?.popLinksId || {}) }}
            ownProfile={ownProfile}
            onStopPOPupOpen={onStopPOPupOpen}
            buttonTitle="Ask Anything"
            infoCard={{
              title: 'How does a Digital Download Work?',
              items: [
                {
                  icon: '/assets/images/svg/ok.svg',
                  label: 'Choose your options',
                },
                {
                  icon: '/assets/images/svg/card.svg',
                  label: 'Complete payment',
                },
                {
                  icon: '/assets/images/svg/book-video-icon.svg',
                  label: 'Download your file',
                },
              ],
            }}
          />
        );
      case ServiceType.ADDITIONAL_SERVICES:
        return (
          <Generic
            // pop={pop}
            isPreview={true}
            pop={{ ...(previewPop?.popLinksId || {}) }}
            ownProfile={ownProfile}
            onStopPOPupOpen={onStopPOPupOpen}
            buttonTitle="Order Now"
          />
        );
      case ServiceType.ADVERTISE:
        return (
          <Advertise
            // pop={pop}

            pop={{ ...(previewPop?.popLinksId || {}) }}
            ownProfile={ownProfile}
            onStopPOPupOpen={onStopPOPupOpen}
          />
        );
      case ServiceType.CHAT_SUBSCRIPTION:
        console.log(previewPop);
        return (
          <>
            {slider === 'Add_Post' ? (
              <MyWall createPost={false} />
            ) : (
              <ChatSubscription
                publicUser={publicUser}
                // pop={pop}
                pop={{ ...(previewPop?.popLinksId || {}) }}
                ownProfile={ownProfile}
                onStopPOPupOpen={onStopPOPupOpen}
              />
            )}
          </>
        );

      case ServiceType.POP_COURSE:
        return (
          <Course
            publicUser={publicUser}
            // pop={pop}
            pop={{ ...(previewPop?.popLinksId || {}) }}
            ownProfile={ownProfile}
            onStopPOPupOpen={onStopPOPupOpen}
          />
        );
      default:
        return (
          <Generic
            pop={{}}
            ownProfile={false}
            onStopPOPupOpen={onStopPOPupOpen}
            infoCard={{}}
            isPreview={true}
            skeleton
          />
        );
    }
  }, [onStopPOPupOpen, ownProfile, pop, subType, viewType]);
  return (
    <div className={className}>
      {GetPop}
      <Model
        open={stopPOPup}
        icon="error"
        title="Can not perform this action"
        text="Sorry, but you can not purchase a service from yourself."
        onClose={onStopPOPupClose}
        onConfirm={onStopPOPupClose}
      />
    </div>
  );
};

export default styled(PreviewPublicServices)`
  padding-top: 15px;
  .chat_widget_height {
    height: calc(100vh - 114px);
    display: flex;
    flex-direction: column;
  }
`;
