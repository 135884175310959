/* eslint-disable no-useless-escape */
import { getAllThemes } from 'api/theme';
import { event } from 'api/User';
import attrAccept from 'attr-accept';
import axios from 'axios';
import { GOOGLE_TRACK, IMAGE_UPLOAD_URL } from 'config';
import dayjs from 'dayjs';
import { Time } from 'enums';
import { useAppDispatch } from 'hooks/useAppDispatch';
import qs from 'querystring';
import { isDesktop, isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import { EventArgs, GaOptions } from 'react-ga/types';
import { addPop } from 'store/reducer/course';
import { Message } from 'types/ChatSubscription';
import IPop from 'types/Pop.type';
import ITheme, { IGradient } from 'types/Theme';
import IUser from 'types/user';
import { initialPallet } from '../constants';
import { Font } from '../types/font';

export const getDuration = (file: any) => {
  return new Promise((resolve, reject) => {
    if (!file?.url?.length) {
      return reject('Url must be provided...');
    }
    let duration = undefined;
    var video = document.createElement('video');
    const sour = document.createElement('source');
    sour.setAttribute('type', 'video/mp4');
    sour.setAttribute('src', file.url);
    video.appendChild(sour);
    video.load();
    video.addEventListener('error', (ex) => {
      reject('error when loading video file');
    });

    video.addEventListener('loadedmetadata', () => {
      duration = secondsToHms(video.duration);
      resolve({ timeDuration: duration, duration: video.duration });
    });
  });
};

export const getAudioDuration = (file: any) => {
  const isVidAud = /(audio)\/(.*)/gm;
  // Create a non-dom allocated Audio element
  return new Promise((resolve, reject) => {
    if (!isVidAud.test(file.type)) {
      return reject('File is not audio type');
    }
    var audio = document.createElement('audio');
    audio.src = file.url;
    if (file) {
      audio.addEventListener(
        'loadedmetadata',
        function () {
          // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          const duration = audio.duration;
          // example 12.3234 seconds
          console.log(
            'The duration of the song is of: ' + duration + ' seconds',
          );
          // Alternatively, just display the integer value with
          // parseInt(duration)
          // 12 seconds
          resolve({ timeDuration: secondsToHms(duration), duration });
        },
        false,
      );
      audio.addEventListener('error', (ex) => {
        reject('error when loading audio file');
      });
    }
  });
};

export function secondsToHms(d: any, displayalphabets = false) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h : '';
  var mDisplay = m > 0 ? m : '00';
  var sDisplay = s > 0 ? s : '00';
  // var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
  // var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
  // var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
  if (displayalphabets) {
    hDisplay = !!hDisplay ? `${hDisplay}h` : hDisplay;
    mDisplay = !!mDisplay ? `${mDisplay}m` : mDisplay;
    sDisplay = !!sDisplay ? `${sDisplay}s` : sDisplay;
  }

  if (!!hDisplay) {
    return `${hDisplay}:${mDisplay}:${sDisplay}`;
  }

  return `${mDisplay}:${sDisplay}`;
}
export async function getAudioFileDuration(file: File) {
  return new Promise((resolve, reject) => {
    var audio = document.createElement('audio');
    // audio.src = (file as any).result;
    const src = URL.createObjectURL(file);
    audio.setAttribute('src', src);
    audio.load();
    audio.addEventListener(
      'loadedmetadata',
      function () {
        // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
        var duration = audio.duration;

        // example 12.3234 seconds
        console.log('The duration of the song is: ' + duration + ' seconds');
        // Alternatively, just display the integer value with
        // parseInt(duration)
        // 12 seconds
        resolve({
          timeDuration: secondsToHms(duration),
          duration,
          src,
        });
      },
      false,
    );
    audio.addEventListener('error', (ex) => {
      reject('error when loading audio file');
    });
  });
}
export async function getVideoCover(file: File, seekTo = 0.1) {
  console.log('getting video cover for file: ', file);
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video');
    const src = URL.createObjectURL(file);
    videoPlayer.setAttribute('src', src);
    videoPlayer.load();
    videoPlayer.addEventListener('error', (ex) => {
      reject('error when loading video file');
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', (meta) => {
      // seek to user defined timestamp (in seconds) if possible
      const duration = secondsToHms(videoPlayer.duration);
      if (videoPlayer.duration < seekTo) {
        reject('video is too short.');
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 300);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        console.log('video is now paused at %ss.', seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx?.canvas.toBlob(
          (blob) => {
            resolve({
              blob,
              timeDuration: duration,
              duration: videoPlayer.duration,
              src: src,
              width: videoPlayer.videoWidth || 450,
              height: videoPlayer.videoHeight || 450,
            });
          },
          'image/jpeg',
          0.75 /* quality */,
        );
      });
    });
  });
}

export const dataURLtoFile = (
  dataurl: string | any,
  filename: string = 'file',
) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // TODO: Fix it Next Time n-- is wrong condition
  // tslint:disable-next-line: no-increment-decrement
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
export const dataURLtoFile1 = (
  dataurl: string | any,
  filename: string = 'file',
  rest?: Record<string, any>,
) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // TODO: Fix it Next Time n-- is wrong condition
  // tslint:disable-next-line: no-increment-decrement
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime, ...rest });
};

export const arrayMoveMutate = <T>(array: T[], from: number, to: number) => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

export const arrayMove = <T>(array: T[], from: number, to: number): T[] => {
  const newArray = [...array];
  arrayMoveMutate(newArray, from, to);
  return newArray;
};

export const cardNumberValidation = (cardNumber: string) => {
  const cardNo = /^(?:(?:2131|1800|35\d{3})\d{11})$/;
  if (Boolean(cardNumber)) {
    return cardNumber.match(cardNo) ? true : false;
  } else {
    return 'something went wrong with card number';
  }
};

export const getCardType = (cardNumber: string) => {
  let re = new RegExp('^4');
  if (cardNumber.match(re) != null) {
    return 'Visa';
  }

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      cardNumber,
    )
  ) {
    return 'Mastercard';
  }

  // AMEX
  re = new RegExp('^3[47]');
  if (cardNumber.match(re) != null) {
    return 'AMEX';
  }

  // Discover
  re = new RegExp(
    '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)',
  );
  if (cardNumber.match(re) != null) {
    return 'Discover';
  }

  // Diners
  re = new RegExp('^36');
  if (cardNumber.match(re) != null) {
    return 'Diners';
  }

  // Diners - Carte Blanche
  re = new RegExp('^30[0-5]');
  if (cardNumber.match(re) != null) {
    return 'Diners - Carte Blanche';
  }

  // JCB
  re = new RegExp('^35(2[89]|[3-8][0-9])');
  if (cardNumber.match(re) != null) {
    return 'JCB';
  }

  // Visa Electron
  re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
  if (cardNumber.match(re) != null) {
    return 'Visa Electron';
  }

  return '';
};
export function validURL(str: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+$@]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
}

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const CopyToClipBoard = (text: string) => {
  let textArea: any;

  function isOS() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  function createTextArea(text: string) {
    textArea = document.createElement('textArea');
    textArea.value = text;
    textArea.readOnly = true;
    textArea.style = '{display:none}';
    document.body.appendChild(textArea);
  }

  function selectText() {
    let range;
    let selection;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyToClipboard() {
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  createTextArea(text);
  selectText();
  copyToClipboard();
};

export const getSortbyParam = (sortby: any) => {
  switch (sortby) {
    case 'type':
      return 'popType';
    case 'status':
      return 'orderStatus';
    case 'date':
      return 'createdAt';
    case 'updatedAt':
      return 'updatedAt';
    default:
      return 'createdAt';
  }
};
export const getLocation = function (href: string) {
  let url = href;
  const p = new RegExp('^(http|https)://'); // fragment locator
  if (!!!p.test(url)) {
    url = `http://${url}`;
  }
  const l = document.createElement('a');
  l.href = url;
  return l;
};
export function capitalizeFirstLetter(strng: string) {
  if (strng.length < 1) return strng;
  return strng.charAt(0).toUpperCase() + strng.slice(1);
}

export function arrayFilter<T>(array: T[], option: Partial<T>): T[] {
  if (!array?.length) return array;

  return array.filter((item: T) => {
    for (const key in option) {
      if (option[key] !== item[key]) {
        return false;
      }
    }
    return true;
  });
}
export function arrayFind<T>(array: T[], option: Partial<T>): T | undefined {
  if (!array.length) return undefined;

  return array.find((item: T) => {
    for (const key in option) {
      if (option[key] !== item[key]) {
        return false;
      }
    }
    return true;
  });
}

export const slugify = (input: string) => {
  return (
    input &&
    input
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
  );
};

export const setLocalStorage = (name: string, value: any, strigify = true) => {
  if (strigify) {
    return localStorage.setItem(name, JSON.stringify(value));
  } else {
    return localStorage.setItem(name, value);
  }
};

export const getLocalStorage = (name: string, parse = true) => {
  try {
    if (parse) {
      return JSON.parse(localStorage.getItem(name) || '{}');
    } else {
      return localStorage.getItem(name);
    }
  } catch (e) {
    return undefined;
  }
};

export const removeLocalStorage = (name: string) => {
  localStorage.removeItem(name);
};

export const getCSSFontURL = (font: Font) => {
  if (!font) return '';
  const apiUrl = [];
  apiUrl.push('https://fonts.googleapis.com/css?family=');
  apiUrl.push(font.family.replace(/ /g, '+'));
  if (font.variants.includes('italic')) {
    apiUrl.push(':');
    apiUrl.push('italic');
  }
  if (font?.subsets.includes('greek')) {
    apiUrl.push('&subset=');
    apiUrl.push('greek');
  }
  return apiUrl.join('');
};

export function getBase64(url: string) {
  return axios
    .get(url, {
      responseType: 'arraybuffer',
    })
    .then((response) => Buffer.from(response.data, 'binary').toString('base64'))
    .catch(console.log);
}

export const parseQuery = (search: string): qs.ParsedUrlQuery => {
  return qs.parse(search.substring(1));
};

export const onboardingSequency = [
  '/onboarding/profile-photo',
  '/onboarding/interests-setup',
  '/onboarding/theme-selection',
  '/onboarding/set-password',
];

export const getUserSetupUri = (userSetupStatus: number) => {
  return onboardingSequency[userSetupStatus] || '/my-profile';
};
export const getActiveState = () => {
  return onboardingSequency.findIndex((u) => u === window.location.hostname);
};

export function omit<T extends object, K extends keyof T>(
  obj: T,
  fields: K[],
): Omit<T, K> {
  const clone = { ...obj };

  if (Array.isArray(fields)) {
    fields.forEach((key) => {
      delete clone[key];
    });
  }

  return clone;
}
export const isEmpty = (obj: Record<string, any>) => {
  return Object.keys(obj || {})?.length === 0;
};
export const isValidUrl = (url?: string) => {
  const regex = new RegExp(
    /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  );
  return regex.test(url || '');
};

export const addProtocol = (url: string) => {
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = 'http://' + url;
  }
  return url;
};

export function getUrlParts(url: string | undefined) {
  if (url) {
    const vidUrl = new URL(addProtocol(url));
    const hostName = vidUrl.hostname;
    const protocol = vidUrl.protocol;
    const search = vidUrl.search;
    const pathname = vidUrl.pathname;
    let companyName = '';
    if (hostName) {
      if (hostName.includes('www')) {
        companyName = hostName.split('.')[1];
      } else {
        companyName = hostName.split('.')[0];
      }
    }
    return { hostName, companyName, protocol, search, pathname };
  }
  return {
    hostName: '',
    companyName: '',
    protocol: '',
    search: '',
    pathname: '',
  };
}

export const rgbToRgba = (rgb: any, a = 1) =>
  rgb.replace('rgb(', 'rgba(').replace(')', `, ${a})`);

export const getGradient = (
  subtype: 'solid' | 'gradient',
  gradient?: IGradient,
  solidColor?: string,
) => {
  switch (subtype) {
    case 'gradient':
      const gradientAngle = gradient?.angle || 0;
      const gradientColors = (gradient?.pallette || initialPallet)
        ?.sort((a, b) => (a.id < b.id ? 1 : 0))
        ?.reduce((p, c, i) => {
          return `${p},${rgbToRgba(c.color, c.opacity)} ${
            parseFloat(c.offset) * 100
          }%`;
        }, '');
      return `linear-gradient(${gradientAngle}deg${gradientColors})`;
    default:
      return `linear-gradient(${solidColor},${solidColor})`;
  }
};

export function isObject(item: { [key: string]: any }) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export default function mergeDeep(
  target: { [key: string]: any },
  source: { [key: string]: any },
): { [key: string]: any } {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

export const getThemes = async (
  cb: (userThemes: ITheme[], systemThemes: ITheme[]) => void,
) => {
  return getAllThemes()
    .then((response) => {
      const themes = response.userTheme.items;
      cb(themes, response.systemTheme.items);
      return themes;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
};

let pollCounter = 0;
export const startThemePolling = (
  cb: (userThemes: ITheme[], systemThemes: ITheme[]) => void,
  systemThemePoll: boolean = false,
) => {
  getThemes((userThemes: ITheme[], systemThemes: ITheme[]) => {
    cb(userThemes, systemThemes);
    const themes = systemThemePoll
      ? [...userThemes, ...systemThemes]
      : userThemes;
    if (themes?.some((theme: any) => theme.isRendering)) {
      pollCounter = 0;
      pollThemes(30 * Time.SECONDS, cb, systemThemePoll);
    }
  });
};
const pollThemes = (
  timeout: number,
  cb: (userThemes: ITheme[], systemThemes: ITheme[]) => void,
  systemThemePoll: boolean = false,
) => {
  setTimeout(() => {
    pollCounter += 1;
    if (pollCounter <= 12) {
      getThemes((userThemes: ITheme[], systemThemes: ITheme[]) => {
        cb(userThemes, systemThemes);
        const themes = systemThemePoll
          ? [...userThemes, ...systemThemes]
          : userThemes;
        if (themes?.some((theme: any) => theme.isRendering)) {
          pollThemes(10 * Time.SECONDS, cb, systemThemePoll);
        }
      });
    }
  }, timeout);
};

export const stopThemePolling = () => {
  pollCounter = 13;
};

export const getImageDimension = async (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (!attrAccept({ name: file.name, type: file.type }, 'image/*')) {
      return resolve(file);
    }
    if (attrAccept({ name: file.name, type: file.type }, 'audio/*')) {
      return resolve(file);
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        Object.defineProperty(file, 'width', {
          value: image.width,
          writable: false,
        });
        Object.defineProperty(file, 'height', {
          value: image.height,
          writable: false,
        });
        resolve(file);
      };
      reader.onerror = (err) => reject(err);
    };
  });
};

export const simpleEvent = (data: EventArgs) => {
  ReactGA.event(data);
};

export const GAUserTracking = (userData: GaOptions) => {
  ReactGA.addTrackers([
    {
      trackingId: GOOGLE_TRACK,
      gaOptions: userData,
    },
  ]);
};

//Google Analytic

export const sendObjectEvents = ({
  category,
  action,
  data,
}: {
  category: string;
  action: string;
  data: { [key: string]: number | boolean };
}) => {
  for (let key in data) {
    ReactGA.event({
      category,
      action,
      label: key,
      value: Number(data[key]),
    });
  }
};
export const sendEvent = async ({
  category,
  action,
  label,
  data,
}: {
  category: string;
  action: string;
  label: string;
  data: { [key: string]: number | boolean };
}) => {
  const value: Record<string, any> = {};
  for (let key in data) {
    value[key] = Number(data[key]);
  }
  try {
    await event({
      category,
      action,
      label,
      value,
    });
  } catch (error) {
    console.log(error);
  }
};
// Analytics

export const analyticsReport = async ({
  category,
  action,
  label,
  data,
}: {
  category: string;
  action: string;
  label: string;
  data: { [key: string]: number | boolean };
}) => {
  sendEvent({
    category,
    action,
    label,
    data,
  });

  sendObjectEvents({
    category,
    action,
    data,
  });
};

export const createPendingMessage = (
  data: Partial<Message> & { [key: string]: any },
) => {
  return {
    createdAt: dayjs().format(),
    emojis: [],
    isPaidType: false,
    isRead: false,
    messageType: 'SIMPLE',
    messageValue: '',
    paymentComplete: false,
    isSent: false,
    ...data,
  };
};
export const addPopToState = (
  id?: string,
  user?: IUser,
  dispatch?: ReturnType<typeof useAppDispatch>,
  pop?: IPop,
) => {
  if (id && !pop?._id) {
    const links = user?.links;
    const selectedLink = links?.find((l: any) => l._id === id);
    dispatch?.(addPop({ ...selectedLink, addPop: true }));
  } else {
    const mypop = getLocalStorage('pop', true);
    if (!pop?._id && mypop?._id) {
      const links = user?.links;
      const selectedLink = links?.find((l: any) => l._id === mypop?._id);
      dispatch?.(addPop({ ...selectedLink, addPop: true }));
    }
  }
};
export const removeQueryString = (
  url?: string,
): { url: string; queryString: string } => {
  if (!url) {
    return { url: '', queryString: '' };
  }
  const spl = url?.split(/[?#]/);
  return { url: spl[0], queryString: spl?.length > 1 ? spl[1] : '' };
};
export const checkUrlForImages = (
  url?: string,
  removequeryStrings?: boolean,
) => {
  let newUrl = url;
  if (removequeryStrings && newUrl) {
    newUrl = removeQueryString(newUrl).url;
  }

  if (newUrl && !newUrl?.includes(IMAGE_UPLOAD_URL as string)) {
    const newImageUrl = newUrl?.split('.com');
    if (newImageUrl?.length > 1) {
      newUrl = `${IMAGE_UPLOAD_URL}${newImageUrl[1]}`;
    }
  }
  return newUrl;
};
export const URLParts = (url?: string) => {
  let obj: {
    url: string;
    isImgix: boolean;
    hostName: string;
    protocol: string;
    search: string;
    pathname: string;
  } = {
    url: '',
    isImgix: false,
    hostName: '',
    protocol: '',
    search: '',
    pathname: '',
  };
  if (url) {
    const { hostName, protocol, search, pathname } = getUrlParts(url);
    const isImgix = hostName ? !!IMAGE_UPLOAD_URL?.includes(hostName) : false;
    obj = {
      url: `${protocol}//${hostName}${pathname}`,
      isImgix,
      hostName,
      protocol,
      search,
      pathname,
    };
  }
  return obj;
};
const checkIfExist = (path: string) => {
  const checksizes = ['mobile', 'desktop', 'smobile', 'xsmobile'];
  // const;
  let obj = { path, isExist: false };
  if (path) {
    const isExist = checksizes.find((s) => path.includes(`/${s}`));
    if (isExist) {
      const sp = path.split('/');
      sp.splice(1, 1);
      let newUrl = [...(sp || [])];
      obj.path = newUrl.join('/');
      obj.isExist = true;
    }
  }
  return obj;
};
const checkSizes = () => {};
export const getImageURL = (
  url?: string,
  sizes?: {
    defaultSize?: string;
    defaultUrl?: string;
    mobile?: string;
    all?: string;
    desktop?: string;
    smobile?: string;
    onlyMobile?: boolean;
    bdesktop?: boolean;
    isThumbBdesktop?: boolean;
    onlyDesktop?: boolean;
    onlysMobile?: boolean;
    onlyxsmobile?: boolean;
    imgix?: {
      mobile?: string;
      all?: string;
      desktop?: string;
    };
  },
) => {
  let obj: {
    url: any;
    isImgix: boolean;
    fallbackUrl?: string;
    hostName?: string;
    protocol?: string;
    search?: string;
    pathname?: string;
  } = {
    url,
    fallbackUrl: sizes?.defaultUrl || url,
    isImgix: false,
  };
  if (url) {
    obj = {
      ...obj,
      fallbackUrl: sizes?.defaultUrl || url,
      ...URLParts(url),
    };

    if (obj?.isImgix && sizes?.imgix) {
      if (sizes?.imgix?.all && obj.url) {
        obj.url = obj.search
          ? `${obj.url}${obj.search}&${sizes.imgix?.all}`
          : `${obj.url}?${sizes.imgix?.all}`;
      } else if (isMobile && sizes?.imgix?.mobile && obj.url) {
        obj.url = obj.search
          ? `${obj.url}${obj.search}&${sizes.imgix?.mobile}`
          : `${obj.url}?${sizes.imgix?.mobile}`;
      } else if (isDesktop && sizes?.imgix?.desktop && obj.url) {
        obj.url = obj.search
          ? `${obj.url}${obj.search}&${sizes.imgix?.desktop}`
          : `${obj.url}?${sizes.imgix?.desktop}`;
      }
    } else {
      if (obj?.pathname && !obj?.isImgix) {
        const { isExist, path } = checkIfExist(obj.pathname);

        const imgparts = isExist ? path?.split('/') : obj?.pathname?.split('/');

        if (imgparts?.length) {
          const imag = imgparts[imgparts.length - 1];
          const issp = imag.startsWith('sp_');
          if (issp) {
            const newPath = imgparts?.slice(0, imgparts?.length - 1);
            const split = imag.split('_');
            if (split.length) {
              const id = split?.pop();
              let isOnlyScreen = false;
              if (sizes?.onlyMobile) {
                isOnlyScreen = true;
                obj.url = `${obj.protocol}//${
                  obj.hostName
                }/mobile${newPath.join('/')}${id ? `/${id}` : ''}${obj.search}`;
              }
              if (sizes?.onlyDesktop) {
                isOnlyScreen = true;
                obj.url = `${obj.protocol}//${
                  obj.hostName
                }/desktop${newPath.join('/')}${id ? `/${id}` : ''}${
                  obj.search
                }`;
              }
              if (sizes?.onlyxsmobile) {
                isOnlyScreen = true;
                obj.url = `${obj.protocol}//${
                  obj.hostName
                }/xsmobile${newPath.join('/')}${id ? `/${id}` : ''}${
                  obj.search
                }`;
              }

              if (sizes?.bdesktop) {
                isOnlyScreen = true;
                obj.url = `${obj.protocol}//${
                  obj.hostName
                }/bdesktop${newPath.join('/')}${id ? `/${id}` : ''}${
                  obj.search
                }`;
              }
              if (sizes?.onlysMobile) {
                isOnlyScreen = true;

                obj.url = `${obj.protocol}//${
                  obj.hostName
                }/smobile${newPath.join('/')}${id ? `/${id}` : ''}${
                  obj.search
                }`;
              }
              if (isDesktop && !isOnlyScreen) {
                obj.url = `${obj.protocol}//${
                  obj.hostName
                }/desktop${newPath.join('/')}${id ? `/${id}` : ''}${
                  obj.search
                }`;
              }
              if (isMobile && !isOnlyScreen) {
                obj.url = `${obj.protocol}//${
                  obj.hostName
                }/mobile${newPath.join('/')}${id ? `/${id}` : ''}${obj.search}`;
              }
            } else {
              obj.url = obj.fallbackUrl;
            }
          } else {
            if (sizes?.isThumbBdesktop) {
              obj.url = `${obj.protocol}//${obj.hostName}/blur${obj?.pathname}`;
            } else {
              obj.url = obj.url || obj.fallbackUrl;
            }
          }
        }
      }
    }
  }
  return obj;
};
export const appendScreenSizesToId = (data: {
  id?: string;

  sizes?: string[];
  userId?: string;
  file?: File;
  rotateAll?: number | string;
  createpathagain?: boolean;
  allblur?: string;
}) => {
  const {
    id,
    sizes,
    userId,
    file,
    rotateAll = '',
    createpathagain,
    allblur = 'X0',
  } = data;
  let obj = {
    id: id,
    file: file,
  };
  if (file && attrAccept({ type: file?.type }, 'image/*')) {
    let newSizes = '';
    if (sizes?.length) {
      sizes.forEach(
        (s) =>
          (newSizes += `${s}${allblur}${
            rotateAll !== '' ? `X${rotateAll}` : ''
          }_`),
      );
    }
    if (id && sizes?.length) {
      let prefix = sizes ? `sp_${newSizes}` : '';
      const imageId = `${prefix}${id}`;
      if (!id?.includes(`sp_${newSizes}`) || createpathagain) {
        if (file?.name) {
          const n = imageId + '.' + file.name.split('.').pop();
          Object.defineProperty(obj.file, 'fileName', {
            writable: true,
            value: n,
          });
        }
        obj.id = imageId;
      }
    }
  }

  return obj;
};
export const appendScreenSizesToIdForUploadedFiles = (data: {
  id?: string;

  sizes?: string[];
  userId?: string;
  rotateAll?: number | string;
  createpathagain?: boolean;
  allblur?: string;
  file?: Record<string, any>;
}) => {
  const {
    id,
    sizes,
    rotateAll = '',
    createpathagain,
    allblur = 'X0',
    file,
  } = data;
  let obj = {
    id: id,
    file,
  };
  if (file && attrAccept({ type: file?.type }, 'image/*')) {
    let newSizes = '';
    if (sizes?.length) {
      sizes.forEach(
        (s) =>
          (newSizes += `${s}${allblur}${
            rotateAll !== '' ? `X${rotateAll}` : ''
          }_`),
      );
    }
    if (id && sizes?.length) {
      let prefix = sizes ? `sp_${newSizes}` : '';
      const imageId = `${prefix}${id}`;
      if (file?.name) {
        const n = imageId + '.' + file.name.split('.').pop();

        obj.id = n;
      }
    }
  }

  return obj;
};

export const processFilesForRotation = (
  files: any[],
  screenSizes: string[],
) => {
  if (files.length < 1) {
    return { files, screenSizes };
  }
  let filesWithoutRotation = [...files].map((nmedia: any) => {
    const { rotate, ...rest } = nmedia;
    return rest;
  });
  const filesTobeUpdated = [];
  for (let index = 0; index < files.length; index++) {
    const element = files[index];

    if (element.rotate !== undefined) {
      const isRotateable = (((element.rotate || 0) % 360) + 360) % 360 !== 0;
      if (isRotateable) {
        filesTobeUpdated.push(element);
      }
    }
  }
  let newFiles: any = [];

  for (let i = 0; i < filesTobeUpdated.length; i++) {
    newFiles[i] = filesTobeUpdated[i];
  }

  if (!!newFiles?.length) {
    newFiles = newFiles.map((fil: any) => {
      const { rotate, ...rest } = fil;
      const { file, id } = appendScreenSizesToIdForUploadedFiles({
        id: rest?.id,
        sizes: screenSizes,
        rotateAll: rotate || 0,
        file: rest,
        createpathagain: true,
      });
      let hostprotocol = getUrlParts(rest.url);
      const pathname = hostprotocol.pathname.split('/');
      pathname.pop();
      let createUrl = `${hostprotocol.protocol}//${
        hostprotocol.hostName
      }${pathname.join('/')}/${id}`;
      return {
        ...rest,
        imageURL: createUrl,
        path: createUrl,
        url: createUrl,
        oldUrl: rest.url || rest.imageURL || rest.path,
      };
    });
  }
  filesWithoutRotation = filesWithoutRotation.map((nmedia: any) => {
    let founditem = newFiles.find((f: any) => f.id === nmedia.id);
    if (founditem) {
      const { oldUrl, ...rest } = founditem;
      return {
        ...nmedia,
        updatedAt: new Date().getTime() + '',
      };
    }
    return nmedia;
  });
  return { filesWithoutRotation, newFiles };
};
