// import 'photoswipe/dist/default-skin/default-skin.css';
// import 'photoswipe/dist/photoswipe.css';
import attrAccept from 'attr-accept';
import AttachmentLightBoxSwiper from 'components/AttachmentLightBoxSwiper';
import React, { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import 'react-h5-audio-player/src/styles.scss';
import styled from 'styled-components';
import { IPostMedia } from 'types/Sales';
import { getImageURL, isValidUrl } from 'util/index';
import MobileSwiper from './MobileSwiper';

interface ImagesHolderProps {
  className?: string;
  media?: IPostMedia[];
}

const ImagesHolder: React.FunctionComponent<ImagesHolderProps> = ({
  className,
  media = [],
}) => {
  const [images, setImages] = useState<any[]>([]);
  const updatedImages = (media: any[] = [], applyNowTime = false) => {
    let images: any = [];

    media.forEach((element: any, idx) => {
      const isAudio = attrAccept({ type: element.type }, 'audio/*');
      if (
        element &&
        isValidUrl(element.path || element.url) &&
        attrAccept({ type: element.type }, 'image/*')
      ) {
        const { size = 34311243, ...el } = { ...element };
        const { url, fallbackUrl, isImgix } = getImageURL(
          el.path || el.url || '',
          {
            defaultUrl: el.path,
            onlyDesktop: true,
            imgix: {
              all: 'fit=clip&h=900',
            },
          },
        );
        const { url: murl } = getImageURL(el.path || el.url || '', {
          defaultUrl: el.path,
          onlyMobile: true,
          imgix: {
            all: 'h=500&w=500',
          },
        });
        if (idx === 0) {
          images.push({
            ...el,
            src: url,
            fallback: el.path ? el.path : el.url,
            path: url,
            thumb: url,
          });
        } else {
          images.push({
            ...el,
            src: url,
            fallback: el.path ? el.path : el.url,
            path: url,
            thumb: murl,
          });
        }
      } else {
        const { size, ...rest } = element;
        images.push({
          ...rest,
          poster: isAudio ? '/assets/images/mp3-icon.png' : element.thumbnail,
          fallback: element.url || element.path,
          thumb: isAudio ? '/assets/images/mp3-icon.png' : element.thumbnail,
          src: element.url || element.path,
          width: '650',
          height: '720',
        });
      }
    });
    setImages((_: any) => [...images]);
  };
  useEffect(() => {
    updatedImages(media);
  }, [media]);

  return (
    <div className={className}>
      {images?.length && isDesktop ? (
        <AttachmentLightBoxSwiper media={images} maxThumbnailCount={5} />
      ) : (
        <MobileSwiper items={images} />
      )}
    </div>
  );
};

export default styled(ImagesHolder)`
  .slick-slide {
    min-height: 150px;
  }

  .slide-holder {
    > div {
      padding-top: 100%;
      position: relative;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .slick-dots {
    display: flex;
    position: static;
    margin-top: 15px;

    button {
      &:hover {
        background: #255b87;
      }
    }
  }
`;
