import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import AddMessage from 'pages/schedule-messaging/components/RightView/AddMessage';
import React from 'react';
import { setMessage } from 'store/reducer/chat';
import { Message } from 'types/ChatSubscription';

interface Props {
  onCloseTabs(): void;
  onSubmit(
    files: any[],
    message?: string,
    price?: number,
    messageKey?: string,
  ): void;
  media?: Message['messageMedia'];
  selectedMessageType?: 'pay-to-view' | 'standard';
}

const ImagesMessageInput: React.FC<Props> = (props) => {
  const {
    onCloseTabs,
    onSubmit: onSubmitCb,
    media,
    selectedMessageType,
  } = props;
  const dispatch = useAppDispatch();
  const message = useAppSelector((state) => state.chat.message);
  return (
    <AddMessage
      className="sub-tabs-holder"
      selectedMessageType={selectedMessageType}
      setSelectedMessageType={() => {}}
      showChooseTabsOptions={false}
      showClose
      onCloseTabs={onCloseTabs}
      onSubmitCb={onSubmitCb}
      message={message}
      media={media}
      preivewMessage="Create Your Message"
      preivewSubMessage="You can attach multiple media your message."
      validateFiles={(files) => {
        return true;
      }}
      onMessageChange={(message: string) => dispatch(setMessage(message))}
    />
  );
};

export default ImagesMessageInput;
