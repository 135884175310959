import Button from 'components/NButton';
import { setTwoPanelLayoutHeight } from 'layout/TwoPanelLayout';
import { setChatLayoutHeight } from 'pages/chat';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { sendEmailVerificationLink, update } from '../api/User';
import useAuth from '../hooks/useAuth';
import InvitationStatus from './Model/InvitationStatus';
import { toast } from './toaster';
const ReactFuncation: React.FC<any> = ({
  isOpen: open,
  onClose,
  user: u,
  isPublicPage,
  className,
}) => {
  const history = useHistory();
  const { setUser: setUserContext } = useAuth();

  const [emailSend, setEmailSend] = useState<string | undefined>('Resend');
  const [disableButton, setDisableButton] = useState(false);
  const [user, setUser] = useState(u);

  const handleClose = () => {
    onClose();

    setTwoPanelLayoutHeight();
    setChatLayoutHeight();
  };

  const ActivateProfile = async () => {
    await update({ isActiveProfile: true }).then(() => {
      setUserContext({ ...user, isActiveProfile: true });
      setUser({ ...user, isActiveProfile: true });
      history.push(`/${user.username}`);
    });
  };
  const goToEditProfile = async () => {
    handleClose();
    history.push('/my-profile');
  };
  const sentVerificationLink = async () => {
    setEmailSend('Sending');
    try {
      await sendEmailVerificationLink(user.email);

      setEmailSend('Sent');
      setDisableButton(true);
      setTimeout(() => {
        setEmailSend('Resent');
        setDisableButton(false);
      }, 30000);
    } catch (error) {
      setEmailSend('Resent');
      setDisableButton(false);
      if (error?.message) {
        if (error?.message.trim() === 'This user Already Verified') {
          toast.error('Email already verified!');
          setUserContext({ ...user, isEmailVerified: true });
          setUser({ ...user, isEmailVerified: true });
          if (user?.userSetupStatus > 9) {
            handleClose();
          }
        }
      }
    }
  };
  const {
    message,
    buttonText,
    onButtonClick,
    showCloseButton,
    showLink,
    type,
  }: any = InvitationStatus(user, {
    goToEditProfile,
    ActivateProfile,
    isPublicPage,
    handleClose,
    button: sentVerificationLink,
    emailSent: emailSend,
  });

  const isDefault = type === 'default';
  const HandleBtn = () => {
    if (onButtonClick) onButtonClick();
  };

  return (
    <div className={`${className} ${open ? 'N-bar show' : 'N-bar m-close'}`}>
      <div className={`notification-bar info ${!isDefault && 'bordered'}`}>
        <div
          className={`${
            isDefault && 'container sm-container'
          } d-flex justify-content-center justify-content-md-between align-items-center flex-wrap flex-md-nowrap`}
        >
          <div className="notification-bar__left-box text-center text-md-left">
            {showCloseButton && (
              <span onClick={handleClose} className="notification-bar__close">
                <i className="icon icon-close"></i>
              </span>
            )}
            <span className="notification-bar__title">
              <div className="icon icon-info"></div>
            </span>
            {message}{' '}
            {showLink && (
              <Link to="/my-profile">
                <span>Click here to go to your dashboard.</span>
              </Link>
            )}
          </div>

          <Button
            onClick={HandleBtn}
            className="btn-bar"
            disabled={disableButton}
          >
            {buttonText ? buttonText : 'OK'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default styled(ReactFuncation)`
  .btn-bar {
    color: white;
    min-width: 128px;
  }
`;
