import { payForMessage } from 'api/ChatSubscriptions';
import { DollarChat, ImageThumbnail } from 'assets/svgs';
import axios from 'axios';
import Button from 'components/NButton';
import { toast } from 'components/toaster';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useAuth from 'hooks/useAuth';
import useSocket from 'hooks/useSocket';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import {
  addMessage,
  addPendingMessage,
  getMessages,
  markAllAsRead,
  sendMessage,
  setMessage,
  setMessages,
  setPrice,
  updateEmojis,
  updateMessage,
} from 'store/reducer/chat';
import { setSliderAttachments, toggleSlider } from 'store/reducer/slider';
import styled from 'styled-components';
import { ChatSubsType, Message } from 'types/ChatSubscription';
import { createPendingMessage } from 'util/index';
import { v4 } from 'uuid';
import MessageInput from '../MessageInput';
import MessagesContainer from '../MessagesContainer';
import PaidMessageInput from '../PaidMessageInput';
import ImagesMessageInput from './ImagesMessageInput';

interface Props {
  className?: string;
  messages?: Message[];
}

const ChatWidget: React.FC<Props> = (props) => {
  const { className } = props;
  const { user } = useAuth();
  const { socket } = useSocket();
  const selectedSubscription = useAppSelector(
    (state) => state.chat.selectedSubscription,
  );
  const isFetchingMessages = useAppSelector(
    (state) => state.chat.isFetchingMessages,
  );
  const cancelToken = useRef<any>();
  const [selectedOptions, setSelectedOptions] = useState<
    'chat' | 'thumbs' | null
  >(null);

  const scrollbarRef = useRef<any>(null);
  const messages = useAppSelector((state) => state.chat.messages);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const viewBy =
    user?._id === selectedSubscription?.sellerId._id ? 'SELLER' : 'BUYER';

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollbarRef.current) {
        scrollbarRef.current.scrollToBottom();
      }
    }, 0);
  };

  useEffect(() => {
    setSelectedOptions(null);
    if (selectedSubscription?._id) {
      history.push(`/chat?subscription=${selectedSubscription._id}`);
      getSubscriptionMessages(selectedSubscription, () => {
        scrollToBottom();
      });
      socket?.on(selectedSubscription._id, (data) => {
        if (data.type === 'paymentComplete') {
          dispatch(updateMessage(data.chat));
        }
        if (data.type === 'chat') {
          if (
            data.chat.subscriptionId === selectedSubscription._id &&
            data.chat.sentFrom !== viewBy
          ) {
            dispatch(addMessage(data.chat));
            scrollToBottom();
            socket.emit('messageView', data.chat._id);
          }
        }

        if (data.type === 'messageView') {
          if (data.messageId) {
            dispatch(updateMessage({ _id: data.messageId, isRead: true }));
          } else {
            dispatch(markAllAsRead({}));
          }
        }

        if (data.type === 'emoji') {
          dispatch(
            updateEmojis({ emojis: data.emojis, messageId: data.messageId }),
          );
        }
      });
    }

    return () => {
      socket?.off(selectedSubscription?._id);
      // dispatch(setSelectedChat(undefined));
    };
  }, [selectedSubscription?._id]);

  const getSubscriptionMessages = async (
    sub: ChatSubsType,
    callback?: () => void,
    params: Record<string, any> = {},
  ) => {
    if (cancelToken.current !== undefined) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }
    cancelToken.current = axios.CancelToken.source();
    dispatch(setMessages({ items: [], totalCount: 0 }));
    dispatch(
      getMessages({
        subscriptionId: sub._id,
        callback,
        options: {
          params: { limit: 10, ...params },
          cancelToken: cancelToken.current.token,
        },
        handleError: false,
      }),
    );
  };

  const getPaginatedSubscriptionMessages = async (
    sub: ChatSubsType,
    params: Record<string, any> = {},
    callback?: (...args: any) => void,
  ) => {
    if (sub?._id) {
      if (cancelToken.current !== undefined) {
        cancelToken.current.cancel('Operation canceled due to new request.');
      }
      cancelToken.current = axios.CancelToken.source();
      dispatch(
        getMessages({
          subscriptionId: sub._id,
          callback,
          options: {
            params: { limit: 10, ...params },
            cancelToken: cancelToken.current.token,
          },
        }),
      );
    }
  };

  const handleSendMessage = async (message: string) => {
    if (selectedSubscription) {
      dispatch(
        sendMessage({
          subscriptionId: selectedSubscription._id,
          message,
          paidtype: false,
          id: v4(),
        }),
      );
      dispatch(setMessage(''));
    }
    scrollToBottom();
  };

  const buyPaidMessage = useCallback(
    async (subscriptionId, MessageId, viewBy) => {
      if (viewBy === 'BUYER') {
        await payForMessage(subscriptionId!, MessageId!).catch((e) => {
          toast.error(e.message);
        });
        return;
      }
      toast.error(`Can't buy your own message`);
    },
    [],
  );
  const handleScroll = async () => {
    if (scrollbarRef.current) {
      const isTop = scrollbarRef.current.view.scrollTop === 0;
      if (isTop && messages.hasMore && !isFetchingMessages) {
        getPaginatedSubscriptionMessages(
          selectedSubscription!,
          {
            endingBefore: messages.items[0]._id,
          },
          (response: any) => {
            if (!!response?.items?.length) {
              setTimeout(() => {
                const getlement = document?.querySelector(
                  `.messages-container > div:nth-child(${response.items.length})`,
                );

                getlement?.scrollIntoView({
                  // behavior: 'smooth',
                  block: 'end',
                });
              }, 0);
            }
          },
        );
      }
    }
  };

  return (
    <div className={className}>
      {selectedSubscription?._id &&
        !messages.items?.length &&
        !isFetchingMessages && (
          <Button disabled block shape="circle" className="empty_message">
            Start Conversation...
          </Button>
        )}
      <MessagesContainer
        handleScroll={handleScroll}
        scrollbarRef={scrollbarRef}
        buyPaidMessage={buyPaidMessage}
        onAttachmentClick={(attachment) => {
          dispatch(setSliderAttachments({ items: attachment, active: null }));
          dispatch(toggleSlider(true));
        }}
      />
      {selectedOptions === 'chat' ? (
        <PaidMessageInput
          onSubmit={(files, message, price, messageKey) => {
            dispatch(
              addPendingMessage(
                createPendingMessage({
                  isPaidType: true,
                  messageMedia: files?.map((f) => ({
                    createdAt: dayjs().format(),
                    isPaidType: f.isPaidType,
                    islock: f.islock,
                    name: f.name,
                    path: f.path,
                    thumbnail: f.thumb,
                    type: f.type,
                    _id: f.id,
                    videoDuration: f.videoDuration,
                  })),
                  messageType: 'SIMPLE',
                  messageValue: message,
                  price,
                  sentFrom: viewBy,
                  subscriptionId: selectedSubscription?._id,
                  _id: messageKey,
                }),
              ),
            );
            scrollToBottom();

            setSelectedOptions(null);
            dispatch(setMessage(''));
            dispatch(setPrice(5));
          }}
          onCloseTabs={() => {
            setSelectedOptions(null);
            dispatch(setMessage(''));
            setPrice(5);
          }}
        />
      ) : (
        <div className="chat-input">
          {selectedOptions === null && (
            <div className="input-wrap">
              <MessageInput handleSendMessage={handleSendMessage} />
            </div>
          )}
          <hr />
          <div className="input-actions">
            {selectedOptions === 'thumbs' && (
              <ImagesMessageInput
                onSubmit={(files, message, price, messageKey) => {
                  dispatch(
                    addPendingMessage(
                      createPendingMessage({
                        messageMedia: files?.map((f) => ({
                          createdAt: dayjs().format(),
                          isPaidType: false,
                          name: f.name,
                          path: f.path,
                          thumbnail: f.thumb,
                          type: f.type,
                          _id: f.id,
                          videoDuration: f.videoDuration,
                        })),
                        messageType: 'SIMPLE',
                        messageValue: message,
                        sentFrom: viewBy,
                        subscriptionId: selectedSubscription?._id,
                        _id: messageKey,
                      }),
                    ),
                  );
                  scrollToBottom();

                  setSelectedOptions(null);
                  dispatch(setMessage(''));
                }}
                onCloseTabs={() => {
                  setSelectedOptions(null);
                  dispatch(setMessage(''));
                }}
              />
            )}
            <span
              className="input-actions__img"
              onClick={() => setSelectedOptions('chat')}
            >
              <ImageThumbnail />
            </span>

            {viewBy === 'SELLER' && (
              <span
                className="input-actions__img"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedOptions('chat');
                  dispatch(setMessage(''));
                }}
              >
                <DollarChat />
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default styled(ChatWidget)`
  padding: 20px 20px 0;
  .empty_message {
    margin-top: -1.6rem;

    @media (max-width: 767px) {
      margin-top: -8px;
    }
  }
  @media (max-width: 767px) {
    padding: 16px 10px 0;
  }

  .messages-container {
    padding-bottom: 10px;
  }

  .send-spinner {
    background: #255b87;
    border-radius: 50%;
    padding: 6px;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 42px;
    cursor: pointer;
    z-index: 2;

    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .chat-input {
    padding: 0 15px 0 0;

    @media (max-width: 767px) {
      padding: 0 6px 0 0;
    }

    .text-input {
      margin-bottom: 15px !important;
    }

    .form-control {
      border: none;
      background: #f5f7fb;
      height: 60px;
      border-radius: 60px;
      padding: 10px 70px;
      font-weight: 400;
      font-size: 16px;

      &::placeholder {
        color: #255b87;
        opacity: 0.63;
      }
    }

    .pre-fix {
      width: 22px;
      color: #255b87;
      top: 50%;
      transform: translate(0, -50%);
      left: 18px;

      svg {
        width: 100%;
        height: auto;
        vertical-align: top;
      }
    }

    .icon {
      top: 50%;
      transform: translate(0, -50%);
      width: 42px;
      max-width: inherit;

      svg {
        width: 100%;
        height: auto;
        vertical-align: top;
      }
    }

    hr {
      margin: 0 -20px;
      border-color: #e6ecf1;

      @media (max-width: 767px) {
        margin: 0 -10px;
      }
    }

    .input-actions {
      padding: 11px 0;
      color: #255b87;

      .input-actions__img {
        margin-right: 26px;
        cursor: pointer;
      }

      path {
        fill: currentColor;
      }
    }

    /*.emoji-mart {
      position: absolute;
      bottom: 100%;
      left: 0;
      margin: 0 0 15px;
    }*/
  }

  .input-wrap {
    position: relative;
  }

  .sub-tabs-holder {
    padding: 10px 0 0;
    margin: 0 0 0 -20px;
    position: relative;
    max-width: inherit;

    @media (max-width: 767px) {
      margin: 0 0 0 -10px;
    }

    .rc-tabs-content-holder {
      overflow: visible;
    }

    .sub-tab-cotnent {
      padding: 0 20px 20px;

      @media (max-width: 767px) {
        padding: 0 10px 6px;
      }
    }

    .btns-links {
      .button {
        color: #03053d;

        svg {
          margin-right: 5px;
        }
      }
    }

    /* tabs updated style for chat */
    .chat_sub {
      position: relative;
    }

    .rc-tabs-card {
      .rc-tabs-nav-list {
        margin: 0;
      }

      .rc-tabs-nav-wrap {
        padding: 0 44px 0 0;

        @media (max-width: 767px) {
          padding: 0;
        }
      }

      .rc-tabs-tab {
        margin: 0;
        flex: 1;
        border-radius: 0;
        padding: 10px 16px;

        &:nth-last-child(2) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .btns-links {
      .button {
        color: #03053d;

        svg {
          margin-right: 5px;
        }
      }
    }

    .tab-close {
      position: absolute;
      right: -1px;
      top: 0;
      width: 46px;
      height: 43px;
      border: 1px solid #e6ecf1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #255b87;
      z-index: 10;
      background: #fff;

      @media (max-width: 767px) {
        border: none;
        height: 40px;
      }

      &.top-align {
        top: 0;
        right: -1px;

        &.max-top-align {
          /* top: -42px; */
        }
      }
    }
  }

  .input-wrap {
    position: relative;
  }

  .emoji-wrapper {
    position: relative;

    .icon {
      position: absolute;
      right: 8px;
      top: 8px;
      z-index: 3;
      cursor: pointer;
      transform: none;
    }
  }

  .react-emoji {
    display: block;
    position: relative;

    .react-input-emoji--container {
      margin: 0 !important;
      border: none;
      background: none;
    }

    .react-input-emoji--wrapper {
      background: #f5f7fb;
      border-radius: 30px;
      padding: 20px 70px 20px 50px;
    }

    .react-input-emoji--placeholder {
      /* color: #255b87; */
      opacity: 0.63;
    }

    .react-input-emoji--placeholder {
      left: 50px;
      width: calc(100% - 60px);
    }

    .react-input-emoji--input {
      min-height: inherit;
      height: auto;
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #242b32;
      white-space: normal;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .react-input-emoji--button {
      position: absolute;
      left: 13px;
      bottom: 18px;
      color: #255b87;
      z-index: 2;
      padding: 0;

      svg {
        fill: currentColor;
      }
    }

    .react-emoji-picker--wrapper {
      right: auto;
      left: 0;
      width: 355px;
    }
  }

  .btn-send {
    position: absolute;
    right: 8px;
    bottom: 8px;
    width: 42px;
    cursor: pointer;
    z-index: 2;
    padding: 0;
    top: auto;

    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }
`;
