//// Example
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IUserLink } from 'types';
import { removeLocalStorage, setLocalStorage } from 'util/index';
import type { RootState } from '../store';

// Define a type for the slice state
interface CourseState {
  pop: Partial<IUserLink>;
  previewPop: Partial<IUserLink>;
  enableTheme?: boolean;
}

// Define the initial state using that type
const initialState: CourseState = {
  pop: {},
  previewPop: {},
  enableTheme: true,
};

interface MyReturnType {
  // ...
}

const getUserCount = createAsyncThunk<MyReturnType, number>(
  'users/getUserCount',
  async (userId: number) => {
    // const response = await userAPI.fetchById(userId)
    return 2;
  },
);

export const courseSlice = createSlice({
  name: 'counter',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updatePop: (state, { payload }) => {
      state.pop = { ...state.pop, ...payload };
      setLocalStorage('pop', state.pop, true);
    },
    addPop: (state, { payload }) => {
      const { addPop, ...rest } = payload;
      if (addPop) {
        state.previewPop = { ...rest };
      }
      state.pop = { ...rest };
      // state.previewPop = { ...payload };
      setLocalStorage('pop', { ...rest }, true);
    },
    removePop: (state) => {
      removeLocalStorage('pop');
      return initialState;
    },
    updatePreviewPop: (state, { payload }) => {
      state.previewPop = { ...state.previewPop, ...payload };
      state.enableTheme = false;
      // setLocalStorage('pop', state.pop, true);
    },
    addPreviewPop: (state, { payload }) => {
      const { addPop, ...rest } = payload;
      state.previewPop = { ...rest };
      state.enableTheme = false;
      if (addPop) {
        state.pop = { ...rest };
        setLocalStorage('pop', { ...rest }, true);
      }
    },
    removePreviewPop: (state) => {
      state.previewPop = {};
      state.enableTheme = true;
      // removeLocalStorage('pop');
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
  extraReducers: (builder) => {},
});

// Extract the action creators object and the reducer
const { actions, reducer } = courseSlice;

export const { addPop, removePop, addPreviewPop, removePreviewPop } = actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.course;

export default reducer;
