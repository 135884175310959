import { orderCreate } from 'api/Order';
import { createOrderWithGuestUser } from 'api/Utils';
import useAuth from 'hooks/useAuth';
import useDispatch from 'hooks/useDispatch';
import useRequestLoader from 'hooks/useRequestLoader';
import { IServiceProps } from 'interfaces/IPublicServices';
import { stringify } from 'querystring';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { RESET_PURCHASE_STATES, SET_GUEST_USER } from 'reducer/purchaseStates';
import styled from 'styled-components';
import IUser from 'types/user';
import CourseAccording from './CourseAccording';
import CourseDetails from './CourseDetails';
import CourseHeader from './CourseHeader';
import CourseInfoCard from './CourseInfoCard';
import CouserVideoPlay from './CouserVideoPlay';

const Course = ({
  className,
  pop,
  ownProfile,
  onStopPOPupOpen,
  publicUser,
}: IServiceProps & { publicUser: IUser }): ReactElement => {
  const { user: authUser, isAuthenticated, loggedIn } = useAuth();
  const dispatch = useDispatch();
  const { withLoader } = useRequestLoader();
  const history = useHistory();
  const { username } = useParams<{ username: string }>();
  const handleCreateOrder = async () => {
    if (ownProfile && onStopPOPupOpen) {
      onStopPOPupOpen();
      return;
    }

    dispatch({ type: RESET_PURCHASE_STATES });

    const requestData = {
      popId: pop?._id,
      buyer: loggedIn && authUser?._id ? authUser?._id : null,
    };
    try {
      const response = isAuthenticated
        ? await withLoader(orderCreate(requestData)).catch(console.log)
        : await withLoader(createOrderWithGuestUser(requestData)).catch(
            console.log,
          );

      if (!response) {
        return;
      }

      const { order, guestUser } = response;

      if (!isAuthenticated)
        dispatch({ type: SET_GUEST_USER, payload: guestUser });

      const prams = stringify({ order: order._id, type: pop?.popType });

      return history.push(
        `/${username}/purchase/add-a-card-and-checkout?${prams}`,
      );
    } catch (e) {}
  };
  return (
    <div className={`${className} course-detail-wrap`}>
      <CouserVideoPlay additionalArt={pop?.additionalArt} />
      <CourseHeader pop={pop} publicUser={publicUser} />

      <CourseInfoCard
        onSubmit={handleCreateOrder}
        pop={pop}
        publicUser={publicUser}
      />

      {!!pop?.courseLessons?.length && (
        <CourseAccording
          onSubmit={handleCreateOrder}
          pop={pop}
          publicUser={publicUser}
        />
      )}
      <CourseDetails pop={pop} publicUser={publicUser} />
    </div>
  );
};

export default styled(Course)`
  max-width: 850px;
  margin: 0 auto;
`;
