import { TickIcon } from 'assets/svgs';
import { Card } from 'components/PrivatePageComponents';
import Tags from 'components/Tag';
import styled from 'styled-components';
import IPop from 'types/Pop.type';
import IUser from 'types/user';
interface ICourseInfoCard {
  className?: string;
  pop?: IPop;
  publicUser?: IUser;
  onSubmit?: Function;
}

const CourseInfoCard: React.FunctionComponent<ICourseInfoCard> = ({
  className,
  pop,
  publicUser,
  onSubmit: handleSubmit,
}) => {
  return (
    <div className={className}>
      {!!pop?.courseBenefits?.length && (
        <Card className={`pop-card mb-35`}>
          <h4>What you’ll learn:</h4>

          <div className="card-main-body">
            <ul className="course_info_list">
              {pop?.courseBenefits?.map((benefit, index) => (
                <li key={index}>
                  <span className="img-tick">
                    <TickIcon />
                  </span>
                  <p>{benefit}</p>
                </li>
              ))}
              {/* 
            <li>
              <span className="img-tick">
                <TickIcon />
              </span>
              <p>Write solid, good Javascript code</p>
            </li>
            <li>
              <span className="img-tick">
                <TickIcon />
              </span>
              <p>
                Understand advanced concepts such as closures, prototypal
                inheritance, IIFEs, and much more.
              </p>
            </li>
            <li>
              <span className="img-tick">
                <TickIcon />
              </span>
              <p>
                Drastically improve your ability to debug problems in
                Javascript.
              </p>
            </li>
            <li>
              <span className="img-tick">
                <TickIcon />
              </span>
              <p>
                Avoid common pitfalls and mistakes other Javascript coders make
              </p>
            </li>
            <li>
              <span className="img-tick">
                <TickIcon />
              </span>
              <p>Understand the source code of popular Javascript frameworks</p>
            </li>
            <li>
              <span className="img-tick">
                <TickIcon />
              </span>
              <p>Build your own Javascript framework or library</p>
            </li> */}
            </ul>
          </div>
        </Card>
      )}
      {!!pop?.courseSkills?.length && (
        <Card className={`pop-card mb-35`}>
          <h4>Skills you will gain:</h4>

          <div className="card-main-body">
            <ul className="course_tags_list">
              {pop?.courseSkills
                ?.filter((courseSkill) => courseSkill !== '')
                ?.map((courseSkill, index) => (
                  <li key={index}>
                    <Tags
                      closeAble={false}
                      label={courseSkill}
                      showCloseIcon={false}
                    />
                  </li>
                ))}
              {/*
            <li>
              <span className="img-tick">
                <TickIcon />
              </span>
              <p>Write solid, good Javascript code</p>
            </li>
            <li>
              <span className="img-tick">
                <TickIcon />
              </span>
              <p>
                Understand advanced concepts such as closures, prototypal
                inheritance, IIFEs, and much more.
              </p>
            </li>
            <li>
              <span className="img-tick">
                <TickIcon />
              </span>
              <p>
                Drastically improve your ability to debug problems in
                Javascript.
              </p>
            </li>
            <li>
              <span className="img-tick">
                <TickIcon />
              </span>
              <p>
                Avoid common pitfalls and mistakes other Javascript coders make
              </p>
            </li>
            <li>
              <span className="img-tick">
                <TickIcon />
              </span>
              <p>Understand the source code of popular Javascript frameworks</p>
            </li>
            <li>
              <span className="img-tick">
                <TickIcon />
              </span>
              <p>Build your own Javascript framework or library</p>
            </li> */}
            </ul>
          </div>
        </Card>
      )}

      {/* <Button
        type="primary"
        block
        className="btn btn-primary"
        size="x-large"
        onClick={() => handleSubmit?.()}
        // isLoading={isUpdating}
        // disabled={isUpdating}
      >
        Purchase Course - ${Number(pop?.price || 0)?.toFixed(2)}
      </Button> */}
    </div>
  );
};

export default styled(CourseInfoCard)`
  border-radius: 4px;
  .course_tags_list {
    margin: 0 -5px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      padding: 0 5px;
      margin: 0 0 10px;
    }
  }
  .label-text {
    border-radius: 40px;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 20px;
    background: #000000;
  }
  h4 {
    color: #404040;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 20px;
    font-weight: 500;
  }
  .btn {
    background: #000000;
    border-radius: 6px;

    &:hover {
      background: #000000;
    }
  }
  .card-body {
    padding: 0;
  }

  .course_info_list {
    margin: 0 -25px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400;
    color: #000;

    @media (max-width: 579px) {
      margin: 0 -15px;
    }

    li {
      width: 50%;
      padding: 0 25px 0 68px;
      position: relative;
      margin: 0 0 10px;

      @media (max-width: 579px) {
        padding: 0 15px 0 45px;
      }

      @media (max-width: 479px) {
        width: 100%;
      }
    }

    p {
      margin: 0;
    }

    .img-tick {
      position: absolute;
      left: 25px;
      top: 0;
      width: 20px;
      color: #03053d;

      @media (max-width: 579px) {
        left: 15px;
      }

      circle {
        fill: #03053d;
        stroke: #03053d;
      }
    }
  }
`;
