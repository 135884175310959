import { Spinner, StatisticsIcon } from 'assets/svgs';
import Modal from 'components/modal';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from 'recharts';
import { getPostStatistics, onToggleModal } from 'store/reducer/statisticsModelState';
import styled from 'styled-components';
type DeleteModelProps = {
  onClick?: (...args: any[]) => void;
  title?: string;
  subTitle?: string;
  className?: string;
  onClose?: (...args: any[]) => void;
  isOpen?: boolean;
};
export const LineChartData = [
  {
    month: dayjs('2021-03-09').format('DD MMM'),
    // price: FormatNumber(4000),
    price: 0,
  },
  {
    month: dayjs('2021-03-10').format('DD MMM'),
    price: 3000,
  },
  {
    month: dayjs('2021-03-11').format('DD MMM'),
    price: 2000,
  },
  {
    month: dayjs('2021-03-15').format('DD MMM'),
    price: 3490,
  },
];
const GalleryLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
function StatisTicsModal({
  onClick,
  title,
  subTitle,
  className,
  onClose,
}: // isOpen,
DeleteModelProps) {
  const isOpen = useAppSelector((state) => state.stateModal.isOpen);
  const post = useAppSelector((state) => state.stateModal.post);
  const fetchingPostStatistics = useAppSelector((state) => state.stateModal.fetchingPostStatistics);
  const postStatistics = useAppSelector((state) => state.stateModal.postStatistics);
  const dispatch = useAppDispatch();
  useEffect(()=> {
isOpen && post?._id && dispatch(getPostStatistics({postId:post?._id || ''}))
  }, [isOpen])
  const handleClose = () => {
    dispatch(onToggleModal({ isOpen: false, post: {}, postStatistics: {} }));
  };
  const Chart = () => {
    return  <ResponsiveContainer width="100%" height={300}>
              <LineChart
                // width={500}
                // height={300}
                data={LineChartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                <XAxis dataKey="month" axisLine={false}  />
                {/* <YAxis
                  dataKey={"price"}
                  tickFormatter={item => {
                    return item
                  }}
                /> */}
                <Tooltip />
                {/* <Legend /> */}
                <Line
                stroke="#90CAF8"
                  // type="monotone"
                  dataKey="price"

                  // activeDot={{ r: 10 }}
                  dot={{
                    r: 0,
                  }}
                />
                {/* <Line type="monotone" dataKey="price" stroke="#82ca9d" /> */}
              </LineChart>
            </ResponsiveContainer>
  }
  return (
    <div>
      {isOpen && (
        <Modal
          isOpen={!!isOpen}
          onClose={handleClose}
          showFooter={false}
          className={`${className}`}
          title={
            <>
              <span className="img-title img-delete">
                <StatisticsIcon />
              </span>{' '}
              POST STATISTICS
            </>
          }
        >

          <div className="modal-content-holder">
            <p>Some stats may be delayed or approximated.</p>


      {fetchingPostStatistics ? <GalleryLoader>
        <Spinner color="#255B87" />;
      </GalleryLoader>:
<>
           {/* {Chart()} */}
            <ul className="list-post-stats">
              <li>
                <span className="title">PURCHASES</span>{' '}
                <span className="circle"></span>{' '}
                <span className="value">${postStatistics?.payToView || '00.00'}</span>
              </li>
              {/* <li>
                <span className="title">VIEWS </span>
                <span className="circle sky-blue"></span>
                <span className="value">1312</span>
              </li>
              <li>
                <span className="title">UNIQUE VIEWERS</span>{' '}
                <span className="circle orange"></span>
                <span className="value">331</span>
              </li> */}
              <li>
                <span className="title">LIKES</span>{' '}
                <span className="circle grey"></span>
                <span className="value">{postStatistics?.totalLikes || 0}</span>
              </li>
              <li>
                <span className="title">COMMENTS</span>{' '}
                <span className="circle blue"></span>
                <span className="value">{postStatistics?.totalComments || 0}</span>
              </li>
              <li>
                <span className="title">TIPS </span>
                <span className="circle brown"></span>
                <span className="value">${postStatistics?.totalTips || '00.00'}</span>
              </li>
              {/* <li>
                <span className="title">DURATION</span>{' '}
                <span className="circle light-grey"></span>
                <span className="value">3:32:11</span>
              </li>
              <li>
                <span className="title">AVERAGE DURATION</span>{' '}
                <span className="circle light-grey"></span>
                <span className="value">0:24</span>
              </li> */}
            </ul>
</>
            }
          </div>
        </Modal>
      )}
    </div>
  );
}
export default styled(StatisTicsModal)`
  max-width: 355px;

  .modal-content {
    padding: 25px 20px;
  }

  .modal-header {
    padding: 0 0 12px;
    border: none;
  }

  .modal-body {
    padding: 10px 20px 0;
    margin: 0 -20px;
    max-height: calc(100vh - 130px);
    overflow: auto;

    p {
      margin: 0 0 11px;
    }
  }

  .recharts-responsive-container {
    margin: 0 0 10px;
  }

  .modal-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #252631;
    font-weight: 500;

    .img-title {
      margin: 0 15px 0 0;
      width: 18px;
      display: inline-block;
      vertical-align: top;
      height: 20px;

      svg {
        width: 100%;
        height: auto;
        vertical-align: top;
      }
    }
  }

  .modal-content-holder {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #495057;
  }

  .list-post-stats {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 13px;
    line-height: 17px;
    color: #000;
    font-weight: 400;

    li {
      border-bottom: 1px solid #f4f3f3;
      padding: 9px 0 9px 14px;
      position: relative;
      display: flex;
      justify-content: space-between;

      &:last-child {
        border: none;
      }

      &:hover {
        .title {
          color: #000;
        }
      }
    }

    .title {
      font-weight: 500;
      color: #a6a7b0;
      text-transform: uppercase;
      transition: all 0.4s ease;
    }

    .circle {
      position: absolute;
      left: 0;
      top: 50%;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: #eed7a9;
      transform: translate(0, -50%);

      &.sky-blue {
        background: #90caf8;
      }

      &.orange {
        background: #f9d089;
      }

      &.grey {
        background: #a6a7b0;
      }

      &.blue {
        background: #2c99c2;
      }

      &.brown {
        background: #9f8146;
      }

      &.light-grey {
        background: #c4c4c4;
      }
    }
  }
`;
