import Accordion from 'components/according';
import Button from 'components/NButton';
import { Card } from 'components/PrivatePageComponents';
import parse from 'html-react-parser';
import { useState } from 'react';
import styled from 'styled-components';
import IPop from 'types/Pop.type';
import IUser from 'types/user';

interface ICourseDetailsPorps {
  className?: string;
  pop?: IPop;
  publicUser?: IUser;
}
const data = [
  'Basic Javascript knowledge (variables, loops, and basic functions)',

  'A browser and text editor that actually works',

  'Some sense of style and ability that won’t bog you down in the out bank',
];
const CourseDetails: React.FunctionComponent<ICourseDetailsPorps> = ({
  className,
  pop,
  publicUser,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className={className}>
      {!!pop?.courseRequirements?.length && (
        <div className="course-requirement">
          <h2>Requirements</h2>
          <ul className="options-list">
            {pop?.courseRequirements?.map((req: any) => {
              return (
                <li className="option-item">
                  <i className="icon icon-check"></i>
                  <span className="title">{req}</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {pop?.courseDescription && (
        <div className="course-description">
          <h2>Description</h2>
          {parse(pop?.courseDescription)}
        </div>
      )}
      {!!pop?.courseAudience?.length && (
        <div className="course-prerequisites">
          <h2>Who is this course for?</h2>
          <ul className="list-bullets">
            {pop?.courseAudience?.map((ca, index) => (
              <li key={index}>{ca}</li>
            ))}
            {/* <li>Those with basic Javascript skills who wish to improve.</li>
          <li>Experienced coders coming from other programming languages.</li>
          <li>
            New and experienced Javascript coders who want to deepen their
            understanding of the language.
          </li>
          <li>
            Anyone who has found concepts just as object prototypes, closures,
            and other advanced concepts difficult to learn something fun and
            interesting.
          </li>
          <li>
            Those who have suffered surprising errors while writing Javascript,
            and want to learn why and how to avoid them at all costs!!
          </li> */}
          </ul>
        </div>
      )}
      <div className="course-extras">
        <Accordion
          title={isOpen ? 'Show Less' : 'Show More'}
          defaultOpen={isOpen}
          onToggle={(isOpen: boolean) => {
            setIsOpen(isOpen);
          }}
          showIcon={false}
          indicator={<span className="icon-keyboard_arrow_left"></span>}
          alt
        >
          <Card className="card">
            <Card.Body>
              <div className="visual cover-image">
                <div className="mb-10 text-center profile">
                  <div className="profile--photo">
                    <img
                      src={`${
                        publicUser?.profileImage ||
                        '/assets/images/default-profile-img.svg'
                      }`}
                      alt="..."
                    />
                  </div>
                  <div className="profile--info">
                    <h1>
                      <span className="instructor_firstname">
                        {publicUser?.firstName}
                      </span>{' '}
                      {publicUser?.lastName}
                    </h1>
                    {publicUser?.tagLine && <h2> {publicUser.tagLine}</h2>}
                  </div>
                </div>
              </div>
              <p>{publicUser?.description}</p>
            </Card.Body>
            <Card.Footer>
              {' '}
              <div className="post_buy_button">
                <Button
                  shape="circle"
                  type="primary"
                  htmlType="submit"
                  className="post_btn"
                  block
                  onClick={(e) => {
                    window.open(`/${publicUser?.username}`, '_blank');
                  }}
                >
                  View{' '}
                  {
                    <span className="instructor_firstname">
                      {publicUser?.firstName}
                    </span>
                  }
                  's Profile
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Accordion>
      </div>
    </div>
  );
};

export default styled(CourseDetails)`
  font-weight: 400;

  .course-requirement {
    margin: 0 0 12px;
    overflow: hidden;
  }

  h2 {
    font-size: 22px;
    line-height: 24px;
    color: #330c3a;
    margin: 0 0 19px;
    font-weight: 500;
  }

  .options-list {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #303030;
    font-size: 16px;
    line-height: 1.375;

    li {
      padding: 0 0 0 34px;
      position: relative;
      margin: 0 0 22px;

      @media (max-width: 479px) {
        padding: 0 0 0 25px;
      }

      .icon {
        position: absolute;
        left: 2px;
        top: 4px;
        color: #03053d;
        font-size: 16px;
        line-height: 1;
      }
    }
  }

  .course-description {
    margin: 0 0 35px;
    color: #303030;
    font-size: 16px;
    line-height: 1.375;
    overflow: hidden;

    p {
      margin: 0;
    }
  }

  .course-prerequisites {
    margin: 0 0 38px;
    overflow: hidden;
  }

  .list-bullets {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding: 0 0 0 34px;
      margin: 0 0 19px;

      @media (max-width: 479px) {
        padding-left: 25px;
      }

      &:before {
        width: 4px;
        height: 4px;
        background: #03053d;
        border-radius: 100%;
        content: '';
        position: absolute;
        left: 7px;
        top: 8px;
      }
    }
  }

  .course-extras {
    padding: 0 0 30px;

    .rc-according.alt {
      margin: 0;

      &:before {
        position: absolute;
        left: 0;
        right: 0;
        top: 16px;
        height: 1px;
        border: 1px dashed rgba(51, 12, 58, 0.18);
        content: '';
      }

      .rc-header {
        width: 115px;
        margin: 0 auto 46px;
        color: #fff;
        background: #000;
        border-radius: 30px;
        padding: 7px 10px;

        .title {
          color: #fff;

          &:before {
            border-color: #fff transparent transparent transparent;
          }
        }

        &:before {
          display: none;
        }
      }

      .content {
        padding: 60px 0 0;
      }
    }

    .profile {
      margin-top: -78px;
    }

    .profile--photo {
      width: 114px;
      height: 114px;
      box-shadow: none;
      margin-bottom: 14px;
    }
    .instructor_firstname {
      text-transform: capitalize;
    }
    .pop-card {
      background: #fafbfd;
      border-color: rgba(187, 175, 191, 0.3);
      text-align: center;
      font-size: 13px;
      line-height: 1.846;
      color: #603e66;
      font-weight: 500;
      max-width: 740px;
      margin: 0 auto;
    }

    h1 {
      font-size: 22px;
      line-height: 24px;
      color: rgba(3, 5, 61, 0.77);
      margin: 0 0 5px;
      font-weight: 500;
    }

    h2 {
      font-size: 15px;
      line-height: 18px;
      color: #6b6c8d;
      margin: 0 0 10px;
      font-weight: 500;
    }

    p {
      margin: 0 0 16px;
    }

    .button-primary {
      max-width: 457px;
      margin: 0 auto;
      background: #000;
      border-color: #000;
      font-size: 16px;
      padding: 10px 15px;

      &:hover {
        background: #9fa8da;
        border-color: #9fa8da;
      }
    }
  }
`;
