//// Example
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getOrder,
  getOrdersList,
  getUserNotPurchaseCourse,
  getUserNotPurchaseServices,
  getUserOrders,
} from 'api/Order';
// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootStimport { User } from './../../assets/svgs/index';
// ate) => state.support;import { dayjs } from 'dayjs';
import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import { ChatSubsType, Message } from 'types/ChatSubscription';
import {
  IOrderType,
  IOrderUser,
  IOrderUserType,
  IPost,
  IPostComment,
  IPostCommentType,
  IPostMemberships,
} from 'types/Sales';
import { v4 as uuid } from 'uuid';
import {
  addCommentReply,
  createPost,
  deletePost as postDelete,
  editPost as editWallPost,
  getCommentReplies,
  getMemberShipsByUserId,
  GetMembershipsTiers,
  GetMyPosts,
  GetMyScheduledPost,
  getPostComments as getPstComments,
  GetPosts,
  getPostTips as getPostTipss,
  getSubscriptionIdByUserId,
  GetUserPost,
  payForMembership,
  payForMembershipUpgrade,
  payForPost,
  tipForPost,
  toggleCommentLike as toggleCommntLike,
} from '../../api/sales';
import { IFileGroup } from './files';
// Define a type for the slice state
interface MySalesState {
  selectedUser?: IOrderUserType | null;
  selectedSubscription?: ChatSubsType | null;
  tempComments: Record<string | number, any[]>;
  pendingMessages: (Message & {
    messageMedia: (Message['messageMedia'][number] &
      IFileGroup['files'][number])[];
    isSent?: boolean;
  })[];
  pendingEditMessages: (Message & {
    messageMedia: (Message['messageMedia'][number] &
      IFileGroup['files'][number])[];
    isSent?: boolean;
  })[];
  filteredSchdulePost: any[];
  selectedScheduledPost?: any;
  selectedPostTemplate?: any;
  posts: {
    items: IPost[];
    totalCount: number;
  };
  myposts: {
    items: IPost[];
    totalCount: number;
  };
  ordersList?: {
    orders: { items: IOrderType[]; totalCount: number };
    pops?: { items: IOrderType[]; totalCount: number };
  };
  ordersListNotPurchased: {
    course: { items: IOrderType[]; totalCount: number };
    service: { items: IOrderType[]; totalCount: number };
  };
  usersList: { items: IOrderUserType[]; totalCount: number };
  userMemberships?: IPostMemberships;
  fetchedUsersIds?: Record<string, any>;
  subscriptionId?: string | null;
  isMemberShipsFetching?: boolean;
  isPostUnlocking?: boolean;
  isContentAccess?: boolean;
  isCommentsFetching?: boolean;
  showEditPost?: boolean;
  isPostsFetching?: boolean;
  isUserPostsFetching?: boolean;
  isTipPaying?: boolean;
  isMyPostsFetching?: boolean;
  isPostDeleting?: boolean;
  isUserOrdersFetching?: boolean;
  isUserListFetching?: boolean;
  issubIdFetching?: boolean;
  issubFetching?: boolean;
  isMemberShipUpgrading?: boolean;
  isOrderFetching?: boolean;
  isAllowMessages?: boolean;
  isUpgradingMemberShip?: boolean;
  selectedOrder?: IOrderType;
  isUsersFetched?: boolean;
  // pendingMessages: {
  //   data: Record<string, any>;
  //   status: 'COMPLETED' | 'FAILED' | 'IN_PROGRESS' | 'CANCELLED';
  //   groupId: string;
  //   files: IFileGroup['files'][number][];
  //   isSent?: boolean;
  // }[];
  isChatWindowActive?: boolean;
}

// Define the initial state using that type
const initialState: MySalesState = {
  tempComments: {},
  selectedScheduledPost: {},
  fetchedUsersIds: {},
  selectedSubscription: null,
  subscriptionId: null,
  selectedUser: null,
  pendingMessages: [],
  pendingEditMessages: [],
  filteredSchdulePost: [],
  isUsersFetched: false,
  posts: {
    items: [],
    totalCount: 0,
  },
  myposts: {
    items: [],
    totalCount: 0,
  },
  ordersList: {
    orders: { items: [], totalCount: 0 },
    pops: { items: [], totalCount: 0 },
  },
  ordersListNotPurchased: {
    course: { items: [], totalCount: 0 },
    service: { items: [], totalCount: 0 },
  },
  usersList: {
    items: [],
    totalCount: 0,
  },
  userMemberships: {},
  isMemberShipsFetching: false,
  isUpgradingMemberShip: false,
  isAllowMessages: true,
  isCommentsFetching: false,
  isPostsFetching: false,
  showEditPost: false,
  isContentAccess: false,
  isPostUnlocking: false,
  isUserPostsFetching: false,
  isMemberShipUpgrading: false,
  isTipPaying: false,
  isMyPostsFetching: false,
  isPostDeleting: false,
  selectedOrder: {},
  isOrderFetching: false,
  isUserOrdersFetching: false,
  isUserListFetching: false,
  issubIdFetching: false,
  issubFetching: false,
  isChatWindowActive: false,
};

interface MyReturnType {
  // ...
}
export const getSelectedOrder = createAsyncThunk<
  {
    data: MySalesState['selectedOrder'];
  },
  {
    orderId?: string;
    callback?: (...args: any) => void;
    customError?: { statusCodes: [number] };
  }
>('sale/getOrder', async ({ orderId, callback, customError }) => {
  const response = await getOrder(orderId || '', null, customError);
  callback?.(response);
  return { data: response };
});
export const getUsersList = createAsyncThunk<
  {
    data: MySalesState['usersList'];
    defaultOrder?: string;
    params?: Record<string, any>;
    isInitial: boolean;
    firstSelect?: boolean;
  },
  {
    firstSelect?: boolean;
    defaultOrder?: string;
    params?: Record<string, any>;
    callback?: (...args: any) => void;
    options?: AxiosRequestConfig;
    isInitial?: boolean;
  }
>(
  'sale/getUserList',
  async ({
    defaultOrder,
    params,
    callback,
    options,
    isInitial,
    firstSelect,
  }) => {
    const data = await getOrdersList(params, options);
    callback?.(data);
    return {
      data,
      defaultOrder,
      isInitial: isInitial ?? !params?.skip,
      firstSelect,
    };
  },
);
export const getSubItem = createAsyncThunk<
  {
    data: MySalesState['usersList'];
    userId: string;
    type: string;
    popType: string;
    subId: string;
    insertatbeginning?: boolean;
  },
  {
    userId: string;
    type: string;
    popType: string;
    subId: string;

    insertatbeginning?: boolean;
  }
>(
  'sale/getSubItem',
  async ({ userId, type, popType, subId, insertatbeginning }) => {
    const data = await getOrdersList({
      userId,
      type: type,
      popType: popType,
    });
    return { data, userId, subId, type, popType, insertatbeginning };
  },
);
export const toggleCommentLike = createAsyncThunk<
  {
    _id: string;
    postId?: string;
    data: { message: string; success: boolean };
  },
  {
    id: string;
    postId?: string;
    callback?: (...args: any) => void;
    params?: any;
  }
>('sale/postcommenttoggle', async ({ id, postId, callback, params }) => {
  const data = await toggleCommntLike(id, params);
  callback?.(data);
  return { data, _id: id, postId };
});
export const toggleReplyCommentLike = createAsyncThunk<
  {
    _id: string;
    nestcommentId: string;
    postId?: string;
    data: { message: string; success: boolean };
  },
  {
    commentId: string;
    nestcommentId: string;
    postId?: string;
    callback?: (...args: any) => void;
    params?: Record<string, any>;
  }
>(
  'sale/postreplycommentliketoggle',
  async ({ commentId, nestcommentId, postId, callback, params }) => {
    const data = await toggleCommntLike(nestcommentId, params);
    callback?.(data);
    return { data, _id: commentId, nestcommentId, postId };
  },
);
export const getUserMemberships = createAsyncThunk<
  {
    data: MySalesState['userMemberships'];
  },
  {
    params?: Record<string, any>;
    callback?: (...args: any) => void;
    customError?: { statusCodes: [number] };
    options?: AxiosRequestConfig;
  }
>(
  'sale/postMemberships',
  async ({ params, callback, customError, options }) => {
    const data = await GetMembershipsTiers(params, customError, options);
    callback?.(data);
    return { data, isInitial: !params?.skip };
  },
);
export const CreatePost = createAsyncThunk<
  {
    data: IPost;
  },
  { values: Record<string, any> }
>('sale/postCreate', async ({ values }, { dispatch }) => {
  const data = await createPost(values);
  dispatch(insertBeginningOfUserPosts({ data }));
  return { data };
});
export const addReplyToComment = createAsyncThunk<
  {
    data: IPostComment;

    postId?: string;
    commentId: string;
    nestcommentId: string;
  },
  {
    commentId: string;
    nestcommentId: string;
    values: Record<string, any>;
    postId?: string;
    params?: Record<string, any>;
  }
>(
  'sale/addReplyToComment',
  async (
    { commentId, nestcommentId, values, postId, params },
    { dispatch },
  ) => {
    const data = await addCommentReply(nestcommentId, values, params);
    return { data, commentId, nestcommentId, postId };
  },
);
export const GetPostsList = createAsyncThunk<
  {
    data: MySalesState['posts'];
    params?: Record<string, any>;
  },
  { params?: Record<string, any> }
>('sale/postList', async ({ params }) => {
  const data = await GetPosts();
  return { data };
});

export const myPostsList = createAsyncThunk<
  {
    data: MySalesState['myposts'];
    isInitial: boolean;
  },
  {
    params?: Record<string, any>;
    callback?: (...args: any) => void;
    customError?: { statusCodes: [number] };
    options?: AxiosRequestConfig;
  }
>('sale/myPostList', async ({ params, callback, customError, options }) => {
  const data = await GetMyPosts(params, customError, options);
  callback?.(data);
  // return { data: postsData, isInitial: !params?.skip };
  return { data, isInitial: !params?.skip };
});
export const mySchedulePostsList = createAsyncThunk<
  {
    data: any;
  },
  {
    params?: Record<string, any>;
    customError?: { statusCodes: [number] };
  }
>('sale/mySchedulePostsList', async ({ params, customError }) => {
  const res = await GetMyScheduledPost(params, customError);
  return { data: res?.items };
});
export const userPostsList = createAsyncThunk<
  {
    data: MySalesState['myposts'];
    isInitial: boolean;
  },
  {
    params?: Record<string, any>;
    userId?: string;
    callback?: (...args: any) => void;
    customError?: { statusCodes: [number] };
  }
>('sale/userPostsList', async ({ params, callback, userId, customError }) => {
  const data = await GetUserPost(userId, params, customError);
  callback?.(data);
  // return { data: postsData, isInitial: !params?.skip };
  return { data, isInitial: !params?.skip };
});
export const deletePost = createAsyncThunk<any, any>(
  'sale/deletePost',
  async ({ _id, sellerId }) => {
    const data = await postDelete(_id || '', { sellerId });
    return { ...data, _id };
  },
);
export const getPostComments = createAsyncThunk<
  {
    data: IPostCommentType;
    params?: Record<string, any>;
    _id: string;
    isInitial: boolean;
    append?: boolean;
  },
  {
    id: string;
    params?: Record<string, any>;
    callback?: (...args: any) => void;
    append?: boolean;
  }
>('sale/getPostComments', async ({ id, params, callback, append }) => {
  const data = await getPstComments(id, params);
  callback?.(data);
  // return { data: postsData, isInitial: !params?.skip };
  return { data, _id: id, isInitial: !params?.skip, append };
});
export const getPostCommentReplies = createAsyncThunk<
  {
    data: IPostCommentType;
    params?: Record<string, any>;
    _id: string;
    postId: string;
    isInitial: boolean;
    append?: boolean;
  },
  {
    id: string;
    postId: string;
    params?: Record<string, any>;
    append?: boolean;
    callback?: (...args: any) => void;
  }
>(
  'sale/getPostCommentReplies',
  async ({ id, postId, params, callback, append }) => {
    const data = await getCommentReplies(id, params);
    callback?.(data);
    // return { data: postsData, isInitial: !params?.skip };
    return { data, _id: id, postId, isInitial: !params?.skip, append };
  },
);
export const unlockPost = createAsyncThunk<
  {
    data: any;
    postId: string;
  },
  {
    postId: string;
  }
>('sale/postUnlock', async ({ postId }) => {
  const data = await payForPost(postId);
  return { data, postId };
});
export const paymentForMembership = createAsyncThunk<
  {
    data: any;
    postId: string;
  },
  {
    postId: string;
    membershipId: string;
  }
>('sale/paymentForMembership', async ({ postId, membershipId }) => {
  const data = await payForMembership(postId, membershipId);
  return { data, postId };
});
export const paymentForMembershipUpgrade = createAsyncThunk<
  {
    data: any;
    subId: string;
  },
  {
    subId: string;
    membershipId: string;
  }
>('sale/paymentForMembershipUpgrade', async ({ subId, membershipId }) => {
  const data = await payForMembershipUpgrade(subId, membershipId);
  return { data, subId };
});
export const tipPayForPost = createAsyncThunk<
  {
    data: any;
    postId: string;
  },
  {
    postId: string;
    amount: number;
  }
>('sale/tipForPost', async ({ postId, amount }) => {
  const data = await tipForPost(postId, amount);
  return { data, postId };
});

export const getPostTips = createAsyncThunk<
  {
    data: IPostCommentType;
    params?: Record<string, any>;
    _id: string;
    isInitial: boolean;
  },
  {
    id: string;
    params?: Record<string, any>;
    callback?: (...args: any) => void;
  }
>('sale/getPostTips', async ({ id, params, callback }) => {
  const data = await getPostTipss(id, params || {});
  callback?.(data);
  // return { data: postsData, isInitial: !params?.skip };
  return { data, _id: id, isInitial: !params?.skip };
});

export const getUserMembershops = createAsyncThunk<
  {
    data: IPostCommentType;
    params?: Record<string, any>;
    _id: string;
    isInitial: boolean;
  },
  {
    id: string;
    params?: Record<string, any>;
    callback?: (...args: any) => void;
    customError?: { statusCodes: [number] };
  }
>('sale/userpostmemberships', async ({ id, params, callback, customError }) => {
  const data = await getMemberShipsByUserId(id, params || {}, customError);
  callback?.(data);
  // return { data: postsData, isInitial: !params?.skip };
  return { data, _id: id, isInitial: !params?.skip };
});
export const getSubIdByUserId = createAsyncThunk<
  {
    data: any;
    params?: Record<string, any>;
    _id: string;
    isInitial: boolean;
  },
  {
    id: string;
    params?: Record<string, any>;
    callback?: (...args: any) => void;
    customError?: { statusCodes: [number] };
  }
>('sale/getSubIdByUserId', async ({ id, params, callback, customError }) => {
  const data = await getSubscriptionIdByUserId(id, params || {}, customError);
  callback?.(data);
  // return { data: postsData, isInitial: !params?.skip };
  return { data, _id: id, isInitial: !params?.skip };
});

export const getSelectedUserOrders = createAsyncThunk<
  {
    data: MySalesState['ordersList'];
    params?: Record<string, any>;
    isInitial: boolean;
  },
  {
    userId?: string;
    callback?: (...args: any) => void;
    params?: Record<string, any>;
    customError?: { statusCodes: [number] };
  }
>('sale/getUsersOrders', async ({ userId, callback, params, customError }) => {
  const response = await getUserOrders(userId || '', params, customError);
  callback?.(response);
  return { data: response, isInitial: !params?.skip };
});

export const getUserOrderNotPurchaseService = createAsyncThunk<
  {
    data?: any;
    params?: Record<string, any>;
    isInitial: boolean;
  },
  {
    userId?: string;
    callback?: (...args: any) => void;
    params?: Record<string, any>;
  }
>(
  'sale/getUserOrderNotPurchaseService',
  async ({ userId, callback, params }) => {
    const response = await getUserNotPurchaseServices(userId || '', params);

    callback?.(response);
    return { data: response, isInitial: !params?.skip };
  },
);

export const getUserOrderNotPurchaseCourse = createAsyncThunk<
  {
    data?: any;
    params?: Record<string, any>;
    isInitial: boolean;
  },
  {
    userId?: string;
    callback?: (...args: any) => void;
    params?: Record<string, any>;
  }
>(
  'sale/getUserOrderNotPurchaseCourse',
  async ({ userId, callback, params }) => {
    const response = await getUserNotPurchaseCourse(userId || '', params);
    callback?.(response);
    return { data: response, isInitial: !params?.skip };
  },
);
export const editPost = createAsyncThunk<
  {
    data?: any;
    params?: Record<string, any>;
    postId?: string;
  },
  {
    postId?: string;
    callback?: (...args: any) => void;
    data: Record<string, any>;
  }
>('sale/updatePost', async ({ postId, callback, data }) => {
  const response = await editWallPost(postId || '', data);
  callback?.(response);
  return { data: response, postId };
});
function updateUserOnlineStatus(user: IOrderUser, isOnline: boolean) {
  user.isOnline = isOnline;
  user.offlineAt = dayjs().format();
}
export const MySalesSlice = createSlice({
  name: 'mysales',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserMemberships: (state, action) => {
      state.userMemberships = action.payload;
    },
    reInitializeState: (state) => {
      return initialState;
    },
    setIsUsersFetched: (state, action) => {
      state.isUsersFetched = action.payload;
    },
    updateChatWindowActiveStatus: (state, action) => {
      state.isChatWindowActive = action.payload;
    },
    resetpostschatmedia: (state, action) => {
      state.myposts = { items: [], totalCount: 0 };
    },
    updateFilteredSchedulePost: (state, action) => {
      state.filteredSchdulePost = state.myposts.items.filter(
        (post: any) =>
          post.publishAt &&
          dayjs(post.publishAt).isSame(action?.payload?.selectedDate, 'date'),
      );
    },
    updateEditSchedulePost: (state, action) => {
      const postIndex: any = state.myposts?.items?.findIndex(
        (o: any) => o._id === action.payload?._id,
      );
      if (postIndex !== -1) {
        state.myposts.items[postIndex] = action.payload;
      }
    },
    resetSubId: (state) => {
      state.subscriptionId = undefined;
    },
    setSelectedSub: (state, action) => {
      state.selectedSubscription = action.payload;
    },
    setSelectedUser: (state, action) => {
      if (action.payload === null) {
        state.selectedUser = undefined;
      } else {
        state.selectedUser = { ...action.payload, unread: 0 };
        state.usersList.items = state.usersList.items.map((item) =>
          item._id === action.payload._id ? { ...item, unread: 0 } : item,
        );
      }
    },
    searchUserList: (state, action) => {
      state.usersList = action.payload;
    },
    setSelectedOrder: (state, action) => {
      state.selectedOrder = action.payload || {};
    },
    addPendingMessage: (state, action) => {
      state.pendingMessages.push(action.payload);
    },
    addEditPendingMessage: (state, action) => {
      state.pendingEditMessages.push(action.payload);
    },

    removeMediaFromPendingMessage: (state, action) => {
      state.pendingMessages = state.pendingMessages?.filter((message) => ({
        ...message,
        ...action.payload,
        messageMedia: message?.messageMedia.filter(
          (m) => m.id !== action.payload.id,
        ),
      }));
    },
    addComment: (state, action) => {
      const index = state?.myposts?.items.findIndex(
        (item) => item?._id === action?.payload?.comment?.postId,
      );
      if (index > -1) {
        if (state.myposts.items[index].comments?.items) {
          state.myposts.items[index].comments!.items!.unshift({
            likeCount: 0,
            childCount: 0,
            commentLiked: false,
            ...action?.payload?.comment,
          });
          state.myposts.items[index].comments!.totalCount!++;
        } else {
          state.myposts.items[index].comments = {
            items: [
              {
                likeCount: 0,
                childCount: 0,
                commentLiked: false,
                ...action.payload?.comment,
              },
            ],
            totalCount: 1,
          };
        }
      }
    },

    updatelikes: (state, action) => {
      const index = state?.myposts?.items.findIndex(
        (item) => item?._id === action?.payload?.postId,
      );
      state.myposts.items[index].postLikes = action?.payload?.postLikes;
    },
    toggleSendMessage: (state, action) => {
      state.isAllowMessages = action?.payload.allowBuyerToMessage;
    },
    toggleContentAccess: (state, action) => {
      state.isContentAccess = action?.payload.isContentAccess;
    },
    removeFromPendingMessage: (state, action) => {
      state.pendingMessages = state.pendingMessages?.filter((message) => {
        return message?._id !== action.payload?.groupId;
      });
      state.pendingEditMessages = state.pendingEditMessages?.filter(
        (message) => {
          return message?._id !== action.payload?.groupId;
        },
      );
    },
    updatePendingMessage: (state, action) => {
      state.pendingMessages = state.pendingMessages.map((message) =>
        message._id === action.payload._id
          ? { ...message, ...action.payload }
          : message,
      );
      state.pendingEditMessages = state.pendingEditMessages.map((message) =>
        message._id === action.payload._id
          ? { ...message, ...action.payload }
          : message,
      );
    },

    insertBeginningOfUserList: (state, action) => {
      const index = action.payload.insertatbeginning
        ? -1
        : state.usersList.items.findIndex(
            (u) => u._id === action.payload.data?._id,
          );
      if (index === -1) {
        state.usersList.items.unshift(action.payload.data);
        // state.myposts.totalCount++;
      } else {
        state.usersList.items[index] = action.payload.data;
      }
    },

    insertBeginningOfUserPosts: (state, action) => {
      state.myposts.items.unshift(action.payload.data);
      state.myposts.totalCount++;
    },
    resetUsers: (state, action) => {
      state.usersList = { items: [], totalCount: 0 };
      state.selectedUser = undefined;
    },
    setUserOrders: (state, action) => {
      state.ordersList = action.payload || {
        orders: { items: [], totalCount: 0 },
        pops: { items: [], totalCount: 0 },
      };
    },
    setUserListOrders: (state, action) => {
      state.usersList = action.payload || {
        orders: { items: [], totalCount: 0 },
        pops: { items: [], totalCount: 0 },
      };
    },
    updateUserOrders: (state, action) => {
      const orderIndex: any = state.ordersList?.orders?.items?.findIndex(
        (o: any) => o._id === action.payload?.orderId,
      );
      if (orderIndex > -1) {
        state.ordersList!.orders.items[orderIndex] = action.payload?.order;
      }
      state.ordersList = {
        orders: {
          items: state.ordersList?.orders?.items || [],
          totalCount: state.ordersList?.orders?.items.length || 0,
        },
        ...state.ordersList?.pops,
      };
    },
    updateUserOrdersByStatus: (state, action) => {
      state.ordersList = {
        orders: {
          items:
            state.ordersList?.orders?.items?.map((o: any) =>
              o._id === action.payload.orderId
                ? { ...o, orderStatus: action.payload.status }
                : o,
            ) || [],
          totalCount:
            state.ordersList?.orders?.items?.map((o: any) =>
              o._id === action.payload.orderId
                ? { ...o, orderStatus: action.payload.status }
                : o,
            ).length || 0,
        },
        ...state.ordersList?.pops,
      };
    },
    updateUsersUnreadCount: (state, action) => {
      if (
        state.selectedSubscription?._id !== action.payload?.subscriptionId ||
        (state.selectedSubscription?._id === action.payload?.subscriptionId &&
          !action.payload?.isChatWindowActive)
      ) {
        const index = state?.usersList?.items.findIndex(
          (item) => item?._id === action?.payload?.subscriptionId,
        );
        if (index !== -1) {
          const Top = state?.usersList?.items[index];
          state?.usersList?.items?.splice(index, 1);
          state?.usersList?.items?.splice(0, 0, Top);
          if (index !== -1) {
            state.usersList.items[0].unread!++;
          }
        }
      }
    },
    resetUnreadCount: (state, action) => {
      if (action.payload?.subscriptionId) {
        // if (
        //   state.selectedSubscription?._id &&
        //   state.selectedSubscription?._id !== action.payload?.subscriptionId
        // ) {
        const index = state?.usersList?.items.findIndex(
          (item) => item?._id === action?.payload?.subscriptionId,
        );
        if (index !== -1) {
          state.usersList.items[index].unread = 0;
        }
      }
    },
    setUserUnreadCount: (state, action) => {
      if (action.payload?.subscriptionId) {
        // if (
        //   state.selectedSubscription?._id &&
        //   state.selectedSubscription?._id !== action.payload?.subscriptionId
        // ) {
        const index = state?.usersList?.items.findIndex(
          (item) => item?._id === action?.payload?.subscriptionId,
        );
        if (state.selectedUser?._id === action.payload.subscriptionId) {
          state.selectedUser = {
            ...state.selectedUser,
            unread: action.payload?.unread || 0,
          };
        }
        if (index !== -1) {
          state.usersList.items[index].unread = action.payload?.unread || 0;
        }
      }
    },
    updateUserLastMessage: (state, action) => {
      if (action.payload?.id) {
        const index = state.usersList.items.findIndex(
          (item) => item?._id === action.payload.id,
        );
        if (index !== -1) {
          const sub = state.usersList.items[index];
          sub.lastMessage = action.payload.message.messageValue;
          sub.lastMessageAt = action.payload.message.createdAt;
          state.usersList.items[index] = sub;

          state.usersList.items.splice(index, 1);
          state.usersList.items.splice(0, 0, sub);
        }
      }
    },
    appendTagInSub: (state, action) => {
      const index = state.usersList.items.findIndex(
        (item) => item?._id === action.payload._id,
      );
      if (index !== -1) {
        state.usersList.items[index].tags?.push(action.payload?.tag);
      }
    },
    addUserLastMessage: (state, action) => {
      if (state.selectedSubscription?._id) {
        const index = state.usersList.items.findIndex(
          (item) => item?._id === action.payload.id,
        );
        if (index !== -1) {
          const sub = state.usersList.items[index];
          sub.lastMessage = action.payload.message.messageValue;
          sub.lastMessageAt = action.payload.message.createdAt;
          state.usersList.items[index] = sub;

          state.usersList.items.splice(index, 1);
          state.usersList.items.splice(0, 0, sub);
        }
      }
    },
    updatePost: (state, action) => {
      const index = state.myposts.items.findIndex(
        (item) => item?._id === action.payload?._id,
      );
      if (index !== -1) {
        state.myposts.items[index] = action.payload.data;
      }
    },
    addLikePost: (state, action) => {
      state.myposts.items.unshift({
        ...action.payload?.post,
        paymentComplete: false,
        tips: {
          items: [],
          totalCount: 0,
        },
        postLikes: {
          items: [],
          totalCount: 0,
        },
        liked: false,
        comments: {
          items: [],
          totalCount: 0,
        },
      });
      state.myposts.totalCount = state.myposts.totalCount + 1;
    },
    EditLivePost: (state, action) => {
      const PostIndex =
        state.myposts.items.findIndex(
          (post) => post._id === action.payload?.post?._id,
        ) || 0;

      if (PostIndex > -1) {
        state.myposts.items[PostIndex] = {
          ...state.myposts.items[PostIndex],
          ...action.payload?.post,
        };
      }
    },
    addLiveLikeToPost: (state, action) => {
      const PostIndex =
        state.myposts.items.findIndex(
          (post) => post._id === action.payload?.postId,
        ) || 0;

      if (PostIndex !== -1) {
        const likeIndex = state.myposts.items[
          PostIndex
        ].postLikes?.items.findIndex(
          (post) => (post.userId as any)?._id === action.payload.user?._id,
        );
        if (likeIndex !== -1) {
          state.myposts.items[PostIndex].postLikes?.items?.splice(
            likeIndex as number,
            1,
          );
          state.myposts.items[PostIndex].postLikes!.totalCount =
            state.myposts.items[PostIndex].postLikes!.totalCount - 1;
        } else {
          const isZero =
            state.myposts.items[PostIndex].postLikes?.items?.length;
          state.myposts.items[PostIndex].postLikes?.items.push({
            createdAt: dayjs().format(),
            _id: uuid(),
            userId: action.payload.user,
          });
          if (!isZero) {
            state.myposts.items[PostIndex].postLikes!.totalCount = 1;
          } else {
            state.myposts.items[PostIndex].postLikes!.totalCount =
              state.myposts.items[PostIndex].postLikes!.totalCount + 1;
          }
        }
      }

      // if (PostIndex !== -1) {
      //   state.myposts.items[PostIndex].postLikes = {
      //     items: [
      //       obj,
      //       ...(state.myposts.items[PostIndex].postLikes?.items as any),
      //     ],
      //     totalCount: [
      //       ...(state.myposts.items[PostIndex].postLikes?.items as any),
      //       obj,
      //     ].length,
      //   };
      // }
    },
    addLiveunLikeToPost: (state, action) => {
      const PostIndex =
        state.myposts.items.findIndex(
          (post) => post._id === action.payload?.postId,
        ) || 0;

      if (PostIndex !== -1) {
        const likeIndex = state.myposts.items[
          PostIndex
        ].postLikes?.items.findIndex(
          (post) => (post.userId as any)?._id === action.payload.user?._id,
        );
        if (likeIndex !== -1) {
          state.myposts.items[PostIndex].postLikes?.items?.splice(
            likeIndex as number,
            1,
          );
          state.myposts.items[PostIndex].postLikes!.totalCount =
            state.myposts.items[PostIndex].postLikes!.totalCount - 1;
        }
      }

      // if (PostIndex !== -1) {
      //   state.myposts.items[PostIndex].postLikes = {
      //     items: [
      //       obj,
      //       ...(state.myposts.items[PostIndex].postLikes?.items as any),
      //     ],
      //     totalCount: [
      //       ...(state.myposts.items[PostIndex].postLikes?.items as any),
      //       obj,
      //     ].length,
      //   };
      // }
    },
    updateScheduledPost: (state, action) => {
      const PostIndex = state.myposts.items.findIndex(
        (post) => post._id === action.payload?.post?._id,
      );
      const FilterPostIndex = state.filteredSchdulePost?.findIndex(
        (post) => post._id === action.payload?.post?._id,
      );
      if (PostIndex !== -1) {
        state.myposts.items[PostIndex] = action.payload?.post;
      }
      if (PostIndex !== -1) {
        state.filteredSchdulePost[FilterPostIndex || 0] = action.payload?.post;
      }
    },
    addLiveCommentToPost: (state, action) => {
      const PostIndex =
        state.myposts.items.findIndex(
          (post) => post._id === action.payload?.postId,
        ) || 0;

      const obj = {
        ...action.payload.comment,
        childCount: 0,
        likeCount: 0,
        commentLiked: false,
      };
      if (PostIndex !== -1) {
        state.myposts.items[PostIndex].comments = {
          items: [
            obj,
            ...(state.myposts.items[PostIndex].comments?.items || []),
          ],
          totalCount:
            (state.myposts.items[PostIndex].comments?.totalCount || 0) + 1,
          // totalCount:  [
          //   ...(state.myposts.items[PostIndex].comments?.items as any),
          //   obj,
          // ].length,
        };

        // if (
        //   state.myposts?.items[PostIndex]?.comments?.totalCount !==
        //   state.myposts?.items[PostIndex]?.comments?.items?.length
        // ) {
        //   state.tempComments[`post-${obj.postId}`] = [
        //     ...(state.tempComments[`post-${obj.postId}`] || []),
        //     obj._id,
        //   ];
        // }
      }
    },
    addLiveCommentLikeToPost: (state, action) => {
      const PostIndex: any = state.myposts.items.findIndex(
        (post) => post._id === action.payload?.postId,
      );
      if (PostIndex !== -1) {
        const commentId = action.payload?.parentId || action.payload?.commentId;
        const commentIndex: any = state.myposts?.items[
          PostIndex
        ].comments?.items?.findIndex((comment) => comment._id === commentId);
        if (commentIndex > -1) {
          const nestedComent =
            state.myposts.items?.[PostIndex]?.comments?.items[commentIndex] ||
            {};
          if (!action.payload.parentId) {
            if (nestedComent.likeCount) {
              nestedComent.likeCount = nestedComent.likeCount + 1;
            } else {
              nestedComent.likeCount = 1;
            }
          } else {
            let nestedCommentItem = {
              ...(nestedComent || {}),
            };
            const childComments = {
              ...(nestedCommentItem?.childComments || {}),
            };
            if (childComments.items?.length) {
              let nestedCommentitemIndex: any = childComments?.items?.findIndex(
                (cc) => cc._id === action.payload?.commentId,
              );

              if (nestedCommentitemIndex !== -1) {
                const item = childComments!.items![nestedCommentitemIndex];
                item.likeCount = (item.likeCount || 0) + 1;
                childComments!.items![nestedCommentitemIndex] = item;
                nestedComent.childComments = childComments as any;
              }
            }
          }
        }
      }
    },
    addLiveCommentUnLikeToPost: (state, action) => {
      const PostIndex: any = state.myposts.items.findIndex(
        (post) => post._id === action.payload?.postId,
      );
      if (PostIndex !== -1) {
        const commentId = action.payload?.parentId || action.payload?.commentId;
        const commentIndex: any = state.myposts?.items[
          PostIndex
        ].comments?.items?.findIndex((comment) => comment._id === commentId);
        if (commentIndex > -1) {
          const nestedComent =
            state.myposts.items?.[PostIndex]?.comments?.items[commentIndex] ||
            {};
          if (!action.payload.parentId) {
            if (nestedComent.likeCount) {
              nestedComent.likeCount = nestedComent.likeCount - 1;
            } else {
              nestedComent.likeCount = 1;
            }
          } else {
            let nestedCommentItem = {
              ...(nestedComent || {}),
            };
            const childComments = {
              ...(nestedCommentItem?.childComments || {}),
            };
            if (childComments.items?.length) {
              let nestedCommentitemIndex: any = childComments?.items?.findIndex(
                (cc) => cc._id === action.payload?.commentId,
              );

              if (nestedCommentitemIndex !== -1) {
                const item = childComments!.items![nestedCommentitemIndex];
                item.likeCount = (item.likeCount || 0) - 1;
                childComments!.items![nestedCommentitemIndex] = item;
                nestedComent.childComments = childComments as any;
              }
            }
          }
        }
      }
    },
    addLiveTipsToPost: (state, action) => {
      if (action.payload?.tip?._id) {
        const PostIndex: any = state.myposts?.items?.findIndex(
          (post) => post._id === action.payload?.tip?.postId,
        );
        if (PostIndex !== -1) {
          state.myposts.items[PostIndex].tips = {
            items: [
              action.payload?.tip,
              ...(state.myposts.items[PostIndex]?.tips?.items || []),
            ],
            totalCount:
              (state.myposts.items[PostIndex]?.tips?.totalCount || 0) + 1,
          };

          state.myposts.items[PostIndex].comments = {
            items: [
              action.payload.tip || {},
              ...(state.myposts.items[PostIndex].comments?.items || []),
            ],
            totalCount:
              (state.myposts.items[PostIndex].comments?.totalCount || 0) + 1,
          };
        }
      }
    },
    addLiveReplyToComment: (state, action) => {
      const index = state.myposts.items.findIndex(
        (p) => p._id === action.payload?.postId,
      );
      if (index > -1) {
        const nestedCommentIndex = state.myposts.items[
          index
        ].comments!.items!.findIndex(
          (n) => n._id === action.payload?.comment?.commentId,
        );
        if (nestedCommentIndex > -1) {
          const obj = {
            ...action.payload.comment,
            postId: action.payload.postId,
          };
          let nestedComment = {
            ...(state.myposts.items[index].comments!.items![
              nestedCommentIndex
            ] || {}),
          };
          const childComments = {
            ...(nestedComment.childComments || {}),
          };

          nestedComment.childCount!++;
          state.myposts.items[index].comments!.items![nestedCommentIndex] =
            nestedComment;

          if (!!childComments && childComments?.items?.length) {
            childComments.items!.push({
              likeCount: 0,
              childCount: 0,
              commentLiked: false,
              tempComment: true,
              ...obj,
            });
            childComments.totalCount = nestedComment.childCount || 0;

            state.myposts.items[index].comments!.items![
              nestedCommentIndex
            ].childComments = (childComments as IPostCommentType) || {
              totalCount: 0,
              items: [],
            };
          } else {
            childComments['items'] = [
              {
                likeCount: 0,
                childCount: 0,
                commentLiked: false,
                tempComment: true,
                ...obj,
              },
            ];
            childComments['totalCount'] = nestedComment.childCount || 0;

            state.myposts.items[index].comments!.items![
              nestedCommentIndex
            ].childComments = (childComments as IPostCommentType) || {
              totalCount: 0,
              items: [],
            };
          }
          if (childComments.totalCount !== childComments?.items?.length) {
            state.tempComments[
              (action.payload?.comment?.commentId as string) || ''
            ] = [
              ...(state.tempComments[
                (action.payload?.comment?.commentId as string) || ''
              ] || []),
              obj._id,
            ];
          }
        }
      }
    },
    deletePostLive: (state, action) => {
      const PostIndex =
        state.myposts.items.findIndex(
          (post) => post._id === action.payload?.postId,
        ) || 0;

      if (PostIndex !== -1) {
        state.myposts.items.splice(PostIndex as number, 1);
        state.myposts.totalCount = state.myposts.totalCount - 1;
      }
    },
    setSelectedScheduledPost: (state, action) => {
      state.selectedScheduledPost = action.payload;
    },
    delScheduledPost: (state, action) => {
      state.myposts = {
        items: state.myposts.items.filter(
          (post) => post._id === action.payload._id,
        ),
        totalCount: 0,
      };
    },
    removeSelectedPost: (state) => {
      state.selectedScheduledPost = {};
      state.selectedPostTemplate = {};
    },
    updateSelectedScheduledPost: (state, action) => {
      state.selectedScheduledPost = action.payload;
    },
    editedPostTemplate: (state, action) => {
      state.selectedPostTemplate = action.payload;
    },
    toggleEditPost: (state) => {
      state.showEditPost = !state.showEditPost;
    },
    updateOnlineStatus: (state, { payload }) => {
      const isOnline = payload.type === 'userOnline';
      const { id = '', isSeller } = payload;
      if (state.selectedSubscription?.buyerId._id === id && isSeller) {
        updateUserOnlineStatus(state.selectedSubscription!.buyerId!, isOnline);
      } else if (state.selectedSubscription?.sellerId._id === id) {
        updateUserOnlineStatus(state.selectedSubscription!.sellerId!, isOnline);
      }
      state.usersList.items.forEach((user) => {
        if (isSeller) {
          if (user.buyer?._id === id) {
            updateUserOnlineStatus(user!.buyer!, isOnline);
          }
        } else {
          if (user.seller?._id === id) {
            updateUserOnlineStatus(user!.seller!, isOnline);
          }
        }
      });
    },
  },
  extraReducers: (builder) => {
    // * Get All posts
    builder.addCase(GetPostsList.fulfilled, (state, action) => {
      state.posts = action.payload.data;
      state.isPostsFetching = false;
      // Add user to the state array
      // state.value = 10;
    });
    builder.addCase(GetPostsList.pending, (state, action) => {
      state.isPostsFetching = true;
    });
    builder.addCase(GetPostsList.rejected, (state, action) => {
      state.isPostsFetching = false;
    });

    builder.addCase(paymentForMembershipUpgrade.fulfilled, (state, action) => {
      state.isMemberShipUpgrading = false;
      // Add user to the state array
      // state.value = 10;
    });
    builder.addCase(paymentForMembershipUpgrade.pending, (state, action) => {
      state.isMemberShipUpgrading = true;
    });
    builder.addCase(paymentForMembershipUpgrade.rejected, (state, action) => {
      state.isMemberShipUpgrading = false;
    });
    builder.addCase(
      getUserOrderNotPurchaseService.fulfilled,
      (state, action) => {
        if (action.payload.isInitial) {
          state.ordersListNotPurchased.service = action.payload.data;
        } else {
          state.ordersListNotPurchased.service = {
            items: [
              ...state.ordersListNotPurchased.service.items,
              ...action.payload.data.items,
            ],
            totalCount: action.payload.data.totalCount,
          };
        }
        // state.isPostsFetching = false;
      },
    );
    builder.addCase(getUserOrderNotPurchaseService.pending, (state, action) => {
      // state.isPostsFetching = true;
    });
    builder.addCase(
      getUserOrderNotPurchaseService.rejected,
      (state, action) => {
        // state.isPostsFetching = false;
      },
    );
    // export const
    builder.addCase(
      getUserOrderNotPurchaseCourse.fulfilled,
      (state, action) => {
        if (action.payload.isInitial) {
          state.ordersListNotPurchased.course = action.payload.data;
        } else {
          state.ordersListNotPurchased.course = {
            items: [
              ...state.ordersListNotPurchased.course.items,
              ...action.payload.data.items,
            ],
            totalCount: action.payload.data.totalCount,
          };
        }
        // state.isPostsFetching = false;
      },
    );
    builder.addCase(getUserOrderNotPurchaseCourse.pending, (state, action) => {
      // state.isPostsFetching = true;
    });
    builder.addCase(getUserOrderNotPurchaseCourse.rejected, (state, action) => {
      // state.isPostsFetching = false;
    });
    //Unlocking Posts
    builder.addCase(unlockPost.fulfilled, (state, action) => {
      const index = state.myposts.items.findIndex(
        (p) => p._id === action.payload?.postId,
      );
      if (index > -1) {
        state.myposts.items[index] = {
          ...state.myposts.items[index],
          paymentComplete: true,
        };
      }
      state.isPostUnlocking = false;
    });
    builder.addCase(unlockPost.pending, (state, action) => {
      state.isPostUnlocking = true;
    });
    builder.addCase(unlockPost.rejected, (state, action) => {
      state.isPostUnlocking = false;
    });
    //Updating post
    builder.addCase(editPost.fulfilled, (state, action) => {
      const index = state.myposts.items.findIndex(
        (item) => item?._id === action.payload?.postId,
      );
      if (index !== -1) {
        state.myposts.items[index] = {
          ...state.myposts.items[index],
          ...action.payload.data,
        };
      }
    });

    //Upgrade memeberShip
    builder.addCase(paymentForMembership.fulfilled, (state, action) => {
      state.isUpgradingMemberShip = false;
    });
    builder.addCase(paymentForMembership.pending, (state, action) => {
      state.isUpgradingMemberShip = true;
    });
    builder.addCase(paymentForMembership.rejected, (state, action) => {
      state.isUpgradingMemberShip = false;
    });
    // * Get All posts for Logged In user
    builder.addCase(myPostsList.fulfilled, (state, action) => {
      if (action.payload.isInitial) {
        state.myposts = action.payload.data;
      } else {
        state.myposts.items = [
          ...state.myposts.items,
          ...action.payload.data.items,
        ];
      }
      state.isMyPostsFetching = false;
    });
    builder.addCase(myPostsList.pending, (state, action) => {
      state.isMyPostsFetching = true;
    });
    builder.addCase(myPostsList.rejected, (state, action) => {
      state.isMyPostsFetching = false;
    });
    builder.addCase(mySchedulePostsList.fulfilled, (state, action) => {
      state.myposts = {
        items: action.payload.data,
        totalCount: 0,
      };
      state.isMyPostsFetching = false;
    });
    builder.addCase(mySchedulePostsList.pending, (state, action) => {
      state.isMyPostsFetching = true;
    });
    builder.addCase(mySchedulePostsList.rejected, (state, action) => {
      state.isMyPostsFetching = false;
    });
    // Selected User Post
    builder.addCase(userPostsList.fulfilled, (state, action) => {
      if (action.payload.isInitial) {
        state.myposts = action.payload.data;
      } else {
        state.myposts.items = [
          ...state.myposts.items,
          ...action.payload.data.items,
        ];
      }
      state.isMyPostsFetching = false;
    });
    builder.addCase(userPostsList.pending, (state, action) => {
      state.isMyPostsFetching = true;
    });
    builder.addCase(userPostsList.rejected, (state, action) => {
      state.isMyPostsFetching = false;
    });

    // * Get All posts for specific User

    // * Get All posts for specific User
    builder.addCase(getUserMemberships.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.userMemberships = {
          ...action.payload?.data,
          memberShips: (action?.payload?.data as any)?.meberships || [],
        };
        state.isMemberShipsFetching = false;
      }
    });
    builder.addCase(getUserMemberships.pending, (state, action) => {
      state.isMemberShipsFetching = true;
    });
    builder.addCase(getUserMemberships.rejected, (state, action) => {
      state.isMemberShipsFetching = false;
    });
    //TipPaying
    builder.addCase(tipPayForPost.fulfilled, (state, action) => {
      state.isTipPaying = false;
    });
    builder.addCase(tipPayForPost.pending, (state, action) => {
      state.isTipPaying = true;
    });
    builder.addCase(tipPayForPost.rejected, (state, action) => {
      state.isTipPaying = false;
    });
    // * delete Post
    builder.addCase(deletePost.fulfilled, (state, action) => {
      const index = state.myposts.items.findIndex(
        (p) => p._id === action.payload?._id,
      );
      const filteredSchdulePostIndex = state.filteredSchdulePost.findIndex(
        (p) => p._id === action.payload?._id,
      );
      if (index > -1) {
        state.myposts.items.splice(index, 1);
        state.myposts.totalCount--;
      }
      if (filteredSchdulePostIndex > -1) {
        state.filteredSchdulePost.splice(filteredSchdulePostIndex, 1);
      }
      state.isPostDeleting = false;
    });
    builder.addCase(deletePost.pending, (state, action) => {
      state.isPostDeleting = true;
    });
    builder.addCase(deletePost.rejected, (state, action) => {
      state.isPostDeleting = false;
    });
    // * toggle Comemnt Like
    builder.addCase(toggleCommentLike.fulfilled, (state, action) => {
      const index = state.myposts.items.findIndex(
        (p) => p._id === action.payload?.postId,
      );
      if (index > -1) {
        const commentIndex = state.myposts.items[
          index
        ].comments!.items.findIndex((cm) => cm._id === action.payload?._id);
        if (commentIndex > -1) {
          let comment =
            state.myposts.items[index].comments!.items[commentIndex];
          if (comment.commentLiked) {
            comment = {
              ...comment,
              commentLiked: false,
              likeCount: comment.likeCount! - 1,
            };
          } else {
            comment = {
              ...comment,
              commentLiked: true,
              likeCount: comment.likeCount! + 1,
            };
          }
          state.myposts.items[index].comments!.items[commentIndex] = comment;
        }
      }
      // state.isPostDeleting = false;
    });
    // builder.addCase(toggleCommentLike.pending, (state, action) => {
    //   state.isPostDeleting = true;
    // });
    // builder.addCase(toggleCommentLike.rejected, (state, action) => {
    //   state.isPostDeleting = false;
    // });
    builder.addCase(getPostComments.fulfilled, (state, action) => {
      const index = state.myposts.items.findIndex(
        (p) => p._id === action.payload?._id,
      );
      if (index > -1) {
        if (action.payload.isInitial && !action.payload.append) {
          state.myposts.items[index].comments = action.payload.data;
        } else {
          state.myposts.items[index].comments!.items = [
            ...(state.myposts.items[index].comments?.items || []),
            ...action.payload.data.items,
          ];
        }
      }
      state.isCommentsFetching = false;

      // state.isPostDeleting = false;
    });
    builder.addCase(getPostComments.pending, (state, action) => {
      state.isCommentsFetching = true;
    });
    builder.addCase(getPostComments.rejected, (state, action) => {
      state.isCommentsFetching = false;
    });
    builder.addCase(addReplyToComment.fulfilled, (state, action) => {
      const index = state.myposts.items.findIndex(
        (p) => p._id === action.payload?.postId,
      );
      if (index > -1) {
        const nestedCommentIndex = state.myposts.items[
          index
        ].comments!.items!.findIndex(
          (n) => n._id === action.payload?.commentId,
        );
        if (nestedCommentIndex > -1) {
          let nestedComment = {
            ...(state.myposts.items[index].comments!.items![
              nestedCommentIndex
            ] || {}),
          };
          const childComments = {
            ...(nestedComment.childComments || {}),
          };

          nestedComment.childCount!++;
          state.myposts.items[index].comments!.items![nestedCommentIndex] =
            nestedComment;

          if (!!childComments && childComments?.items?.length) {
            childComments.items!.push({
              likeCount: 0,
              childCount: 0,
              commentLiked: false,
              tempComment: true,
              ...action?.payload?.data,
            });
            childComments.totalCount = nestedComment.childCount || 0;

            state.myposts.items[index].comments!.items![
              nestedCommentIndex
            ].childComments = (childComments as IPostCommentType) || {
              totalCount: 0,
              items: [],
            };
          } else {
            childComments['items'] = [
              {
                likeCount: 0,
                childCount: 0,
                commentLiked: false,
                tempComment: true,
                ...action?.payload?.data,
              },
            ];
            childComments['totalCount'] = nestedComment.childCount || 0;

            state.myposts.items[index].comments!.items![
              nestedCommentIndex
            ].childComments = (childComments as IPostCommentType) || {
              totalCount: 0,
              items: [],
            };
          }
          if (childComments.totalCount !== childComments?.items?.length) {
            state.tempComments[(action.payload?.commentId as string) || ''] = [
              ...(state.tempComments[
                (action.payload?.commentId as string) || ''
              ] || []),
              action?.payload?.data._id,
            ];
          }
        }
      }

      // state.isPostDeleting = false;
    });
    builder.addCase(getPostCommentReplies.fulfilled, (state, action) => {
      const index = state.myposts.items.findIndex(
        (p) => p._id === action.payload?.postId,
      );
      if (index > -1) {
        const nestedComment = state.myposts.items[
          index
        ].comments!.items!.findIndex((n) => n._id === action.payload?._id);
        if (nestedComment > -1) {
          let childComments = {
            ...(state.myposts.items[index].comments!.items![nestedComment]
              .childComments || {}),
          };

          if (action.payload.isInitial && !action.payload.append) {
            childComments = action.payload.data;
            state.myposts.items[index].comments!.items![
              nestedComment
            ].childComments = childComments as IPostCommentType;
          } else {
            const newTempComments: any[] = [
              ...(state.tempComments[action.payload._id || ''] || []),
            ];
            const newReplies = state.tempComments[action.payload._id]
              ? action.payload.data.items.filter((i) => {
                  const itemIndex = state.tempComments[
                    action.payload._id
                  ].findIndex((a) => {
                    return a === i._id;
                  });
                  if (itemIndex === -1) {
                    return true;
                  }
                  newTempComments.splice(itemIndex, 1);
                  return false;
                })
              : action.payload.data.items;
            childComments.items = [
              ...(childComments.items || []),
              ...newReplies,
            ];
            if (state.tempComments[action.payload._id]) {
              const isElementEquals =
                childComments.items.length !== childComments.totalCount;
              if (!!newTempComments.length && isElementEquals) {
                state.tempComments[action.payload._id] = newTempComments;
              }
              if (!isElementEquals) {
                delete state.tempComments[action.payload._id];
              }
            }
            state.myposts.items[index].comments!.items![
              nestedComment
            ].childComments = childComments as IPostCommentType;
          }
        }
      }
    });

    // * toggle Comemnt Like
    builder.addCase(toggleReplyCommentLike.fulfilled, (state, action) => {
      const index = state.myposts.items.findIndex(
        (p) => p._id === action.payload?.postId,
      );
      if (index > -1) {
        const commentIndex = state.myposts.items[
          index
        ].comments!.items.findIndex((cm) => cm._id === action.payload?._id);
        if (commentIndex > -1) {
          let comment = state.myposts.items[index].comments!.items[
            commentIndex
          ].childComments!.items.findIndex(
            (nc) => nc._id === action.payload.nestcommentId,
          );
          if (comment > -1) {
            let nestedComment =
              state.myposts.items[index].comments!.items[commentIndex]
                .childComments!.items[comment];
            if (nestedComment.commentLiked) {
              nestedComment = {
                ...nestedComment,
                commentLiked: false,
                likeCount: nestedComment.likeCount! - 1,
              };
            } else {
              nestedComment = {
                ...nestedComment,
                commentLiked: true,
                likeCount: nestedComment.likeCount! + 1,
              };
            }
            state.myposts.items[index].comments!.items[
              commentIndex
            ].childComments!.items[comment] = nestedComment;
          }
        }
      }
      // state.isPostDeleting = false;
    });
    // * getPost tips
    builder.addCase(getPostTips.fulfilled, (state, action) => {
      const index = state.myposts.items.findIndex(
        (p) => p._id === action.payload?._id,
      );

      if (index > -1) {
        if (action.payload.isInitial) {
          state.myposts.items[index].tips = action.payload.data;
        } else {
          state.myposts.items[index].tips.items = [
            ...(state.myposts.items[index].tips?.items || []),
            ...action.payload.data.items,
          ];
        }
      }
    });

    builder.addCase(getSelectedOrder.pending, (state, action) => {
      state.isOrderFetching = true;
    });
    builder.addCase(getSelectedOrder.rejected, (state, action) => {
      state.isOrderFetching = false;
    });
    builder.addCase(getSelectedOrder.fulfilled, (state, action) => {
      // Add user to the state array
      state.selectedOrder = action.payload.data;
      state.isOrderFetching = false;
    });
    builder.addCase(getSubIdByUserId.pending, (state, action) => {
      state.issubIdFetching = true;
    });
    builder.addCase(getSubIdByUserId.rejected, (state, action) => {
      state.issubIdFetching = false;
    });
    builder.addCase(getSubIdByUserId.fulfilled, (state, action) => {
      // Add user to the state array
      if (action.payload?.data?.subscriptionId) {
        state.subscriptionId = action.payload.data.subscriptionId;
      }
      state.issubIdFetching = false;
    });
    // getUser Orders
    builder.addCase(getSelectedUserOrders.pending, (state, action) => {
      state.isUserOrdersFetching = true;
    });
    builder.addCase(getSelectedUserOrders.rejected, (state, action) => {
      state.isUserOrdersFetching = false;
    });
    builder.addCase(getSelectedUserOrders.fulfilled, (state, action) => {
      // Add user to the state array
      if (action.payload.isInitial) {
        state.ordersList = action.payload.data;
      } else {
        state.ordersList = {
          orders: {
            items: [
              ...(state?.ordersList?.orders?.items || []),
              ...(action?.payload?.data?.orders?.items || []),
            ],
            totalCount: action?.payload?.data?.orders?.totalCount || 0,
          },
          pops: {
            items: [...(action?.payload?.data?.pops?.items || [])],
            totalCount: action?.payload?.data?.pops?.totalCount || 0,
          },
        };
      }
      state.isUserOrdersFetching = false;
    });
    // * getUser Lists
    builder.addCase(getUsersList.pending, (state, action) => {
      state.isUserListFetching = true;
    });
    builder.addCase(getUsersList.rejected, (state, action) => {
      state.isUserListFetching = false;
    });
    builder.addCase(getUsersList.fulfilled, (state, action) => {
      // Add user to the state array
      if (action.payload.data?.totalCount) {
        if (action.payload.isInitial) {
          state.usersList = action.payload.data;
        } else {
          const newItems = action.payload.data.items.filter((item) => {
            return (
              !state.fetchedUsersIds?.[item._id || '']?._id &&
              !state.usersList.items.find((i) => i._id === item._id)?._id
            );
          });
          state.usersList.items = [
            ...(state.usersList.items || []),
            ...newItems,
          ];
        }
        if (action.payload.firstSelect) {
          if (action.payload.defaultOrder) {
            const order =
              state.usersList.items.find(
                (sub) => sub._id === action.payload.defaultOrder,
              ) || state.usersList.items[0];
            // order.unread = 0;
            state.selectedUser = order;
          } else {
            const order = state.usersList.items[0];
            // order.unread = 0;
            state.selectedUser = order;
          }
        }
      }
      state.isUserListFetching = false;
    });
    builder.addCase(getSubItem.fulfilled, (state, action) => {
      // Add user to the state array
      const index = state.usersList.items.findIndex(
        (u) => u._id === action.payload.subId,
      );
      if (index === -1) {
        state.usersList.items.unshift({
          ...action.payload.data.items[0],
        });
        state.fetchedUsersIds![action.payload.data.items[0]._id || ''] =
          action.payload.data.items[0]._id;
      }
    });
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = MySalesSlice;

export const {
  setSelectedOrder,
  setSelectedUser,
  addPendingMessage,
  removeFromPendingMessage,
  removeMediaFromPendingMessage,
  reInitializeState,
  updatePendingMessage,
  addComment,
  searchUserList,
  updatelikes,
  insertBeginningOfUserPosts,
  setUserOrders,
  updateUserOrders,
  resetUsers,
  updateUserOrdersByStatus,
  setSelectedSub,
  resetSubId,
  updateUsersUnreadCount,
  resetUnreadCount,
  toggleSendMessage,
  addUserLastMessage,
  updateUserLastMessage,
  insertBeginningOfUserList,
  updatePost,
  resetpostschatmedia,
  addLiveLikeToPost,
  addLiveCommentToPost,
  addLiveTipsToPost,
  addLiveCommentLikeToPost,
  addLiveunLikeToPost,
  addLiveCommentUnLikeToPost,
  updateChatWindowActiveStatus,
  addLiveReplyToComment,
  updateEditSchedulePost,
  addLikePost,
  deletePostLive,
  EditLivePost,
  updateScheduledPost,
  setUserUnreadCount,
  updateFilteredSchedulePost,
  toggleContentAccess,
  setSelectedScheduledPost,
  updateSelectedScheduledPost,
  removeSelectedPost,
  setUserListOrders,
  toggleEditPost,
  setIsUsersFetched,
  appendTagInSub,
  updateOnlineStatus: updateChatUserOnlineStatus,
  setUserMemberships,
  editedPostTemplate,
  addEditPendingMessage,
} = actions;

export default reducer;
