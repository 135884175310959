import { Subscribers } from 'assets/svgs';
import NewButton from 'components/NButton';
import { useState } from 'react';
import styled from 'styled-components';

interface IProfileCardProps {
  className?: string;
  message?: string;
}

const ProfileCard: React.FunctionComponent<IProfileCardProps> = ({
  className,
}) => {
  const [options, setOptions] = useState([
    {
      title: 'Ullamco laboris nisi ut aliquip ex ea commodo consequat',
      active: true,
      name: 'a1',
    },
    {
      title: 'Consectetur adipiscing elit, sed do eiusmod',
      active: true,
      name: 'b2',
    },
    {
      title: 'Ut enim ad minim veniam, quis nostrud exercitation',
      active: true,
      name: 'c3',
    },
  ]);

  const handleToggleOptions = (e: any) => {
    const { name: tName, checked } = e.target;
    const stateOptions = [...options];

    const newOptions = stateOptions.map((op) => {
      if (op.name === tName) {
        op.active = checked;
      }
      return op;
    });
    setOptions(newOptions);
  };
  return (
    <div className={className}>
      <div className="visual cover-image">
        <div className="visual--image">
          <img src="/assets/images/img-cover.jpg" alt="img description" />
          <div className="user-desc">
            <h1 className="user-name">Mai Seohyn</h1>
            <span className="user-profession">Singer - Actress</span>
            <span className="user-subs">
              <Subscribers /> 2,226,524
            </span>
          </div>
        </div>
      </div>
      <div className="user-pop-desc">
        <div className="profile--photo">
          <img
            src="/assets/images/default-profile-img.svg"
            alt="img description"
          />
        </div>
        <div className="profile--info">
          <h2>Welcome to my Private Pop Page!</h2>
          <p>
            I’m so glad to have you!! ♡♡ Consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
        <div className="more-options">
          <div className="options">
            <ul className="options-list">
              {options.map((d: any) => {
                return (
                  <li className="option-item">
                    <span className="title">{d.title}</span>
                    <i className="icon icon-check"></i>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="btn-holder">
            <NewButton
              block
              type="primary"
              shape="circle"
              className="btn-disabled"
            >
              Subscribe to my Pop Page - $5.00
            </NewButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default styled(ProfileCard)`
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  .visual--image {
    position: relative;
    height: 220px;

    @media (max-width: 479px) {
      height: 180px;
    }

    &:before {
      position: absolute;
      left: 0;
      right: 0%;
      top: 0;
      bottom: 0;
      content: '';
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 54.17%,
        rgba(0, 0, 0, 0.7) 100%
      );
    }
  }

  .user-desc {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 13px 20px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
    line-height: 18px;

    @media (max-width: 479px) {
      padding: 10px 15px;
    }
  }

  .user-name {
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
    margin: 0 0 2px;
  }

  .user-profession {
    display: block;
    margin: 0 0 7px;
  }

  .user-subs {
    display: block;
    font-size: 12px;
    line-height: 15px;

    svg {
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px 0 0;
    }
  }

  .profile--photo {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: -40px;
    width: 80px;
    height: 80px;
    box-shadow: none;
    border-width: 2px;

    &:before {
      border-width: 2px;
      left: -2px;
      right: -2px;
      top: -2px;
      bottom: -2px;
    }
  }

  .user-pop-desc {
    position: relative;
  }

  .profile--info {
    background: #025e93;
    color: #fff;
    text-align: center;
    padding: 55px 25px 15px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;

    @media (max-width: 479px) {
      padding: 55px 15px 15px;
    }

    h2 {
      font-size: 22px;
      line-height: 24px;
      font-weight: 500;
      margin: 0 0 10px;

      @media (max-width: 479px) {
        font-size: 20px;
      }
    }

    p {
      margin: 0 0 6px;
    }
  }

  .more-options {
    border: 1px solid #e6ecf5;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }

  .options {
    padding: 20px;
    background: #f6f9fc;

    @media (max-width: 479px) {
      padding: 15px;
    }
  }

  .options-list {
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid #edf2f6;
    border-radius: 6px;
    background: #fff;
    font-size: 14px;
    line-height: 18px;
    color: #515365;
    font-weight: 500;

    li {
      padding: 17px 50px 17px 18px;
      position: relative;
      border-bottom: 1px solid #edf2f6;

      &:last-child {
        border: none;
      }

      .icon {
        position: absolute;
        right: 17px;
        top: 50%;
        transform: translate(0, -50%);
        color: #38a9ff;
        font-size: 16px;
        line-height: 1;
        font-weight: 300;
      }
    }
  }

  .btn-holder {
    padding: 20px;
    background: #fff;

    @media (max-width: 479px) {
      padding: 15px;
    }

    .button-primary {
      /* background: #38a9ff !important; */
      font-size: 16px;
      line-height: 20px;
      padding: 11px 15px;
    }
  }
`;
