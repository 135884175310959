import {
  CloseCircle,
  Edit,
  Mp3Icon,
  Play,
  PlayIcon,
  VerticalDots,
  Video,
} from 'assets/svgs';
import attrAccept from 'attr-accept';
import Checkbox from 'components/checkbox';
import Image from 'components/Image';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import React, { ReactElement } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
import { MediaType } from 'types/ChatSubscription';
import { ImageSizesProps } from 'types/ImageTypes';
import { getImageURL, isValidUrl } from 'util/index';

dayjs.extend(isYesterday);
dayjs.extend(isToday);
const ImageComp = styled.div<{ rotation?: number | string }>`
  width: 100%;
  height: 100%;
  ${({ rotation }) => {
    if (rotation !== '' && rotation !== undefined) {
      return ` img {

        transform: rotate(${rotation}deg)
      }
       `;
    }
  }}
`;
interface Props {
  className?: string;
  isGalleryChecked?: boolean;
  media?: MediaType & {
    checked?: boolean;
    progress?: number;
    status?: string;
    rotate?: number | string;
  };
  filekey?: string;
  createdAt?: string;
  dragHandle?: boolean;
  checked?: boolean;
  onClick?: (
    media?: MediaType & { checked?: boolean; rotate?: string | number },
  ) => void;
  showOptions?: {
    video?: boolean;
    play?: boolean;
    timeStampText?: boolean;
    edit?: boolean;
    closeIcon?: boolean;
    selectable?: boolean;
    showprogress?: boolean;
    mutipleSelect?: boolean;
  };
  onSelect?(id?: string, value?: boolean): void;
  onClose?(id?: string): void;
  icon?: HTMLElement | ReactElement;
  ImageSizesProps?: ImageSizesProps;
  breakCache?: boolean;
}

const AttachmentContainer: React.FC<Props> = (props) => {
  const {
    className,
    media,
    createdAt,
    filekey = '_id',
    dragHandle = false,
    isGalleryChecked = false,
    checked = false,
    showOptions = {
      timeStampText: true,
      video: true,
      play: true,
      edit: false,
      closeIcon: false,
      selectable: false,
      showprogress: false,
      mutipleSelect: true,
    },
    onSelect,
    onClose,
    icon: Icon,
    onClick,
    ImageSizesProps = {},
    breakCache = false,
  } = props;
  const isVideo = attrAccept(
    { name: media?.name, type: media?.type },
    'video/*',
  );
  const isImage = attrAccept(
    { name: media?.name, type: media?.type },
    'image/*',
  );
  const isAudio = attrAccept(
    { name: media?.name, type: media?.type },
    'audio/*',
  );
  const handleClose = (e?: any) => {
    e.stopPropagation();
    onClose && onClose(media?.[filekey as keyof typeof media] as string);
  };
  let src = '/assets/images/video-placeholder.png';
  if (isVideo && media?.thumbnail) {
    src = media.thumbnail;
  }
  if (!isVideo && media?.path) {
    src = media.path;
  }
  if (isAudio) {
    src = '/assets/images/mp3.svg';
  }
  let isValidurl = isValidUrl(media?.path || '');
  let fallback = isImage ? media?.path : '';
  if (isImage && media?.path && isValidurl) {
    let date = `?${media.updatedAt}`;
    const { url, fallbackUrl } = getImageURL(media.path, {
      imgix: {
        all: 'w=163&h=163&auto=compress',
      },
      ...ImageSizesProps,
    });
    fallback = media.fallbackUrl
      ? media.fallbackUrl + date
      : media?.path + date;
    src = url + date;
  }
  const DragHandler = SortableHandle(() => (
    <span className="drag-dots">
      <VerticalDots />
    </span>
  ));
  return (
    <div
      className={`img-container ${className} `}
      onClick={() => onClick?.(media)}
    >
      {isImage && isValidurl ? (
        <ImageComp rotation={media?.rotate}>
          <Image
            src={`${src}`}
            fallbackUrl={fallback}
            breakCache={breakCache}
          />
        </ImageComp>
      ) : isAudio ? (
        <div className="audio_thumbnail">
          <div className="icons-holder">
            <span className="icon-play">
              <PlayIcon />
            </span>
            <span className="img-audio">
              <Mp3Icon />
            </span>
          </div>
        </div>
      ) : (
        <ImageComp rotation={media?.rotate}>
          <Image src={`${src}`} breakCache={breakCache} />
        </ImageComp>
      )}
      {showOptions.timeStampText && (
        <span className="timestamp">
          {dayjs(media?.createdAt || createdAt).isYesterday()
            ? 'Yesterday'
            : dayjs(media?.createdAt || createdAt).isToday()
            ? 'Today'
            : dayjs(media?.createdAt || createdAt).format('MM/DD/YYYY')}
        </span>
      )}
      {dragHandle && <DragHandler />}
      {showOptions.selectable && (
        <Checkbox
          checked={!showOptions?.mutipleSelect ? checked : media?.checked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onSelect?.(
              media?.[filekey as keyof typeof media] as string,
              e.target.checked,
            )
          }
        />
      )}
      {showOptions.closeIcon && (
        <span className="close-icon " onClick={handleClose}>
          <CloseCircle />
        </span>
      )}
      {isVideo && (
        <>
          {showOptions.video && (
            <span className="video-length">
              <Video /> {media?.videoDuration}
            </span>
          )}
          {Icon && (
            <span
              id="play-icon"
              className={`play-icon ${Icon ? 'custom_icon' : ''}`}
            >
              {Icon}
            </span>
          )}
          {showOptions.play && (
            <span id="play-icon" className="play-icon">
              <Play />
            </span>
          )}
          {showOptions.edit && (
            <span id="edit-icon" className="edit-icon">
              <Edit /> Edit
            </span>
          )}
        </>
      )}
      {showOptions.showprogress && (
        <span className="progress-bar">
          <span
            className="progress"
            style={{
              width: `${(media as any)?.progress}%`,
            }}
          ></span>
        </span>
      )}
    </div>
  );
};

export default styled(AttachmentContainer)`
  cursor: pointer;
  width: calc(25% - 6px);
  margin: 0 3px 4px;
  padding-top: calc(25% - 6px);
  position: relative;
  @media (max-width: 1199px) {
    width: calc(33.333% - 6px);
    padding-top: calc(33.333% - 6px);
  }
  @media (max-width: 767px) {
    width: calc(50% - 6px);
    padding-top: calc(50% - 6px);
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .timestamp {
    position: absolute;
    left: 10px;
    top: 10px;
    min-width: 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    font-size: 13px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 500;
    border-radius: 4px;
    padding: 4px 5px;
  }
  .video-length {
    position: absolute;
    left: 10px;
    bottom: 10px;
    min-width: 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    font-size: 13px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 500;
    border-radius: 4px;
    padding: 4px 5px;
    svg {
      width: 18px;
      height: 16px;
    }
  }
  .edit-icon {
    position: absolute;
    right: 6px;
    bottom: 10px;
    min-width: 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    font-size: 13px;
    line-height: 15px;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    border-radius: 4px;
    padding: 4px 10px;
    svg {
      width: 14px;
      height: 13px;
    }
  }
  .close-icon {
    width: 24px;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    color: #fff;
    @media (max-width: 767px) {
      width: 12px;
      right: 5px;
      top: 5px;
    }
    svg {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }
  .play-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    background: rgba(0, 0, 0, 0.07);
    color: #fff;
    cursor: pointer;
    border-radius: 100%;
    &:hover {
      background: #000;
    }
  }
  .custom_icon {
    background: rgba(0, 0, 0, 0.7);
  }
  .progress-bar {
    position: absolute;
    left: 5px;
    height: 6px;
    border-radius: 6px;
    bottom: 5px;
    right: 5px;
    background: #fff;
    overflow: hidden;
    .progress {
      background: #a3a5ba;
      border-radius: 6px;
    }
  }

  .audio_thumbnail {
    width: 100%;
    height: 100%;
    background: #f5f7fb;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;

    .icons-holder {
      position: relative;
    }

    .img-audio {
      max-width: 60px;
      display: block;

      svg {
        width: 100%;
        height: auto;
        vertical-align: top;

        path {
          fill-opacity: 0.4;
        }
      }
    }

    .icon-play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }

    svg {
      max-width: 100%;
      height: auto;
    }
  }
`;
