import styled from 'styled-components';
interface IPostCaption {
  className?: string;
  postText?: string;
}

const PostCaption: React.FunctionComponent<IPostCaption> = ({
  className,
  postText,
}) => {
  return (
    <div className={className}>
      <p>
        <pre>{postText}</pre>
      </p>
    </div>
  );
};

export default styled(PostCaption)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #515365;
  padding: 0 0 10px;

  p {
    margin: 0 0 10px;
  }

  pre {
    font-family: 'Roboto', sans-serif;
    font-size: 100%;
    overflow: hidden;
    white-space: pre-wrap;
    margin: 0;
  }
`;
