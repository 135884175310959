import { SendMessage, Spinner } from 'assets/svgs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import InputEmoji from 'react-input-emoji';
import { setMessage } from 'store/reducer/chat';

interface Props {
  handleSendMessage(message: string): void;
}

const MessageInput: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const isSendingMessage = useAppSelector(
    (state) => state.chat.isSendingMessage,
  );

  const message = useAppSelector((state) => state.chat.message);
  const { handleSendMessage } = props;
  const handleSend = () => {
    if (message && !isSendingMessage) {
      handleSendMessage(message);
    }
  };
  return (
    <>
      <InputEmoji
        disabled={true}
        value={message}
        onChange={(value: string) => {
          dispatch(setMessage(value));
        }}
        keepOpenend={true}
        cleanOnEnter={false}
        onEnter={handleSend}
        placeholder={!!!message?.length ? 'Type a message' : ''}
      />
      {!isSendingMessage ? (
        <div onClick={handleSend} className="btn-send">
          <SendMessage />
        </div>
      ) : (
        <div className="send-spinner">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default MessageInput;
