import styled from 'styled-components';
interface IPublicCardImage {
  className?: string;
}

const PublicCardImage: React.FunctionComponent<IPublicCardImage> = ({
  className,
}) => {
  return (
    <div className={`${className} public-image-holder`}>
      <img
        src="https://images.unsplash.com/photo-1546587348-d12660c30c50?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bmF0dXJhbHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80"
        alt="..."
        className="public_post"
      />
    </div>
  );
};

export default styled(PublicCardImage)`
  &.public-image-holder {
    border-radius: 4px;
    overflow: hidden;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }
`;
