import { ArrowBack, CircleAvatar, IconChat, ImageThumbnail } from 'assets/svgs';
import AttachmentPreviewModal from 'components/AttachmentPreviewModal';
import Tabs from 'components/Tabs';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useAuth from 'hooks/useAuth';
import useSocket from 'hooks/useSocket';
import React, { ReactElement, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router';
import {
  getSubscriptionList,
  setSelectedChat,
  updateOnlineStatus,
} from 'store/reducer/chat';
import styled from 'styled-components';
import { ChatSubsType } from 'types/ChatSubscription';
import { getSortbyParam, parseQuery } from 'util/index';
import AvatarStatus from './components/AvatarStatus';
import ChatWidget from './components/ChatWidget';
import GalleryView from './components/GalleryView';
import RoomDetail from './components/RoomDetail';
import RoomListingBar from './components/RoomListingBar';

const TabPane = Tabs.TabPane;
interface Props {
  className?: string;
}

const media = {
  messageType: 'video',
  thumb:
    'https://analyticsindiamag.com/wp-content/uploads/2020/10/7d744a684fe03ebc7e8de545f97739dd.jpg',
  url: '',
};

const messages = [
  {
    messageValue:
      'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget tortor risus.',
    createdAt: '2021-05-01T12:45Z',
    name: 'Saiban Ali',
    orientation: 'left',
    image: '/assets/images/default-profile-img.svg',
    messageType: 'message',
  },
  {
    messageValue:
      'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget tortor risus.',
    createdAt: '2021-05-01T12:45Z',
    isRead: true,
    name: 'Saiban Ali',
    orientation: 'right',
    image: '/assets/images/default-profile-img.svg',
    messageType: 'message',
  },
  {
    createdAt: '2021-05-01T12:45Z',
    isRead: true,
    name: 'Saiban Ali',
    orientation: 'left',
    image: '/assets/images/default-profile-img.svg',
    messageType: 'image',
    url: 'https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bmV3JTIwemVhbGFuZCUyMHBlb3BsZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80',
  },
  {
    createdAt: '2021-05-01T12:45Z',
    isRead: true,
    name: 'Saiban Ali',
    orientation: 'right',
    image: '/assets/images/default-profile-img.svg',
    messageType: 'image',
    url: 'https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bmV3JTIwemVhbGFuZCUyMHBlb3BsZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80',
  },
  {
    messageValue:
      'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget tortor risus.',
    createdAt: '2021-05-01T12:45Z',
    name: 'Saiban Ali',
    orientation: 'left',
    image: '/assets/images/default-profile-img.svg',
    messageType: 'message',
  },
  {
    messageValue:
      'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget tortor risus.',
    createdAt: '2021-05-01T12:45Z',
    isRead: true,
    name: 'Saiban Ali',
    orientation: 'right',
    image: '/assets/images/default-profile-img.svg',
    messageType: 'message',
  },
  {
    createdAt: '2021-05-01T12:45Z',
    isRead: true,
    name: 'Saiban Ali',
    orientation: 'left',
    image: '/assets/images/default-profile-img.svg',
    messageType: 'image',
    url: 'https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bmV3JTIwemVhbGFuZCUyMHBlb3BsZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80',
  },
  {
    createdAt: '2021-05-01T12:45Z',
    isRead: true,
    name: 'Saiban Ali',
    orientation: 'right',
    image: '/assets/images/default-profile-img.svg',
    messageType: 'image',
    url: 'https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bmV3JTIwemVhbGFuZCUyMHBlb3BsZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80',
  },
];

export const setChatLayoutHeight = () => {
  setTimeout(() => {
    const el = document.getElementById('chat-layout');
    el?.setAttribute(
      'style',
      `
      height: calc(100vh - ${el.getBoundingClientRect().y}px)
    `,
    );
  }, 0);
};

const variants = {
  initial: (direction: string) => {
    return direction === 'left'
      ? {
          left: '-100vw',
          opacity: 0,
          position: 'absolute',
        }
      : {
          left: '100vw',
          opacity: 0,
          position: 'absolute',
        };
  },
  animate: {
    left: '0',
    opacity: 1,
    // position: 'relative',
  },
  exit: (direction: string) => {
    return direction === 'left'
      ? {
          left: '-100vw',
          opacity: 0,
          position: 'absolute',
        }
      : {
          left: '100vw',
          opacity: 0,
          position: 'absolute',
        };
  },
};

function Chat({ className }: Props): ReactElement {
  const [activeView, setActiveView] = useState<'left' | 'right'>('left');
  const { user } = useAuth();
  const sub = useAppSelector((state) => state.chat.selectedSubscription);
  const subs = useAppSelector((state) => state.chat.subscriptions);
  const isBuyer = user?._id !== sub?.sellerId._id ? true : false;
  const subUser = isBuyer ? sub?.sellerId : sub?.buyerId;
  const location = useLocation();
  const history = useHistory();
  const { subscription: subscriptionId } = parseQuery(location.search);
  const { socket } = useSocket();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const paramsList: any = {
      // skip,
      // orderId,
      // limit: PAGE_LIMIT,
      sort: getSortbyParam('updatedAt'),
      // filter: filterby,
      order: 'desc',
      // isActive: true,
    };
    dispatch(
      getSubscriptionList({
        defaultSubscription: subscriptionId as string,
        params: paramsList,
      }),
    );
    if (subscriptionId) {
      setActiveView('right');
    }
  }, []);
  useEffect(() => {
    if (subUser && subscriptionId && sub?._id !== subscriptionId) {
      setActiveView('right');
      const index = subs.items?.findIndex(
        (item) => item._id === subscriptionId,
      );
      if (index !== -1) {
        dispatch(setSelectedChat(subs.items[index]));
      }
    }
  }, [subscriptionId]);

  useEffect(() => {
    socket?.on('chat', (data) => {
      dispatch(updateOnlineStatus(data));
    });
    return () => {
      socket?.off('chat');
    };
  }, [socket]);
  useEffect(() => {
    setChatLayoutHeight();
  }, [user]);

  return (
    <div className={className}>
      <div id="chat-layout" className="three-cols">
        {!isMobileOnly ? (
          <>
            <div className="left-col">
              <RoomListingBar
                onChatClick={(subscription) =>
                  dispatch(setSelectedChat(subscription))
                }
              />
            </div>
            <div className="middle-block">
              <Tabs defaultActiveKey="1" type="card">
                <TabPane
                  tab={
                    <span>
                      <IconChat />
                      Chat
                    </span>
                  }
                  key="1"
                >
                  <ChatWidget className="chat-block" />
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      {' '}
                      <ImageThumbnail />
                      Media
                    </span>
                  }
                  key="2"
                >
                  <GalleryView />
                </TabPane>
                <TabPane
                  className="hidden-desktop"
                  tab={
                    <span>
                      {' '}
                      <CircleAvatar />
                      Subscriber Info
                    </span>
                  }
                  key="3"
                >
                  <RoomDetail className="user-detail" />
                </TabPane>
              </Tabs>
            </div>
            <div className="right-col">
              <RoomDetail />
            </div>
          </>
        ) : (
          <AnimatePresence initial={false}>
            {activeView === 'left' && (
              <motion.div
                key="left"
                custom={'left'}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={variants as any}
                style={{ width: '100%', position: 'relative' }}
                transition={{ mass: 0.2, duration: 0.6 }}
                className="left-col"
              >
                <RoomListingBar
                  onChatClick={(sub: ChatSubsType) => {
                    setActiveView('right');
                    dispatch(setSelectedChat(sub));
                  }}
                />
              </motion.div>
            )}
            {activeView === 'right' && (
              <motion.div
                key="right"
                custom={'right'}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={variants as any}
                style={{ width: '100%', position: 'relative' }}
                transition={{ mass: 0.2, duration: 0.6 }}
                className="middle-block"
              >
                <div className="back-bar">
                  <div
                    className="arrow-back"
                    onClick={() => {
                      dispatch(setSelectedChat(null));
                      history.push('/chat');
                      setActiveView('left');
                    }}
                  >
                    <ArrowBack />
                  </div>
                  <AvatarStatus src={subUser?.profileImage} />
                  {subUser && (
                    <div className="user-info">
                      <strong className="name">{`${subUser?.firstName} ${subUser?.lastName}`}</strong>
                      <span className="user-name">@{subUser?.username}</span>
                    </div>
                  )}
                </div>
                <Tabs defaultActiveKey="1" type="card">
                  <TabPane
                    tab={
                      <span>
                        <IconChat />
                        Chat
                      </span>
                    }
                    key="1"
                  >
                    <ChatWidget className="chat-block" />
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        <ImageThumbnail />
                        Media
                      </span>
                    }
                    key="2"
                  >
                    <GalleryView />
                  </TabPane>
                  <TabPane
                    className="hidden-desktop"
                    tab={
                      <span>
                        <CircleAvatar />
                        Subscriber Info
                      </span>
                    }
                    key="3"
                  >
                    <RoomDetail className="user-detail" />
                  </TabPane>
                </Tabs>
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </div>
      <AttachmentPreviewModal />
    </div>
  );
}

export default styled(Chat)`
  margin: -51px 0 0;

  @media (max-width: 767px) {
    margin: 0;
  }

  .three-cols {
    display: flex;
    max-height: -webkit-fill-available;
  }

  .left-col {
    width: 375px;
    border-right: 1px solid #e6ecf1;
    height: 100%;

    @media (max-width: 1199px) {
      width: 300px;
    }
  }

  .right-col {
    width: 320px;
    border-left: 1px solid #e6ecf1;
    /* padding: 20px; */
    background: #f6f7fb;

    @media (max-width: 1199px) {
      /* padding: 15px; */
      width: 280px;
    }

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .scroll-wrap {
    padding: 20px;

    @media (max-width: 1199px) {
      padding: 15px;
    }
  }

  .rc-tabs-tab {
    &:nth-child(3) {
      @media (min-width: 1024px) {
        display: none;
      }
    }

    @media (max-width: 767px) {
      min-width: inherit;
    }
  }

  .rc-tabs-content-holder {
    flex-grow: 1;
    flex-basis: 0;
    background: #fff;
  }

  .rc-tabs-content {
    height: 100%;
  }

  .rc-tabs-tabpane {
    height: 100%;
  }

  .middle-block {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    padding: 20px 0 0;
    background: #f9fafe;
    height: 100%;

    @media (max-width: 1023px) {
      padding: 12px 0 0;
    }

    .chat-block {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .user-detail {
      padding: 20px 20px 0;
      background: #fff;

      @media (max-width: 767px) {
        padding: 15px 10px 0;
      }
    }

    .widget-box {
      margin-bottom: 20px;
    }

    .left-col {
      border-right: none;
      position: relative;
    }
  }

  .back-bar {
    padding: 0 15px 12px 45px;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 25px;

    .arrow-back {
      width: 15px;
      position: absolute;
      left: 15px;
      top: calc(50% - 6px);
      transform: translate(0, -50%);
    }

    .user-info {
      padding: 0 0 0 10px;
      flex-grow: 1;
      flex-basis: 0;
    }

    .name {
      display: block;
      font-size: 16px;
      line-height: 20px;
      color: #495057;
      font-weight: 500;
    }

    .user-name {
      display: block;
      color: #778ca2;
      font-size: 14px;
      line-height: 17px;
      color: #778ca2;
    }
  }
`;
