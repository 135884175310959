import { H1 } from 'components/Typography';
import React from 'react';
import styled, { withTheme } from 'styled-components';

interface Props {
  className?: string;
}

export const PageTitle: React.FC<Props> = ({ className, children }) => {
  return <H1 className={className}>{children}</H1>;
};

export default withTheme(styled(PageTitle)`
  text-align: center;
  color: black;
  margin: 0 0 10px;
  line-height: 1;
  ${({ theme }: any) =>
    theme?.additional?.titleSize && `font-size:${theme.additional.titleSize};`}
  ${({ theme }: any) =>
    theme?.additional?.titleColor && `color:${theme.additional.titleColor};`}
`);
