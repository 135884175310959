import { orderCreate } from 'api/Order';
import { createOrderWithGuestUser } from 'api/Utils';
import { Advertise as AdvertiseIcon, SnapChat } from 'assets/svgs';
import NewButton from 'components/NButton';
import SPCard from 'components/SPCards';
import { SocialPlatforms } from 'enums';
import useAuth from 'hooks/useAuth';
import useDispatch from 'hooks/useDispatch';
import useRequestLoader from 'hooks/useRequestLoader';
import { IServiceProps } from 'interfaces/IPublicServices';
import { stringify } from 'querystring';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { RESET_PURCHASE_STATES, SET_GUEST_USER } from 'reducer/purchaseStates';
import styled from 'styled-components';
import { PriceVariant } from 'types';
import { addProtocol, arrayFilter, arrayFind } from 'util/index';
type variation = {
  _id: string;
  title: string;
  price: number;
  description: string;
  type: string;
  isActive: boolean;
  stats: {
    label: string;
    value: string;
  };
};

const StyledAdvertise = styled.div`
  .title-box {
    padding-right: 0 !important;
  }
  .heading-box {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 30px;

    h2 {
      margin: 0;
      font-size: 26px;
      padding: 0 0 0 15px;
    }

    .icon {
      width: 54px;
      min-width: 54px;

      svg {
        width: 100%;
        height: auto;
        vertical-align: top;

        g {
          &#Layer_1 {
            fill: #fff !important;
          }
        }
      }
    }
  }
  .text-description {
    color: #666666;
    font-size: 17px;
    line-height: 25px;
    font-weight: 400;
  }
  .header-bottom-bar {
    display: flex;
    // justify-content: space-between;
    width: 80%;
    font-size: 15px;
    line-height: 18px;

    strong {
      font-weight: 700;
    }
  }
  .header-bottom-bar div {
    min-width: 33.333%;
    padding-right: 10px;
  }
  .main--card--header .icon img,
  .main--card--header img,
  .main--card--header .icon svg {
    width: 100%;
    height: auto;
  }
  .main--card--header .caption {
    font-size: 16px;
  }
  .main--card--header .icon {
    width: 100px;
    height: 100px;
  }
  .image-holder {
    width: auto;
    margin-bottom: 5px;
    display: inline-block;
  }
  .pop-wigdet .header .icon {
    background-color: #fff !important;
  }
  .sub--card {
    background-color: #fff;
    border: 1px solid #dddede;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    transition: all 0.4s ease;

    &:hover {
      box-shadow: 0px 0px 4px #79b530;
    }

    .body {
      border-top: 1px solid #e5e5e5;
      border-bottom: none;
      padding: 0;
    }

    .title {
      font-weight: 500;
      text-transform: capitalize;
      margin: 0 0 2px;
    }

    .dashed {
      display: none;
    }

    .title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .priceTag {
      font-size: 18px;
      line-height: 21px;
      background: #79b530;
      border-radius: 4px;
      min-width: 65px;
      text-align: center;
      color: #fff;
      padding: 4px 5px;
    }

    .caption {
      color: #3f3f3f;
      margin: 0 0 5px;
    }

    .body {
      p {
        margin: 0;
      }
    }
  }
  .sub--card .header {
    padding: 15px 19px;
    background: #f6f7f9;
  }
  .sub--card .header .header-right {
    display: block;
  }
  .sub--card .icon {
    width: 80px;
    height: 80px;
    min-width: 80px;
    border: none;
  }

  .sub--card .body {
    font-weight: 400;
  }

  .sub--card .icon img {
    width: 100%;
    height: auto;
  }
  .twitter-btn {
    background-color: #53abed;
    border-color: #53abed;
  }
  .facebook-btn {
    background-color: #1777f1;
    border-color: #1777f1;
  }
  .youtube-btn {
    background-color: #ff0000;
    border-color: #ff0000;
  }
  .instagram-btn {
    background-color: #8139ab;
    border-color: #8139ab;
  }
  @media (max-width: 767px) {
    .header-bottom-bar {
      width: 100%;
    }
    .main--card--header .icon {
      width: 70px;
      height: 70px;
    }
    .image-holder {
      width: 120px;
      display: inline-block;
      margin-bottom: 1px;
    }

    .main--card--header .caption {
      font-size: 14px;
    }
    .main--card--header .header-bottom-bar {
      font-size: 14px;
    }
    .sub--card .header {
      padding: 10px 15px;
    }

    .sub--card {
      .icon {
        width: 50px;
        height: 50px;
        min-width: 50px;
        margin: 0 10px 0 0;
      }

      .title {
        font-size: 18px;
        margin: 0 0 6px;
      }

      .title-wrap {
        flex-wrap: wrap;
      }

      .priceTag {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
`;
const SnapChatComponent = styled.span`
  .icon {
    width: 100%;
    overflow: hidden;
    > span {
      width: 100%;
      svg {
        width: 100%;
      }
    }
  }
`;
const VariationSubtitle: React.FC<{ url: string }> = ({ url }) => {
  const updateUrl = addProtocol(url);

  return (
    <a
      href={updateUrl}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      {url}
    </a>
  );
};

const Advertise: React.FC<IServiceProps> = (props) => {
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const { username } = useParams<{ username: string }>();
  const { user: authUser, isAuthenticated, loggedIn } = useAuth();
  const dispatch = useDispatch();
  const { withLoader } = useRequestLoader();

  const {
    ownProfile,
    onStopPOPupOpen,
    pop: { title, description, priceVariations, popType, socialStats },
  } = props;
  const handleCreateOrder = async (variationId: string) => {
    if (ownProfile && onStopPOPupOpen) {
      onStopPOPupOpen();
      return;
    }
    const variation: any = arrayFind(priceVariations, { _id: variationId });
    const questions = arrayFilter(variation?.questions, {
      isActive: true,
    });
    setloading(true);
    dispatch({ type: RESET_PURCHASE_STATES });

    const requestData = {
      questions,
      popId: props.pop?._id,
      vpopId: variationId,
      buyer: loggedIn && authUser?._id ? authUser?._id : null,
    };

    try {
      const response = isAuthenticated
        ? await withLoader(orderCreate(requestData)).catch(console.log)
        : await withLoader(createOrderWithGuestUser(requestData)).catch(
            console.log,
          );

      if (!response) {
        return;
      }

      const { order, guestUser } = response;

      if (!isAuthenticated)
        dispatch({ type: SET_GUEST_USER, payload: guestUser });

      const prams = stringify({ order: order._id, type: popType });

      return history.push(
        `/${username}/purchase/add-a-card-and-checkout?${prams}`,
      );
    } catch (e) {}
    setloading(false);
  };

  const instagramComponent = ({
    _id,
    title,
    price,
    description,
    stats,
  }: PriceVariant) => {
    const { value } = stats || {};
    let url = '';
    // media = 0,
    // follows = 0,
    // followers = 0
    if (value) {
      const stat = socialStats?.find?.((p: any) => p._id === value);
      if (stat) {
        // media = stat.stats?.posts || 0;
        // follows = stat.stats?.following || 0;
        // followers = stat.stats?.followers || 0;
        url = stat.url;
      }
    }
    return (
      <div onClick={() => handleCreateOrder(_id!)}>
        <SPCard
          showCaption={false}
          bodyClass={!description ? 'd-none' : ''}
          icon={
            <span>
              <img src="/assets/images/Instagram.svg" alt="logo" />
            </span>
          }
          className="advertise-card"
          title={
            <div className="title-wrap">
              <div className="title-holder">{title}</div>
              <span className="priceTag">${Number(price).toFixed(2)}</span>
            </div>
          }
          subtitle={<VariationSubtitle url={url} />}
          showClose={false}
          classes={{ card: 'sub--card' }}
          showFooter={false}
          // extra={
          //   <div className="header-bottom-bar">
          //     {media != null && (
          //       <div>
          //         <strong>{media}</strong> posts
          //       </div>
          //     )}
          //     {followers != null && (
          //       <div>
          //         <strong>{followers}</strong> followers
          //       </div>
          //     )}
          //     {follows != null && (
          //       <div>
          //         <strong>{follows}</strong> following
          //       </div>
          //     )}
          //   </div>
          // }
        >
          <div className="px-20 bg-white p-15 py-md-15">
            <p className="text-justify">{description}</p>
          </div>
        </SPCard>
      </div>
    );
  };

  const twitterComponent = ({
    _id,
    title,
    price,
    description,
    stats,
  }: PriceVariant) => {
    const { value } = stats || {};
    // let follows = 0,
    //   followers = 0,
    let url = '';
    const stat = socialStats?.find?.((p: any) => p._id === value);

    if (stat) {
      // followers = stat.stats?.followers || 0;
      // follows = stat.stats?.following || 0;
      url = stat.url;
    }

    return (
      <div onClick={() => handleCreateOrder(_id!)}>
        <SPCard
          showCaption={false}
          bodyClass={!description ? 'd-none' : ''}
          icon={
            <span>
              <img src="/assets/images/twitter.svg" alt="logo" />
            </span>
          }
          title={
            <div className="title-wrap">
              <div className="title-holder">{title}</div>
              <span className="priceTag">${Number(price).toFixed(2)}</span>
            </div>
          }
          subtitle={<VariationSubtitle url={url} />}
          showClose={false}
          classes={{ card: 'sub--card' }}
          showFooter={false}
          // extra={
          //   <div className="header-bottom-bar">
          //     {followers != null && (
          //       <div>
          //         <strong>{followers}</strong> followers
          //       </div>
          //     )}
          //     {follows != null && (
          //       <div>
          //         <strong>{follows}</strong> following
          //       </div>
          //     )}
          //   </div>
          // }
        >
          <div className="px-20 bg-white p-15 py-md-15">
            <p className="text-justify">{description}</p>
          </div>
        </SPCard>
      </div>
    );
  };

  const facebookComponent = ({
    _id,
    title,
    description,
    price,
    stats,
  }: PriceVariant) => {
    const { value } = stats || {};
    // let likes = 0,
    //   follows = 0,
    //   members = 0,
    let url = '';
    const stat = socialStats?.find?.((p: any) => p._id === value);
    if (stat) {
      url = stat?.url;
      // if (stat?.url?.includes('facebook.com/groups')) {
      //   members = stat.stats?.members || 0;
      // } else {
      //   likes = stat.stats?.likes || 0;
      //   follows = stat.stats?.followers || 0;
      // }
    }
    return (
      <div onClick={() => handleCreateOrder(_id!)}>
        <SPCard
          showCaption={false}
          bodyClass={!description ? 'd-none' : ''}
          key={_id}
          subtitle={<VariationSubtitle url={url} />}
          icon={
            <span>
              <img src="/assets/images/facebook.svg" alt="logo" />
            </span>
          }
          title={
            <div className="title-wrap">
              <div className="title-holder">{title}</div>
              <span className="priceTag">${Number(price).toFixed(2)}</span>
            </div>
          }
          headerStyle={{}}
          showClose={false}
          titleStyle={{}}
          classes={{ card: 'sub--card' }}
          showFooter={false}
          // extra={
          //   <div className="header-bottom-bar">
          //     {!stat?.url?.includes('facebook.com/groups') ? (
          //       <>
          //         {likes != null && (
          //           <div>
          //             <strong>{likes}</strong> likes
          //           </div>
          //         )}
          //         {follows != null && (
          //           <div>
          //             <strong>{follows}</strong> followers
          //           </div>
          //         )}
          //       </>
          //     ) : (
          //       <>
          //         {members != null && (
          //           <div>
          //             <strong>{members}</strong> members
          //           </div>
          //         )}
          //       </>
          //     )}
          //   </div>
          // }
        >
          <div className="px-20 bg-white p-15 py-md-15">
            <p className="text-justify">{description}</p>
          </div>
        </SPCard>
      </div>
    );
  };

  const youtubeComponent = ({
    _id,
    price,
    title,
    description,
    stats,
  }: PriceVariant) => {
    const { value } = stats || {};
    // let subscribers = 0,
    let url = '';
    if (value) {
      const stat = socialStats?.find?.((p: any) => p._id === value);
      if (stat) {
        // subscribers = stat.stats?.subscribers || 0;
        url = stat.url;
      }
    }
    return (
      <div onClick={() => handleCreateOrder(_id!)}>
        <SPCard
          showCaption={false}
          bodyClass={!description ? 'd-none' : ''}
          subtitle={<VariationSubtitle url={url} />}
          icon={
            <span>
              <img src="/assets/images/youtube.svg" alt="logo" />
            </span>
          }
          title={
            <div className="title-wrap">
              <div className="title-holder">{title}</div>
              <span className="priceTag">${Number(price).toFixed(2)}</span>
            </div>
          }
          showClose={false}
          classes={{ card: 'sub--card' }}
          showFooter={false}
          // extra={
          //   <div className="header-bottom-bar">
          //     {subscribers != null && (
          //       <div>
          //         <strong>{subscribers || 0}</strong> subscribers
          //       </div>
          //     )}
          //   </div>
          // }
        >
          <div className="px-20 bg-white p-15 py-md-15">
            <p className="text-justify">{description}</p>
          </div>
        </SPCard>
      </div>
    );
  };

  const tiktokComponent = ({
    _id,
    price,
    title,
    description,
    stats,
  }: PriceVariant) => {
    const { value } = stats || {};
    // let likes = 0,
    //   followers = 0,
    //   following = 0,
    let url = '';
    if (value) {
      const stat = socialStats?.find?.((p: any) => p._id === value);
      if (stat) {
        // followers = stat.stats?.followers || 0;
        // following = stat.stats?.following || 0;
        // likes = stat.stats?.likes || 0;
        url = stat.url;
      }
    }
    return (
      <div onClick={() => handleCreateOrder(_id!)}>
        <SPCard
          showCaption={false}
          bodyClass={!description ? 'd-none' : ''}
          subtitle={<VariationSubtitle url={url} />}
          icon={
            <span style={{ width: '100%' }}>
              <img src="/assets/images/tictoc-logo.png" alt="logo" />
            </span>
          }
          title={
            <div className="title-wrap">
              <div className="title-holder">{title}</div>
              <span className="priceTag">${Number(price).toFixed(2)}</span>
            </div>
          }
          showClose={false}
          classes={{ card: 'sub--card' }}
          showFooter={false}
          // extra={
          //   <div className="header-bottom-bar">
          //     {followers != null && (
          //       <div>
          //         <strong>{followers || 0}</strong> followers
          //       </div>
          //     )}
          //     {following != null && (
          //       <div>
          //         <strong>{following || 0}</strong> following
          //       </div>
          //     )}
          //     {likes != null && (
          //       <div>
          //         <strong>{likes || 0}</strong> likes
          //       </div>
          //     )}
          //   </div>
          // }
        >
          <div className="px-20 bg-white p-15 py-md-15">
            <p className="text-justify">{description}</p>
          </div>
        </SPCard>
      </div>
    );
  };

  const onlyfansComponent = ({
    _id,
    price,
    title,
    description,
    stats,
  }: PriceVariant) => {
    const { value } = stats || {};
    // let likes = 0,
    let url = '';
    if (value) {
      const stat = socialStats?.find?.((p: any) => p._id === value);
      if (stat) {
        // likes = stat.stats?.likes || 0;
        url = stat.url;
      }
    }
    return (
      <div onClick={() => handleCreateOrder(_id!)}>
        <SPCard
          showCaption={false}
          bodyClass={!description ? 'd-none' : ''}
          subtitle={<VariationSubtitle url={url} />}
          icon={
            <span>
              <img src="/assets/images/onlyfans.svg" alt="logo" />
            </span>
          }
          title={
            <div className="title-wrap">
              <div className="title-holder">{title}</div>
              <span className="priceTag">${Number(price).toFixed(2)}</span>
            </div>
          }
          headerStyle={{}}
          showClose={false}
          titleStyle={{}}
          classes={{ card: 'sub--card' }}
          showFooter={false}
          // extra={
          //   <div className="header-bottom-bar">
          //     {likes != null && (
          //       <div>
          //         <strong>{likes || 0}</strong> likes
          //       </div>
          //     )}
          //   </div>
          // }
        >
          <div className="px-20 bg-white p-15 py-md-15">
            <p className="text-justify">{description}</p>
          </div>
        </SPCard>
      </div>
    );
  };
  const snapChatComponent = ({
    _id,
    price,
    title,
    description,
    stats,
  }: PriceVariant) => {
    const { value } = stats || {};
    // let likes = 0,
    let url = '';
    if (value) {
      const stat = socialStats?.find?.((p: any) => p._id === value);
      if (stat) {
        // likes = stat.stats?.likes || 0;
        url = stat.url;
      }
    }
    return (
      <SnapChatComponent onClick={() => handleCreateOrder(_id!)}>
        <SPCard
          showCaption={false}
          bodyClass={!description ? 'd-none' : ''}
          subtitle={<VariationSubtitle url={url} />}
          icon={
            <span>
              <SnapChat />
            </span>
          }
          title={
            <div className="title-wrap">
              <div className="title-holder">{title}</div>
              <span className="priceTag">${Number(price).toFixed(2)}</span>
            </div>
          }
          headerStyle={{}}
          showClose={false}
          titleStyle={{}}
          classes={{ card: 'sub--card' }}
          showFooter={false}
          // extra={
          //   <div className="header-bottom-bar">
          //     {likes != null && (
          //       <div>
          //         <strong>{likes || 0}</strong> likes
          //       </div>
          //     )}
          //   </div>
          // }
        >
          <div className="px-20 bg-white p-15 py-md-15">
            <p className="text-justify">{description}</p>
          </div>
        </SPCard>
      </SnapChatComponent>
    );
  };

  const activeVariations = priceVariations?.filter(
    (varr: PriceVariant) => varr.isActive,
  );
  return (
    <StyledAdvertise className="mb-80">
      <div className="mb-30 mb-md-50">
        <div className="text-center title-box">
          <div className="heading-box">
            <span className="icon">
              <AdvertiseIcon />
            </span>
            <h2 className="text-capitalize">{title}</h2>
          </div>
          {/* {additionalArt.length > 0 && <Slider additionalArt={additionalArt} />} */}

          <div className="text-justify font-weight-normal text-description">
            {description}
          </div>
        </div>
      </div>
      {!!activeVariations?.length ? (
        activeVariations.map((variation: PriceVariant) => {
          switch (variation.type) {
            case SocialPlatforms.YOUTUBE:
              return (
                <div key={variation._id}>{youtubeComponent(variation)}</div>
              );
            case SocialPlatforms.INSTAGRAM:
              return (
                <div key={variation._id}>{instagramComponent(variation)}</div>
              );
            case SocialPlatforms.TWITTER:
              return (
                <div key={variation._id}>{twitterComponent(variation)}</div>
              );
            case SocialPlatforms.ONLYFANS:
              return (
                <div key={variation._id}>{onlyfansComponent(variation)}</div>
              );
            case SocialPlatforms.TIKTOK:
              return (
                <div key={variation._id}>{tiktokComponent(variation)}</div>
              );
            case SocialPlatforms.FACEBOOK:
              return (
                <div key={variation._id}>{facebookComponent(variation)}</div>
              );
            case SocialPlatforms.SNAPCHAT:
              return (
                <div key={variation._id}>{snapChatComponent(variation)}</div>
              );
            default:
              return null;
          }
        })
      ) : (
        <div style={{ maxWidth: '402px', margin: '0 auto' }}>
          <NewButton
            block
            type="primary"
            shape="circle"
            className="btn-disabled"
          >
            This Pop currently has no data.
          </NewButton>
        </div>
      )}
    </StyledAdvertise>
  );
};

export default Advertise;
