import Slider from 'components/slider';
import styled from 'styled-components';
interface ICouserVideoPlay {
  className?: string;
  additionalArt?: [];
}

const CouserVideoPlay: React.FunctionComponent<ICouserVideoPlay> = ({
  className,
  additionalArt,
}) => {
  return (
    <div className={`${className}  pop-card mb-15 mt-n20`}>
      <Slider additionalArt={additionalArt || []} />
    </div>
  );
};

export default styled(CouserVideoPlay)``;
