// import { ExpiratinIcon, GalleryPlus, PublishArrow } from 'assets/svgs';
import { GalleryPlus, PostTemplateIcon, PublishArrow } from 'assets/svgs';
import attrAccept from 'attr-accept';
import Button from 'components/NButton';
import ToolTip from 'components/tooltip';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useOpenClose from 'hooks/useOpenClose';
import FileUploader from 'pages/Sales/components/FileUploader';
import ExpirationModal from 'pages/Sales/components/Model/expirationModal';
import React from 'react';
import { onRemoveGroup } from 'store/reducer/files';
import {
  removeFromPendingMessage,
  removeSelectedPost,
  toggleEditPost,
} from 'store/reducer/salesState';
// import { onToggleModal } from 'store/reducer/statisticsModelState';
import styled from 'styled-components';
import { MediaType } from 'types/ChatSubscription';
import { getAudioFileDuration, getVideoCover } from 'util/index';
import { v4 as uuid } from 'uuid';
import PostTemplateModalWrapper from '../PostTemplateWrapper';

interface Props {
  className?: string;
  isPaid?: boolean;
  hasTemplate?: boolean;
  changeSubmitLabel?: string;
  form?: any;
  actions?: boolean;
  setShowPoll?: Function;

  onPublish: () => void;
  onFileChanges?: (files: any[], type: string) => void;
  files?: any[];
  messageKey?: string;
  managedAccountId?: string;
}

const Footer: React.FC<Props> = ({
  className,
  isPaid,
  onPublish,
  changeSubmitLabel = undefined,
  form,
  setShowPoll,
  onFileChanges,
  files = [],
  messageKey = '',
  actions = true,
  hasTemplate = true,
  managedAccountId,
}) => {
  const [isOpen, onOpen, onClose] = useOpenClose();
  const dispatch = useAppDispatch();
  const uploadingGroup = useAppSelector((state) => state.fileGroups);
  const showEditPost = useAppSelector((state) => state.mysales.showEditPost);
  const {
    isSubmitting,
    values,

    resetForm,
    setFieldValue,
  } = form || {};
  const OnFileChanges = async (fls: any[], type: string) => {
    const newFiles: MediaType[] = [];
    for (let index = 0; index < fls.length; index++) {
      const file = fls[index];
      let url = undefined;
      let thumb = undefined;
      let duratonInSeconds = 0;
      let duration = undefined;
      if (attrAccept(file, 'video/*')) {
        await getVideoCover(file)
          .then((payload: any) => {
            duration = payload.timeDuration;
            duratonInSeconds = payload.duration;
            url = payload.src;
            thumb = URL.createObjectURL(payload.blob);
            if (payload.width && payload.height) {
              Object.defineProperty(file, 'width', {
                value: payload.width,
                writable: true,
              });
              Object.defineProperty(file, 'height', {
                value: payload.height,
                writable: true,
              });
            }
          })
          .catch((e) => {
            console.log(e, 'error');
          });
      } else if (attrAccept(file, 'image/*')) {
        url = URL.createObjectURL(file);
      } else if (attrAccept(file, 'audio/*')) {
        await getAudioFileDuration(file)
          .then((payload: any) => {
            duration = payload.timeDuration;
            duratonInSeconds = payload.duration;
            url = payload.src;
          })
          .catch((e) => {
            console.log(e, 'error');
          });
      }
      newFiles.push({
        ...file,
        name: file.name,
        type: file.type,
        id: uuid(),
        size: file.size,
        orignalFile: file,
        path: url,
        thumbnail: thumb,
        videoDuration: duration,
        duration: duratonInSeconds,
        isPaidType: type === 'paid',
        islocK: type === 'paid',
        updatedAt: file.updatedAt || new Date().getTime() + '',
      });
    }
    const totalFiles = files
      .map((f) => ({
        ...f,
        isPaidType: type === 'paid',
        islocK: type === 'paid',
      }))
      .concat(newFiles);
    onFileChanges?.(totalFiles, type);
  };

  return (
    <div className={`btns-actions-area ${className}`}>
      <PostTemplateModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        managedAccountId={managedAccountId}
      />
      <div className="icons-actions">
        <div className="icon-holder">
          <FileUploader
            customRequest={(files) => OnFileChanges(files, 'standard')}
            accept={['image/*', 'video/*', 'audio/*']}
          >
            <ToolTip overlay={'Add media'}>
              <GalleryPlus />
            </ToolTip>
          </FileUploader>
        </div>
        {hasTemplate && (
          <div onClick={onOpen} className="icon-holder">
            <ToolTip overlay={'Template'}>
              <PostTemplateIcon />
            </ToolTip>
          </div>
        )}

        {/* <div className="icon-holder">
          <ToolTip overlay={'Set Post Expiry'}>
            <div
              className="link-expiration"
              onClick={() =>
                dispatch(
                  onToggleModal({ isOpen: false, post: {}, isModalOpen: true }),
                )
              }
            >
              <ExpiratinIcon />
            </div>
          </ToolTip>
        </div> */}
        {/* {!!user.links?.find(
          (link: any) => link.popLinksId?.popType === 'chat-subscription',
        ) && (
          <div className="icon-holder">
            <FileUploader
              customRequest={(files) => {
                OnFileChanges(files, 'paid');
              }}
              accept={['image/*', 'video/*', 'audio/*']}
            >
              <ToolTip overlay={'Add paid media'}>
                <DallorBorderd />
              </ToolTip>
            </FileUploader>
          </div>
        )} */}
      </div>
      {actions && (
        <div className="right-area">
          <Button
            // isLoading={isSubmitting}
            // disabled={isSubmitting}
            onClick={() => {
              dispatch(removeSelectedPost());
              if (messageKey && isSubmitting) {
                const group = uploadingGroup[messageKey];
                if (group?.files?.length) {
                  group.files.forEach((f) => {
                    f.requestToken.cancel('file uploading cancelled');
                  });
                }
                dispatch(
                  removeFromPendingMessage({
                    groupId: messageKey,
                  }),
                );
                dispatch(
                  onRemoveGroup({
                    groupId: messageKey,
                  }),
                );
              }
              resetForm();
              onFileChanges?.([], 'standard');
              showEditPost && dispatch(toggleEditPost());
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            isLoading={isSubmitting}
            disabled={isSubmitting}
            onClick={onPublish}
          >
            <span className="btn-text mr-5">
              {changeSubmitLabel ?? 'Publish'}
            </span>{' '}
            <PublishArrow />
          </Button>
        </div>
      )}
      <ExpirationModal
        expireTime={values?.expireAt}
        expireDays={values?.expireDays}
        onSave={(time, dayjs) => {
          setFieldValue('expireAt', time);
          setFieldValue('expireDays', dayjs);
        }}
      />
    </div>
  );
};

export default styled(Footer)`
  padding: 15px 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 -20px;
  border-top: 1px solid #e6ecf5;

  @media (max-width: 479px) {
    margin: 0 -15px;
    padding: 15px 12px 0;
  }

  .icons-actions {
    display: flex;
    align-items: center;
    color: #a3a5ba;

    .icon-holder {
      transition: all 0.4s ease;
      cursor: pointer;
      margin: 0 20px 0 0;

      @media (max-width: 479px) {
        margin: 0 10px 0 0;
      }

      &:hover {
        color: #083e67;
      }
    }

    .link-expiration {
      width: 24px;
      color: #a3b9cc;
      transition: all 0.4s ease;

      &:hover {
        color: #083e67;
      }

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  .right-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .price-detail {
      margin: 0 15px 0 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #a3a5ba;

      @media (max-width: 479px) {
        margin: 0 10px 0 0;
      }

      .img-close {
        display: inline-block;
        vertical-align: top;
        margin: 0 10px 0 0;
        width: 20px;

        @media (max-width: 479px) {
          margin: 0 5px 0 0;
        }

        svg {
          width: 100%;
          height: auto;
          vertical-align: top;
        }
      }

      .amount {
        color: #252631;
      }
    }

    .button {
      min-width: 113px;

      svg {
        margin-right: 0;
      }
    }
  }
`;
