import { getChatTemplates } from 'api/PostTemplate';
import { PostTemplateIcon, Spinner } from 'assets/svgs';
import Modal from 'components/modal';
import Button from 'components/NButton';
import Scrollbar from 'components/Scrollbar';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import AttachmentContainer from 'pages/chat/components/AttachmentContainer';
import React, { useCallback, useEffect, useState } from 'react';
import { positionValues } from 'react-custom-scrollbars-2';
import { setTemplateMessage } from 'store/reducer/chat';
import styled from 'styled-components';

interface IChatTemplateModal {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  managedAccountId?: string;
}

const ChatTemplateModal: React.FC<IChatTemplateModal> = ({
  className,
  isOpen = false,
  onClose,
  managedAccountId,
}) => {
  const selectedMessage = useAppSelector((state) => state.chat.templateMessage);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [useTemplate, setUseTemplate] = useState<any>(null);
  const [MessageTemplate, seMessageTemplate] = useState<any>({
    items: [],
    totalCount: 0,
  });
  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      getChatTemplates({
        limit: 50,
        sellerId: managedAccountId,
        sort: 'createdAt',
      })
        .then((res) => {
          seMessageTemplate({
            items: res?.items.map((val: any) => {
              if (
                !!val.messageMedia.length &&
                selectedMessage?._id === val?._id
              ) {
                val.messageMedia[0].checked = true;
              }
              return val;
            }),
            totalCount: res?.totalCount,
          });
        })
        .catch(console.log)
        .finally(() => setIsLoading(false));
      return () => {
        seMessageTemplate({
          items: [],
          totalCount: 0,
        });
      };
    }
  }, [isOpen]);
  useEffect(() => {
    if (useTemplate) {
      seMessageTemplate((val: any) => {
        return {
          items: val.items.map((item: any) => {
            if (useTemplate?._id !== item?._id && !!item?.messageMedia.length) {
              item.messageMedia[0].checked = false;
            }
            return item;
          }),
          totalCount: val?.totalCount,
        };
      });
    }
  }, [useTemplate]);

  const loadMore = useCallback(() => {
    if (MessageTemplate?.items?.length < MessageTemplate?.totalCount) {
      setIsLoading(true);
      getChatTemplates({
        skip: MessageTemplate?.items?.length,
        sellerId: managedAccountId,
      })
        .then((res) => {
          seMessageTemplate((val: any) => {
            return {
              items: [
                ...val.items,
                ...res?.items.map((val: any) => {
                  if (
                    !!val.messageMedia.length &&
                    val?.messageMedia?.[0] &&
                    selectedMessage?._id === val?._id
                  ) {
                    val.messageMedia[0].checked = true;
                  }
                  return val;
                }),
              ],
              totalCount: res?.totalCount,
            };
          });
        })
        .catch(console.log)
        .finally(() => setIsLoading(false));
    }
  }, [MessageTemplate?.items?.length, selectedMessage?._id]);

  const handleUpdate = useCallback(
    (values: positionValues) => {
      if (isLoading || !MessageTemplate?.items?.length) return;
      const { scrollTop, scrollHeight, clientHeight } = values;
      const pad = 300; // 50px of the bottom
      // t will be greater than 1 if we are about to reach the bottom
      const t = (scrollTop + pad) / (scrollHeight - clientHeight);
      if (t > 1) loadMore();
    },
    [isLoading, MessageTemplate?.items.length, loadMore],
  );
  return (
    <Modal
      onOk={() => {
        if (useTemplate) {
          dispatch(setTemplateMessage({ ...(useTemplate as any) }));
          setUseTemplate(null);
        }
        onClose();
      }}
      showHeader={true}
      showFooter={true}
      sumbitTitle="USE THIS MESSAGE"
      title={
        <React.Fragment>
          <PostTemplateIcon />
          MESSAGE TEMPLATES
        </React.Fragment>
      }
      isOpen={isOpen}
      onClose={onClose}
      className={`${className} modal-templates`}
    >
      <Scrollbar
        autoHeight={true}
        autoHeightMax={'calc(100vh - 170px)'}
        style={{ overflow: 'hidden' }}
        onUpdate={handleUpdate}
      >
        <div className="gallery-holder">
          <div className="lg-react-element">
            {MessageTemplate?.items?.map((item: any, idx: number) => (
              <AttachmentContainer
                checked={
                  item?._id ===
                  (useTemplate?._id || selectedMessage?._id || false)
                }
                key={item._id}
                onClick={(media) => {
                  if (item?._id === useTemplate?._id) {
                    setUseTemplate(null);
                    return;
                  }
                  setUseTemplate({ ...item, template_id: item?._id });
                }}
                createdAt={item.createdAt}
                media={item?.messageMedia?.[0]}
                showOptions={{
                  timeStampText: true,
                  video: true,
                  play: true,
                  edit: false,
                  closeIcon: false,
                  selectable: true,
                  showprogress: false,
                  mutipleSelect: false,
                }}
              />
            ))}
          </div>
          {isLoading && (
            <LoaderWrapper>
              <Spinner width="28px" height="28px" color="#1da1f3" />
            </LoaderWrapper>
          )}
          {!MessageTemplate?.items?.length && !isLoading && (
            <Button disabled block shape="circle">
              You don't have any template.
            </Button>
          )}
        </div>
      </Scrollbar>
    </Modal>
  );
};

export default styled(ChatTemplateModal)`
  &.modal-dialog {
    max-width: 697px;

    .modal-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;

      svg {
        display: inline-block;
        vertical-align: bottom;
        margin: 0 10px 0 0;
      }

      path {
        fill: #000;
      }
    }

    .modal-body {
      padding: 3px 1px;

      .button-default {
        margin: 10px 5px !important;
        width: calc(100% - 10px);
      }
    }

    .messageMedia {
      padding: 0;
    }

    .user-detail {
      padding: 0;
    }

    .lg-react-element {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .gallery-item,
      .img-container {
        width: calc(20% - 2px);
        margin: 0 1px 2px;
        padding-top: calc(20% - 2px);

        @media (max-width: 767px) {
          width: calc(33.333% - 2px);
          padding-top: calc(33.333% - 2px);
        }

        @media (max-width: 479px) {
          width: calc(50% - 2px);
          padding-top: calc(50% - 2px);
        }

        .image-comp {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .timestamp {
      position: absolute;
      left: 10px;
      top: 10px;
      min-width: 50px;
      text-align: center;
      background: rgba(0, 0, 0, 0.3);
      font-size: 13px;
      line-height: 15px;
      color: rgba(255, 255, 255, 1);
      font-weight: 500;
      border-radius: 4px;
      padding: 4px 5px;
      z-index: 2;
    }

    .video-length {
      position: absolute;
      left: 10px;
      bottom: 10px;
      min-width: 50px;
      text-align: center;
      background: rgba(0, 0, 0, 0.3);
      font-size: 13px;
      line-height: 15px;
      color: rgba(255, 255, 255, 1);
      font-weight: 500;
      border-radius: 4px;
      padding: 4px 5px;
      z-index: 2;

      svg {
        width: 18px;
        height: 16px;
      }
    }

    .checkbox {
      pointer-events: none;
      position: absolute;
      right: 9px;
      top: 9px;
      width: 26px;
      height: 26px;
      z-index: 2;

      label {
        padding: 0;
      }

      input[type='checkbox']:checked + .custom-input-holder .custom-input {
        background: #255b87;
        border-color: #255b87;
      }

      .custom-input {
        margin: 0;
        width: 24px;
        height: 24px;
        border: 2px solid rgba(255, 255, 255, 0.8);
        border-radius: 100%;
        background: none;

        &:after {
          display: none;
        }

        &:before {
          color: #fff !important;
          font-size: 9px;
        }
      }
    }
  }
`;
const LoaderWrapper = styled.div`
  z-index: 9;
  display: flex;
  padding-bottom: 1rem;
  padding-top: 1rem;
  justify-content: center;

  -moz-user-select: none;
  -webkit-user-select: none;
`;
