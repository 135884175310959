// import 'cropperjs/dist/cropper.css';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import Button from '../button';
import Input from '../input';

Modal.setAppElement('#root');
const ResetPasswordLink = styled.div`
  text-align: center;
  padding: 0 0 1rem 0;
  strong {
    display: block;
    margin-bottom: 10px;
  }
  strong,
  a {
    color: white;
  }
  a {
    text-decoration: underline;
  }
`;
const PopupArea = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.81);
  overflow: auto;

  .popup-area__wrap {
    display: table;
    width: 100%;
    height: 100%;
  }

  .popup-area__frame {
    display: table-cell;
    vertical-align: middle;
    padding: 0 20px;
  }

  .share-popup.popup-box {
    position: relative;
    border: none;
  }

  .share-popup.popup-box:before {
    position: absolute;
    left: -4px;
    top: -4px;
    right: -4px;
    bottom: -4px;
    background: rgba(0, 0, 0, 0.14);
    content: '';
    z-index: -1;
    border-radius: 20px;
  }

  .popup-box__detail-area {
    padding: 30px 30px 10px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    position: relative;
    z-index: 1;
  }

  .popup-box__h2 {
    font-size: 40px;
    line-height: 48px;
    font-weight: 500;
    padding: 0 0 25px;
    margin: 0 0 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }

  .popup-box__logo {
    padding: 0 0 15px;
    margin-bottom: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }

  .popup-box__subtitle {
    font-size: 29px;
    line-height: 42px;
    font-weight: 400;
    padding: 5px 0 5px;
    margin: 0 0 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }

  .alert-message {
    color: #fff;
    padding: 0 30px 10px;
  }

  .error-msg.is-invalid {
    color: #fff !important;
  }

  .popup-box__h2__name {
    display: block;
  }

  .popup-box__p {
    margin: 0 0 20px;
  }

  .popup-box__link-wrap {
    border-top: 2px dashed rgba(255, 255, 255, 0.4);
    border-bottom: 2px dashed rgba(255, 255, 255, 0.4);
  }

  .popup-box .form-block label {
    color: #fff;
  }

  .popup-box .form-control {
    padding: 15px;
    width: 100%;
    margin: 0 0 5px;
  }

  .popup-box .btn {
    min-width: 160px;
  }

  .popup-box .btn.btn-primary {
    width: auto;
    min-width: 160px;
    display: inline-block;
    vertical-align: top;
  }

  .popup-box .btn .img-circle {
    width: 20px;
    height: 20px;
    background: #fff;
    display: inline-block;
    vertical-align: middle;
    color: #e51075;
    border-radius: 100%;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin: -2px 0 0 15px;
  }

  .popup-box .btn .img-circle .icon {
    margin: 1px 0 0;
  }

  .popup-box .live-link-bar {
    border-radius: 5px;
    padding: 5px 5px 5px 18px;
  }

  .popup-box .live-link-bar .btn {
    min-width: 90px;
    background: #000;
    border-color: #000;
  }

  .popup-box .live-link-bar .btn:hover {
    background: #e61c7c;
    border-color: #e61c7c;
  }

  .popup-box .live-link-bar .btn .icon-url {
    background: none;
  }

  .btn-black {
    background: #000;
    color: #fff;
  }

  .btn-black:hover {
    color: #fff !important;
  }

  &.signup .btn-black {
    width: 100%;
  }

  .confetti {
    position: relative;
  }

  .confetti img {
    position: absolute;
    width: 97%;
    top: 4px;
    left: 6px;
  }
`;

const validationSchema = yup.object().shape({
  password: yup.string().required('Enter your password'),
});
const SignUpPopUP: React.FC<any> = ({
  isOpen,
  onClose,
  onSuccessCallback,
  email,
}: any) => {
  const { Login } = useAuth();
  const [serverError, setserverError] = useState<string | null>(null);

  const {
    values,
    handleChange,
    handleBlur,
    isSubmitting,
    resetForm,
    handleSubmit,
    errors,
    touched,
    // tslint:disable-next-line: react-hooks-nesting
  } = useFormik({
    validationSchema,
    initialValues: {
      password: '',
    },
    // validateOnBlur:true,
    onSubmit: async (formData: any) => {
      await Login({ email, password: formData.password })
        .then((user: any) => {
          onSuccessCallback && onSuccessCallback(user.data);
        })
        .catch((e: Error) => {
          setserverError(e.message);
        });
    },
  });
  const handleClose = () => {
    onClose && onClose();
    resetForm();
  };
  return (
    <Modal
      isOpen={isOpen}
      // onAfterOpen={afterOpenModal}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleClose}
      className="modal"
      overlayClassName="Overlay"
    >
      <div className="modal-dialog">
        <div className="modal-content" style={{ border: 'hidden' }}>
          <PopupArea className="popup-area signup">
            <div className="popup-area__wrap">
              <div className="popup-area__frame">
                <div className="share-popup popup-box mb-30 mb-md-50">
                  <span className="btn-close" onClick={handleClose}>
                    <span className="icon-clearclose"></span>
                  </span>
                  <div className="popup-box__detail-area">
                    <figure className="popup-box__logo">
                      <img src="/assets/images/logo-w.png" width="100%" />
                    </figure>
                    <h3 className="popup-box__subtitle">
                      This email already exist. Please enter your password and
                      click continue
                    </h3>
                  </div>
                  {serverError && (
                    <div className="alert alert-message">{serverError}</div>
                  )}
                  <form className="form-block" onSubmit={handleSubmit}>
                    <div className="input mb-30">
                      <Input
                        id="passowrd"
                        name="password"
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        error={errors.password}
                        touched={touched.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                    </div>

                    <div className="text-center mt-30">
                      <Button
                        type="submit"
                        className="btn btn-black"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                      >
                        Sign In
                      </Button>
                    </div>
                  </form>
                  <ResetPasswordLink>
                    <strong>Forget your password?</strong>
                    <Link
                      target="_blank"
                      to={`/recover-password`}
                      className="link"
                    >
                      Reset Password
                    </Link>
                  </ResetPasswordLink>
                </div>
              </div>
            </div>
          </PopupArea>
        </div>
      </div>
    </Modal>
  );
};

export default SignUpPopUP;
