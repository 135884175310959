// import { StarFill } from 'assets/svgs';
import { editPost, updateImagesPhysically } from 'api/sales';
import { FireIcon } from 'assets/svgs';
import Model from 'components/modal';
import { toast } from 'components/toaster';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import Createpost from 'pages/Sales/Createpost';
import { ReactElement } from 'react';
import {
  removeSelectedPost,
  toggleEditPost,
  updateEditSchedulePost,
} from 'store/reducer/salesState';
import styled from 'styled-components';
import { processFilesForRotation } from 'util/index';
import { ImagesScreenSizes } from '../../../constants';
type Props = {
  onClose?: (...args: any) => void;
  isOpen: boolean;
  className?: string;
  managedAccountId?: string;
};

function EditPostModal({
  onClose,
  isOpen,
  className,
  managedAccountId,
}: Props): ReactElement {
  const selectedPost = useAppSelector(
    (state) => state.mysales.selectedScheduledPost,
  );
  const pendingEditMessages = useAppSelector(
    (state) => state.mysales.pendingEditMessages,
  );
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(toggleEditPost());
    dispatch(removeSelectedPost());
    onClose && onClose();
  };
  const onSubmit = async (values: any, form: any) => {
    if (!values?.media?.length && values.text === '') {
      toast.error('Please select aleast one media or enter text');
      return;
    }
    let newMedia: any[] = values?.media || [];

    const { filesWithoutRotation, newFiles } = processFilesForRotation(
      newMedia,
      ImagesScreenSizes.post,
    );
    if (selectedPost?._id) {
      let EditPost: any = {
        ...values,
        media: filesWithoutRotation,
        postText: values.text,
      };
      const promises: any = [];
      newFiles?.forEach((f: any) => {
        promises.push(
          updateImagesPhysically({
            url: f.oldUrl,
            name: f.path.split('/').pop(),
          }),
        );
      });
      return await Promise.all([...promises])
        .then(async (d: any) => {
          return await editPost(
            (EditPost as any)?._id,
            {
              ...EditPost,
            },
            { sellerId: managedAccountId },
          ).then((data: any) => {
            dispatch(updateEditSchedulePost(data));
            dispatch(toggleEditPost());
            form.resetForm();
          });
        })
        .catch((e) => console.log({ e }));
    }
  };
  return (
    <>
      <Model
        className={className}
        isOpen={isOpen}
        title={
          <div className="title-holder user_list">
            <span className="title-area">
              <span className={`title-icon`}>{<FireIcon />}</span>
              <span className="title-text">{'Edit Post'}</span>
            </span>
          </div>
        }
        showFooter={false}
        onClose={handleClose}
      >
        <Createpost
          editPost={true}
          onSubmit={onSubmit}
          pendingEditMessages={pendingEditMessages}
          hasTemplate={false}
          selectedPost={selectedPost}
          showBackButton={false}
          showForwardButton={false}
        />
      </Model>
    </>
  );
}

export default styled(EditPostModal)`
  max-width: 493px;
  border-radius: 10px;
  overflow: hidden;

  .modal-header {
    padding: 20px 24px 13px;
    border: none;

    .title-icon {
      display: inline-block;
      vertical-align: top;
      margin: 0 15px 0 0;
      width: 14px;

      svg {
        width: 100%;
        height: auto;
        vertical-align: top;
      }
    }

    .title-text {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .modal-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    flex-grow: 1;
    flex-basis: 0;

    .title-holder {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .modal-body {
    padding: 0;
    max-height: calc(100vh - 120px);
    overflow: auto;
  }

  .action_buttons {
    display: flex;
    justify-content: flex-end;
    padding: 13px 16px;
    border-top: 1px solid #e6ecf1;

    .button {
      min-width: inherit;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0;
    }
  }

  .btn-note {
    color: #357ea9;
    text-transform: uppercase;
    background: transparent;
    padding: 3px 10px;

    &:hover {
      color: #fff;
    }

    &.button-sm {
      padding: 3px 10px;
    }

    svg {
      width: 14px;
    }
  }

  .loader-holder {
    padding: 20px;
    text-align: center;
  }

  .membership-area {
    padding: 23px 20px;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    max-height: calc(100vh - 170px);
    overflow: auto;

    .title {
      display: block;
      color: #050505;
      margin: 0 0 23px;
      font-weight: 400;
    }

    .profile-detail {
      background: #f4f6fb;
      border-radius: 4px;
      padding: 15px;
    }

    .profile-image {
      border: 2px solid #ffffff;
      width: 54px;
      height: 54px;
      border-radius: 100%;
      margin: 0 auto 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .user-name {
      display: block;
      font-weight: 500;
      margin: 0 0 4px;
    }

    .button {
      min-width: 103px;
      padding: 6px 10px;
    }
  }

  .duration {
    display: block;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 0 0;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
`;
