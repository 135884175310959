import classNames from 'classnames';
import EditBack from 'components/partials/components/profileBack';
import Scrollbar from 'components/Scrollbar';
import { ServiceType } from 'enums';
import { AnimatePresence, Variants } from 'framer-motion';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useAuth from 'hooks/useAuth';
import useControllTwopanelLayoutView from 'hooks/useControllTwopanelLayoutView';
import useSelector from 'hooks/useSelector';
import React, { ReactElement, useEffect, useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { myPostsList } from 'store/reducer/salesState';
import styled, { CSSProperties } from 'styled-components';
import ITheme from 'types/Theme';
import { getSortbyParam, parseQuery } from 'util/index';
import PageTransition from './page-transition';

const rtr = {
  pageInitial: {
    opacity: 0,
    left: '660px',
  },
  pageAnimate: {
    opacity: 1,
    left: '0',
  },
  pageExit: {
    opacity: 0,
    left: '660px',
    position: 'absolute',
  },
};
const ltl = {
  pageInitial: {
    opacity: 0,
    left: '-660px',
  },
  pageAnimate: {
    opacity: 1,
    left: '0',
  },
  pageExit: {
    opacity: 0,
    left: '-660px',
    position: 'absolute',
  },
};

interface Props {
  leftView?: React.ReactNode;
  rightView?: React.ReactNode;
  defaultRightSide?: boolean;
  classes?: { leftView?: string; rightView?: string; content?: string };
  styles?: { leftView?: CSSProperties; rightView?: CSSProperties };
  className?: string;
  children?: JSX.Element | JSX.Element[];
  defaultBackButton?: boolean;
}

export const setTwoPanelLayoutHeight = () => {
  setTimeout(() => {
    const ele = document.getElementById('twopanellayout');
    ele?.setAttribute(
      'style',
      `
      height: calc(100vh - ${ele?.getBoundingClientRect().y}px)
    `,
    );
  }, 0);
};

export function Layout({
  leftView,
  rightView,
  classes = {},
  styles = {},
  className = '',
  children,
  defaultBackButton = true,
  ...rest
}: Props): ReactElement {
  const { user } = useAuth();
  const { activeView, showLeftView } = useControllTwopanelLayoutView();
  const location = useLocation();
  const dispatch = useAppDispatch();
  // const { pathname, search } = useLocation();
  const { slider, subType } = parseQuery(location.search);
  useEffect(() => {
    showLeftView();
  }, [location.pathname, showLeftView]);

  const rendering = useRef(true);
  const scrollbarRef = useRef<any>(null);
  const leftSCrollbarRef = useRef<any>(null);

  const {
    leftView: leftViewClass,
    rightView: rigthViewClass,
    content: contentClass,
  } = classes;
  const { leftView: leftViewStyle, rightView: rigthViewStyle } = styles;
  const myPostList = useAppSelector((state) => state.mysales.myposts);
  const isMyPostsFetching = useAppSelector(
    (state) => state.mysales.isMyPostsFetching,
  );
  const { current: theme }: { current: ITheme } = useSelector(
    (state) => state.theme,
  );
  const history = useHistory();
  const isMySalesMember = matchPath(history.location.pathname, {
    path: '/my-profile/subscriber',
    exact: true,
    strict: false,
  });

  const isMyPosts = matchPath(history.location.pathname, {
    path: '/my-profile/post',
    exact: true,
    strict: false,
  });
  const Childrens = React.Children.toArray(children);
  let LeftPanelView = leftView;
  let RightPanelView = rightView;

  if (Childrens.length === 2) {
    LeftPanelView = Childrens[0];
    RightPanelView = Childrens[1];
  }

  useEffect(() => {
    setTwoPanelLayoutHeight();
    showLeftView();
    rendering.current = false;
  }, []);

  const handleScroll = async () => {
    if (slider === 'Add_Post' && subType === ServiceType.CHAT_SUBSCRIPTION) {
      if (
        scrollbarRef.current &&
        myPostList?.items?.length < myPostList?.totalCount
      ) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollbarRef.current.view;
        const pad = 1; // 100px of the bottom
        // t will be greater than 1 if we are about to reach the bottom
        const t = (scrollTop + pad) / (scrollHeight - clientHeight);
        if (t > 1 && !isMyPostsFetching) {
          dispatch(
            myPostsList({
              params: {
                skip: myPostList?.items?.length,
                limit: 10,
                sort: getSortbyParam('createdAt'),
                order: 'desc',
              },
            }),
          ).catch((e) => console.log(e));
        }
      }
    }
  };
  const isRightScroll = isMyPosts || isMySalesMember;
  const isLeftScroll = slider === 'subscriber';
  return (
    <div className={classNames(className)}>
      <div id="twopanellayout">
        <BrowserView
          viewClassName={classNames('desktop_view', 'two--panel-layout')}
          style={{ height: '100%' }}
        >
          <div
            className={classNames('col-left', leftViewClass)}
            style={{ position: 'relative', zIndex: 1, ...leftViewStyle }}
          >
            {isLeftScroll ? (
              <div className={classNames('col-inner-content', contentClass)}>
                {React.cloneElement(LeftPanelView as any, {
                  ...rest,
                  scrollref: leftSCrollbarRef,
                  user,
                  theme: theme || user.userThemeId,
                })}
              </div>
            ) : (
              <Scrollbar
                className="custom-scroll-bar"
                style={{ overflowX: 'hidden' }}
                scrollRef={leftSCrollbarRef}
              >
                <div className={classNames('col-inner-content', contentClass)}>
                  {React.cloneElement(LeftPanelView as any, {
                    ...rest,
                    scrollref: leftSCrollbarRef,
                    user,
                    theme: theme || user.userThemeId,
                  })}
                </div>
              </Scrollbar>
            )}
          </div>
          <div
            className={classNames('col-right', rigthViewClass)}
            style={rigthViewStyle}
          >
            {isRightScroll ? (
              <div className={classNames('col-inner-content', contentClass)}>
                {React.cloneElement(RightPanelView as any, {
                  ...rest,
                  user,
                  theme: theme || user.userThemeId,
                  twoPanelLayout: true,
                })}
              </div>
            ) : (
              <Scrollbar
                className="custom-scroll-bar"
                onScrollStop={handleScroll}
                scrollRef={scrollbarRef}
              >
                <div className={classNames('col-inner-content', contentClass)}>
                  {React.cloneElement(RightPanelView as any, {
                    ...rest,
                    user,
                    scrollref: scrollbarRef,
                    theme: theme || user.userThemeId,
                    twoPanelLayout: true,
                  })}
                </div>
              </Scrollbar>
            )}
          </div>
        </BrowserView>
        <MobileView
          viewClassName={classNames('two--panel-layout-', 'mobile_view')}
        >
          <AnimatePresence initial={false}>
            {activeView === 'left' || rendering.current ? (
              <div key="1">
                <PageTransition variants={ltl as Variants}>
                  <div
                    className={classNames('col-left', leftViewClass)}
                    style={{
                      position: 'relative',
                      zIndex: 1,
                      ...leftViewStyle,
                    }}
                  >
                    <div
                      className={classNames('col-inner-content', contentClass)}
                    >
                      {React.cloneElement(LeftPanelView as any, {
                        ...rest,
                        user,
                        theme: theme || user.userThemeId,
                      })}
                    </div>
                  </div>
                </PageTransition>
              </div>
            ) : (
              <div key="2">
                <PageTransition variants={rtr as Variants}>
                  <div
                    className={classNames('col-right', rigthViewClass)}
                    style={rigthViewStyle}
                  >
                    <div
                      className={classNames('col-inner-content', contentClass)}
                    >
                      {defaultBackButton && (
                        <EditBack
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            showLeftView();
                          }}
                        />
                      )}
                      {React.cloneElement(RightPanelView as any, {
                        ...rest,
                        user,
                        theme: theme || user.userThemeId,
                        twoPanelLayout: true,
                      })}
                    </div>
                  </div>
                </PageTransition>
              </div>
            )}
          </AnimatePresence>
        </MobileView>
      </div>
    </div>
  );
}

export default styled(Layout)`
  .two--panel-layout {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    #wrapper {
      min-height: calc(100vh - 114px) !important;
    }
    .container.mb-80 {
      margin-bottom: 20px !important;
    }
    .card-item-holder {
      @media (min-width: 1024px) {
        width: 33.333%;
      }
    }
  }
  .col-left {
    width: 600px;
    background-color: #f5f6f8;
    min-width: 600px;
    .custom-scroll-bar > div:nth-child(1) {
      overflow-x: hidden !important;
      margin-bottom: 0px !important;
    }

    @media (max-width: 1023px) {
      width: 100%;
      min-width: inherit;
    }

    .rc-scollbar {
      width: 600px;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }

    .card-body-wrapper {
      .rc-scollbar {
        width: 100%;
      }
    }
  }
  .col-left::after {
    width: 1px;
    height: 100%;
    background: #d5dade !important;
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;

    @media (max-width: 1023px) {
      display: none;
    }
  }
  .rc-scollbar {
    height: 100%;
  }
  .col-left .col-inner-content {
    padding: 24px 18px;
    height: 100%;
    width: 100%;

    > div:only-child {
      height: 100%;

      > div:only-child {
        height: 100%;
      }
    }

    .pop__edit {
      min-height: 100%;
      background: #fff;
    }
  }
  .col-right {
    flex-grow: 1;
    flex-basis: 0;
  }

  .mobile_view {
    .col-left {
      width: 100%;
      min-height: calc(100vh - 173px);
    }

    .col-inner-content {
      width: 600px;
      min-width: 600px;
      margin: 0 auto;

      @media (max-width: 1023px) {
        width: 100%;
        min-width: inherit;
      }
    }

    .card-item-holder {
      @media (min-width: 1024px) {
        width: 33.333%;
      }
    }

    .button-holder {
      width: 100%;
    }
  }

  ${({ defaultRightSide }) =>
    defaultRightSide && `.col-right .col-inner-content { padding:0px }`}
`;
