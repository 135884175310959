//// Example
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { getPresignedUrl } from 'api/User';
import { upload } from 'api/Utils';
import attrAccept from 'attr-accept';
import axios, { CancelTokenSource } from 'axios';
import { toast } from 'components/toaster';
import { CLOUD_FRONT_S3, CLOUD_FRONT_THUMBNAI, VIDEOURL } from 'config';
import { getAudioDuration, getImageURL } from 'util/index';

// Define a type for the slice state
export interface IFileGroup {
  [key: string]: any;
  progress?: number;
  status?: 'COMPLETED' | 'FAILED' | 'IN_PROGRESS' | 'CANCELLED';
  numberOfCompletedFiles?: number;
  files: {
    id?: string;
    orignalFile: File;
    progress: number;
    status: 'COMPLETED' | 'FAILED' | 'IN_PROGRESS' | 'ENCODING' | 'CANCELLED';
    requestToken: CancelTokenSource;
    url?: string;
    [key: string]: any;
    videoDuration: string;
    duration?: number;
  }[];
}
interface Ifiles {
  [key: string]: IFileGroup;
}

// Define the initial state using that type
const initialState: Ifiles = {};

interface MyReturnType {
  [key: string]: any;
}
export const uploadFiles = createAsyncThunk<MyReturnType, IFileGroup>(
  'files/startUploading',
  async (payload, { dispatch }) => {
    const {
      key: groupKey,
      url,
      socket,
      onCompletedCallback,
      onSingleFileUpload,
      onCancelFileUpload,
      ...rest
    } = payload;

    if (!url) {
      throw new Error('Please Provide a Url to upload');
    }

    if (rest.files.length < 1) {
      onCompletedCallback();
      return;
    }

    dispatch(startGroupUploading({ ...rest, key: groupKey }));
    for (let index = 0; index < rest.files.length; index++) {
      const file = rest.files[index];
      if (file.orignalFile) {
        if (attrAccept({ name: file.name, type: file.type }, 'video/*')) {
          let extention: any = file.name.split('.');
          extention = extention[extention.length - 1];
          const key = `${file.id}.${extention}`;
          getPresignedUrl(key, file.type)
            .then((res) => {
              const ourRequest = axios.CancelToken.source();
              dispatch(
                startFileUploading({
                  groupId: groupKey,
                  fileId: file.id,
                  requestToken: ourRequest,
                }),
              );
              upload(
                '',
                file.orignalFile,
                {
                  method: 'PUT',
                  cancelToken: ourRequest.token,
                  headers: { 'Content-Type': file.type },
                  baseURL: res.signedUrl,
                  onUploadProgress: (PE) => {
                    dispatch(
                      setFileUpoadingProgress({
                        groupId: groupKey,
                        fileId: file.id,
                        progress: Math.floor((PE.loaded * 100) / PE.total),
                        ...PE,
                      }),
                    );
                  },
                },
                false,
              )
                .then((res) => {
                  const videoThumbnail = `${CLOUD_FRONT_THUMBNAI}/thumb-pops/order-videos/${file.id}.0000001.png`;
                  const videoPath = `${CLOUD_FRONT_S3}/vid-pops/order-videos/${file.id}.mp4`;
                  if (!res?.sucess && res?.reason === 'cancelled') {
                    dispatch(
                      onFileUploadingCancelled({
                        groupId: groupKey,
                        fileId: file.id,
                        status: 'CANCELLED',
                        callBack: onCancelFileUpload,
                      }),
                    );
                  }
                  socket?.on(`${VIDEOURL}${key}`, ({ status }: any) => {
                    if (status === 'PROGRESSING') {
                      dispatch(
                        setFileUpoadingProgress({
                          groupId: groupKey,
                          fileId: file.id,
                          progress: 100,
                          status: 'ENCODING',
                        }),
                      );
                    } else if (status === 'COMPLETE') {
                      let blurThumnail = undefined;
                      if (index === 0) {
                        blurThumnail = getImageURL(videoThumbnail, {
                          isThumbBdesktop: true,
                        })?.url;
                      }
                      dispatch(
                        onFileUpoadingComplete({
                          onSingleFileUpload,
                          onCompletedCallback,
                          groupId: groupKey,
                          fileId: file.id,
                          url: videoPath,
                          path: videoPath,
                          status: 'COMPLETED',
                          thumbnail: videoThumbnail,
                          thumb: videoThumbnail,
                          videoDuration: file.videoDuration,
                          blurThumnail,
                        }),
                      );
                    } else if (status === 'ERROR') {
                      dispatch(
                        onFileUpoadingFailed({
                          groupId: groupKey,
                          fileId: file.id,
                        }),
                      );
                    }
                  });
                })
                .catch((e) => {
                  toast.error('Sorry, Please try again');
                  dispatch(
                    onFileUpoadingFailed({
                      groupId: groupKey,
                      fileId: file.id,
                    }),
                  );
                });
            })
            .catch((e) => {
              toast.error('Sorry, Please try again');
              dispatch(
                onFileUpoadingFailed({
                  groupId: groupKey,
                  fileId: file.id,
                }),
              );
            });
        } else if (
          attrAccept({ name: file.name, type: file.type }, 'audio/*')
        ) {
          let extention: any = file.name.split('.');
          extention = extention[extention.length - 1];
          const key = `audio/${file.id}.${extention}`;

          getPresignedUrl(key, file.type, false)
            .then((pres) => {
              const ourRequest = axios.CancelToken.source();
              dispatch(
                startFileUploading({
                  groupId: groupKey,
                  fileId: file.id,
                  requestToken: ourRequest,
                }),
              );
              upload(
                '',
                file.orignalFile,
                {
                  method: 'PUT',
                  cancelToken: ourRequest.token,
                  headers: { 'Content-Type': file.type },
                  baseURL: pres.signedUrl,
                  onUploadProgress: (PE) => {
                    dispatch(
                      setFileUpoadingProgress({
                        groupId: groupKey,
                        fileId: file.id,
                        progress: Math.floor((PE.loaded * 100) / PE.total),
                        ...PE,
                      }),
                    );
                  },
                },
                false,
              )
                .then(async (res) => {
                  const path = pres.signedUrl?.split('?')[0];
                  let duration = file?.duration;
                  let videoDuration = file?.videoDuration;
                  if (!duration) {
                    try {
                      const data: any = await getAudioDuration({
                        ...file,
                        url: path,
                      } as any);
                      duration = data.duration || undefined;
                      videoDuration = data.timeDuration || undefined;
                    } catch (error) {
                      console.log({ error });
                    }
                  }

                  dispatch(
                    onFileUpoadingComplete({
                      ...file,
                      percentCompleted: 100,
                      onSingleFileUpload,
                      onCompletedCallback,
                      groupId: groupKey,
                      encoding: 'completed',
                      fileId: file.id,
                      url: path,
                      path: path,
                      status: 'COMPLETED',
                      duration,
                      videoDuration,
                    }),
                  );
                })
                .catch((e) => {
                  toast.error('Sorry, Please try again');
                  dispatch(
                    onFileUpoadingFailed({
                      groupId: groupKey,
                      fileId: file.id,
                    }),
                  );
                });
            })
            .catch((e) => {
              toast.error('Sorry, Please try again');
              dispatch(
                onFileUpoadingFailed({
                  groupId: groupKey,
                  fileId: file.id,
                }),
              );
            });
        } else {
          const from = new FormData();
          from.append('file', file.orignalFile!);
          from.append('folder', 'users/link-image');
          const updatedFile: any = file.orignalFile;
          const fileName = updatedFile?.fileName || file?.id;

          from.append('fileName', fileName);
          const ourRequest = axios.CancelToken.source();
          dispatch(
            startFileUploading({
              groupId: groupKey,
              fileId: file.id,
              requestToken: ourRequest,
            }),
          );
          upload(url, from, {
            onUploadProgress: (PE) => {
              dispatch(
                setFileUpoadingProgress({
                  groupId: groupKey,
                  fileId: file.id,
                  progress: Math.floor((PE.loaded * 100) / PE.total),
                  ...PE,
                }),
              );
            },
            cancelToken: ourRequest.token,
          })
            .then((data) => {
              if (!data.sucess && data?.reason === 'cancelled') {
                dispatch(
                  onFileUploadingCancelled({
                    groupId: groupKey,
                    fileId: file.id,
                    status: 'CANCELLED',
                    callBack: onCancelFileUpload,
                  }),
                );
              } else {
                const url = data.imageURL;
                let blurThumnail = undefined;
                if (index === 0) {
                  blurThumnail = getImageURL(data.imageURL, {
                    bdesktop: true,
                  })?.url;
                }
                dispatch(
                  onFileUpoadingComplete({
                    groupId: groupKey,
                    fileId: file.id,
                    url: url,
                    onCompletedCallback,
                    onSingleFileUpload,
                    ...data,
                    path: url,
                    imageURL: url,
                    fallbackUrl: url,
                    blurThumnail,
                  }),
                );
              }
            })
            .catch((e) => {
              // throw e;
              toast.error('Sorry, Please try again');
              console.log(e);
              dispatch(
                onFileUpoadingFailed({
                  groupId: groupKey,
                  fileId: file.id,
                }),
              );
            });
        }
      } else {
        let url = file.path || file.url;

        dispatch(
          onFileUpoadingComplete({
            onCompletedCallback,
            onSingleFileUpload,
            groupId: groupKey,
            fileId: file.id,
            url: url,
            path: url,
            status: 'COMPLETED',
            thumbnail: file.thumbnail,
            thumb: file.thumbnail,
          }),
        );
      }
    }
  },
);
export const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    startGroupUploading: (state, { payload }) => {
      state[payload.key] = {
        ...payload,
        progress: 0,
        status: 'IN_PROGRESS',
        numberOfCompletedFiles: 0,
      };
    },
    startFileUploading: (state, { payload }) => {
      const { groupId, fileId, requestToken } = payload;
      state[groupId].files = state[groupId].files.map((f) => {
        if (f.id !== fileId) return f;
        f.requestToken = requestToken;
        f.progress = 0;
        f.status = 'IN_PROGRESS';
        return f;
      });
    },
    setFileUpoadingProgress: (state, { payload }) => {
      const { groupId, fileId, progress, ...rest } = payload;
      if (state[groupId]) {
        state[groupId].progress =
          (state[groupId].files.reduce((pv, cv) => {
            return pv + cv.progress;
          }, 0) /
            state[groupId]?.files?.length) |
          0;
        state[groupId].files = state[groupId].files.map((f) => {
          return f.id !== fileId ? f : { ...f, progress, ...rest };
        });
      }
    },
    onFileUpoadingFailed: (state, { payload }) => {
      const { groupId, fileId, ...rest } = payload;

      state[groupId].files = state[groupId].files.map((f) => {
        return f.id !== fileId ? f : { ...f, ...rest, status: 'FAILED' };
      });
    },

    onFileUploadingCancelled: (state, { payload }) => {
      const { groupId, fileId, callBack, ...rest } = payload;

      if (state[groupId]) {
        const cancelledFileIndex = state[groupId].files.findIndex(
          (f) => f.id === fileId,
        );
        if (cancelledFileIndex > -1) {
          state[groupId].files[cancelledFileIndex] = {
            ...state[groupId].files[cancelledFileIndex],
            ...rest,
            status: 'CANCELLED',
          };
        }
        let isInprogress = true;
        const isAllCancelled = state[groupId].files.every(
          (f) => f.status === 'CANCELLED',
        );
        if (isAllCancelled) {
          state[groupId] = {
            ...state[groupId],
            status: 'CANCELLED',
            progress: 0,
          };
        } else {
          isInprogress = !!state[groupId].files.find(
            (f) => f.status !== 'CANCELLED' && f.status !== 'COMPLETED',
          );

          state[groupId] = {
            ...state[groupId],
            status: isInprogress ? 'IN_PROGRESS' : 'COMPLETED',
          };
        }
        callBack?.(
          groupId,
          state[groupId],
          state[groupId].files,
          isAllCancelled,
          !state[groupId]?.files?.find((f) => f.status === 'IN_PROGRESS'),
        );
      }
    },
    onRemoveGroup: (state, { payload }) => {
      const { groupId, fileId, callBack, ...rest } = payload;
      if (state?.[groupId]) {
        delete state[groupId];
      }
    },
    onUpdateUploadingFiles: (state, { payload }) => {
      const { groupId, fileId, callBack, ...rest } = payload;
      let isGroupDeleted = true;
      if (state?.[groupId]) {
        if (state?.[groupId]?.files?.length === 1) {
          delete state[groupId];
          isGroupDeleted = true;
        } else {
          state[groupId].files = state[groupId].files.filter((f) => {
            return f.id !== fileId;
          });
          isGroupDeleted = false;
        }
      }
      callBack?.(isGroupDeleted, groupId, fileId);
    },
    onFileUpoadingComplete: (state, { payload }) => {
      const { onCompletedCallback, onSingleFileUpload } = payload;
      const { groupId, fileId, ...rest } = payload;
      if (!!state?.[groupId]?.files?.length) {
        state[groupId].files = state[groupId].files.map((f) => {
          if (f.id !== fileId) {
            return f;
          }
          onSingleFileUpload?.({
            ...f,
            ...rest,
            status: 'COMPLETED',
            progress: 100,
          });
          return { ...f, ...rest, status: 'COMPLETED', progress: 100 };
        });

        state[groupId].progress =
          (state[groupId].files.reduce((pv, cv) => {
            return pv + cv.progress;
          }, 0) /
            state[groupId]?.files?.length) |
          0;

        state[groupId].numberOfCompletedFiles =
          (state[groupId]?.numberOfCompletedFiles! | 0) + 1;

        state[groupId].status =
          state[groupId].numberOfCompletedFiles === state[groupId].files.length
            ? 'COMPLETED'
            : 'IN_PROGRESS';

        if (state[groupId].status === 'COMPLETED' && onCompletedCallback) {
          const fileGroup = current(state[groupId]);
          setTimeout(() => {
            onCompletedCallback(fileGroup);
          }, 1000);
        }
      }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(uploadFiles.fulfilled, (state, { payload }) => {
      // Add user to the state array
      for (const files in payload) {
        state[files] = payload[files];
      }
    });
  },
});

// Extract the action creators object and the reducer
const { reducer, actions } = filesSlice;
export const {
  startGroupUploading,
  startFileUploading,
  setFileUpoadingProgress,
  onFileUpoadingFailed,
  onFileUpoadingComplete,
  onUpdateUploadingFiles,
  onFileUploadingCancelled,
  onRemoveGroup,
} = actions;

export default reducer;
