import { MutableRefObject } from 'react';
import styled from 'styled-components';
import { MediaType } from 'types/ChatSubscription';
import GalleryViewUploadWidget from './GalleryViewUploadWidget';
const StandardChatSubMessageInput: React.FC<{
  message?: string;
  onMessage?: (...args: any) => void;
  files?: MediaType[];
  onFileChange?: (files: MediaType[]) => void;
  onSortCallback?: (files: MediaType[]) => void;
  price?: Number;
  onPriceChange?: (p: number) => void;
  onCloseTabs?: (...args: any) => void;
  onSend?: () => void;
  defaultFiles?: any[];
  isSending?: boolean;
  openinGallery?: boolean;
  preivewMessage?: string;
  preivewSubMessage?: string;
  buttonRef?: MutableRefObject<any>;
  type?: string;
}> = ({
  onMessage,
  onFileChange,
  onSend,
  files = [],
  message = '',
  price,
  isSending,
  preivewMessage,
  preivewSubMessage,
  buttonRef,
  onSortCallback,
  type = '',
  openinGallery = false,
}) => {
  const handleFileChange = (ffls: MediaType[]) => {
    onFileChange &&
      onFileChange(
        ffls.map((i: any) => {
          return { ...i, isPaidType: false, islocK: false };
        }) as any,
      );
  };
  return (
    <div className="sub-tab-cotnent">
      <div className="sub-tab-holder">
        <GalleryViewUploadWidget
          openinGallery={openinGallery}
          buttonRef={buttonRef}
          accept={['image/*', 'video/*', 'audio/*']}
          message={preivewMessage}
          subMessage={preivewSubMessage}
          onChange={handleFileChange}
          onSortCallback={handleFileChange}
          value={files?.map((i) => ({
            ...i,
            isPaidType: false,
            islocK: false,
          }))}
        />
      </div>
    </div>
  );
};
export const StandardMessage = styled(StandardChatSubMessageInput)``;
const PaidChatSubMessageInput: React.FC<{
  message?: string;
  onMessage?: (...args: any) => void;
  files?: MediaType[];
  onFileChange?: (files: MediaType[]) => void;
  price?: Number;
  onPriceChange?: (p: number) => void;
  onCloseTabs?: (...args: any) => void;
  onSend?: () => void;
  defaultFiles?: any[];
  isSending?: boolean;
  openinGallery?: boolean;
  preivewMessage?: string;
  preivewSubMessage?: string;
  buttonRef?: MutableRefObject<any>;
  type?: string;
}> = ({
  onMessage,
  onFileChange,
  onSend,
  files = [],
  message = '',
  price,
  isSending,
  preivewMessage,
  preivewSubMessage,
  buttonRef,
  type = '',
  openinGallery = false,
}) => {
  const handleFileChange = (pfs: MediaType[]) => {
    onFileChange &&
      onFileChange(
        pfs.map((i: any) => {
          return { ...i, isPaidType: true, islocK: true };
        }) as any,
      );
  };

  return (
    <div className="sub-tab-cotnent">
      <div className="sub-tab-holder">
        <GalleryViewUploadWidget
          openinGallery={openinGallery}
          buttonRef={buttonRef}
          accept={['image/*', 'video/*', 'audio/*']}
          message={preivewMessage}
          subMessage={preivewSubMessage}
          onChange={handleFileChange}
          value={files?.map((i) => ({ ...i, isPaidType: true, islocK: true }))}
          breakCache={true}
        />
      </div>
    </div>
  );
};
export const PaidMessage = styled(PaidChatSubMessageInput)``;
