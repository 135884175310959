import { toast } from 'components/toaster';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import AddMessage from 'pages/schedule-messaging/components/RightView/AddMessage';
import React, { useState } from 'react';
import { setMessage, setPrice } from 'store/reducer/chat';
import { Message } from 'types/ChatSubscription';
interface Props {
  onCloseTabs(): void;
  onSubmit(
    files: any[],
    message?: string,
    price?: number,
    messageKey?: string,
  ): void;
  defaultType?: 'pay-to-view' | 'standard';
  media?: Message['messageMedia'];
  managedAccountId?: string;
  hasPaid?: boolean;
}

const PaidMessageInput: React.FC<Props> = (props) => {
  const {
    hasPaid = true,
    onCloseTabs,
    onSubmit: onSubmitCb,
    media,
    managedAccountId,
    defaultType = 'standard',
  } = props;
  const [selectedMessageType, setselectedMessageType] = useState<
    'pay-to-view' | 'standard'
  >(defaultType);
  const dispatch = useAppDispatch();
  const message = useAppSelector((state) => state.chat.message);
  const price = useAppSelector((state) => state.chat.price || 0);
  return (
    <AddMessage
      showTemplate={false}
      hasPaid={hasPaid}
      className="sub-tabs-holder"
      selectedMessageType={selectedMessageType}
      setSelectedMessageType={(val: 'pay-to-view' | 'standard') => {
        setselectedMessageType(val);
      }}
      showChooseTabsOptions={false}
      showClose
      onCloseTabs={onCloseTabs}
      onSubmitCb={onSubmitCb}
      price={price}
      message={message}
      media={media}
      validateFiles={(files) => {
        if (!message?.trim().length) {
          toast.error('Please enter a message');
          return false;
        }
        if (files.findIndex((i) => i.isPaidType) !== -1 && !files.length) {
          toast.error('Please add at least one media');
          return false;
        }
        if (files.findIndex((i) => i.isPaidType) !== -1 && price < 1) {
          toast.error('Price should be at least $1');
          return false;
        }

        return true;
      }}
      preivewMessage={
        selectedMessageType === 'standard' ? 'Create Your Message' : undefined
      }
      preivewSubMessage={
        selectedMessageType === 'standard'
          ? 'You can attach multiple media your message.'
          : undefined
      }
      onPriceChange={(price) => dispatch(setPrice(price))}
      onMessageChange={(message: string) => dispatch(setMessage(message))}
      managedAccountId={managedAccountId}
    />
  );
};

export default PaidMessageInput;
