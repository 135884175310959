import { VerticalDots } from 'assets/svgs';
import Image from 'components/Image';
import useDropDown from 'hooks/useDropDown';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

interface ICardHeaderProps {
  className?: string;
  showRightView?: boolean;
  title?: string | ReactElement | ReactNode | HTMLElement;
  subTitle?: string | ReactElement | ReactNode | HTMLElement;
  img?: string;
  ontitleClick?: Function;
  onConformToDelete?: any;
  Rightside?: any;
  fallbackUrl?: string;
}

const CardHeader: React.FunctionComponent<ICardHeaderProps> = ({
  className,
  title = 'Mai Seohyn',
  subTitle = '4 hours ago',
  showRightView = true,
  img = '/assets/images/default-profile-img.svg',
  onConformToDelete,
  ontitleClick,
  Rightside,
  fallbackUrl,
}) => {
  const { isVisible, ref } = useDropDown(false, false);

  return (
    <div className={className}>
      <div className="info-box">
        <div className="img">
          <Image src={img} alt="img description" fallbackUrl={fallbackUrl} />
        </div>
        {(title || subTitle) && (
          <div className="text">
            {title && (
              <strong onClick={() => ontitleClick?.()} className="title">
                {title}
              </strong>
            )}

            {subTitle && <span className="post-time">{subTitle}</span>}
          </div>
        )}
      </div>
      {showRightView && (
        <div ref={ref} className="options">
          <VerticalDots />
          {isVisible && <Rightside />}
        </div>
      )}
    </div>
  );
};

export default styled(CardHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 20px;

  @media (max-width: 479px) {
    padding: 0 0 15px;
  }

  .info-box {
    display: flex;
    align-items: center;

    .title {
      display: block;
      font-size: 15px;
      line-height: 18px;
      color: #515365;
      font-weight: 500;
    }

    .post-time {
      display: block;
      color: #888da8;
      font-size: 13px;
      line-height: 15px;
      font-weight: 400;
    }

    .img {
      width: 38px;
      height: 38px;
      border-radius: 100%;
      overflow: hidden;
      margin: 0 15px 0 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .options-opener {
    display: block;
    transform: rotate(90deg);
    color: #a7b0ba;
    cursor: pointer;

    &:hover {
      color: #255b87;
    }
  }
  &.dropdown-active {
    .options {
      background: #f5f8fd;
      color: #255b87;
    }
  }

  .options {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 36px;
    height: 36px;
    color: #255b87;
    cursor: pointer;
    position: relative;
    z-index: 3;

    svg {
      transform: rotate(-90deg);
      color: #255b87;
    }
  }

  .actions {
    position: absolute;
    right: 0;
    top: 100%;
    width: 106px;
    border: 1px solid #f1f3f8;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin: 1px 0 0;
    padding: 0;
    list-style: none;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: #8d778d;
    background: #fff;
    padding: 5px;
    cursor: default;

    li {
      &.delete {
        border-top: 1px solid #f0f2f6;
      }

      .button {
        display: inline-block;
        vertical-align: top;
        background: none;
        color: #8d778d;
        padding: 6px 10px;
        min-width: inherit;
        margin: 0;
        text-align: left;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        border-radius: 4px;

        &:hover {
          color: #49718f;
          background: rgba(230, 236, 245, 0.62);
        }
      }
    }
  }
`;
