import { LockIcon, PlusFilled } from 'assets/svgs';
import attrAccept from 'attr-accept';
import GallaryViewModal from 'components/PrivatePageComponents/GallaryViewModal';
import Scrollbar from 'components/Scrollbar';
import UploadandEditor from 'components/UploadandEditor';
import { useAppSelector } from 'hooks/useAppSelector';
import AttachmentContainer from 'pages/chat/components/AttachmentContainer';
import { MutableRefObject, ReactElement, useEffect, useState } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import styled from 'styled-components';
import { MediaType } from 'types/ChatSubscription';
import {
  getAudioFileDuration,
  getImageURL,
  getVideoCover,
  isValidUrl,
} from 'util/index';
import { v4 as uuid } from 'uuid';
import SortableList from './InputDragable/SortableList';

interface Props {
  showButton?: boolean;
  onChange?: (files: MediaType[]) => void;
  limit?: number;
  accept?: string[] | string;
  value?: MediaType[] & {
    checked?: boolean;
    progress?: number;
    status?: string;
  };
  openinGallery?: boolean;
  className?: string;
  message?: string;
  subMessage?: string;
  buttonRef?: MutableRefObject<any>;
  onSortCallback?: (files: MediaType[]) => void;
  breakCache?: boolean;
}
function GallaryViewUploadWidget({
  value,
  showButton = true,
  onChange,
  limit = 10000,
  accept = ['image/*', 'video/*'],
  className,
  message,
  subMessage,
  buttonRef,
  onSortCallback,
  openinGallery = false,
  breakCache = false,
  ...rest
}: Props): ReactElement {
  const [files, setFiles] = useState<
    (MediaType & { checked?: boolean; progress?: number; status?: string })[]
  >([]);
  // const [isLoading, setIsLoading] = useState(false);
  const uploadingGroups = useAppSelector((state) => state.fileGroups);
  const [slideIndex, setSlideIndex] = useState(-1);
  const [gallaryFiles, setGalleryFiles] = useState(files);

  // useEffect(() => {
  //   if (value) setFiles(value);
  // }, [value]);
  useEffect(() => {
    let images: any = [];
    const newFiles = [...files.map((f) => ({ ...f }))];
    newFiles.forEach((element) => {
      const isAudio = attrAccept({ type: element.type }, 'audio/*');
      if (
        element &&
        isValidUrl(element.path || element.url) &&
        attrAccept({ type: element.type }, 'image/*')
      ) {
        const { size, ...el } = { ...element } as any;
        let date = `?${el.updatedAt}`;
        const { url, fallbackUrl, isImgix } = getImageURL(
          el.path || el.url || '',
          {
            defaultUrl: el.path,
            onlyDesktop: true,
            imgix: {
              all: 'fit=clip&h=900',
            },
          },
        );
        const { url: murl } = getImageURL(el.path || el.url || '', {
          defaultUrl: el.path,
          onlyMobile: true,
          imgix: {
            all: 'h=500&w=500',
          },
        });
        images.push({
          ...el,
          src: url + `?${date}`,
          fallback: el.path ? el.path + `?${date}` : el.url + `?${date}`,
          path: url + `?${date}`,
          url: url + `?${date}`,
          thumb: murl + `?${date}`,
        });
      } else {
        const { size, ...rest } = element as any;
        images.push({
          ...rest,
          poster: isAudio ? '/assets/images/mp3-icon.png' : element.thumbnail,
          fallback: element.url || element.path,
          thumb: isAudio ? '/assets/images/mp3-icon.png' : element.thumbnail,
          src: element.url || element.path,
          width: '650',
          height: '720',
        });
      }
    });
    setGalleryFiles((_: any) => [...images]);
  }, [files]);
  useEffect(() => {
    const prevFiles: any[] = value ? [...value] : [];
    Object.values(uploadingGroups).forEach((group) => {
      group.files.forEach((file) => {
        const index = prevFiles.findIndex((f: any) => f.id === file.id);
        if (index > -1) {
          prevFiles[index] = {
            ...prevFiles[index],
            progress: file.progress,
            status: file.status,
          };
        }
      });
    });
    setFiles(prevFiles);
  }, [uploadingGroups, value]);

  const RenderPlusIcon = (props: any) => {
    return (
      <div className="upload_placeholder">
        <PlusFilled />
      </div>
    );
  };
  const handleSuccess = async (fls: any) => {
    const newFiles: MediaType[] = [];
    for (let index = 0; index < fls.length; index++) {
      const file = fls[index];
      let url = undefined;
      let thumb = undefined;
      let duratonInSeconds = 0;
      let duration = undefined;
      if (attrAccept(file, 'video/*')) {
        await getVideoCover(file)
          .then((payload: any) => {
            duration = payload.timeDuration;
            duratonInSeconds = payload.duration;
            try {
              thumb = URL.createObjectURL(payload.blob);
            } catch (error) {}
            url = payload.src;
            if (payload.width && payload.height) {
              Object.defineProperty(file, 'width', {
                value: payload.width,
                writable: true,
              });
              Object.defineProperty(file, 'height', {
                value: payload.height,
                writable: true,
              });
            }
          })
          .catch((e) => {
            console.log(e, 'error');
          });
      } else if (attrAccept(file, 'image/*')) {
        try {
          url = URL.createObjectURL(file);
        } catch (error) {}
      } else if (attrAccept(file, 'audio/*')) {
        await getAudioFileDuration(file)
          .then((payload: any) => {
            duration = payload.timeDuration;
            duratonInSeconds = payload.duration;
            url = payload.src;
          })
          .catch((e) => {
            console.log(e, 'error');
          });
      }
      newFiles.push({
        name: file.name,
        type: file.type,
        id: uuid(),
        size: file.size,
        orignalFile: file,
        path: url,
        thumbnail: thumb,
        thumb: thumb,
        videoDuration: duration,
        duration: duratonInSeconds,
        updatedAt: new Date().getTime() + '',
      } as any);
    }

    const f = files.concat(newFiles);
    setFiles(f);
    onChange?.(f);
  };

  const removeFile = (id: string) => {
    const f = files.filter((file) => file.id !== id);
    setFiles(f);
    onChange?.(f);
  };
  const onSortEnd = async ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex === newIndex) return;
    const sortedArray = arrayMove<any>(files, oldIndex, newIndex).map(
      (item, index) => ({ ...item, sortOrder: index }),
    );
    setFiles(sortedArray);
    onSortCallback?.(sortedArray);
  };
  const handleImageClick = (index: number) => {
    setSlideIndex(index);
  };
  return (
    <div className={className}>
      <div className={files.length > 0 ? 'imag_media d-flex' : ''}>
        <Scrollbar className="custom-scroll-bar-holder">
          <SortableList
            useDragHandle
            axis="xy"
            onSortEnd={onSortEnd}
            distance={5}
            lockAxis="x"
            items={files}
            renderItem={(m: any, index: number) => {
              return (
                <AttachmentContainer
                  isGalleryChecked={true}
                  onClick={() => {
                    handleImageClick(index);
                  }}
                  media={m}
                  dragHandle={true}
                  showOptions={{
                    closeIcon: true,
                    edit: false,
                    play: attrAccept({ type: m.type }, 'video/*'),
                    video: attrAccept({ type: m.type }, 'video/*'),
                    timeStampText: false,
                    showprogress: m.status === 'IN_PROGRESS',
                  }}
                  filekey={'id'}
                  onClose={removeFile}
                  icon={m.islocK ? <LockIcon /> : undefined}
                  breakCache={breakCache}
                />
              );
            }}
          />
          {openinGallery && (
            <GallaryViewModal
              className="gallaryswiper"
              isOpen={slideIndex !== -1}
              items={gallaryFiles as any}
              currentSlideIndex={slideIndex}
              disableVideoAudioRotation={true}
              onCloseModel={(items) => {
                const newFiles = [...files];
                items
                  // .filter((k: any) => k.rotateable)
                  .forEach((k1: any) => {
                    const fileIndex: any = files.findIndex(
                      (f) => f.id === k1.id,
                    );
                    const fileItem: any = files[fileIndex];
                    const isVideo = attrAccept(
                      { type: fileItem.type },
                      'vidoe/*',
                    );
                    const isAudio = attrAccept(
                      { type: fileItem.type },
                      'audio/*',
                    );
                    if (!isVideo && !isAudio) {
                      newFiles[fileIndex] = {
                        ...fileItem,
                        rotate: k1.rotate,
                      };
                    }
                  });
                setFiles(newFiles);
                onChange?.(newFiles);
                setGalleryFiles(newFiles);
              }}
              onClose={() => setSlideIndex(-1)}
              rotate={true}
            />
          )}
          {showButton && (
            <UploadandEditor
              customRequest={handleSuccess}
              disabled={files.length >= limit}
              cropper={false}
              accept={accept}
              {...rest}
            >
              <RenderPlusIcon />
            </UploadandEditor>
          )}
        </Scrollbar>
      </div>
    </div>
  );
}

export default styled(GallaryViewUploadWidget)`
  padding: 1px 0 0;
  .attachmentlightbox {
    display: none;
  }
  .upload_placeholder {
    width: 106px;
    min-width: 106px;
    height: 136px;
    background: #e1e4eb;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 767px) {
      height: 77px;
      width: 77px;
      min-width: 77px;
    }

    svg {
      width: 46px;
      height: 46px;
      color: #255b87;

      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
      }
    }
  }

  .imag_media {
    margin: 20px -5px 10px;
    overflow: auto;
    height: 172px;

    @media (max-width: 767px) {
      height: 112px;
    }

    .rc-scollbar {
      display: flex;
      width: auto !important;
    }

    .custom-scroll-bar-holder {
      > div {
        z-index: 10;

        &:first-child {
          overflow-x: auto !important;
        }
      }
    }

    .sortable {
      display: flex;
      margin: 0;
    }

    .drag-dots {
      width: 4px;
      height: 4px;
      display: block;
      margin: 8px auto 0;
      color: #d6dade;
      transform: rotate(90deg);
      transition: all 0.4s ease;
      position: relative;
      right: -8px;

      @media (max-width: 767px) {
        right: -13px;
      }

      &:hover {
        color: #255b87;
      }
    }
  }

  .img-container {
    width: 136px;
    min-width: 136px;
    height: 136px;
    padding: 0;
    margin: 0 10px 10px 5px;

    @media (max-width: 767px) {
      height: 77px;
      width: 77px;
      min-width: 77px;
    }

    img {
      position: static;
    }
  }
`;
