import { AudioSVG, CourseFile, CourseVideo, ImageThumbnail } from 'assets/svgs';
import attrAccept from 'attr-accept';
import According from 'components/according';
import Modal from 'components/modal';
import Button from 'components/NButton';
import { CardSection } from 'components/SPCards/SimpleCard';
import VideoPlay from 'components/VideoPlay';
import { useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import styled from 'styled-components';
import IPop, { IPopMedia } from 'types/Pop.type';
import IUser from 'types/user';
import { secondsToHms } from 'util/index';
interface ICourseAccording {
  pop?: IPop;
  publicUser?: IUser;
  className?: string;
  onSubmit?: Function;
}

const CourseAccording: React.FunctionComponent<ICourseAccording> = ({
  className,
  pop,
  publicUser,
  onSubmit: handleSubmit,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [modalValue, setModalValue] = useState<any>(null);
  const [lessonsToggelState, setLessonsToggelState] = useState<
    Record<string, boolean>
  >({});
  const docs = [
    {
      uri: modalValue?.url,
    },
  ];
  const getTotalLectures = () => {
    let totalLectures = 0;
    pop?.courseLessons?.forEach((cl) => {
      totalLectures += cl.lessonMedia?.length || 0;
    });
    return totalLectures;
  };
  const CheckFileType = (value: any) => {
    if (attrAccept({ type: value?.type }, ['audio/*', 'video/*', 'image/*'])) {
      setModalValue(value);
    } else if (value?.type === 'application/pdf') {
      window.open(`${value?.url}`, '_blank');
    } else {
      window.open(
        `https://view.officeapps.live.com/op/embed.aspx?src=${value?.url}`,
        '_blank',
      );
    }
  };

  // * set All lessons accordion states to false
  useEffect(() => {
    if (!!pop?.courseLessons?.length) {
      const toggleStates: Record<string, boolean> = {};
      pop.courseLessons?.forEach((cl) => {
        toggleStates[cl._id || ''] = false;
      });
      setLessonsToggelState(toggleStates);
    }
    return () => {};
  }, [pop?.courseLessons]);

  // * get a Lesson meidias total seconds
  const getTotalLessonSeconds = (media: IPopMedia[]) => {
    const totalSeconds = media?.reduce(
      (prev, current) => prev + (Number(current?.duration || 0) || 0),
      0,
    );
    return totalSeconds;
  };

  // * get total of all Lessons meidias total seconds
  const getTotalTime = () => {
    const seconds = pop?.courseLessons?.reduce((prev, current) => {
      return prev + getTotalLessonSeconds(current.lessonMedia || []);
    }, 0);
    return secondsToHms(seconds, true);
  };

  // * handle accordion state
  const handleAccordionOpenState = () => {
    const Keys = Object.keys(lessonsToggelState);
    const newLessonsToggleStates: Record<string, boolean> = {};
    Keys.forEach((k) => {
      newLessonsToggleStates[k] = !isAccordionOpen;
    });
    setLessonsToggelState(newLessonsToggleStates);

    setIsAccordionOpen((isOpen: boolean) => !isOpen);
  };
  // * handle accordion state for particular accordion
  const handleToggle = (state: boolean, id: string) => {
    if (state !== isAccordionOpen) {
      const Keys = Object.keys(lessonsToggelState);
      const isOppositeExist = Keys?.find((k) => {
        const item = lessonsToggelState[k];
        return item === isAccordionOpen && id !== k;
      });
      if (!isOppositeExist) {
        setIsAccordionOpen(!isAccordionOpen);
      }
    }
    setLessonsToggelState({ ...lessonsToggelState, [id]: state });
  };
  return (
    <div className={className}>
      <Button
        type="primary"
        block
        className="btn btn-primary mb-30"
        size="x-large"
        onClick={() => handleSubmit?.()}
        // isLoading={isUpdating}
        // disabled={isUpdating}
      >
        Purchase Course - ${Number(pop?.price || 0)?.toFixed(2)}
      </Button>
      <h3>Course Content</h3>
      <div className="course-info">
        <ul className="course-info-list">
          <li>
            <strong>{pop?.courseLessons?.length || 0}</strong> sections
          </li>
          <li>
            <strong>{getTotalLectures()}</strong> lessons
          </li>
          <li>
            {/* <strong>12h 13m</strong> total length */}
            <strong>{getTotalTime()}</strong> total length
          </li>
        </ul>
        <span
          className={`btn-expand ${isAccordionOpen ? 'active' : ''}`}
          onClick={handleAccordionOpenState}
        >
          {isAccordionOpen ? 'Collapse All' : 'Expand All'}
        </span>
      </div>
      <div className="accordion-wrap">
        {pop?.courseLessons
          ?.filter((l) => l.isActive)
          .map((cl) => {
            const totalDuration = secondsToHms(
              getTotalLessonSeconds(cl.lessonMedia || []),
              true,
            );
            return (
              <According
                isOpen={isAccordionOpen}
                onToggle={(isOpen) => {
                  handleToggle(isOpen, cl._id || '');
                }}
                title={
                  <span className="title-wrap">
                    <span className="title-text">{cl.lessonTitle}</span>{' '}
                    <ul className="lecture-info">
                      <li>{cl.lessonMedia?.length}</li>{' '}
                      <li>{totalDuration || '00:00'}</li>
                    </ul>
                  </span>
                }
                showIcon={false}
                indicator={<span className="icon-keyboard_arrow_right"></span>}
              >
                <CardSection>
                  <ul className="course-detail-list">
                    {cl.lessonMedia?.map((m) => {
                      const isVid = /(video)\/(.*)/gm;
                      const isAud = /(audio)\/(.*)/gm;
                      return (
                        <li>
                          <div className="course_section_item_heading">
                            {m.title || m.name}
                            {/* put media title */}
                          </div>
                          <span className="img">
                            {isAud.test(m.type) ? (
                              <AudioSVG />
                            ) : isVid.test(m.type) ? (
                              <CourseVideo />
                            ) : m.type === 'image' ? (
                              <ImageThumbnail />
                            ) : (
                              <CourseFile />
                            )}
                          </span>
                          <div className="more-info">
                            {(m as any)?.isPreview && (
                              <>
                                <div
                                  className="course_section_item_mode"
                                  onClick={() => CheckFileType(m)}
                                >
                                  Preview
                                </div>
                              </>
                            )}

                            <div className="course_section_item_duration">
                              {secondsToHms(m.duration) || '00:00'}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </CardSection>
              </According>
            );
          })}
      </div>
      {modalValue !== null && (
        <Modal
          className={`${className} ${
            attrAccept(
              { name: modalValue?.name, type: modalValue?.type },
              'audio/*',
            )
              ? 'audio'
              : ''
          }`}
          title={modalValue?.title || modalValue?.name}
          onClose={() => setModalValue(null)}
          showFooter={false}
          isOpen={modalValue !== null}
        >
          {attrAccept({ type: modalValue?.type }, ['audio/*', 'video/*']) ? (
            <VideoPlay url={modalValue?.url} playing={true} />
          ) : attrAccept({ type: modalValue?.type }, ['image/*']) ? (
            <img src={modalValue?.url} alt="" />
          ) : (
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              documents={docs}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                },
              }}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default styled(CourseAccording)`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #000;
  padding: 0 0 30px;

  h3 {
    font-size: 22px;
    line-height: 24px;
    color: #330c3a;
    margin: 0;
    font-weight: 500;
  }
  .btn {
    background: #000000;
    border-radius: 6px;

    &:hover {
      background: #000000;
    }
  }
  .course-info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media (max-width: 579px) {
      display: block;
    }
  }

  .course-info-list {
    margin: 0 -14px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    letter-spacing: 0.15px;
    color: #6b6c8d;

    li {
      padding: 0 14px;
      margin: 0 0 15px;
      position: relative;

      &:first-child {
        &:before {
          display: none;
        }
      }

      &:before {
        position: absolute;
        left: -2px;
        top: 6px;
        content: '';
        width: 4px;
        height: 4px;
        background: #330c3a;
        border-radius: 100%;
      }

      strong {
        color: #330c3a;
        font-weight: 500;
      }
    }
  }

  .btn-expand {
    display: inline-block;
    vertical-align: top;
    background: #f7f9fa;
    color: #7d6481;
    font-size: 13px;
    line-height: 16px;
    min-width: 115px;
    padding: 8px 30px 8px 10px;
    text-align: center;
    border-radius: 30px;
    transition: all 0.4s ease;
    margin: 0 0 15px;
    cursor: pointer;
    position: relative;

    &.active {
      &:after {
        transform: rotate(180deg);
      }
    }

    &:hover {
      background: #edeef1;
    }

    &:after {
      position: absolute;
      right: 15px;
      top: 13px;
      content: '';
      border-style: solid;
      border-width: 6px 4px 0 4px;
      border-color: #7d6481 transparent transparent transparent;
      transition: all 0.4s ease;
    }
  }

  p {
    margin: 0 0 10px;
  }

  .accordion-wrap {
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    overflow: hidden;
  }

  .rc-according {
    &:last-child {
      .rc-header {
        border-bottom: none;
      }

      .ReactCollapse--content {
        border-top: 1px solid #e4e4e4;
        border-bottom: none;
      }
    }

    .content {
      padding: 21px 23px;

      @media (max-width: 579px) {
        padding: 15px;
      }
    }

    .ReactCollapse--content {
      border-bottom: 1px solid #e4e4e4;
    }

    .indicator {
      right: auto;
      left: 20px;
      color: rgba(76, 42, 83, 0.68);
      font-size: 28px;
      top: 15px;

      @media (max-width: 579px) {
        left: 5px;
        top: 5px;
      }
    }

    .rc-header {
      background: #fafbfd;
      padding: 18px 23px 18px 73px;
      cursor: pointer;

      @media (max-width: 579px) {
        padding: 10px 15px 10px 40px;
      }

      h5 {
        margin: 0;
      }

      .title {
        width: 100%;
        padding: 0;
        display: block;
      }
    }

    .title-text {
      font-size: 18px;
      line-height: 22px;
      color: #330c3a;
      font-weight: 500;
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;

      @media (max-width: 579px) {
        font-size: 15px;
        line-height: 18px;
      }
    }

    .title-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .lecture-info {
      margin: 0 -15px;
      padding: 3px 0 0;
      list-style: none;
      font-size: 15px;
      line-height: 18px;
      color: #000;
      display: flex;

      @media (max-width: 579px) {
        font-size: 12px;
        line-height: 15px;
        margin: 0 -8px;
      }

      li {
        padding: 0 15px;
        position: relative;

        @media (max-width: 579px) {
          padding: 0 8px;
        }

        &:first-child {
          &:before {
            display: none;
          }
        }

        &:before {
          position: absolute;
          left: -2px;
          top: 50%;
          transform: translate(0, -50%);
          width: 4px;
          height: 4px;
          border-radius: 100%;
          background: #000;
          content: '';

          @media (max-width: 579px) {
            width: 2px;
            height: 2px;
            left: -1px;
          }
        }
      }
    }
  }

  .card-section {
    .rc-card-header {
      display: none;
    }
  }

  .course-detail-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 18px;
      padding: 0 0 0 40px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: 579px) {
        padding: 0 0 0 30px;
      }

      &:last-child {
        margin: 0 0 5px;
      }
    }

    .course_section_item_heading {
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;
    }

    .img {
      position: absolute;
      left: 0;
      top: 0;
      color: rgb(37, 91, 135);
    }

    .more-info {
      display: flex;
      align-items: center;
      color: #7d6481;
      padding: 0 0 0 10px;
    }

    .course_section_item_mode {
      font-size: 12px;
      line-height: 15px;
      cursor: pointer;

      &:hover {
        color: #255b87;
      }
    }

    .course_section_item_duration {
      font-weight: 500;
      color: #330c3b;
      padding: 0 0 0 15px;
      min-width: 60px;
      text-align: right;
    }
  }
  &.audio .rc-vidoe-player {
    padding-top: 14%;
  }
  .modal-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .modal-body {
    max-height: calc(100vh - 120px);
    overflow-y: auto;
  }
  .rpv-core__page-layer {
    margin: 0 auto;
  }
  #pdf-download {
    display: none;
  }
  .msdoc-iframe {
    width: 300px;
    height: 200px;
  }
  .modal-body {
    img {
      width: 100%;
    }
  }
`;
