import { getCounterLabel } from 'api/Utils';
import Loading from 'components/loader';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useAuth from 'hooks/useAuth';
import SinglePanelLayout from 'layout/SinglePanelLayout';
import AffliatedPage from 'pages/account/affiliate-program';
import ThemeLibrary from 'pages/theme-library';
import ThemePreview from 'pages/theme-library/preview';
import React, { ReactElement, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { headerCount } from 'store/reducer/counter';
import { getSupportTicketCount } from 'store/reducer/support';
import RcRoute from './components/RcRoute';

const ProfileEditorRoutes = React.lazy(() => import('./profileEditorRoutes'));
const ManagerRoutes = React.lazy(() => import('./managerRoutes'));
const OnboardingRoutes = React.lazy(() => import('./onBoardingRoutes'));

const AccountSetting = React.lazy(() => import('pages/account/account-info'));
const Billing = React.lazy(() => import('pages/account/billing'));
const Payments = React.lazy(() => import('pages/account/payments/index'));
const Withdraw = React.lazy(() => import('pages/account/payments/withdraw'));
const SocialAccounts = React.lazy(
  () => import('pages/account/social-accounts'),
);
const Support = React.lazy(() => import('pages/account/support'));

const PurchaseModule = React.lazy(() => import('pages/purchases'));

const ContestThisOrder = React.lazy(
  () => import('pages/review/contest-this-order'),
);
const RateYourOrder = React.lazy(() => import('pages/review/rate-your-order'));
const ContestThisOrderInfo = React.lazy(
  () => import('pages/review/contest-this-order-info'),
);
const MyPuchase = React.lazy(() => import('pages/my-purchases'));

export const RoutesArray = [
  '/review/contest-this-order',
  '/review/contest-this-order-info',
  '/review/rate-your-order',
  '/schedule-messaging',
  '/my-subscriptions',
  '/my-purchases',
  '/support',
  '/theme-library',
  '/theme-library/preview',
  '/account',
  '/account/social-accounts',
  '/account/billing',
  '/account/inner-circle',
  '/account/payments/withdraw',
  '/account/payments',
  '/my-profile',
  '/onboarding',
  '/onboarding/profile-photo',
  '/onboarding/theme-selection',
  '/onboarding/interests-setup',
  '/onboarding/set-password',
  '/my-profile',
  '/my-profile/sliders',
  '/my-profile/profile',
  '/my-profile/my-sales',
  '/my-profile/members-content',
  '/my-profile/members-content/rules',
  '/my-profile/members-content/rules/create',
  '/my-profile/members-content/rules/:ruleId',
  '/my-profile/subscriber',
  '/my-profile/member-levels',
  '/my-profile/themes-listing',
  '/my-profile/services',
  '/my-profile/theme-editor',
  '/my-profile/analytics',
  '/my-profile/link/subType/:section',
  '/my-profile/link/:id/subType/:section',
  '/my-profile/link/:id',
  '/my-profile/pop-list',
  '/my-profile/link',
  '/managed-accounts',
  '/managed-accounts/:id',
  '/managed-accounts/:id/membership-levels',
  '/managed-accounts/:id/subscribers',
  '/managed-accounts/:id/subscribers/:subscriberId',
  '/managed-accounts/:id/rules',
  '/managed-accounts/:id/rules/create',
  '/managed-accounts/:id/rules/:ruleId',
  '/managed-accounts/:id/schedule',
];
export default function Routes(): ReactElement {
  const dispatch = useAppDispatch();
  const { loggedIn } = useAuth();

  useEffect(() => {
    if (loggedIn) {
      dispatch(getSupportTicketCount());
      getCounterLabel()
        .then((counter) => {
          dispatch(headerCount(counter));
        })
        .catch(console.error);
    }
  }, [dispatch, loggedIn]);

  return (
    <React.Suspense fallback={<Loading loading={true} />}>
      <Switch>
        <RcRoute path="/review" privateFC>
          <Switch>
            <RcRoute path="/review/contest-this-order" exact>
              <SinglePanelLayout>
                <ContestThisOrder />
              </SinglePanelLayout>
            </RcRoute>
            <RcRoute path="/review/contest-this-order-info" exact>
              <SinglePanelLayout>
                <ContestThisOrderInfo />
              </SinglePanelLayout>
            </RcRoute>
            <RcRoute path="/review/rate-your-order" exact>
              <SinglePanelLayout>
                <RateYourOrder />
              </SinglePanelLayout>
            </RcRoute>
          </Switch>
        </RcRoute>
        <RcRoute path="/my-subscriptions" exact privateFC>
          <PurchaseModule />
        </RcRoute>
        <RcRoute path="/my-purchases" exact privateFC>
          <MyPuchase />
        </RcRoute>
        <RcRoute path="/support" exact privateFC>
          <Support />
        </RcRoute>
        <RcRoute path="/theme-library" exact privateFC>
          <ThemeLibrary />
        </RcRoute>
        <RcRoute path="/theme-library/preview" exact privateFC>
          <ThemePreview />
        </RcRoute>
        <RcRoute path="/account" privateFC>
          <Switch>
            <Route path="/">
              <SinglePanelLayout>
                <Switch>
                  <Route path="/account" exact>
                    <AccountSetting />
                  </Route>
                  <Route path="/account/social-accounts" exact>
                    <SocialAccounts />
                  </Route>
                  <Route path="/account/billing" exact>
                    <Billing />
                  </Route>
                  <Route path="/account/inner-circle" exact>
                    <AffliatedPage />
                  </Route>
                  <Route path="/account/payments/withdraw" exact>
                    <Withdraw />
                  </Route>
                  <Route path="/account/payments" exact>
                    <Payments />
                  </Route>
                </Switch>
              </SinglePanelLayout>
            </Route>
          </Switch>
        </RcRoute>
        <RcRoute
          path="/onboarding"
          privateFC
          layoutProps={{ showHeader: false, showHeaderMenu: false }}
        >
          <OnboardingRoutes />
        </RcRoute>
        <RcRoute path="/my-profile" privateFC>
          <ProfileEditorRoutes />
        </RcRoute>
        <RcRoute path="/managed-accounts" privateFC>
          <ManagerRoutes />
        </RcRoute>
      </Switch>
    </React.Suspense>
  );
}
