import { getPostTemplates } from 'api/PostTemplate';
import TemplateModal from 'components/TemplateModal';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useCallback, useEffect, useState } from 'react';
import { editedPostTemplate } from 'store/reducer/salesState';
import { IPost } from 'types/Sales';

interface IPostTemplateModalWrapper {
  className?: string;
  managedAccountId?: string;
  isOpen: boolean;
  onClose: () => void;
}
const PostTemplateModalWrapper: React.FC<IPostTemplateModalWrapper> = (
  props,
) => {
  const { className, isOpen, onClose, managedAccountId } = props;
  const dispatch = useAppDispatch();
  const selectedPost = useAppSelector(
    (state) => state.mysales.selectedPostTemplate,
  );
  const [selectedTemplate, setSelectedTemplate] = useState<IPost | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [postTemplate, setPostTemplate] = useState<any>({
    items: [],
    totalCount: 0,
  });
  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      getPostTemplates({ limit: 50, sellerId: managedAccountId })
        .then((res) => {
          setPostTemplate({
            items: res?.items,
            totalCount: res?.totalCount,
          });
        })
        .catch(console.log)
        .finally(() => setIsLoading(false));
      return () => {
        setPostTemplate({
          items: [],
          totalCount: 0,
        });
      };
    }
  }, [isOpen]);
  const loadMore = useCallback(() => {
    setIsLoading(true);
    getPostTemplates({
      skip: postTemplate?.items?.length,
      sellerId: managedAccountId,
    })
      .then((res) => {
        setPostTemplate((val: any) => {
          return {
            items: [...val.items, ...res?.items],
            totalCount: res?.totalCount,
          };
        });
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  }, [postTemplate?.items?.length, selectedPost?._id]);
  const oK = () => {
    if (selectedTemplate) {
      dispatch(
        editedPostTemplate({
          ...(selectedTemplate as IPost),
          template_id: selectedTemplate?._id,
        }),
      );
      setSelectedTemplate(null);
    }
    onClose();
  };
  return (
    <TemplateModal
      className={className}
      onClick={(item: any) => {
        if (item?._id === selectedTemplate?._id) {
          setSelectedTemplate(null);
          return;
        }
        setSelectedTemplate(item as IPost);
      }}
      checked={selectedTemplate?._id || selectedPost?._id || ''}
      title="POST TEMPLATES"
      templates={postTemplate?.items as IPost[]}
      mediaKey="media"
      isLoading={isLoading}
      oK={oK}
      loadMore={loadMore}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default PostTemplateModalWrapper;
