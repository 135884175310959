//// Example
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface HeaderState {
  title: string;
  backUrl: string;
  shareModal?: boolean;
}

const initialState: HeaderState = {
  title: '',
  backUrl: '#',
  shareModal: false,
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeaderTitle: (state, { payload }) => {
      state.title = payload;
    },
    setHeaderUrl: (state, { payload }) => {
      state.backUrl = payload;
    },
    setHeaderProps: (
      state,
      action: PayloadAction<Pick<HeaderState, 'title' | 'backUrl'>>,
    ) => {
      state.title = action.payload.title;
      state.backUrl = action.payload.backUrl;
    },
    toggleModal: (state) => {
      state.shareModal = !state.shareModal;
    },
  },
  extraReducers: (builder) => {},
});

const { actions, reducer } = headerSlice;

export const { setHeaderTitle, setHeaderProps, setHeaderUrl, toggleModal } =
  actions;

export const selectCount = (state: RootState) => state.header;

export default reducer;
