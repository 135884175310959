import { createComment, togglePostLike } from 'api/sales';
import { ArrowBack, Spinner } from 'assets/svgs';
import axios, { AxiosRequestConfig } from 'axios';
import { WhatsAppLoader as WLoader } from 'components/loader';
import Button from 'components/NButton';
import { Card } from 'components/PrivatePageComponents';
import CardAddComments from 'components/PrivatePageComponents/CardAddComments';
import CardListComments from 'components/PrivatePageComponents/CardListComments';
import CardPublicFooter from 'components/PrivatePageComponents/CardPublicFooter';
import PostBannerImages from 'components/PrivatePageComponents/PostBannerImages';
import PostCaption from 'components/PrivatePageComponents/PostCaption';
import Scrollbar from 'components/Scrollbar';
import { toast } from 'components/toaster';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useAuth from 'hooks/useAuth';
import useControllTwopanelLayoutView from 'hooks/useControllTwopanelLayoutView';
import useOpenClose from 'hooks/useOpenClose';
import MemberWelcomePage from 'pages/MemberWelcomePage';
import EditPostModal from 'pages/my-profile/Modal/EditPostModal';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isDesktop } from 'react-device-detect';
import {
  addComment,
  deletePost,
  getPostComments,
  getUserMemberships,
  myPostsList,
  reInitializeState,
  setSelectedScheduledPost,
  toggleCommentLike,
  toggleEditPost,
  updatelikes,
} from 'store/reducer/salesState';
import styled from 'styled-components';
import swal from 'sweetalert';
import { IPost, IPostLikesType } from 'types/Sales';
import { getImageURL, getSortbyParam } from 'util/index';
import DeleteModal from './components/Model/DeleteModal';
import MembershipBuy from './components/Model/MembershipBuy';
import StatisticsModal from './components/Model/StatisticsModal';

dayjs.extend(utc);
dayjs.extend(relativeTime);
const WhatsAppLoader = styled(WLoader)`
  margin-top: 1rem;
`;
const variants = {
  initial: (direction: string) => {
    return direction === 'left'
      ? {
          left: '-100vw',
          opacity: 0,
          position: 'absolute',
        }
      : {
          left: '100vw',
          opacity: 0,
          position: 'absolute',
        };
  },
  animate: {
    left: '0',
    opacity: 1,
    // position: 'relative',
  },
  exit: (direction: string) => {
    return direction === 'left'
      ? {
          left: '-100vw',
          opacity: 0,
          position: 'absolute',
        }
      : {
          left: '100vw',
          opacity: 0,
          position: 'absolute',
        };
  },
};

const BackArrow = styled.div`
  /* position: absolute; */
  /* left: -80%; */
  cursor: pointer;
  transition: all 0.3ms ease;
  &:hover {
    color: #255b87;
  }
`;
type ModelOptions = 'delete' | 'edit' | '';
const PAGE_LIMIT = 10;
function MyWall({
  className,
  managedAccountId,
  createPost = true,
  isPreview = false,
  backButton = false,
  showAttachmentViwe = true,
  showRightView = true,
  managedUser,
}: {
  managedAccountId?: string;
  managedUser?: any;
  className?: string;
  createPost?: boolean;
  isPreview?: boolean;
  backButton?: boolean;
  showAttachmentViwe?: boolean;
  showRightView?: boolean;
}): ReactElement {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const scrollbarRef = useRef<any>(null);
  const { showLeftView } = useControllTwopanelLayoutView();
  const [commentOpen, setCommentOpen] = useState<string | undefined>(undefined);
  const [open, onToggle] = useOpenClose();
  const showEditPost = useAppSelector((state) => state.mysales.showEditPost);
  const cancelToken = useRef<any>();
  const [activeView, setActiveView] = useState<'left' | 'right'>('left');
  const [openModel, setOpenModel] = useState<ModelOptions>('');
  const myPostList = useAppSelector((state) => state.mysales.myposts);
  const isMyPostsFetching = useAppSelector(
    (state) => state.mysales.isMyPostsFetching,
  );
  const [selected, setSelected] = useState<any>();
  const onConformToDelete = (val: ModelOptions, obj: Record<string, any>) => {
    setSelected(obj);
    setOpenModel(val);
  };
  useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    const paramsList: any = {
      skip: 0 * PAGE_LIMIT,
      limit: PAGE_LIMIT,
      sort: getSortbyParam('createdAt'),
      order: 'desc',
    };
    if (
      isDesktop &&
      user.links?.find(
        (link: any) => link.popLinksId?.popType === 'chat-subscription',
      )
    ) {
      dispatch(
        getUserMemberships({
          customError: { statusCodes: [404] },
          params: { sellerId: managedAccountId },
          options: {
            cancelToken: cancelToken.current.token,
          },
        }),
      ).catch((e) => console.log(e));
    }
    if (!managedAccountId && !isDesktop) {
      dispatch(reInitializeState());
      getPaginatedPosts(paramsList, (response) => {}, {
        cancelToken: cancelToken.current.token,
      }).catch((e) => console.log(e));
    }
    return () => {
      cancelToken.current.cancel('Operation canceled due to Unmount.');
    };
  }, []);
  const onSort = (key: string) => {
    getPaginatedPosts(
      {
        skip: 0,
        limit: PAGE_LIMIT,
        sort: getSortbyParam(key),
        order: 'desc',
      },
      (response: any) => {},
    ).catch((e) => console.log(e));
  };
  const onChangeLike = async (
    id: string,
    isAdd: boolean,
    postlikes: IPostLikesType,
  ) => {
    const pLikes = { ...postlikes };
    dispatch(
      updatelikes({
        postLikes: pLikes,
        postId: id,
      }),
    );
    return await togglePostLike(id, { sellerId: managedAccountId })
      .then((l) => {
        return l;
      })
      .catch(console.log);
  };
  const getPaginatedPosts = useCallback(
    async (
      params: Record<string, any> = {},
      callback?: (...args: any) => void,
      options?: AxiosRequestConfig,
    ) => {
      return dispatch(
        myPostsList({
          params,
          callback,
          customError: { statusCodes: [404] },
          options,
        }),
      ).catch((e) => console.log(e));
    },
    [managedAccountId],
  );
  const current = dayjs();
  const handleScroll = async () => {
    if (
      scrollbarRef.current &&
      myPostList?.items?.length < myPostList?.totalCount
    ) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollbarRef.current.view;
      const pad = 1; // 100px of the bottom
      // t will be greater than 1 if we are about to reach the bottom
      const t = (scrollTop + pad) / (scrollHeight - clientHeight);
      if (t > 1 && !isMyPostsFetching) {
        getPaginatedPosts(
          {
            skip: myPostList?.items?.length,
            limit: PAGE_LIMIT,
            sort: getSortbyParam('createdAt'),
            order: 'desc',
            sellerId: managedAccountId,
          },
          (response: any) => {},
        ).catch((e) => console.log(e));
      }
    }
  };
  const toggleLike = useCallback(async (id: string, postId?: string) => {
    return dispatch(
      toggleCommentLike({
        id,
        postId,
        callback: (data) => {},
        params: { sellerId: managedAccountId },
      }),
    ).catch(console.log);
  }, []);
  const handleCommentspagination = useCallback(
    async (
      id: string,
      params: Record<string, any> = {},
      append: boolean = false,
    ) => {
      return dispatch(
        getPostComments({
          id: id,
          params: params,
          append,
          callback: (data) => {},
        }),
      ).catch(console.log);
    },
    [],
  );
  const handleAddComemnt = async (id: string, values: Record<string, any>) => {
    return await createComment(id, values, { sellerId: managedAccountId })
      .then((v: any) => {
        setCommentOpen(undefined);
        dispatch(addComment({ comment: v }));
        return v;
      })
      .catch((err) => swal('', err.message, 'error'));
  };
  const setOpenedComemnt = useCallback(
    (id: string) => {
      if (commentOpen === id) {
        setCommentOpen(undefined);
      } else setCommentOpen(id);
    },
    [commentOpen],
  );

  const getComponent = () => {
    return (
      <React.Fragment>
        <div className="posts-wrap">
          {myPostList?.items?.map((itm: IPost) => {
            const item = { ...itm };

            let timestamp =
              item?.postType === 'scheduled'
                ? dayjs(item.publishAt)
                : item.createdAt
                ? dayjs(item.createdAt)
                : '';
            if (timestamp) {
              timestamp = (timestamp as dayjs.Dayjs).isSame(current, 'date')
                ? (timestamp as dayjs.Dayjs).fromNow()
                : (timestamp as dayjs.Dayjs).format('MM/DD/YYYY hh:mm A');
            }

            const ispostText = !!item.postText;
            const isMedia = !!item.media?.length;
            const { url: profileImage, fallbackUrl: profileFallback } =
              getImageURL(managedUser?.profileImage ?? user.profileImage, {
                defaultUrl: '/assets/images/default-profile-img.svg',

                onlyMobile: true,
                imgix: { all: 'w=200&h=200' },
              });

            return (
              <Card cardClass="mb-20 post-card" key={item?._id}>
                <Card.Header
                  ontitleClick={() => {
                    window.open(
                      `/${managedUser?.username ?? user?.username}`,
                      '_blank',
                    );
                  }}
                  img={
                    managedUser?.profileImage || user.profileImage
                      ? profileImage
                      : '/assets/images/default-profile-img.svg'
                  }
                  fallbackUrl={
                    managedUser?.profileImage
                      ? managedUser?.profileImage
                      : user.profileImage || profileFallback
                  }
                  title={`${managedUser?.firstName ?? user.firstName} ${
                    managedUser?.lastName ?? user.lastName
                  }`}
                  subTitle={timestamp}
                  showRightView={showRightView}
                  className="card-header_name"
                  Rightside={() => (
                    <ul className="actions">
                      <li>
                        <Button
                          type="text"
                          onClick={() => {
                            onConformToDelete('delete', item || {});
                          }}
                        >
                          Delete post
                        </Button>
                      </li>
                      <li>
                        <Button
                          type="text"
                          onClick={() => {
                            dispatch(setSelectedScheduledPost(item));
                            dispatch(toggleEditPost());
                            // onConformToDelete('edit', item || {});
                          }}
                        >
                          Edit
                        </Button>
                      </li>
                    </ul>
                  )}
                />
                {(ispostText || isMedia) && (
                  <Card.Body
                    caption={
                      ispostText ? <PostCaption postText={item.postText} /> : ''
                    }
                  >
                    {isMedia && (
                      <PostBannerImages
                        media={item.media?.map((med: any) => ({
                          ...med,
                          imageURL: `${med?.imageURL}?${
                            med?.updatedAt ?? itm?.updatedAt
                          }`,
                          url: `${med?.url}?${
                            med?.updatedAt ?? itm?.updatedAt
                          }`,
                          path: `${med?.path}?${
                            med?.updatedAt ?? itm?.updatedAt
                          }`,
                        }))}
                      />
                    )}
                  </Card.Body>
                )}
                <Card.Footer>
                  <CardPublicFooter
                    user={managedUser ?? user}
                    postliked={item.liked}
                    postLikes={item.postLikes}
                    onChangeLike={onChangeLike}
                    id={item?._id}
                    post={item}
                    setCommentOpen={setOpenedComemnt}
                    comments={item?.comments}
                    isSeller={true}
                  />
                </Card.Footer>
                {commentOpen && commentOpen === item?._id && (
                  <CardAddComments
                    id={item?._id}
                    onSubmit={handleAddComemnt}
                    user={managedUser ?? user}
                  />
                )}
                {!!item?.comments?.totalCount && (
                  <CardListComments
                    managedAccountId={managedAccountId}
                    toggleCommentLike={toggleLike}
                    getPostComments={handleCommentspagination}
                    item={item}
                    managedUser={managedUser}
                    user={user}
                    postComment={commentOpen}
                    setOpenedComemnt={setOpenedComemnt}
                  />
                )}
              </Card>
            );
          })}
        </div>
        <StatisticsModal
          title="Edit Post"
          isOpen={openModel === 'edit'}
          onClose={() => setOpenModel('')}
        />
        <DeleteModal
          isOpen={openModel === 'delete'}
          onClose={() => setOpenModel('')}
          onClick={() => {
            setOpenModel('');
            dispatch(deletePost({ ...selected, sellerId: managedAccountId }))
              .then((e: any) => {
                toast.success('The post has been deleted successfully');
              })
              .catch(console.log);
          }}
        />

        {isMyPostsFetching && !!myPostList.items?.length && (
          <WhatsAppLoader>
            <Spinner width="28px" height="28px" color="#255b87" />
          </WhatsAppLoader>
        )}
      </React.Fragment>
    );
  };
  return (
    <div className={`${className} mt-20`}>
      <EditPostModal
        managedAccountId={managedAccountId}
        isOpen={showEditPost as boolean}
        onClose={() => {}}
      />
      <MembershipBuy onClose={onToggle} isOpen={open} />
      {!isPreview ? (
        <AnimatePresence initial={false}>
          {activeView === 'left' && (
            <motion.div
              key="left"
              custom={'left'}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={variants as any}
              style={{ width: '100%', position: 'relative' }}
              transition={{ mass: 0.2, duration: 0.6 }}
              className="left-col"
            >
              {backButton && !isDesktop && (
                <BackArrow
                  className="mb-10 back-arrow"
                  onClick={() => {
                    showLeftView();
                  }}
                >
                  <ArrowBack /> <strong className="ml-5">Back</strong>
                </BackArrow>
              )}

              <>
                {isMyPostsFetching && !myPostList.items?.length && (
                  <WhatsAppLoader>
                    <Spinner width="28px" height="28px" color="#255b87" />
                  </WhatsAppLoader>
                )}
                <div className="post-scrollerparant">
                  <Scrollbar
                    onScrollStop={handleScroll}
                    scrollRef={scrollbarRef}
                    className="post-scroller"
                  >
                    {!isMyPostsFetching && !myPostList.items?.length && (
                      <div className="p-10 empty-data">
                        <MemberWelcomePage />
                      </div>
                    )}
                    {getComponent()}
                  </Scrollbar>
                </div>
              </>
            </motion.div>
          )}
        </AnimatePresence>
      ) : (
        <React.Fragment>
          {isMyPostsFetching && !myPostList.items?.length && (
            <WhatsAppLoader>
              <Spinner width="28px" height="28px" color="#255b87" />
            </WhatsAppLoader>
          )}

          {getComponent()}
        </React.Fragment>
      )}
    </div>
  );
}

export default styled(MyWall)`
  position: relative;
  .back-arrow {
    padding: 15px 15px 0;
  }

  .empty-data {
    flex-grow: 1;
    flex-basis: 0;
  }
  .card-header_name {
    .title {
      text-transform: capitalize;
    }
  }
  .post-scrollerparant {
    height: calc(100vh - 258px);
    margin: 0 -15px;

    @media (max-width: 767px) {
      height: calc(100vh - 240px);
    }

    .posts-wrap {
      padding: 0 15px;
    }
  }
  .post-scroller {
    > div {
      z-index: 2;
    }
  }
  .delete-modal {
    text-align: center;
  }
  .card-header_name .title {
    cursor: pointer;
  }
`;
