import { createTheme, updateTheme } from 'api/theme';
import { update } from 'api/User';
import { Copy, LogoUpdated } from 'assets/svgs';
import classNames from 'classnames';
import ApplyThemeModal from 'components/ApplyThemeModal';
import NewButton from 'components/NButton';
import HeaderWithOption from 'components/partials/components/profileBack';
import { toast } from 'components/toaster';
import { ServiceType, Slider } from 'enums';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useAuth from 'hooks/useAuth';
import useControllTwopanelLayoutView from 'hooks/useControllTwopanelLayoutView';
import useCopyToClipBoard from 'hooks/useCopyToClipBoard';
import useDispatch from 'hooks/useDispatch';
import useOpenClose from 'hooks/useOpenClose';
import useQuery from 'hooks/useQuery';
import useSelector from 'hooks/useSelector';
import { stringify } from 'querystring';
import React, { useEffect, useRef, useState } from 'react';
import { UnmountClosed } from 'react-collapse';
import { isDesktop, isMobile } from 'react-device-detect';
import {
  Link,
  NavLink,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { SET_APPLY_THEME_MODAL } from 'reducer/appStates';
import { SET_CURRENT_THEME, SET_THEMES } from 'reducer/theme';
import { toggleModal } from 'store/reducer/headerState';
import { removeSelectedPost } from 'store/reducer/salesState';
import { setSelectedView } from 'store/reducer/scheduledMessaging';
import styled from 'styled-components';
import swal from 'sweetalert';
import { IAlert, PriceVariant } from 'types';
import ITheme from 'types/Theme';
import { parseQuery } from 'util/index';
import AvatarDropDown from './components/AvatarDropDown';
import QRmodel from './components/model/QRmodel';

const noSubHeader = [
  '/my-sales',
  '/my-purchases',
  '/my-subscriptions',
  '/support',
  '/theme-library',
  '/theme-library/preview',
  '/schedule-messaging',
  '/managed-accounts',
];

export const Header = styled.header<any>`
  width: 100%;
  padding: 15px 3px;
  background: #000;
  position: relative;
  margin: 0 0 51px;
  z-index: 4;
  border-bottom: 1px solid #d5dade;

  .counter {
    display: inline-block;
    vertical-align: middle;
    min-width: 21px;
    text-align: center;
    color: #fff;
    background: #7ccf78;
    border-radius: 4px;
    font-size: 14px;
    line-height: 17px;
    padding: 2px 3px;
    margin: 0 0 0 5px;
    display: none;

    @media (max-width: 1023px) {
      font-size: 10px;
      line-height: 12px;
      padding: 2px;
    }

    &.bg-blue {
      background: #255b87;
    }
  }
  .counter.show {
    display: inline-block;
  }
  .dropdown-menu {
    right: 0px;
    left: auto;
  }

  @media (max-width: 767px) {
    padding: 15px 0;
    z-index: 9;
    margin: 0;
  }

  .logo {
    width: 143px;

    @media (max-width: 1199px) {
      width: 100px;
    }

    @media (max-width: 1023px) {
      width: 80px;
    }

    a {
      display: flex;
      align-items: center;
      font-size: 11px;
      line-height: 14px;
      font-weight: 400;
      color: #255b87;

      svg {
        width: 143px;
      }
    }

    .text-beta {
      margin: 2px 0 0 -2px;
    }
  }
`;

const HeaderLinkArea = styled.div`
  background: #fff;
  // border-top: 1px solid #d5dade;
  border-bottom: 1px solid #d5dade;
  padding: 9px 15px 8px;
  margin-bottom: 0;
  position: absolute;
  left: 599px;
  right: 0;
  top: calc(100% + 1px);

  &.mobile-menu {
    display: block;
    position: static;
  }

  @media (max-width: 1023px) {
    display: none;

    // left: 0;
    // top: auto;
    // bottom: -52px;
    // z-index: 9;

    // &.is-active {
    //   display: block;
    // }
  }

  @media (max-width: 767px) {
    bottom: 0;
  }
  .min-33 {
    min-height: 33px;
  }

  .left-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 5px 0 0;

    .button {
      min-width: 54px;
      margin: 0 15px 0 0;
    }
  }

  .header-link-area .btn-round {
    border-radius: 4px;
    font-size: 12px;
    line-height: 15px;
    min-width: 58px;
    padding: 4px 6px 3px;
  }
  .header-link-area__link-holder {
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    color: #303030;
    position: relative;
    padding: 0 0 0 30px;
    min-width: 0;
    flex-grow: 1;
    flex-basis: 0;
    text-align: left;
  }
  .header-link-area__link-holder .icon-star1 {
    position: absolute;
    left: 0;
    top: -2px;
    font-size: 20px;
  }
  .header-link-area__link {
    color: #255b87;
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 991px) {
    .header-link-area__link-holder {
      margin: 0 -10px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 15px;
    padding-bottom: 15px;
    .header-link-area__link-holder {
      font-size: 14px;
      margin: 0 -5px;
      padding: 0 0 0 20px;
    }
    .header-link-area__link-holder .icon-star1 {
      font-size: 14px;
      top: 1px;
    }
    .header-link-area .btn.btn-round {
      min-width: 58px;
      font-size: 12px;
      text-transform: capitalize;
      margin-right: -5px;
    }
  }
  .link_copied {
    background: #255b87 !important;
    color: white !important;
  }
`;

const RemoveSubHeader: React.FC = () => {
  useEffect(() => {
    const rootelem: any = document.getElementById('root');
    if (!rootelem?.classList.contains('isactive-sub_menu')) {
      rootelem.classList.add('isactive-sub_menu');
    }

    return () => {
      if (rootelem?.classList.contains('isactive-sub_menu')) {
        rootelem.classList.remove('isactive-sub_menu');
      }
    };
  }, []);

  return null;
};

function getPopCourseURL(qs: Record<string, any>, pathname: string) {
  const { type, mode, subType } = qs;
  if (subType === 'pop-course' && mode === 'add') {
    const { step, slider, ...rest } = qs;
    if (step === '1') {
      return {
        URL: `/my-profile/link?${stringify({ ...rest })}`,
        headerTitle: 'Lessons',
      };
    }
    if (step === '2') {
      const { step, lessonId, addId, ...rest } = qs;
      return {
        URL: `${pathname}?${stringify({ ...rest, step: 1 })}`,
        headerTitle: lessonId ? 'Edit Lesson' : 'Create Lesson',
      };
    }
    if (step) {
      return {
        URL: `/my-profile/link?${stringify({ ...rest })}`,
        headerTitle: 'Edit Course',
      };
    }
    return {
      URL: `/my-profile/pop-list?type=${type === 'service' ? 'services' : ''}`,
      headerTitle: 'Add a New Pop',
    };
  }
  if (subType === 'pop-course' && mode === 'edit') {
    const { step, slider, ...rest } = qs;
    const path = pathname.split('/subType/course')[0];
    if (slider) {
      return {
        URL: `/my-profile/link/${pathname.split('/')[3]}?${stringify({
          ...rest,
        })}`,
        headerTitle: 'Edit Course',
      };
    }
    if (step === '1') {
      return {
        URL: `${path}?${stringify({ ...rest })}`,
        headerTitle: 'Edit Lesson',
      };
    }
    if (step === '2') {
      const { step, lessonId, ...rest } = qs;
      return {
        URL: `${pathname}?${stringify({ ...rest, step: 1 })}`,
        headerTitle: 'Edit Lesson',
      };
    }
    if (step) {
      return {
        URL: `${path}?${stringify({ ...rest })}`,
        headerTitle: 'Edit Course',
      };
    }
    return {
      URL: `/my-profile/pop-list?type=${type === 'service' ? 'services' : ''}`,
      headerTitle: 'Add a New Pop',
    };
  }
  return { URL: `/my-profile`, headerTitle: 'Add a New Pop' };
}

const getPopURL = (
  qs: Record<string, any>,
  pathname: string,
  params: Record<string, any>,
) => {
  const { type, mode } = qs;
  if (mode === 'add') {
    const { step, ...rest } = qs;
    if (step === '1') {
      return {
        URL: `/my-profile/link?${stringify({ ...rest })}`,
        headerTitle: 'Collection',
      };
    }
    if (step) {
      return {
        URL: `/my-profile/link?${stringify({ ...rest })}`,
        headerTitle: 'Edit Collection',
      };
    }
    return {
      URL: `/my-profile/pop-list?type=${type === 'service' ? 'services' : ''}`,
      headerTitle: 'Add a New Pop',
    };
  }
  if (mode === 'edit') {
    const { step, slider, date, levelStep, variantId, view, ...rest } = qs;
    if (levelStep === '1') {
      return {
        URL: `${pathname}?${stringify({
          ...rest,
          step: 1,
          slider: slider,
        })}`,
        headerTitle: 'Edit Lesson',
      };
    }
    if (step === '1') {
      return {
        URL: `/my-profile/link/${pathname.split('/')[3]}?${stringify({
          ...rest,
        })}`,
        headerTitle: 'Edit Collection',
      };
    }
    if (step === '2') {
      return {
        URL: `${pathname}?${stringify({
          ...rest,
          slider: 'schedule',
          step: 1,
        })}`,
        headerTitle: 'Edit Lesson',
      };
    }
    return { URL: `/my-profile`, headerTitle: 'Add a New Pop' };
  }
  return { URL: `/my-profile`, headerTitle: 'Add a New Pop' };
};
function getUrl(
  qss: Record<string, any>,
  pathname: string,
  params: Record<string, any>,
) {
  const { subType } = qss;
  const { newPop, ...qs } = qss;
  if (subType === 'pop-course') {
    return getPopCourseURL(qs, pathname);
  }
  if (
    subType === 'shoutout' ||
    subType === 'payma' ||
    subType === 'chat-subscription' ||
    subType === 'poplive' ||
    subType === 'advertise' ||
    subType === 'additional-services' ||
    subType === 'digital-download'
  ) {
    return getPopURL(qs, pathname, params);
  }

  return { URL: `/my-profile`, headerTitle: 'Add a New Pop' };
}
const TwoPanelRightSideSubHeader: React.FC<{
  user: any;
  className?: string;
}> = ({ user, className }) => {
  const [isCopied, copy] = useCopyToClipBoard();
  const [isCopy, setIsCopy] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const param = useParams();
  const modalRef = useRef(false);
  const [isOpenModel, onOpenModel, onCloseModel] = useOpenClose();
  const { showLeftView } = useControllTwopanelLayoutView();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { themeId, systemThemeCreate: isSystemThemeCreate } = parseQuery(
    location.search,
  );
  const { previewPop } = useAppSelector((state) => state.course);
  const { shareModal } = useAppSelector((state) => state.header);
  const { user: loggedUser, setUser } = useAuth();
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const applyThemeModal = useSelector(
    (state) => state.appStates?.applyThemeModal,
  );
  const {
    current,
    allthemes: userThemes,
  }: { current: ITheme; allthemes: ITheme[] } = useSelector(
    (state) => state.theme,
  );
  const qs = useQuery();
  const { viewType } = qs;
  useEffect(() => {
    setShowButtons(true);
    return () => {
      showButtons && setShowButtons(true);
    };
  }, []);
  const applyTheme = async () => {
    setIsApplying(true);
    const { category, ...selectedTheme } = { ...current };
    const profileImage = selectedTheme?.profile?.profileImage;
    //Delete extra keys before sending
    delete selectedTheme?.profile?.profileImage;
    let res: any;
    if (selectedTheme?._id) {
      res = await updateTheme(selectedTheme._id, {
        ...selectedTheme,
        isActive: true,
        baseURL: `${window.location.host}/${loggedUser.username}`,
        categoryId: category?.value,
      }).catch(console.log);
    } else {
      res = await createTheme({
        ...selectedTheme,
        isSystemTheme:
          isSystemThemeCreate === 'true' &&
          !!loggedUser?.enableSystemThemeAccess,
        baseURL: `${window.location.host}/${loggedUser.username}`,
        isActive: true,
        categoryId: category?.value || '',
      });
    }
    dispatch({
      type: SET_THEMES,
      payload: userThemes.map((item) =>
        item._id === res?.theme?._id ? res.theme : { ...item, isActive: false },
      ),
    });
    let updatedUser = loggedUser;
    updatedUser = await update({ profileImage }).then(({ data }) => data);
    if (res?.theme) {
      setUser({ ...loggedUser, ...updatedUser, userThemeId: res.theme });
    }
    dispatch({
      type: SET_APPLY_THEME_MODAL,
      payload: {
        active: false,
        edit: false,
      },
    });
    if (applyThemeModal?.edit) {
      history.push(
        isSystemThemeCreate === 'true'
          ? '/theme-library'
          : '/my-profile/themes-listing',
      );
    }
    setIsApplying(false);
    showLeftView();
  };
  const poplink = previewPop?.popLinksId?.popName;
  let userProfileLink = user?.username || '';
  let publicProfileLink = user?.username || '';
  if (previewPop?._id) {
    if (poplink) userProfileLink += `/${poplink}`;
  }
  let link = `${window.location.host}/${userProfileLink}`.replace('www.', '');
  return (
    <>
      <QRmodel
        link={link}
        isOpen={shareModal || false}
        onClose={() => {
          appDispatch(toggleModal());
          onCloseModel();
        }}
        userProfileLink={publicProfileLink}
      />
      <HeaderLinkArea className={classNames('header-link-area ', className)}>
        {applyThemeModal?.active ? (
          <ApplyThemeModal
            selectedTheme={current}
            isThemeApplying={isApplying}
            compact
            edit={applyThemeModal?.edit}
            onRevertTheme={() => {
              dispatch({
                type: SET_CURRENT_THEME,
                payload: userThemes.find((theme) => theme.isActive),
              });
              dispatch({
                type: SET_APPLY_THEME_MODAL,
                payload: {
                  active: false,
                  edit: false,
                },
              });
              if (applyThemeModal?.edit) {
                history.push(
                  isSystemThemeCreate === 'true'
                    ? '/theme-library'
                    : '/my-profile/themes-listing',
                );
              }
              showLeftView();
            }}
            onApplyTheme={applyTheme}
          />
        ) : (
          <div className="d-flex justify-content-between align-items-center min-33">
            {!viewType && (
              <>
                <div className="left-area">
                  <NewButton
                    color="primary"
                    outline
                    size="small"
                    onClick={() => {
                      appDispatch(toggleModal());
                      onOpenModel();
                    }}
                    shape="round"
                  >
                    Share
                  </NewButton>

                  <div
                    className="header-link-area__link-holder"
                    id="my-public-profile-url"
                  >
                    <i className="icon-star1"></i>My Selfie Pop:{' '}
                    <Link
                      to={`/${userProfileLink}`}
                      className="header-link-area__link"
                      target="_blank"
                    >
                      {link}
                    </Link>
                  </div>
                </div>
                <NewButton
                  className={`${isCopied && isCopy ? 'link_copied' : 'color'}`}
                  outline
                  size="small"
                  icon={<Copy />}
                  onClick={() => {
                    setIsCopy(true);
                    copy(link);
                    toast.info('Copied the url');
                    setTimeout(() => {
                      setIsCopy(false);
                    }, 6000);
                  }}
                  shape="round"
                >
                  {isCopied && isCopy ? 'Copied' : 'Copy'}
                </NewButton>
              </>
            )}
          </div>
        )}
      </HeaderLinkArea>
    </>
  );
};

const SubNav: React.FC<{ items?: any[] }> = ({ items }) => {
  return (
    <div className="sub-items-holder">
      <ul className="navbar-sub-menu">
        {items?.map(
          ({ url, id, name, icon, isHidden, ...rest }, index: number) =>
            isHidden ? null : (
              <li key={index} id={id} className="navbar-sub-menu__item">
                <NavLink
                  to={url}
                  className="navbar-sub-menu__link"
                  activeClassName="is-active"
                  {...rest}
                >
                  {icon}
                  {name}
                </NavLink>
              </li>
            ),
        )}
      </ul>
    </div>
  );
};

const ProfileLinkHeader: React.FC<{ user: any }> = ({ user }) => {
  const [isCopied, copy] = useCopyToClipBoard();
  const [isCopy, setIsCopy] = useState(false);
  const appDispatch = useAppDispatch();
  const [isOpenModel, onOpenModel, onCloseModel] = useOpenClose();
  const shareModal = useAppSelector((state) => state.header.shareModal);
  const link = `${window.location.host}/${user?.username}`.replace('www.', '');
  let publicProfileLink = user.username;
  return (
    <>
      <QRmodel
        isOpen={shareModal || false}
        onClose={() => {
          appDispatch(toggleModal());
          onCloseModel();
        }}
        link={link}
        userProfileLink={publicProfileLink}
      />
      <HeaderLinkArea className="mobile-menu d-md-none">
        <div className="d-flex justify-content-between align-items-center">
          <div className="left-area">
            <NewButton
              onClick={() => {
                appDispatch(toggleModal());
                onOpenModel();
              }}
              color="primary"
              outline
              size="small"
              shape="round"
            >
              Share
            </NewButton>
            <div
              className="header-link-area__link-holder"
              id="my-public-profile-url"
            >
              <i className="icon-star1"></i>My Selfie Pop
              <br />
              <Link
                to={`/${user.username}`}
                className="header-link-area__link"
                target="_blank"
              >
                {link}
              </Link>
            </div>
          </div>
          <NewButton
            className={`${isCopied && isCopy ? 'link_copied' : 'color'}`}
            outline
            size="x-small"
            onClick={() => {
              setIsCopy(true);
              copy(link);
              toast.info('Copied the url');
              setTimeout(() => {
                setIsCopy(false);
              }, 6000);
            }}
            shape="round"
          >
            {isCopied && isCopy ? 'Copied' : 'copy'}
          </NewButton>
        </div>
      </HeaderLinkArea>
    </>
  );
};

export default function Index(props: any) {
  const { showHeaderMenu = true } = props;
  const [show, setshow] = useState<boolean>(false);
  const { activeView, showLeftView } = useControllTwopanelLayoutView();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const leftViewActive: boolean = 'left' === activeView;
  const { Logout, user } = useAuth();
  const params = useParams();
  const location = useLocation();
  const { pathname } = location;

  const { type } = parseQuery(history.location.search);
  const { totalTicketsComments = 0 } = useAppSelector((state) => state.support);
  const { subscription } = useAppSelector((state) => state.counter);
  const title = useAppSelector((state) => state.header.title);
  const backUrl = useAppSelector((state) => state.header.backUrl);
  const managedUser = useAppSelector((state) => state.managedUsers.item);

  const courses = user?.links?.filter(
    (link: any) => link.popLinksId?.popType === ServiceType.POP_COURSE,
  );
  // const {
  //   type,
  //   themeId,
  //   subType,
  //   courseStep,
  //   lessonStep,
  //   subEdit = '',
  //   mode,
  //   back = '',
  //   ...rest
  // } = useQuery();
  const qs = useQuery();
  const { themeId, subType, slider, section, step, levelStep, variantId } = qs;
  const handleLogout = () => {
    swal({
      title: 'Log Out',
      text: 'Are you sure you want to log out?',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Logout'],
    } as IAlert).then(async (willLogout) => {
      if (willLogout) {
        Logout();
      }
    });
  };

  useEffect(() => {
    window.onresize = () => {
      if (window.screen.width < 767) {
        if (!show) return;
        return setshow(true);
      }
      return setshow(false);
    };
  }, []);
  const { headerTitle, URL } = getUrl(qs, pathname, params);
  const getHeaderTitle = (type: string, subType: string, slider?: string) => {
    if (!slider && type === 'service') {
      switch (subType) {
        case ServiceType.ADDITIONAL_SERVICES:
          return 'Additional Services';
        case ServiceType.CHAT_SUBSCRIPTION:
          return 'Chat subscription';
        case ServiceType.ADVERTISE:
          return 'Promotional Shoutouts';
        case ServiceType.DIGITAL_DOWNLOADS:
          return 'Digital downloads';
        case ServiceType.PAYMA:
          return 'Paid Q&A';
        case ServiceType.POPLIVE:
          return 'Poplive';
        case ServiceType.POP_COURSE:
          return 'Course';
        case ServiceType.SHOUTOUT:
          return 'Custom Video';
      }
    } else {
      switch (slider) {
        case Slider.Add_Post:
          return 'Add Post';
        case Slider.Coupon:
          return 'Coupon';
        case Slider.Price_Variation:
          return 'Price Variation';
        case Slider.Promotional_Media:
          return 'Promotional Media';
        case Slider.Promotional_Shoutout:
          return 'Promotional Shoutout';
        case Slider.DigitalDownloadFile:
          return 'Digital Downloads';
        case Slider.Questions:
          return 'Questions';
        case Slider.Schedule:
          return 'Schedule';
      }
    }
  };
  const getSliderBackLink = () => {
    if (slider === 'schedule') {
      if (step === '1') {
        return { URL: '/my-profile/members', HEADER: 'Scheduled Messages' };
      } else if (step === '2') {
        return {
          URL: '/my-profile/sliders?slider=schedule&step=1',
          HEADER: 'Scheduled Messages',
        };
      }

      if (activeView === 'left' || !isMobile) {
        return {
          URL: '/my-profile/members-content',
          HEADER: 'Scheduled Posts / Messages',
        };
      } else if (activeView === 'right') {
        return {
          URL: '/my-profile/sliders?slider=schedule',
          HEADER: 'Scheduled Messages',
        };
      }
    } else if (slider === 'Membership-Type') {
      return { URL: '/my-profile/members', HEADER: 'Membership Options' };
    } else if (slider === 'subscriber') {
      return { URL: '/my-profile/members', HEADER: 'Subscribers' };
    }
  };

  const Menu = () => (
    <ul className="navbar-nav">
      <li className="nav-item">
        <NavLink
          activeClassName="is-active"
          to="/my-profile"
          className="nav-link"
        >
          Profile
        </NavLink>
        <div className={classNames({ 'is-active': true })}>
          <Switch>
            {slider !== 'subscriber' && (
              <Route
                path="/my-profile/sliders"
                render={() => (
                  <HeaderWithOption
                    onClick={(e) => {
                      if (slider === 'schedule' && isMobile) {
                        e.preventDefault();
                        showLeftView();
                        const url = getSliderBackLink()?.URL;
                        dispatch(setSelectedView());
                        dispatch(removeSelectedPost());
                        url && history.push(url);
                      }
                    }}
                    backUrl={getSliderBackLink()?.URL}
                    title={getSliderBackLink()?.HEADER}
                  />
                )}
              />
            )}
            <Route
              path="/my-profile/services"
              render={() => (
                <HeaderWithOption
                  backUrl={'/my-profile'}
                  title={'Links & Services'}
                />
              )}
            />
            {levelStep && (
              <Route
                path="/my-profile/member-levels"
                render={() => (
                  <HeaderWithOption
                    backUrl={'/my-profile/member-levels'}
                    title={`Level ${
                      variantId
                        ? user?.links
                            .find(
                              (l: any) =>
                                l?.popLinksId?.popType ===
                                ServiceType.CHAT_SUBSCRIPTION,
                            )
                            ?.popLinksId?.priceVariations?.findIndex(
                              (f: PriceVariant) => f?._id === variantId,
                            ) + 1
                        : user?.links.find(
                            (l: any) =>
                              l?.popLinksId?.popType ===
                              ServiceType.CHAT_SUBSCRIPTION,
                          )?.popLinksId?.priceVariations?.length + 1
                    }: Membership`}
                  />
                )}
              />
            )}
            <Route path="/my-profile/sliders">
              <HeaderWithOption
                onClick={(e) => {
                  if (slider === 'schedule' && isMobile) {
                    e.preventDefault();
                    showLeftView();
                    const url = getSliderBackLink()?.URL;
                    dispatch(setSelectedView());
                    dispatch(removeSelectedPost());
                    url && history.push(url);
                  }
                }}
                backUrl={getSliderBackLink()?.URL}
                title={getSliderBackLink()?.HEADER}
              />
            </Route>
            {/* <Route path="/my-profile/member-levels?step=1" exact>
              <HeaderWithOption
                backUrl={`/my-profile/member-levels`}
                title="Membership"
              />
            </Route> */}
            <Route path="/my-profile/pop-list" exact>
              <HeaderWithOption
                backUrl={`/my-profile/services`}
                title="Add A New Pop"
              />
            </Route>
            <Route path="/my-profile/link" exact>
              <HeaderWithOption
                backUrl={`/my-profile/pop-list?type=${
                  type === 'service' ? 'services' : 'links'
                }`}
                title={getHeaderTitle(type as string, subType as string)}
              />
            </Route>
            <Route path="/my-profile/link/subType/:section" exact>
              <HeaderWithOption
                backUrl={URL}
                title={getHeaderTitle(
                  type as string,
                  subType as string,
                  slider as string,
                )}
              />
            </Route>
            {(subType === ServiceType.ADDITIONAL_SERVICES ||
              subType === ServiceType.ADVERTISE ||
              subType === ServiceType.DIGITAL_DOWNLOADS ||
              subType === ServiceType.PAYMA ||
              subType === ServiceType.POPLIVE ||
              subType === ServiceType.POP_COURSE ||
              subType === ServiceType.SHOUTOUT ||
              subType === ServiceType.CHAT_SUBSCRIPTION ||
              section === 'links') && (
              <Route path="/my-profile/link/:id" exact>
                <HeaderWithOption
                  backUrl={`/my-profile/services`}
                  title={
                    section
                      ? 'Link'
                      : getHeaderTitle(type as string, subType as string)
                  }
                />
              </Route>
            )}
            <Route path="/my-profile/link/:id/subType/:section" exact>
              <HeaderWithOption
                backUrl={URL}
                title={
                  subType === 'pop-course'
                    ? headerTitle
                    : getHeaderTitle(
                        type as string,
                        subType as string,
                        slider as string,
                      )
                }
              />
            </Route>
            <Route path="/my-profile/my-members">
              <HeaderWithOption
                backUrl={'/my-profile/members'}
                title={`Back to Member List`}
              />
            </Route>
            <Route path="/my-profile/theme-editor" exact>
              <HeaderWithOption
                backUrl="/my-profile/themes-listing"
                title={themeId ? 'Edit Theme' : 'Add Theme'}
              />
            </Route>
            {/* <Route path="/my-profile/services" exact>
              <HeaderWithOption
                backUrl={'/my-profile'}
                title={`Services & Links`}
              />
            </Route> */}

            <Route path="/my-profile/themes-listing" exact>
              <HeaderWithOption
                backUrl={'/my-profile'}
                // title={`${pathname.split('/')[2]}`}
                title={`Themes`}
              />
            </Route>

            <Route path="/my-profile/analytics" exact>
              <HeaderWithOption backUrl={'/my-profile'} title={`Analytics`} />
            </Route>
            <Route path="/my-profile">
              <SubNav
                items={[
                  {
                    name: 'Profile',
                    url: '/my-profile',
                    exact: true,
                    id: 'links-nav',
                  },
                  {
                    name: 'Sales',
                    url: '/my-profile/my-sales',
                    exact: true,
                    isHidden: !user?.allowSelling,
                    id: 'my-profile-orders',
                  },
                  {
                    name: 'Member Levels',
                    url: '/my-profile/member-levels',
                    exact: true,
                    id: 'membership-type-nav',
                    isHidden: !user?.enableMembershipFunctionality,
                  },
                  {
                    name: 'Member Content',
                    url: '/my-profile/members-content',
                    exact: true,
                    id: 'bio-editor-nav',
                    isHidden: !user?.enableMembershipFunctionality,
                  },
                  {
                    isHidden: !user?.enableMembershipFunctionality,
                    name: 'Members',
                    url: '/my-profile/subscriber',
                    exact: true,
                    id: 'subscribers-nav',
                  },
                ]}
              />
            </Route>
          </Switch>
        </div>

        <TwoPanelRightSideSubHeader user={user} />
      </li>
      {user?.showPurchaseMenu && (
        <li className="nav-item">
          <NavLink
            activeClassName="is-active"
            to="/my-purchases"
            className="nav-link"
          >
            Purchases <span className="counter bg-blue">4</span>
          </NavLink>
        </li>
      )}
      {!!subscription && (
        <li className="nav-item">
          <NavLink
            activeClassName="is-active"
            to="/my-subscriptions"
            className="nav-link"
          >
            Subscriptions{' '}
            <span className="counter bg-blue show">
              {/* <span className="counter subscriptons_counter"> */}
              {subscription}
            </span>
            {/* </span> */}
          </NavLink>
        </li>
      )}
      {user?.isAffiliate && (
        <li className="nav-item">
          <NavLink
            activeClassName="is-active"
            to="/account/inner-circle"
            className="nav-link"
            exact
          >
            Inner Circle
          </NavLink>
        </li>
      )}
      {!!courses?.length && user?.enableCourseModule && (
        <li className="nav-item">
          <NavLink
            activeClassName="is-active"
            to="/account/inner-circle"
            className="nav-link"
            exact
          >
            Courses
            <span className="counter bg-blue show">{courses?.length}</span>
          </NavLink>
        </li>
      )}
      <li className="nav-item">
        <NavLink
          activeClassName="is-active"
          to="/account"
          className="nav-link"
          isActive={(match, location) => {
            if (!match) {
              return false;
            }
            return location.pathname !== '/account/inner-circle';
          }}
        >
          My Account
        </NavLink>
        {pathname !== '/account/inner-circle' && (
          <SubNav
            items={[
              {
                name: 'Settings',
                url: '/account',
                exact: true,
              },
              {
                name: 'Billing',
                url: '/account/billing',
                exact: true,
                isHidden: !user.showPurchaseMenu,
              },
              {
                name: 'Wallet',
                url: '/account/payments',
                exact: true,
              },
              {
                name: 'Social Accounts',
                url: '/account/social-accounts',
                exact: true,
              },
            ]}
          />
        )}
      </li>

      {user?.isAcctManager && (
        <li className="nav-item">
          <NavLink
            activeClassName="is-active"
            to="/managed-accounts"
            className="nav-link"
          >
            Managed Accounts
          </NavLink>
          <div className={classNames({ 'is-active': true })}>
            <Switch>
              <Route path="/managed-accounts/:id/rules/:ruleId">
                <RemoveSubHeader />
                {/* <HeaderWithOption backUrl={backUrl} title={title} /> */}
              </Route>
              <Route path="/managed-accounts/:id">
                <HeaderWithOption backUrl={backUrl} title={title} />
              </Route>
            </Switch>
          </div>

          <Route path="/managed-accounts/:id/subscribers">
            <TwoPanelRightSideSubHeader user={managedUser} />
          </Route>
        </li>
      )}
    </ul>
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const rootelem: any = document.getElementById('root');
    if (noSubHeader.includes(pathname) || !showHeaderMenu) {
      if (!rootelem?.classList.contains('isactive-sub_menu')) {
        rootelem.classList.add('isactive-sub_menu');
      }
    } else {
      if (rootelem?.classList.contains('isactive-sub_menu')) {
        rootelem.classList.remove('isactive-sub_menu');
      }
    }
  }, [pathname]);

  if (!props.user) {
    return <div />;
  }

  return (
    <Header id="header">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <strong className="logo">
          <Link to={showHeaderMenu ? '/my-profile' : '#'}>
            <LogoUpdated />
          </Link>
        </strong>
        {showHeaderMenu && (
          <>
            <nav className="navbar navbar-expand-md flex-grow-1">
              <button
                className={classNames('navbar-toggler ml-auto', {
                  isActive: show,
                })}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setshow((state: Boolean) => !state)}
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                {!isDesktop && !noSubHeader.includes(pathname) && (
                  <ProfileLinkHeader user={user} />
                )}
                <Menu />
                <div className="user-login-box ml-md-auto user-loggedin">
                  <AvatarDropDown
                    user={props.user}
                    handleLogout={handleLogout}
                  />
                </div>
              </div>

              <UnmountClosed
                isOpened={show}
                theme={{
                  collapse: 'ReactCollapse--collapse  navbar-collapse',
                  content: 'ReactCollapse--content custom-collapse-nav-content',
                }}
                style={{ position: 'absolute' }}
              >
                {/* <Menu /> */}
                <div className="user-login-box ml-md-auto user-loggedin">
                  <AvatarDropDown
                    user={props.user}
                    handleLogout={handleLogout}
                    alwaysOpen={true}
                  />
                </div>
              </UnmountClosed>
            </nav>
          </>
        )}{' '}
      </div>
    </Header>
  );
}
