import { ArrowBack, ArrowRightAlt, LockIcon } from 'assets/svgs';
import attrAccept from 'attr-accept';
import { Card } from 'components/PrivatePageComponents';
import { toast } from 'components/toaster';
import FileUploadReduxHoc from 'components/UploadWidget/FileUploadReduxHoc';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { PostTierTypes } from 'enums';
import { useFormik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useAuth from 'hooks/useAuth';
import useControllTwopanelLayoutView from 'hooks/useControllTwopanelLayoutView';
import { isEmpty } from 'lodash';
import AttachmentContainer from 'pages/chat/components/AttachmentContainer';
import { useCallback, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { onFileUploadingCancelled, onRemoveGroup } from 'store/reducer/files';
import {
  addEditPendingMessage,
  addPendingMessage,
  getUserMemberships,
  removeFromPendingMessage,
  removeMediaFromPendingMessage,
  removeSelectedPost,
  updatePendingMessage,
} from 'store/reducer/salesState';
import styled from 'styled-components';
import { MediaType } from 'types/ChatSubscription';
import { IPost, IPostMembershipAccessTypes } from 'types/Sales';
import {
  appendScreenSizesToId,
  createPendingMessage,
  getAudioDuration,
  getDuration,
  isValidUrl,
} from 'util/index';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import { ImagesScreenSizes } from '../../constants';
import { PaidMessage, StandardMessage } from './components/Message';
import WritePost from './components/WritePost';

dayjs.extend(utc);
type CreatePostTabProps = {
  className?: string;
  selectedPost?: Partial<IPost>;
  selectedTime?: any;
  onClick?: () => void;
  onValidate?: () => boolean;
  showBackButton?: boolean;
  editPost?: boolean;
  changeSubmitLabel?: string;
  schedulePost?: boolean;
  showForwardButton?: boolean;
  setActiveView?: Function;
  getMemberships?: boolean;
  ImageSizes?: string[];
  onChange?: (values: any) => void;
  onFilesChange?: (files: any) => void;
  actions?: boolean;
  text?: string;
  pendingEditMessages?: any;
  view?: 'add' | 'edit';
  membershipAccessType?: IPostMembershipAccessTypes[];
  files?: MediaType[];
  managedAccountId?: string;
  hasTemplate?: boolean;
  onSubmit?: (...args: any) => void | Promise<any>;
};

const BackArrow = styled.div`
  /* position: absolute; */
  /* left: -80%; */
  cursor: pointer;
  transition: all 0.3ms ease;
  &:hover {
    color: #255b87;
  }
`;
const ForwardArrow = styled.div`
  /* position: absolute; */
  /* left: -80%; */

  text-align: right;
  cursor: pointer;
  transition: all 0.3ms ease;
  padding: 15px 15px 0 0;
  &:hover {
    color: #255b87;
  }
  svg {
    path {
      fill: black;
    }
  }
`;

let messagekey = uuid();

function CreatePost(props: CreatePostTabProps) {
  const {
    className,
    pendingEditMessages = [],
    setActiveView,
    selectedPost,
    showBackButton = true,
    editPost = false,
    hasTemplate = true,
    changeSubmitLabel = undefined,
    showForwardButton = false,
    getMemberships = false,
    onValidate,
    ImageSizes: sizes = ImagesScreenSizes.post,
    onChange: onChangeCallback,
    onFilesChange: onFilesChangeCallback,
    actions = true,
    text = '',
    membershipAccessType,
    files = [],
    managedAccountId,
    onSubmit: onSubmitCallback,
  } = props;
  const [type, setMessageType] = useState('paid');
  const { user } = useAuth();
  const [showPoll, setShowPoll] = useState<boolean>(false);
  const { showRightView } = useControllTwopanelLayoutView();
  const uploadingGroup = useAppSelector((state) => state.fileGroups);
  const pendingMessages = useAppSelector(
    (state) => state.mysales.pendingMessages,
  );

  const dispatch = useAppDispatch();
  const handleClick = (type: string) => {
    setMessageType(type);
    type !== 'paid' && showPoll && setShowPoll(false);
  };
  const validationSchema = yup.object().shape({
    media: yup.array(),
    membershipAccessType: yup.array(),
  });

  const form: any = useFormik({
    validationSchema,
    initialValues: {
      text: !isEmpty(selectedPost) ? selectedPost?.postText : '',
      media: [],
      membershipAccessType: !isEmpty(selectedPost)
        ? selectedPost?.membershipAccessType
        : [],
      postDate: dayjs().utc().format(),
      poll: {},
    },
    onSubmit: async (values) => {
      if (onSubmitCallback) {
        return await onSubmitCallback?.(values, form);
      }
    },
  });

  const {
    setFieldValue,
    handleSubmit,
    setSubmitting,
    values,
    setValues,
    isSubmitting,
  } = form;
  useEffect(() => {
    if (getMemberships) {
      dispatch(
        getUserMemberships({
          customError: { statusCodes: [404] },
          params: { sellerId: managedAccountId },
        }),
      ).catch((e) => console.log(e));
    }

    return () => {
      dispatch(removeSelectedPost());
    };
  }, []);

  useEffect(() => {
    if (text) {
      setFieldValue('text', text);
    }
  }, [text]);

  useEffect(() => {
    if (files?.length > 0) {
      setFieldValue('media', files);
    }
  }, [files]);

  useEffect(() => {
    onChangeCallback?.(values);
  }, [values]);

  useEffect(() => {
    if (!selectedPost?._id) {
      setValues((value: any) => {
        return {
          text: text || '',
          media: files as any,
          membershipAccessType: [],
          postDate: dayjs().utc().format(),
          poll: {},
        };
      });
    }
    if (selectedPost?._id) {
      setValues((value: any) => {
        return {
          ...value,
          ...selectedPost,
          text: selectedPost?.postText,
          media: selectedPost?.media,
        };
      });
      onFilesChangeCallback?.(selectedPost?.media);
    }
  }, [selectedPost?._id]);
  messagekey = (values as any)?._id || messagekey;
  const getComponent = (
    type: string,
    onChange: Function,
    files: any,
    updateFormFiles?: boolean,
  ) => {
    if (type === 'standard' && !!files.length)
      return (
        <StandardMessage
          openinGallery={true}
          onFileChange={(files) => {
            if (selectedPost?._id || updateFormFiles) {
              setFieldValue(
                'media',
                files
                  // ?.filter((f) => !!f?._id)
                  ?.map((f: any) => {
                    const { ...rest } = f;
                    return rest;
                  }),
              );
            }

            let newFiles: any[] = files || [];
            if (newFiles?.length) {
              newFiles = files.map((f: any) => {
                const newFl = { ...f };
                if (!f?._id && !isValidUrl(f?.path)) {
                  const { file } = appendScreenSizesToId({
                    id: f?.id,
                    rotateAll: f?.rotate,
                    sizes,
                    userId: user?._id,
                    file: newFl.orignalFile,
                    createpathagain: true,
                  });

                  newFl.orignalFile = file || newFl.orignalFile;
                  return { ...newFl, isNew: true };
                }
                return f;
              });
            }
            onFilesChangeCallback?.(newFiles);
            onChange(files) as any;
          }}
          files={files}
        />
      );

    if (type === 'paid' && !!files.length)
      return (
        <PaidMessage
          openinGallery={true}
          onFileChange={(files) => {
            if (selectedPost?._id || updateFormFiles) {
              setFieldValue(
                'media',
                files
                  // ?.filter((f) => !!f?._id)
                  ?.map((f: any) => {
                    const { ...rest } = { ...f, checkrotation: true };
                    return rest;
                  }),
              );
            }
            let newFiles: any[] = files || [];
            if (newFiles?.length) {
              newFiles = files.map((f: any) => {
                const newFl = { ...f };
                if (!f?._id && !isValidUrl(f?.path)) {
                  const { file } = appendScreenSizesToId({
                    id: f?.id,
                    sizes,
                    rotateAll: f?.rotate,
                    userId: user?._id,
                    file: newFl.orignalFile,
                    createpathagain: true,
                  });

                  newFl.orignalFile = file || newFl.orignalFile;
                  return { ...newFl, isNew: true };
                }
                return f;
              });
            }
            onFilesChangeCallback?.(newFiles);
            onChange(newFiles) as any;
          }}
          files={files}
        />
      );
    return null;
  };
  const getMediaCompo = useCallback(() => {
    const pendingMessage =
      pendingEditMessages?.length > 0 ? pendingEditMessages : pendingMessages;
    const messages = pendingMessage.find((p: any) => p._id === messagekey);
    if (messages?.messageMedia) {
      return (
        <div className="galleryMediaUploadinig">
          {messages?.messageMedia?.map((m: any) => {
            return (
              <AttachmentContainer
                key={m.id || m._id}
                media={m}
                ImageSizesProps={{
                  onlyMobile: true,
                }}
                showOptions={{
                  closeIcon: true,
                  edit: false,
                  play: attrAccept({ type: m.type }, 'video/*'),
                  video: attrAccept({ type: m.type }, 'video/*'),
                  timeStampText: false,
                  showprogress: true,
                }}
                filekey={'id'}
                onClose={removeFile}
                icon={m.islocK ? <LockIcon /> : undefined}
              />
            );
          })}
        </div>
      );
    }
    return null;
  }, [pendingEditMessages, pendingMessages]);

  const removeFile = (id: string) => {
    const pendingMessage =
      pendingEditMessages?.length > 0 ? pendingEditMessages : pendingMessages;
    const messages = pendingMessage.find((p: any) => p._id === messagekey);
    if (messages?.messageMedia) {
      const file = messages?.messageMedia?.find((f: any) => f._id === id);

      file && (file as any)?.requestToken?.cancel('file uploading cancelled');
      file && dispatch(removeMediaFromPendingMessage({ id }));
      file &&
        (file as any)?.status === 'COMPLETED' &&
        dispatch(onFileUploadingCancelled({ groupId: messagekey, fileId: id }));
    }
    const media = [...values.media];
    if (media.length) {
      const newFiles = media.filter((f) => f.id !== id);
      setFieldValue('media', newFiles);
    }
  };

  useEffect(() => {
    async function sendMed() {
      const PendingMessage =
        pendingEditMessages?.length > 0 ? pendingEditMessages : pendingMessages;
      for (let index = 0; index < PendingMessage.length; index++) {
        const message = PendingMessage[index];
        const group = uploadingGroup[message._id!];
        if (group) {
          let isSent = false;
          if (group.status === 'COMPLETED' && !message.isSent) {
            let files = [];
            for (let j = 0; j < group.files.length; j++) {
              const f = group.files[j];
              let timeDuration = f.videoDuration;
              let duration = f.duration;
              if (!timeDuration && attrAccept({ type: f.type }, 'video/*')) {
                try {
                  const data: any = await getDuration(f as any);
                  timeDuration = data?.timeDuration || undefined;
                  duration = data?.duration || undefined;
                } catch (error) {
                  console.log({ error });
                }
              }
              if (!timeDuration && attrAccept({ type: f.type }, 'audio/*')) {
                try {
                  const data: any = await getAudioDuration(f as any);
                  timeDuration = data?.timeDuration || undefined;
                  duration = data.duration || undefined;
                } catch (error) {
                  console.log({ error });
                }
              }
              const file: any = {
                duration: duration,
                _id: f.id,
                id: f.id,
                imageURL: f.imageURL,
                isTrusted: f.isTrusted,
                islocK: f.islocK,
                size: f.size,
                url: f.url,
                type: f?.orignalFile?.type || f.type,
                path: f.url,
                thumbnail: f.thumbnail,
                isPaidType: f.isPaidType,
                name: f.name,
                videoDuration: timeDuration,
                updatedAt: f.updatedAt || new Date().getTime() + '',
              };

              if (f.rotate !== undefined && !f.isNew) {
                const isRotateable =
                  (((f.rotate || 0) % 360) + 360) % 360 !== 0;
                if (isRotateable) {
                  file.rotate = f.rotate;
                  file.updatedAt = new Date().getTime();
                }
              }

              files.push(file);
            }
            setFieldValue('media', files);
            handleSubmit();
            isSent = true;
          }
          dispatch(
            updatePendingMessage({
              ...message,
              isSent,
              messageMedia: message.messageMedia.map((media: any) => {
                const file = group.files.find((f) => f.id === media._id);
                console.log({ media });
                if (file) {
                  return {
                    ...media,
                    ...file,
                    path: group.status === 'COMPLETED' ? file?.url : media.path,
                  };
                }

                return media;
              }),
            }),
          );
          if (isSent || group.status === 'CANCELLED') {
            dispatch(
              removeFromPendingMessage({
                groupId: messagekey,
              }),
            );
            dispatch(
              onRemoveGroup({
                groupId: messagekey,
              }),
            );
          }
        }
      }
    }
    if (!isEmpty(uploadingGroup)) sendMed();
  }, [uploadingGroup]);

  return (
    <div className={className}>
      {showBackButton && (
        <BackArrow
          className="mb-15"
          onClick={() => {
            setActiveView?.('left');
          }}
        >
          <ArrowBack /> <strong className="ml-5">Back</strong>
        </BackArrow>
      )}
      {showForwardButton && isMobileOnly && (
        <ForwardArrow
          className="mb-15"
          onClick={() => {
            showRightView();
          }}
        >
          <strong className="mr-5">See Posts</strong>
          <ArrowRightAlt />
        </ForwardArrow>
      )}

      <Card>
        {getMediaCompo()}
        <FileUploadReduxHoc files={values?.media}>
          {(files, onChange, { onSubmit }) => {
            const fls = files;

            const submitHandler = (message?: string, price?: number) => {
              if (fls?.length === 0) {
                handleSubmit();
                return;
              }
              if (selectedPost?._id) {
                const { media = [], ...rest } = values || {};

                if (editPost) {
                  dispatch(
                    addEditPendingMessage({
                      ...rest,
                      isSent: false,
                      messageMedia: [
                        ...fls?.map((f) => {
                          const { file } = appendScreenSizesToId({
                            id: f?.id,
                            sizes,
                            userId: user?._id,
                            rotateAll: f.rotate,
                            createpathagain: true,
                            file: f.orignalFile,
                          });

                          return {
                            orignalFile: file,
                            createdAt: dayjs().format(),
                            isPaidType: f.isPaidType,
                            islock: f.islock,
                            name: f.name,
                            path: f.path,

                            thumbnail: f.thumb,
                            type: f.type,
                            _id: f.id,
                            videoDuration: f.videoDuration,
                          };
                        }),
                      ],
                      _id: messagekey,
                    }),
                  );
                } else {
                  dispatch(
                    addPendingMessage({
                      ...rest,
                      isSent: false,
                      messageMedia: [
                        ...fls?.map((f) => {
                          const { file } = appendScreenSizesToId({
                            id: f?.id,
                            sizes,
                            userId: user?._id,
                            rotateAll: f.rotate,
                            createpathagain: true,
                            file: f.orignalFile,
                          });

                          return {
                            orignalFile: file,
                            createdAt: dayjs().format(),
                            isPaidType: f.isPaidType,
                            islock: f.islock,
                            name: f.name,
                            path: f.path,

                            thumbnail: f.thumb,
                            type: f.type,
                            _id: f.id,
                            videoDuration: f.videoDuration,
                          };
                        }),
                      ],
                      _id: messagekey,
                    }),
                  );
                }
              } else {
                dispatch(
                  addPendingMessage(
                    createPendingMessage({
                      isPaidType: true,
                      messageMedia: fls?.map((f) => {
                        const { file } = appendScreenSizesToId({
                          id: f?.id,
                          sizes,
                          rotateAll: f.rotate,
                          createpathagain: true,
                          userId: user?._id,
                          file: f.orignalFile,
                        });

                        return {
                          orignalFile: file || f.orignalFile,
                          createdAt: dayjs().format(),
                          isPaidType: f.isPaidType,
                          islock: f.islock,
                          name: f.name,
                          path: f.path,
                          thumbnail: f.thumb,
                          type: f.type,
                          _id: f.id,
                          videoDuration: f.videoDuration,
                        };
                      }),
                      messageType: 'SIMPLE',
                      messageValue: message,
                      price,
                      _id: messagekey,
                    }),
                  ),
                );
              }
              onSubmit({
                key: messagekey,
                onCompletedCallback: (uploadedFiles: any) => {},
              });
              onChange([]);
              setMessageType('');
            };

            return (
              <>
                {getComponent(type, onChange, [...fls], !!props.files?.length)}
                <WritePost
                  hasTemplate={hasTemplate}
                  changeSubmitLabel={changeSubmitLabel}
                  membershipAccessType={
                    membershipAccessType ?? selectedPost?.membershipAccessType
                  }
                  isDisabled={isSubmitting}
                  setShowPoll={setShowPoll}
                  form={form}
                  files={fls}
                  actions={actions}
                  managedAccountId={managedAccountId}
                  onFileChanges={(files, type) => {
                    let newFiles: any[] = files || [];
                    if (newFiles?.length) {
                      newFiles = files.map((f) => {
                        const newFi = { ...f };
                        if (
                          !f?._id &&
                          !isValidUrl(f?.path) &&
                          newFi?.orignalFile
                        ) {
                          const { file } = appendScreenSizesToId({
                            id: f?.id,
                            sizes,
                            userId: user?._id,
                            file: newFi?.orignalFile,
                            rotateAll: f.rotate,
                            createpathagain: true,
                          });

                          newFi.orignalFile = file || newFi.orignalFile;
                          return { ...newFi, isNew: true };
                        }
                        return f;
                      });
                    }
                    onChange(newFiles);
                    onFilesChangeCallback?.(newFiles);
                    handleClick(type);
                  }}
                  messageKey={messagekey}
                  isPaid={type === 'paid'}
                  onPublish={(data) => {
                    if (
                      values.membershipAccessType?.some(
                        (mem: any) =>
                          mem.accessType === PostTierTypes.pay_to_view,
                      ) &&
                      !fls?.length
                    ) {
                      toast.info(
                        'At least one media is required for paid post.',
                      );
                      return;
                    }
                    if (onValidate) {
                      if (onValidate()) {
                        setSubmitting(true);
                        submitHandler();
                      }
                      return;
                    }
                    setSubmitting(true);
                    submitHandler();
                  }}
                />
              </>
            );
          }}
        </FileUploadReduxHoc>
      </Card>
    </div>
  );
}

export default styled(CreatePost)`
  .galleryMediaUploadinig {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .audio_thumbnail {
      position: absolute;
    }
  }
`;
