import {
  Account,
  CreditCard,
  CustomAgent,
  Edit,
  IconChat,
  Logout,
  ScheduleMessages,
  SocialIcon,
  Star,
  Stars,
  SubscriptionIcon,
  TickStar,
  Wallet,
} from 'assets/svgs';
import Image from 'components/Image';
import { ENVIRONMENT } from 'config';
import { ImagesSizes, ServiceType } from 'enums';
import { useAppSelector } from 'hooks/useAppSelector';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getImageURL } from 'util/index';
import useAuth from '../../../hooks/useAuth';
import useDropDown from '../../../hooks/useDropDown';

const UserDropDown = styled.div`
  display: none;

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .dropdown-toggle {
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .dropdown-toggle:after {
    display: none;
  }

  .dropdown-menu {
    margin-top: 2px;
  }

  .user-name {
    font-size: 18px;
    font-weight: 500;
    margin: 0 10px 0 0;
    color: #fff;
  }

  .user-name em {
    font-weight: 400;
    font-style: normal;
  }

  .user-img {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid #dcdcdc;
  }

  .user-img img {
    width: 43px;
    height: 43px;
    border-radius: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 10px 15px;

    .dropdown-toggle {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }

    .dropdown-menu {
      position: static;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
      width: 100%;
      float: none;
    }

    .user-name {
      margin: 0;
      width: 100%;
      display: block;
      text-align: center;
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      color: #fff;
    }

    .user-img {
      display: block;
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }
  }
`;
let sizes = [ImagesSizes['64x64']];
const AvatarDropDown = ({ user, handleLogout, alwaysOpen }: any) => {
  const { ref, isVisible } = useDropDown();
  const { user: fullUser } = useAuth();

  let isV = isVisible;
  if (alwaysOpen) {
    isV = alwaysOpen;
  }
  const { subscription } = useAppSelector((state) => state.counter);
  const courses = user?.links?.filter(
    (link: any) => link.popLinksId?.popType === ServiceType.POP_COURSE,
  );
  const { url: profileImageUrl, fallbackUrl: profileFallback } = getImageURL(
    user.profileImage,
    {
      defaultUrl: '/assets/images/default-profile-pic.png',
      onlyMobile: true,

      imgix: {
        all: sizes[0],
      },
    },
  );
  return (
    <>
      <UserDropDown className="dropdown user-dropdown">
        <span
          ref={ref}
          className="dropdown-toggle"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="user-name">
            {/* <em>@</em> */}
            {user.firstName || ''}&nbsp;
            {user.lastName || ''}
          </span>
          <span
            className="user-img"
            style={{
              background: '#255b87',
            }}
          >
            <Image
              src={profileImageUrl || '/assets/images/default-profile-pic.png'}
              alt="img description"
              fallbackUrl={
                user.profileImage || '/assets/images/default-profile-pic.png'
              }
            />
          </span>
        </span>
        <div
          className={`dropdown-menu ${isV && 'show'}`}
          aria-labelledby="dropdownMenuButton"
        >
          <Link to={`/${user.username}`} className="dropdown-item">
            {' '}
            <span className="icon">
              <Star secondaryColor="transparent" primaryColor="#255B87" />
            </span>
            My Pop Page
          </Link>
          <Link to={'/my-profile'} className="dropdown-item">
            <span className="icon">
              <Edit />
            </span>
            Edit Pop Page
          </Link>

          {!!subscription && (
            <Link to={'/my-subscriptions'} className="dropdown-item">
              <span className="icon">
                <SubscriptionIcon />
              </span>
              Subscriptions
            </Link>
          )}
          {!!courses?.length && user?.enableCourseModule && (
            <Link to={'/my-courses'} className="dropdown-item">
              <span className="icon">
                <Edit />
              </span>
              Course
            </Link>
          )}
          {fullUser?.showSellerMenu && (
            <Link to={'/my-profile/my-sales'} className="dropdown-item">
              {' '}
              <span className="icon">
                <TickStar />
              </span>
              My Sales
            </Link>
          )}
          {/* {fullUser?.showSellerMenu && (
            <Link to={'/gifts'} className="dropdown-item">
              <span className="icon">
                <Gift />
              </span>
              Gifts
            </Link>
          )} */}
          {user.showPurchaseMenu && (
            <Link to={'/my-purchases'} className="dropdown-item">
              <span className="icon">
                <TickStar />
              </span>
              My Purchases
            </Link>
          )}
          <Link to={'/account/payments'} className="dropdown-item">
            {' '}
            <span className="icon">
              <Wallet />
            </span>
            My Wallet
          </Link>
          <Link to={'/account/billing'} className="dropdown-item">
            {' '}
            <span className="icon">
              <CreditCard />
            </span>
            My Payment Method
          </Link>
          {user.isAffiliate && (
            <Link to={'/account/inner-circle'} className="dropdown-item">
              <span className="icon">
                <Stars />
              </span>
              Inner Circle
            </Link>
          )}

          <Link to={'/support'} className="dropdown-item">
            <span className="icon">
              <CustomAgent />
            </span>
            Support
          </Link>

          <Link to={'/account'} className="dropdown-item">
            <span className="icon">
              <Account />
            </span>
            My Account
          </Link>
          <Link to={'/account/social-accounts'} className="dropdown-item">
            <span className="icon">
              <SocialIcon />
            </span>
            Social Accounts
          </Link>
          {user?.isAcctManager && (
            <Link to={'/managed-accounts'} className="dropdown-item">
              <span className="icon">
                <Stars />
              </span>
              Managed Accounts
            </Link>
          )}
          {ENVIRONMENT === 'development' && (
            <>
              {user.allowSelling && (
                <Link to={'/schedule-messaging'} className="dropdown-item">
                  <span className="icon">
                    <ScheduleMessages />
                  </span>
                  Schedule Messaging
                </Link>
              )}
              <Link to={'/chat'} className="dropdown-item">
                <span className="icon">
                  <IconChat />
                </span>
                Chat
              </Link>{' '}
            </>
          )}
          <span onClick={handleLogout} className="dropdown-item log-out">
            <span className="icon">
              <Logout />
            </span>{' '}
            Log Out
          </span>
        </div>
      </UserDropDown>
    </>
  );
};

export default AvatarDropDown;
