import { updateUserFeatures } from 'api/Utils';
import MessageToast from 'components/MessageToast';
import OnBoardingTour from 'components/OnboardingTour';
import Header from 'components/partials/header';
import SpecialNotification from 'components/specific-notification';
import WelcomeModal from 'components/WelcomeModals/WelcomeModal';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useAuth from 'hooks/useAuth';
import useControllTwopanelLayoutView from 'hooks/useControllTwopanelLayoutView';
import useDispatch from 'hooks/useDispatch';
import useSelector from 'hooks/useSelector';
import useSocket from 'hooks/useSocket';
import React, { useEffect, useState } from 'react';
import { BrowserView, isMobileOnly } from 'react-device-detect';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  SET_EMAIL_NOTIFICATION_CLOSED_BY_USER,
  SET_WELCOMEMODEL_STATE,
} from 'reducer/appStates';
import {
  updateLastMessage,
  updateSubscriptionUnreadCount,
} from 'store/reducer/chat';
import { incrementUnreadMessageCount } from 'store/reducer/counter';
import { removePop, removePreviewPop } from 'store/reducer/course';
import {
  addLiveCommentLikeToPost,
  addLiveCommentToPost,
  addLiveCommentUnLikeToPost,
  addLiveLikeToPost,
  addLiveReplyToComment,
  addLiveTipsToPost,
  addLiveunLikeToPost,
  getSubItem,
  updateScheduledPost,
  updateUserLastMessage,
  updateUsersUnreadCount,
} from 'store/reducer/salesState';
import {
  updatePublishMessage,
  updateSchedulePost,
} from 'store/reducer/scheduledMessaging';
import { setSupportChatCount } from 'store/reducer/support';
import {
  getLocalStorage,
  onboardingSequency,
  parseQuery,
  removeLocalStorage,
} from 'util/index';
interface ISelfiePopProps {
  showHeaderMenu?: boolean;
}
const Users = {
  buyer: 'BUYER',
  seller: 'SELLER',
};
const SelfiePopLayout: React.FC<ISelfiePopProps> = (props) => {
  const { showHeaderMenu = true } = props;
  const { user, setUser } = useAuth();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { subscription, slider } = parseQuery(search);
  const dispatch = useDispatch();
  const previewPop = useAppSelector((state) => state.course.previewPop);
  const appDispatch = useAppDispatch();
  const { loading, showWelcomeModel, emailNotificationClosedByUser } =
    useSelector((state) => state.appStates);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(
    getLocalStorage('showWelcomeModal', false) === 'true',
  );
  const { totalTicketsComments } = useAppSelector((s) => s.support);
  const { activeView } = useControllTwopanelLayoutView();
  const selectedSub = useAppSelector(
    (state) => state.chat.selectedSubscription,
  );
  const isChatActive = useAppSelector(
    (state) => state.mysales.isChatWindowActive,
  );
  const { socket } = useSocket();
  // const dispatch = useDispatch();

  const [notification, setNotification] = useState<boolean>(false);

  // Expample how to use Dispatch just like request
  // useEffect(() => {
  //   dispatch({ type: SET_LOADING, payload: true });
  // }, []);

  const isOnboarding = matchPath(history.location.pathname, {
    path: '/onboarding',
    exact: false,
    strict: false,
  });

  const isChat = matchPath(history.location.pathname, {
    path: '/chat',
    exact: true,
    strict: false,
  });
  const isMyPurchases = matchPath(history.location.pathname, {
    path: '/my-subscriptions',
    exact: true,
    strict: false,
  });
  let isMyMembersSection = matchPath(history.location.pathname, {
    path: '/my-profile/subscriber',
    exact: true,
    strict: false,
  });

  const isMyMember = matchPath(history.location.pathname, {
    path: '/my-profile/subscriber',
    exact: true,
    strict: false,
  });
  // const isMySales = matchPath(history.location.pathname, {
  //   path: '/my-sales',
  //   exact: true,
  //   strict: false,
  // });
  const linkServices = matchPath(history.location.pathname, {
    path: '/profile/link',
    exact: false,
    strict: false,
  });

  const showMessageToast = (data: any) => {
    const isSeller = data.chat.sentFrom !== 'BUYER';
    const isPaymentComplete = !data.isPaymentComplete;
    toast(
      <MessageToast
        message={data.chat}
        user={data.user}
        eventType={data.type}
        className="toast-chat"
      />,
      {
        className: 'message-toast',
        closeOnClick: true,
        onClick: () => {
          if (!isPaymentComplete) {
            return history.push(
              `/my-profile/subscriber?userId=${data.user._id}`,
            );
          } else if (isSeller) {
            return history.push(
              `/my-subscriptions?userId=${data.user._id}&type=chat`,
            );
          } else {
            // return history.push(`/my-sales?userId=${data.user._id}&type=chat`);
            return history.push(
              `/my-profile/subscriber?userId=${data.user._id}`,
            );
          }
          // history.push(`/chat?subscription=${data.chat.subscriptionId}`),
        },
      },
    );
  };
  const checkUsersInList = (
    subId: string,
    userId: string,
    type: string,
    popType: string,
  ) => {
    if (userId) {
      appDispatch(getSubItem({ userId, type, popType, subId }));
    }
  };
  let isMyMembers = isMyMembersSection;
  useEffect(() => {
    user?._id && updateUserFeatures(user, setUser);
  }, [user]);
  useEffect(() => {
    if (!linkServices) {
      const mypop = getLocalStorage('pop', true);

      if (previewPop?._id || mypop?._id) {
        setTimeout(() => {
          dispatch(removePop());
          dispatch(removePreviewPop());
        }, 500);
      }
    }

    if (user?._id) {
      if (!user.skipOnBoarding) {
        let redirect = '';
        if (onboardingSequency[user?.userSetupStatus]) {
          redirect = onboardingSequency[user?.userSetupStatus];
        } else if (isOnboarding) {
          redirect = '/';
        }

        if (redirect) {
          history.push(redirect);
          return;
        }
      } else if (isOnboarding) {
        history.push('/');
        return;
      }
    }
  }, [history.location.pathname]);

  // useEffect(() => {
  //   !totalTicketsComments && appDispatch(getSupportTicketCount());
  // }, []);

  useEffect(() => {
    if (user?._id) {
      if (
        user.userSetupStatus > 9 &&
        !user?.isEmailVerified &&
        !emailNotificationClosedByUser
      ) {
        setNotification(true);
      }
      user?.isSupportAgent &&
        socket?.on('support_agent', (data: any) => {
          if (data.type === 'tickets_count') {
            appDispatch(
              setSupportChatCount({ totalComments: data.total || 0 }),
            );
          }
        });
      socket?.on(user._id, (data: any) => {
        if (data?.type === 'scheduled') {
          return appDispatch(updatePublishMessage(data?.messageData));
        }
        if (data.type === 'post-like' && !isMyPurchases) {
          if (data.user?._id !== user._id) appDispatch(addLiveLikeToPost(data));
          return;
        }
        if (
          data.type === 'post-create' &&
          data.post?.userId === user._id &&
          data.post?.postType === 'scheduled'
        ) {
          appDispatch(updateScheduledPost(data));
          appDispatch(updateSchedulePost(data?.post));
        }
        if (data.type === 'comment' && !isMyPurchases) {
          if (data.comment?.userId?._id !== user._id)
            appDispatch(addLiveCommentToPost(data));
          return;
        }

        if (data.type === 'post-unlike') {
          if (data.user?._id !== user._id)
            appDispatch(addLiveunLikeToPost(data));
          return;
        }
        if (data.type === 'comment-unlike' && !isMyPurchases) {
          if (data.unlikedBy !== user._id)
            appDispatch(addLiveCommentUnLikeToPost(data));
          return;
        }
        if (data.type === 'tip' && !isMyPurchases) {
          if (data.tip?.userId !== user._id)
            appDispatch(addLiveTipsToPost(data));
          return;
        }
        if (data.type === 'comment-like' && !isMyPurchases) {
          if (data.likedBy !== user._id)
            appDispatch(addLiveCommentLikeToPost(data));
          return;
        }
        if (data.type === 'reply-comment' && !isMyPurchases) {
          if (data.comment?.userId?._id !== user._id)
            appDispatch(addLiveReplyToComment(data));
          return;
        }
        if (data.type === 'paymentComplete') {
          if (
            !isChat &&
            // !isMySales &&
            !isMyMembers &&
            !isMyPurchases

            // ||
            // subscription !== data.chat.subscriptionId
          ) {
            showMessageToast({ ...data, isPaymentComplete: true });
          }
        }
        if (data.type === 'tickets_count' && !user?.isSupportAgent) {
          appDispatch(setSupportChatCount({ totalComments: data.total || 0 }));
          return;
        }

        if (data.type === 'chat') {
          if (data?.user?._id === user._id) {
            return;
          }
          if (
            data.isNotification &&
            // (
            !isChat &&
            // !isMySales &&
            // !isMyMembers &&
            !isMyPurchases &&
            // &&
            !isMyMembers &&
            // ||
            //   subscription !== data.chat.subscriptionId)
            data?.user?._id !== user._id
          ) {
            (!isChatActive ||
              selectedSub?._id !== data?.chat?.subscriptionId) &&
              showMessageToast(data);
            appDispatch(incrementUnreadMessageCount());
            // return;
          }
          // if (
          //   // (!isChat && !isMySales && !isMyPurchases) ||
          //   (!isChat && !isMyMembers && !isMyPurchases) ||
          //   selectedSub?._id !== data.chat.subscriptionId
          // ) {

          //   checkUsersInList(data.user._id);
          // }
          else if (
            ((isMyMembers && data.chat.sentFrom === Users.buyer) ||
              (isMyPurchases && data.chat.sentFrom === Users.seller)) &&
            selectedSub?._id !== data.chat.subscriptionId
          ) {
            let userId = data.user._id;
            const sentFrom =
              data.chat.sentFrom === Users.seller ? 'buyer' : 'seller';
            checkUsersInList(
              data.chat.subscriptionId,
              userId,
              sentFrom,
              'chat-subscription',
            );
          }
          if (
            selectedSub?._id !== data.chat.subscriptionId ||
            (selectedSub?._id === data.chat.subscriptionId && !isChatActive)
          ) {
            appDispatch(
              updateSubscriptionUnreadCount({
                subscriptionId: data.chat.subscriptionId,
              }),
            );

            appDispatch(
              updateUsersUnreadCount({
                subscriptionId: data.chat.subscriptionId,
                isChatWindowActive: isChatActive,
              }),
            );
          }
          // if (subscription !== data.chat.subscriptionId) {
          //   appDispatch(
          //     updateSubscriptionUnreadCount({
          //       subscriptionId: data.chat.subscriptionId,
          //     }),
          //   );
          //   appDispatch(
          //     updateUsersUnreadCount({
          //       subscriptionId: data.chat.subscriptionId,
          //     }),
          //   );
          // }

          appDispatch(
            updateLastMessage({
              id: data.chat.subscriptionId,
              message: data.chat,
            }),
          );
          appDispatch(
            updateUserLastMessage({
              id: data.chat.subscriptionId,
              message: data.chat,
            }),
          );
        }
      });
    }

    return () => {
      socket?.off(user._id);
      socket?.off('support_agent');
    };
  }, [user, socket, subscription, selectedSub, isChatActive]);

  const OBT = getLocalStorage('onBoardingTour', false) === 'true';
  const isMyProfile = matchPath(history.location.pathname, {
    path: '/my-profile',
    strict: false,
  });
  const isThemeListing = matchPath(history.location.pathname, {
    path: '/my-profile/themes-listing',
    exact: true,
    strict: false,
  });

  const themePreviewMode =
    pathname.startsWith('/theme-library/preview') ||
    (isMobileOnly && isThemeListing && activeView === 'right');

  return (
    <>
      <BrowserView>{OBT && isMyProfile && <OnBoardingTour />}</BrowserView>
      {user && showHeaderMenu && notification && !themePreviewMode && (
        <SpecialNotification
          isOpen={notification}
          user={user}
          onClose={() => {
            dispatch({
              type: SET_EMAIL_NOTIFICATION_CLOSED_BY_USER,
              payload: true,
            });
            setNotification(false);
          }}
        />
      )}
      <div id="wrapper" className="d-flex flex-column min-vh-100">
        {!themePreviewMode && (
          <Header user={user} showHeaderMenu={showHeaderMenu} />
        )}
        <main id="main" className="flex-grow-1">
          {props.children}
        </main>
      </div>
      <WelcomeModal
        isOpen={isWelcomeModalOpen || showWelcomeModel}
        user={user}
        onClose={() => {
          setIsWelcomeModalOpen(false);
          removeLocalStorage('showWelcomeModal');
          dispatch({
            type: SET_WELCOMEMODEL_STATE,
            payload: false,
          });
        }}
      />
    </>
  );
};

export default SelfiePopLayout;
