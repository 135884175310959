import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Loader from 'components/loader';
import {
  ENVIRONMENT,
  GOOGLE_TRACK,
  LOG_ROCKET_APP_ID,
  SENTRY_DNS,
  VERSION,
} from 'config';
import AppGlobalStatesProvider from 'context/AppGlobalStates';
import AuthProvider from 'context/Auth';
import TwoPanelLayoutProvider from 'context/TwoPanelLayout';
import WebSocketProvider from 'context/WebSocket';
import { createBrowserHistory } from 'history';
import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import reactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppRoutes from 'routes';
import Theme from 'theme';
import { getLocalStorage } from './util';

const history = createBrowserHistory();
const Routes = () => {
  return <AppRoutes />;
};

Sentry.init({
  dsn: SENTRY_DNS,
  release: `selfiepop-react@${VERSION}`,
  integrations: [
    new Integrations.BrowserTracing({
      startTransactionOnLocationChange: true,
      startTransactionOnPageLoad: true,
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1.0,
  maxBreadcrumbs: 80,
  environment: ENVIRONMENT,
  beforeSend(event) {
    if (!event.user) {
      const user = getLocalStorage('user');
      const updatedUser = {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
      };
      event.user = updatedUser;
    }
    return event;
  },
});

if (ENVIRONMENT === 'development') {
  LogRocket.init(`${LOG_ROCKET_APP_ID}`, {
    release: VERSION,
  });
}
console.error = (function (_error) {
  return function (message: any) {
    if (
      typeof message !== 'string' ||
      message.indexOf('component is `contentEditable`') === -1
    ) {
      _error.apply(console, arguments as any);
    }
  };
})(console.error);
function App() {
  const location = useLocation();
  useEffect(() => {
    reactGA.initialize(GOOGLE_TRACK);
  }, []);
  useEffect(() => {
    reactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      <Theme />
      <WebSocketProvider>
        <CookiesProvider>
          <AppGlobalStatesProvider>
            <AuthProvider>
              <TwoPanelLayoutProvider>
                <Routes />
                <Loader />
              </TwoPanelLayoutProvider>
            </AuthProvider>
          </AppGlobalStatesProvider>
        </CookiesProvider>
      </WebSocketProvider>
      <ToastContainer />
    </>
  );
}

export default Sentry.withProfiler(App);
