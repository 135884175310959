import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as services from 'api/managed-users';

const modelName = 'managed-users';
type T = any;

export interface IState {
  loading: boolean;
  list: T[];
  item?: T;
}

const initialState: IState = {
  list: [],
  loading: false,
  item: undefined,
};

export const getManagedUsers = createAsyncThunk<T[]>(
  `${modelName}/getManagedUsers`,
  async () => {
    const res = await services.getManagedUsers();
    return res.data;
  },
);

export const getManagedUserDetails = createAsyncThunk<T[], { userId: string }>(
  `${modelName}/getManagedUserDetails`,
  async ({ userId }) => {
    const res = await services.getManagedUserDetails(userId);
    return res;
  },
);

export const slice = createSlice({
  name: modelName,
  initialState,
  reducers: {
    setManagedUser: (state, action) => {
      state.item = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManagedUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getManagedUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(getManagedUsers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getManagedUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getManagedUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.item = action.payload;
      })
      .addCase(getManagedUserDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setManagedUser } = slice.actions;

export default slice.reducer;
