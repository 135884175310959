import { Spinner } from 'assets/svgs';
import { WhatsAppLoader } from 'components/loader';
import Scrollbar from 'components/Scrollbar';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useAuth from 'hooks/useAuth';
import React, { useEffect } from 'react';
import {
  removePendingMessage,
  sendMedia,
  updatePendingMessage,
} from 'store/reducer/chat';
import { getAudioDuration, getDuration } from 'util/index';
import MessageBox from './ChatWidget/Message';
interface Props {
  scrollbarRef?: any;
  onAttachmentClick?(
    attachment: {
      name?: string;
      type?: string;
      path?: string;
    }[],
  ): void;
  buyPaidMessage(
    subId: string,
    messageId: string,
    viewBy: 'BUYER' | 'SELLER',
  ): Promise<void>;
  handleScroll?: (...args: any) => void;
  managedAccountId?: string;
}
const MessagesContainer: React.FC<Props> = (props) => {
  const {
    scrollbarRef,
    managedAccountId,
    onAttachmentClick,
    buyPaidMessage,
    handleScroll,
  } = props;
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const messages = useAppSelector((state) => state.chat.messages);
  const isFetchingMessages = useAppSelector(
    (state) => state.chat.isFetchingMessages,
  );
  const selectedSubscription = useAppSelector(
    (state) => state.chat.selectedSubscription,
  );
  const uploadingGroup = useAppSelector((s) => s.fileGroups);
  const pendingMessages = useAppSelector((state) => state.chat.pendingMessages);
  let viewBy: 'SELLER' | 'BUYER';
  if (managedAccountId) {
    viewBy = 'SELLER';
  } else {
    viewBy =
      user?._id === selectedSubscription?.sellerId._id ? 'SELLER' : 'BUYER';
  }
  useEffect(() => {
    const isVid = /(video)\/(.*)/gm;
    const isAud = /(audio)\/(.*)/gm;
    async function sendMed() {
      for (let index = 0; index < pendingMessages.length; index++) {
        const message = pendingMessages[index];
        const group = uploadingGroup[message._id!];
        console.log({ group });
        if (group) {
          let isSent = false;
          if (
            group.status === 'COMPLETED' &&
            // selectedSubscription &&
            !message.isSent
          ) {
            let files = [];
            for (let j = 0; j < group.files.length; j++) {
              const f = group.files[j];
              let timeDuration = f.videoDuration;
              let duration = f.duration;
              if (!timeDuration && isVid.test(f.type)) {
                try {
                  const data: any = await getDuration(f as any);
                  timeDuration = data?.timeDuration || undefined;
                  duration = data?.duration || undefined;
                } catch (error) {
                  console.log({ error });
                }
              }
              if (!timeDuration && isAud.test(f.type)) {
                try {
                  const data: any = await getAudioDuration(f as any);
                  timeDuration = data?.timeDuration || undefined;
                  duration = data.duration || undefined;
                } catch (error) {
                  console.log({ error });
                }
              }
              const file = {
                type: f.type,
                path: f.path ? f.path : f.url,
                thumbnail: f.thumbnail,
                isPaidType: f.isPaidType,
                name: group.files[j].name,
                videoDuration: timeDuration,
                duration,
                blurThumnail: f.isPaidType ? f?.blurThumnail : undefined,
              };
              files.push(file);
            }
            dispatch(
              removePendingMessage({
                _id: message._id,
              }),
            );
            dispatch(
              sendMedia({
                subscriptionId: message.subscriptionId,
                data: {
                  price: message.price,
                  message: message?.messageValue,
                  blurThumnail: files?.[0]?.isPaidType
                    ? files?.[0]?.blurThumnail
                    : undefined,
                  library: files,
                  sellerId: managedAccountId,
                },
              }),
            );
            isSent = true;
          }
          dispatch(
            updatePendingMessage({
              ...message,
              isSent,
              messageMedia: message.messageMedia.map((media) => {
                const file = group.files.find((f) => f.id === media._id);
                if (file) {
                  return {
                    ...media,
                    ...file,
                    path:
                      group.status === 'COMPLETED'
                        ? file?.path
                          ? file.path
                          : file.url
                        : media.path,
                  };
                }

                return media;
              }),
            }),
          );
        }
      }
    }
    sendMed();
  }, [uploadingGroup]);
  return (
    <>
      <Scrollbar onScrollStop={handleScroll} scrollRef={scrollbarRef}>
        <div className="messages-container">
          {isFetchingMessages && (
            <WhatsAppLoader>
              <Spinner width="28px" height="28px" color="#255b87" />
            </WhatsAppLoader>
          )}

          {messages.items?.map((message) => {
            const { messageMedia, ...rest } = message;
            let newMessageMedia = messageMedia;
            newMessageMedia = messageMedia.map((m: any) => {
              const newFile = { ...m };
              return newFile;
            });
            return (
              <MessageBox
                key={message._id}
                medias={messageMedia || []}
                message={{ ...rest, messageMedia: newMessageMedia }}
                orientation={message.sentFrom === viewBy ? 'right' : 'left'}
                image={
                  managedAccountId
                    ? '/assets/images/default-profile-img.svg'
                    : message.sentFrom === 'SELLER'
                    ? selectedSubscription?.sellerId?.profileImage
                    : selectedSubscription?.buyerId?.profileImage
                }
                onAttachmentClick={(a: any) => {
                  onAttachmentClick?.(message.messageMedia || []);
                }}
                viewBy={viewBy}
                buyPaidMessage={buyPaidMessage}
                managedAccountId={managedAccountId}
              />
            );
          })}
          {pendingMessages
            ?.filter((m) => m.subscriptionId === selectedSubscription?._id)
            ?.map((message) => {
              return (
                <MessageBox
                  showActions={false}
                  key={message._id}
                  message={message}
                  orientation={message.sentFrom === viewBy ? 'right' : 'left'}
                  image={
                    managedAccountId
                      ? '/assets/images/default-profile-img.svg'
                      : message.sentFrom === 'SELLER'
                      ? selectedSubscription?.sellerId?.profileImage
                      : selectedSubscription?.buyerId?.profileImage
                  }
                  viewBy={viewBy}
                  buyPaidMessage={buyPaidMessage}
                  managedAccountId={managedAccountId}
                />
              );
            })}
        </div>
      </Scrollbar>
    </>
  );
};

export default MessagesContainer;
